
import Vue from "vue";
import { debounce } from "throttle-debounce";
import registerLeaseService from "@/services/portal/registerLeaseService";

import { LesseeResult } from "@/types/portal/Lease";

export default Vue.extend({
  name: "AddLesseeModal",
  data: function () {
    return {
      showDialog: this.$props.lesseeDialog,
      hasSearched: false,
      searchResults: [] as any[],
      selectedLessees: [...this.$props.lesseeInfo],
      membershipID: "",
      headers: [
        { text: "Name", align: "left", value: "name" },
        { text: "Membership ID", align: "left", value: "memberId" },
      ],
    };
  },
  watch: {
    lesseeDialog: function (newVal: boolean) {
      if (!newVal) {
        this.membershipID = "";
        this.hasSearched = false;
        this.searchResults = [] as LesseeResult[];
      }
      this.showDialog = newVal;
    },
    showDialog: function (newVal: boolean) {
      //this is handling situation when the user closes dialog
      //by clicking outside of it
      if (!newVal) {
        this.membershipID = "";
        this.hasSearched = false;
        this.searchResults = [] as LesseeResult[];
        (this.selectedLessees = [...this.$props.lesseeInfo]),
          this.toggleLesseeModal();
      }
    },
    membershipID: {
      handler: debounce(
        1000,
        function (this: any, newVal: string, oldVal: string) {
          if (newVal === oldVal || !newVal) return;
          this.search();
        }
      ),
      deep: true,
    },
  },
  methods: {
    search: function () {
      this.hasSearched = false;
      registerLeaseService
        .searchLessee(this.membershipID)
        .then((response: LesseeResult[]) => {
          this.hasSearched = true;
          this.searchResults = response;
        });
    },
     isValidCoOwner(coowner: LesseeResult) {
      return coowner.canBeCoowner;
    },
    addLessee(row: LesseeResult) {
      if (!row.canBeCoowner) return;
      if (this.selectedLessees.length >= 6) return;
      if (this.selectedLessees.some((el) => el.personId === row.personId))
        return;
      this.searchResults = [] as LesseeResult[];
      this.hasSearched = false;
      this.membershipID = "";
      let item = {
        firstName: row.firstName,
        lastName: row.lastName,
        memberId: row.memberId,
        personId: row.personId,
        isPrimary: false,
      };
      this.selectedLessees.push(item);
    },
    removeLessee(row: LesseeResult) {
      this.selectedLessees.splice(this.selectedLessees.indexOf(row), 1);
      this.changeLesseeInfo(this.selectedLessees);
    },
    submit() {
      this.changeLesseeInfo(this.selectedLessees);
      this.showDialog = false;
    },
  },
  props: {
    changeLesseeInfo: Function,
    lesseeDialog: Boolean,
    toggleLesseeModal: Function,
    lesseeInfo: Array,
  },
});
