import { api } from "./api";

export const getDogsAttributes = async (): Promise<object> => {
    const { data } = await api.get<object>(`/Lookups/dog`);
    return data;
}

export const getPuppyAttributes = async (): Promise<object> => {
    const { data } = await api.get<object>(`/Lookups/puppy`);
    return data;
}

export const getDnaStatuses = async (): Promise<object> => {
    const { data } = await api.get<object>(`/Lookups/dnastatuses`);
    return data;
}


export default {
    getDogsAttributes,
    getPuppyAttributes,
    getDnaStatuses
}