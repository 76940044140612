import { api } from "../api";

import { PortalUserInfo } from '@/types/admin/User';
import { AdminPersonInfo } from '@/types/admin/Person';
import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";

export const getPeopleList = async (
    options: PaginationOptions,
    params: object,
): Promise<PaginatedResponse<object>> => {  
    const { page, itemsPerPage, sortBy, sortDesc } = options;

    const sort = !!sortBy.length ? sortBy[0] : 'requestId';
    const asce = !!sortDesc.length ? !sortDesc[0] : true;

    const { data } = await api.get<PaginatedResponse<object>>(`/Admin/people?pageSize=${itemsPerPage}&page=${page}&ascending=${asce}&sort=${sort}`, { params });

    return data;
};

export const getPersonById = async (personId: Number): Promise<AdminPersonInfo> => {
    const { data } = await api.get<AdminPersonInfo>(`/Admin/people/details/${personId}`);

    return data;
};

export const getPersonByJudgeId = async (judgeId: String): Promise<AdminPersonInfo> => {
    const { data } = await api.get<AdminPersonInfo>(`/Admin/people/judge/${judgeId}`);

    return data;
};

export const editPersonAddNote = async (request: any): Promise<object> => {
    const { data } = await api.post<object>(`/Admin/people/details/note`, request);
    return data;
};

export const editPersonById = async (request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/people/details/edit`, request);
    return data;
};

export const validatePersonEdit = async (request: AdminPersonInfo): Promise<object> => {
    const { data } = await api.post<object>(`/Admin/people/details/validate`, request);
    return data;
};

export const getPortalUserById = async (personId: Number): Promise<PortalUserInfo> => {
    const { data } = await api.get<PortalUserInfo>(`/Admin/portaluser/${personId}`);

    return data;
};

export const changePortalUserRole = async (personId: Number): Promise<PortalUserInfo> => {
    const { data } = await api.get<PortalUserInfo>(`/Admin/portaluser/changerole/${personId}`);

    return data;
};

export default {
    getPeopleList,
    getPersonById,
    editPersonById,
    getPersonByJudgeId,
    validatePersonEdit,
    editPersonAddNote,
    getPortalUserById,
    changePortalUserRole,
};