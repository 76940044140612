
import Vue from "vue";
import CoownersList, { OwnershipType } from "@/components/common/CoownersList.vue";

export default Vue.extend({
  name: "ChangeKennelOwners",
  components: {
    CoownersList,
  },
  props: {
    registration: { type: Object },
  },
  computed: {
    kennelOwnershipType: function () {
      return OwnershipType.Kennel;
    },
  },
});
