import { api } from "../api";

export const getTrackingTitlesForADog = async (dogId: string): Promise<object[]> => {    
    const { data } = await api.get<object[]>(`/AdminTitling/dogs/tracking?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};

export const getRallyTitlesForADog = async (dogId: string): Promise<object[]> => {    
    const { data } = await api.get<object[]>(`/AdminTitling/dogs/rally?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};

export const getObedienceTitlesForADog = async (dogId: string): Promise<object[]> => {    
    const { data } = await api.get<object[]>(`/AdminTitling/dogs/obedience?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};

export const getAgilityTitlesForADog = async (dogId: string): Promise<object[]> => {    
    const { data } = await api.get<object[]>(`/AdminTitling/dogs/agility?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};

export const getStockTitlesForADog = async (dogId: string): Promise<object[]> => {
    const { data } = await api.get<object[]>(`/AdminTitling/dogs/stock?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};

export const getConformationTitlesForADog = async (dogId: string): Promise<object[]> => {
    const { data } = await api.get<object[]>(`/AdminTitling/dogs/conformation?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};

export const getSourceCertificatesForADogSpecialty = async (dogId: string): Promise<object[]> => { 
    const { data } = await api.get<object[]>(`/AdminTitling/dogs/certificates/SP/source?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};

export const getCertificatesForADog = async (dogId: string, category: string): Promise<object[]> => { 
    const { data } = await api.get<object[]>(`/AdminTitling/dogs/certificates?dogId=${encodeURIComponent(dogId.trim())}&categories=${category}`);

    return data;
};

export const getShowResultsForADog = async (dogId: string, category: string): Promise<object[]> => { 
    const { data } = await api.get<object[]>(`/AdminTitling/showresults/dogs?dogId=${encodeURIComponent(dogId.trim())}&categories=${category}`);

    return data;
};
export const getDogsWithUnprocessedResults = async (program: string): Promise<object[]> => { 
    const { data } = await api.get<object[]>(`/AdminTitling/dogs/${program}`);

    return data;
};

export const processShowResults = async (program:string, requesterId: number) : Promise<object[]> => {
    const { data } = await api.post<object[]>(`/AdminTitling/dogs/${program}?requesterId=${requesterId}`);

    return data;
}

export const getCertGenerationList = async () : Promise<object[]> => {
    const { data } = await api.get<object[]>(`/AdminTitling/certgeneration`);

    return data;
}
export const getCertGenerationDogsList = async (executionId: number) : Promise<object[]> => {
    const { data } = await api.get<object[]>(`/AdminTitling/certgeneration/${executionId}`);

    return data;
}

export const approveShowResults = async (executionId: number, requesterId: number) : Promise<object[]> => {
    const { data } = await api.post<object[]>(`/AdminTitling/certgeneration/${executionId}/approve/${requesterId}`);

    return data;
}

export const markCertGenerationPrinted = async (executionId: number, requesterId: number) : Promise<object[]> => {
    const { data } = await api.post<object[]>(`/AdminTitling/certgeneration/${executionId}/print/${requesterId}`);

    return data;
}

export const markUnprintedCertsPrinted = async (certType: string) : Promise<any> => {
    const { data } = await api.post<any>(`/AdminTitling/certgeneration/${certType}/markprinted`);

    return data;
}

export const getGeneratedNameForDog = async (dogId: string): Promise<object> => {
    const { data } = await api.get<object>(`/AdminTitling/dogs/generatedname?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};

export const getNameForDog = async (dogId: string): Promise<object> => {
    const { data } = await api.get<object>(`/AdminTitling/dogs/name?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};

export const getRanchTrialTitlesForADog = async (dogId: string): Promise<object[]> => {
    const { data } = await api.get<object[]>(`/AdminTitling/dogs/ranchtrial?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};

export const getFarmTrialTitlesForADog = async (dogId: string): Promise<object[]> => {
    const { data } = await api.get<object[]>(`/AdminTitling/dogs/farmtrial?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};

export const getSpecialtyTitlesForADog = async (dogId: string): Promise<object[]> => {
    const { data } = await api.get<object[]>(`/AdminTitling/dogs/specialty?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};

export const transferUnusedPointsToACE = async (dogId: string, unusedPoints: any[], resultsSelectedForTransfer: any[]): Promise<any> => {
    var requestData = {
        dogId,
        unusedPoints,
        resultsSelectedForTransfer
    };

    const { data } = await api.post<any>(`/AdminTitling/agility/transferToACE?dogId=${encodeURIComponent(dogId.trim())}`, requestData);

    return data;
};

export const untransferUnusedPointsFromACE = async (dogId: string): Promise<any> => {
    const { data } = await api.post<any>(`/AdminTitling/agility/untransferFromACE?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};

export const updatePrefixSuffix = async (prefix: string, suffix: string, dogId: string): Promise<any> => {
    const { data } = await api.post<any>(`/Admin/dogs/updatePrefixSuffix`, { prefix, suffix, dogId });

    return data;
}

export const applyUtilityResultsToTitle = async (dogId: string, titlePath: string, resultsSelected: any[]): Promise<any> => {
    var requestData = {
        dogId,
        titlePath,
        resultsSelected
    };

    const { data } = await api.post<any>(`/AdminTitling/obedience/applyUtilityResultsToTitle?dogId=${encodeURIComponent(dogId.trim())}`, requestData);

    return data;
};



export default {
    getTrackingTitlesForADog,
    getRallyTitlesForADog,
    getObedienceTitlesForADog,
    getAgilityTitlesForADog,
    getCertificatesForADog,
    getSourceCertificatesForADogSpecialty,
    getShowResultsForADog,
    getDogsWithUnprocessedResults,
    processShowResults,
    approveShowResults,
    markCertGenerationPrinted,
    getStockTitlesForADog,
    getConformationTitlesForADog,
    getCertGenerationList,
    getCertGenerationDogsList,
    getGeneratedNameForDog,
    getNameForDog,
    getFarmTrialTitlesForADog,
    getRanchTrialTitlesForADog,
    getSpecialtyTitlesForADog,
    transferUnusedPointsToACE,
    untransferUnusedPointsFromACE,
    markUnprintedCertsPrinted,
    applyUtilityResultsToTitle,
};