import CommandResult from "@/types/CommandResult";
import DataResponse from "@/types/DataReponse";
import { AxiosResponse } from "axios";
import { api } from "./api";

export const getPuppyReleaseDetailsForSignature = async (signatureId: number) => {
  return await api.get<DataResponse<object>>(`/PuppyRelease/signature/${signatureId}`);
}

export const releasePuppy = async (
  puppyId: number,
  mayBreed: boolean,
  requireCoowner: boolean,
  kennels: number[],
  transferDate: string,
  firstName: string,
  lastName: string,
  email: string,
  signature: string
): Promise<any> => {
  try {
    const request = {
      puppyId,
      mayBreed,
      requireCoowner,
      kennels,
      transferDate,
      firstName,
      lastName,
      email,
      signature
    };
    const { data } = await api.post<object>(
      `/PuppyRelease/release`,
      request
    );
    return data;
  } catch (err) {
    console.error("error", err);
    return err;
  }
};

export const signPuppyRelease = async (signatureId: number, signatureText: String) => {
    const request = { signatureId, signatureText };
    return await api.post<object, AxiosResponse<CommandResult>>(`/PuppyRelease/signature`, request);
}

export default {
  getPuppyReleaseDetailsForSignature,
  releasePuppy,
  signPuppyRelease,
}