
import Vue from "vue";
import lookupsService from "@/services/lookupsService";

import FooterText from "@/components/common/FooterText.vue";
import DatepickerFormatted from '@/components/common/inputs/DatepickerFormatted.vue';

export default Vue.extend({
  name: "DogInformation",
  components: { FooterText, DatepickerFormatted },
  props: {
    changePage: Function,
    dogInfo: Object,
  },
  created: function() {
    this.reloadData();
  },
  data: function () {
    return {
      isValid: false,
      showResults: [] as any[],
      colors: {},
    }
  },
  methods: {
    reloadData: function() {
      lookupsService.getDogsAttributes().then((res) => {
        this.colors = res;
      });
    },
    updateBirthDate: function (val: any) {
      this.dogInfo.birthDate = val.date;
      //workaround to force rerendering after setting up formatted date
      this.$set(this, "showResults", this.showResults);
    },
    getIsValid() {
      return this.isValid && !!this.dogInfo.breed;
    }
  }
});
