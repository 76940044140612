
import Vue from "vue";
import { debounce } from "throttle-debounce";

import { UserInfo } from "@/types/User";
import registerQTService from "@/services/portal/registerQTService";

import { CoOwnerResult } from "@/types/portal/Registration";

export default Vue.extend({
  name: "AddCoOwnerModal",
  props: {
    changeCoOwnerInfo: Function,
    coOwnerDialog: Boolean,
    toggleCoOwnerModal: Function,
    coOwnerInfo: Array,
  },
  data: function () {
    return {
      showDialog: this.coOwnerDialog,
      hasSearched: false,
      searchResults: [] as CoOwnerResult[],
      selectedCoOwners: [...this.coOwnerInfo],
      membershipID: "",
      headers: [
        { text: "Name", align: "left", value: "name" },
        { text: "Member ID", align: "left", value: "memberId" },
      ],
    };
  },
  watch: {
    coOwnerDialog: function (newVal: boolean) {
      if (!newVal) {
        this.membershipID = "";
        this.hasSearched = false;
        this.searchResults = [] as CoOwnerResult[];
      }
      this.showDialog = newVal;
    },
    showDialog: function (newVal: boolean) {
      //this is handling situation when the user closes dialog
      //by clicking outside of it
      if (!newVal) {
        this.membershipID = "";
        this.hasSearched = false;
        this.searchResults = [] as CoOwnerResult[];
        (this.selectedCoOwners = [...this.coOwnerInfo]),
          this.toggleCoOwnerModal();
      }
    },
    membershipID: {
      handler: debounce(
        1000,
        function (this: any, newVal: string, oldVal: string) {
          if (newVal === oldVal || !newVal) return;
          this.search();
        }
      ),
      deep: true,
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    search: function () {
      this.hasSearched = false;
      registerQTService
        .searchCoOwner(this.membershipID)
        .then((response: CoOwnerResult[]) => {
          this.hasSearched = true;
          this.searchResults = response;
        });
    },
    isValidCoOwner(coowner: CoOwnerResult) {
      return coowner.canBeQTCoowner && coowner.personId != this.userInfo.personId;
    },
    addCoOwner(row: CoOwnerResult) {
      const found = this.selectedCoOwners.some(
        (el: any) => el.personId === row.personId
      );
      if (found) return;

      if (!this.isValidCoOwner(row)) return;
      this.searchResults = [] as CoOwnerResult[];
      this.hasSearched = false;
      this.membershipID = "";
      let item = {
        firstName: row.firstName,
        lastName: row.lastName,
        memberId: row.memberId,
        personId: row.personId,
        canBeQTCoowner: row.canBeQTCoowner,
      };
      this.selectedCoOwners.push(item);
    },
    removeCoOwner(row: CoOwnerResult) {
      this.selectedCoOwners.splice(this.selectedCoOwners.indexOf(row), 1);
    },
    submit() {
      this.changeCoOwnerInfo(this.selectedCoOwners);
      this.showDialog = false;
    },
  },
});
