
import Vue from 'vue';
import KennelSimilarNamesDialog from "@/components/Admin/KennelSimilarNamesDialog.vue";
import AdminCoownersList, { OwnershipType } from "@/components/Admin/AdminCoownersList.vue";
import RegistrationRequestLayout from "@/components/common/RegistrationRequestLayout.vue";
import workflowService from "@/services/admin/workflowService";

export default Vue.extend({
  name: 'KennelRegistrationCard',
  props: {
    requestId: { type: Number },
  },
  components: {
    RegistrationRequestLayout,
    KennelSimilarNamesDialog,
    AdminCoownersList,
  },
  data: function() {
    return {
      registration: {
        workflowGuid: "",
        requestId: 0,
        firstName: "",
        secondName: "",
        owners: []
      } as any,
      showDenialReasonDialog: false,
      searchResults: [],
      selectedName: "",
      processingRequest: false,
    };
  },
  computed: {
    workflowService() {
      return workflowService;
    },
    kennelOwnershipType: function() {
      return OwnershipType.Kennel;
    },
  },
  methods: {
    onSetRegistrationData(registration: any) {
      this.registration = registration;
      this.selectedName = registration.approvedName ?? registration.firstName;
    },
    onApproved(guid: string, requestId: number) {
      return workflowService.approveKennelRequest(guid, requestId, this.selectedName);
    },
    onRequestProcessed: function (requestId: number) {
      this.$router.push({ path: '/admin/registration/kennels' });
    },
  }
});
