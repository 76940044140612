
import Vue from 'vue';
import userService from "@/services/portal/userService";
import authService from "./services/authenticationService";
import jwt_decode from "jwt-decode";
import axios from 'axios';
import CookePrompt from '@/components/common/CookiePrompt.vue'
const default_layout = "default";

export default Vue.extend({
  name: 'App',
  data: () => ({
    tokenExpirationTimer: null as number | null,
  }),
  components: {
    CookePrompt,
  },
  methods: {
    userLoaded: function (args: any) {

      this.setTimer(args.detail.token)
      userService.getProfile().then(userInfo => {
        this.$store.commit('loadUserInfo', userInfo);
        if (userInfo.isAdmin) {
          this.$store.dispatch("loadAdminTasks", {});
        } else if (!userInfo.hasAgreedToPolicies) {
          this.$store.commit('changeAgreementFor', "ExistingAccount")
          this.$router.push('/agreements');
        } else if (userInfo.juniorHasToRegister) {
          this.$router.push('/expired-membership');
        } else if (!userInfo.lastPromptedForMembershipAt) {
          this.$router.push('/gain-membership');
        }
        this.$store.dispatch("loadPersonTasks", userInfo.personId);
      });
    },
    refreshToken: function () {
      authService.refresh()
        .then(d => {
          if (!d.token) return this.logoutUser()
          localStorage.setItem("userToken", d.token)
          this.setTimer(d.token)
        }).catch(e=> {
          this.logoutUser();
        })
    },
    logoutUser: function () {
      axios.defaults.headers.common["Authorization"] = null;
      localStorage.removeItem("userToken");
      this.$store.commit("loadUserInfo", null);
      this.$router.push('/login')
    }, 
    onExpiredToken: function () {
      const rememberMe = JSON.parse(localStorage.getItem("rememberMe")||"");
      if (!rememberMe) return this.logoutUser();
      this.refreshToken();
    },
    setTimer(token: string) {
      if (this.tokenExpirationTimer) clearInterval(this.tokenExpirationTimer) // just in case there's an existing timer running
      const decoded: any = jwt_decode(token);
      this.tokenExpirationTimer = setTimeout(this.onExpiredToken, (decoded.exp * 1000) - Date.now()) // refresh jwt when token expires
    },
  },
  computed: {
    layout() {
      return (this.$route.meta?.layout || default_layout) + '-layout';
    },
    showLoader() {
      return this.$store.state.showLoader;
    }
  },
  mounted() {
    window.addEventListener('userLoaded', this.userLoaded);

    const token = localStorage.getItem("userToken");
    if (token && !this.$store.state.userInfo) {
      const decoded: any = jwt_decode(token);
      const event = new CustomEvent("userLoaded", { detail: { personId: decoded.nameid, token: token } });
      window.dispatchEvent(event);
    }

  },
  destroyed() {
    window.removeEventListener('userLoaded', this.userLoaded)
    if (this.tokenExpirationTimer) clearInterval(this.tokenExpirationTimer)
  }
});
