
import Vue from "vue";
import ShowEntry from "@/components/Admin/Details/Shows/ShowEntry.vue";
import ShowProcessing from "@/components/Admin/Details/Shows/ShowProcessing.vue";
import RallyShowResultsEntry from "@/components/Admin/Details/Shows/RallyShowResultsEntry.vue";
import RanchTrialShowResultsEntry from "@/components/Admin/Details/Shows/RanchTrialShowResultsEntry.vue";
import FarmTrialShowResultsEntry from "@/components/Admin/Details/Shows/FarmTrialShowResultsEntry.vue";
import StockdogShowResultsEntry from "@/components/Admin/Details/Shows/StockdogShowResultsEntry.vue";
import AgilityShowResultsEntry from "@/components/Admin/Details/Shows/AgilityShowResultsEntry.vue";
import ConformationShowResultsEntry from "@/components/Admin/Details/Shows/ConformationShowResultsEntry.vue";
import ObedienceShowResultsEntry from "@/components/Admin/Details/Shows/ObedienceShowResultsEntry.vue";
import TrackingShowResultsEntry from "@/components/Admin/Details/Shows/TrackingShowResultsEntry.vue";    
import DefaultShowResultsEntry from "@/components/Admin/Details/Shows/DefaultShowResultsEntry.vue";
import StockResultSummaryEntry from "@/components/Admin/Details/Shows/StockResultsSummaryEntry.vue";
import ConformationShowResultsSummaryEntry from "@/components/Admin/Details/Shows/ConformationShowResultsSummaryEntry.vue";

import showService from "@/services/admin/showService";

export default Vue.extend({
  name: "ShowDetails",
  components: {
    ShowEntry,
    ShowProcessing,
    RallyShowResultsEntry,
    RanchTrialShowResultsEntry,
    FarmTrialShowResultsEntry,
    StockdogShowResultsEntry,
    AgilityShowResultsEntry,
    ConformationShowResultsEntry,
    ObedienceShowResultsEntry,
    TrackingShowResultsEntry,
    DefaultShowResultsEntry,
    StockResultSummaryEntry,
    ConformationShowResultsSummaryEntry,
  },
  data: function() {
    return {
      tab: null,
      showDetails: {
        showTypeId: null
      },
      stockResultsCategories: [] as any[],
      showInfo: {
        categories: [],
        divisions: [],
        judges: [],
        allowedDatesFrom: null,
        allowedDatesTo: null,
        programType: null
      },
    };
  },
  methods: {
    reloadData: function() {
      if (this.showId) {

        showService
          .getShowInfoForShowResults(this.showId)
          .then((res) => {
            this.showInfo = res; 
          })
          .catch((e) => console.log(e));
      }
    },
    reloadStockCategories: function() {
      const forCategories = ['ST', 'RT', 'FT'];
      const defaultCategory = 'ST';

      showService
        .getProgramShowResultCategories(forCategories.includes(this.programType)
          ? this.showInfo.programType as any
          : defaultCategory
        )
        .then((res) => {
          this.stockResultsCategories = res; 
        })
        .catch((e) => console.log(e));
    }
  },    
  created: function() {
    this.reloadData();
  },
  watch: {
    showId: function(newVal: String) {
      this.reloadData();
    },
    programType: function(newValue: any) {
      this.reloadStockCategories();
    }
  },
  props: {
    showId: { type: String },
  },
  computed: {
    programType: function(): any {
      return this.showInfo.programType;
    }
  },
});
