
import Vue from "vue";
import FooterText from "@/components/common/FooterText.vue";
import SimpleFeeTable from "@/components/common/SimpleFeeTable.vue";
import CoownersList, { OwnershipType } from "@/components/common/CoownersList.vue";
import AddLesseeModal from "@/components/common/AddLesseeModal.vue";

export default Vue.extend({
  components: { AddLesseeModal, SimpleFeeTable, FooterText, CoownersList },
  name: "LeaseRequestForm1",
  data: function () {
    return {
      rulesDialog: false,
      lesseeDialog: false,
    };
  },
  computed: {
    dogOwnershipType: function() {
      return OwnershipType.Dog;
    },
    leaseOwnershipType: function() {
      return OwnershipType.Lease;
    },
  },
  methods: {
    toggleRules: function () {
      this.$data.rulesDialog = !this.$data.rulesDialog;
    },
    toggleLesseeModal: function () {
      this.$data.lesseeDialog = !this.$data.lesseeDialog;
    },
  },
  props: {
    dogInfo: Object,
    changePage: Function,
    lesseeInfo: Array,
    changeLesseeInfo: Function,
    priceInfo: Object,
    primaryLessee: Number,
    changePrimaryLessee: Function,
  },
});
