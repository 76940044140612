import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";
import { api } from "../api";

enum WorkflowPath {
    DogRegistration = 'dogs/registrations',
    QTRegistration = 'qtdogs/registrations',
    KennelRegistration = 'kennels/registrations',
    KennelChangeOwners = 'kennels/owners/registrations',
    LitterRegistration = 'litters/registrations',
    LeaseRegistration = 'leases/registrations',
    AffiliateRegistration = 'affiliates/registrations',
    AffiliateRenewals = 'affiliates/renewals',
}

const getWorkflowsList = async (
    options: PaginationOptions,
    statuses: string[],
    path: WorkflowPath
): Promise<PaginatedResponse<Object>> => {
    const { page, itemsPerPage, sortBy, sortDesc } = options;

    const sort = !!sortBy.length ? sortBy[0] : 'requestId';
    const asce = !!sortDesc.length ? !sortDesc[0] : true;

    const params = statuses.join("&statuses=");
    const { data } = await api.get<PaginatedResponse<Object>>(`/Admin/${path}?pageSize=${itemsPerPage}&page=${page}&ascending=${asce}&sort=${sort}&statuses=${params}`);

    return data;
}

const getWorkflow = async (requestId: Number, path: WorkflowPath): Promise<any> => {
    const { data } = await api.get<object[]>(`/Admin/${path}/${requestId}`);
    return data;
}

const signWorkflowSignatureByAdmin = async (
    workflowGuid: String,
    signatureId: number,
    adminPersonId: number,
    adminNotes: String,
    path: WorkflowPath,
): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/${path}/signature`, {
        workflowGuid,
        signatureId,
        adminPersonId,
        adminNotes
    });

    return data;
};

export const markWorkflowPaidByAdmin = async (
    workflowGuid: String,
    adminPersonId: number,
    adminNotes: String,
    paymentType: number,
    path: WorkflowPath,
): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/${path}/payment`, {
        workflowGuid,
        adminPersonId,
        adminNotes,
        paymentType
    });

    return data;
};

export const approveWorkflow = async (workflowGuid: string, requestId: number, path: WorkflowPath): Promise<object> => {
    const { data } = await api.post<object>(`/Admin/${path}/${requestId}/approve`, { workflowGuid, requestId });
    return data;
};

export const denyWorkflow = async (workflowGuid: string, requestId: number, denialReason: string, path: WorkflowPath): Promise<object> => {
    const { data } = await api.post<object>(`/Admin/${path}/${requestId}/deny`, { workflowGuid, requestId, denialReason });
    return data;
};

// ---- LIST ----

export const getQTDogRegistrationsList = async (options: PaginationOptions, statuses: string[]): Promise<PaginatedResponse<Object>> =>
    await getWorkflowsList(options, statuses, WorkflowPath.QTRegistration);

export const getKennelRegistrationsList = async (options: PaginationOptions, statuses: string[]): Promise<PaginatedResponse<Object>> =>
    await getWorkflowsList(options, statuses, WorkflowPath.KennelRegistration);

export const getKennelChangeOwnerRequestsList = async (options: PaginationOptions, statuses: string[]): Promise<PaginatedResponse<Object>> =>
    await getWorkflowsList(options, statuses, WorkflowPath.KennelChangeOwners);

export const getLitterRegistrationsList = async (options: PaginationOptions, statuses: string[]): Promise<PaginatedResponse<Object>> =>
    await getWorkflowsList(options, statuses, WorkflowPath.LitterRegistration);

export const getLeaseRegistrationsList = async (options: PaginationOptions, statuses: string[]): Promise<PaginatedResponse<Object>> =>
    await getWorkflowsList(options, statuses, WorkflowPath.LeaseRegistration);

export const getCreateAffiliateRequestList = async (options: PaginationOptions, statuses: string[]): Promise<PaginatedResponse<Object>> =>
    await getWorkflowsList(options, statuses, WorkflowPath.AffiliateRegistration);

export const getRenewAffiliateRequestList = async (options: PaginationOptions, statuses: string[]): Promise<PaginatedResponse<Object>> =>
    await getWorkflowsList(options, statuses, WorkflowPath.AffiliateRenewals);

// ---- DETAILS ----

export const getQTDogRegistrationRequestById = async (requestId: Number): Promise<any> =>
    await getWorkflow(requestId, WorkflowPath.QTRegistration);

export const getKennelRegistrationRequestById = async (requestId: Number): Promise<any> =>
    await getWorkflow(requestId, WorkflowPath.KennelRegistration);

export const getKennelChangeOwnerRequestById = async (requestId: Number): Promise<any> =>
    await getWorkflow(requestId, WorkflowPath.KennelChangeOwners);

export const getLitterRegistrationById = async (requestId: Number): Promise<any> =>
    await getWorkflow(requestId, WorkflowPath.LitterRegistration);

export const getLeaseRegistrationById = async (requestId: Number): Promise<any> =>
    await getWorkflow(requestId, WorkflowPath.LeaseRegistration);

export const getCreateAffiliateRequestById = async (requestId: Number): Promise<any> =>
    await getWorkflow(requestId, WorkflowPath.AffiliateRegistration);

export const getRenewAffiliateRequestById = async (requestId: Number): Promise<any> =>
    await getWorkflow(requestId, WorkflowPath.AffiliateRenewals);

// ---- SIGNATURE ----

export const signDogRegistrationSignatureByAdmin = async (
    workflowGuid: String,
    signatureId: number,
    adminPersonId: number,
    adminNotes: String
): Promise<any> => await signWorkflowSignatureByAdmin(workflowGuid, signatureId, adminPersonId, adminNotes, WorkflowPath.DogRegistration);

export const signKennelRequestSignatureByAdmin = async (
    workflowGuid: String,
    signatureId: number,
    adminPersonId: number,
    adminNotes: String
): Promise<any> => await signWorkflowSignatureByAdmin(workflowGuid, signatureId, adminPersonId, adminNotes, WorkflowPath.KennelRegistration);

export const signKennelChangeOwnerSignatureByAdmin = async (
    workflowGuid: String,
    signatureId: number,
    adminPersonId: number,
    adminNotes: String
): Promise<any> => await signWorkflowSignatureByAdmin(workflowGuid, signatureId, adminPersonId, adminNotes, WorkflowPath.KennelChangeOwners);

export const signLitterRequestSignatureByAdmin = async (
    workflowGuid: String,
    signatureId: number,
    adminPersonId: number,
    adminNotes: String
): Promise<any> => await signWorkflowSignatureByAdmin(workflowGuid, signatureId, adminPersonId, adminNotes, WorkflowPath.LitterRegistration);

export const signLeaseSignatureByAdmin = async (
    workflowGuid: String,
    signatureId: number,
    adminPersonId: number,
    adminNotes: String
): Promise<any> => await signWorkflowSignatureByAdmin(workflowGuid, signatureId, adminPersonId, adminNotes, WorkflowPath.LeaseRegistration);

export const signCreateAffiliateSignatureByAdmin = async (
    workflowGuid: String,
    signatureId: number,
    adminPersonId: number,
    adminNotes: String
): Promise<any> => await signWorkflowSignatureByAdmin(workflowGuid, signatureId, adminPersonId, adminNotes, WorkflowPath.AffiliateRegistration);

export const signRenewAffiliateSignatureByAdmin = async (
    workflowGuid: String,
    signatureId: number,
    adminPersonId: number,
    adminNotes: String
): Promise<any> => await signWorkflowSignatureByAdmin(workflowGuid, signatureId, adminPersonId, adminNotes, WorkflowPath.AffiliateRenewals);

// ---- MARK PAID ----

export const markDogPaidByAdmin = async (
    workflowGuid: String,
    adminPersonId: number,
    adminNotes: String,
    paymentType: number,
): Promise<any> => await markWorkflowPaidByAdmin(workflowGuid, adminPersonId, adminNotes, paymentType, WorkflowPath.DogRegistration);

export const markKennelPaidByAdmin = async (
    workflowGuid: String,
    adminPersonId: number,
    adminNotes: String,
    paymentType: number,
): Promise<any> => await markWorkflowPaidByAdmin(workflowGuid, adminPersonId, adminNotes, paymentType, WorkflowPath.KennelRegistration);

export const markKennelChangeOwnerPaidByAdmin = async (
    workflowGuid: String,
    adminPersonId: number,
    adminNotes: String,
    paymentType: number,
): Promise<any> => await markWorkflowPaidByAdmin(workflowGuid, adminPersonId, adminNotes, paymentType, WorkflowPath.KennelChangeOwners);

export const markLitterPaidByAdmin = async (
    workflowGuid: String,
    adminPersonId: number,
    adminNotes: String,
    paymentType: number,
): Promise<any> => await markWorkflowPaidByAdmin(workflowGuid, adminPersonId, adminNotes, paymentType, WorkflowPath.LitterRegistration);

export const markLeasePaidByAdmin = async (
    workflowGuid: String,
    adminPersonId: number,
    adminNotes: String,
    paymentType: number,
): Promise<any> => await markWorkflowPaidByAdmin(workflowGuid, adminPersonId, adminNotes, paymentType, WorkflowPath.LeaseRegistration);

export const markCreateAffiliatePaidByAdmin = async (
    workflowGuid: String,
    adminPersonId: number,
    adminNotes: String,
    paymentType: number,
): Promise<any> => await markWorkflowPaidByAdmin(workflowGuid, adminPersonId, adminNotes, paymentType, WorkflowPath.AffiliateRegistration);

export const markRenewAffiliatePaidByAdmin = async (
    workflowGuid: String,
    adminPersonId: number,
    adminNotes: String,
    paymentType: number,
): Promise<any> => await markWorkflowPaidByAdmin(workflowGuid, adminPersonId, adminNotes, paymentType, WorkflowPath.AffiliateRenewals);

// ---- APPROVE ----

export const approveDogRegistration = async (workflowGuid: string, requestId: number): Promise<object> =>
    await approveWorkflow(workflowGuid, requestId, WorkflowPath.DogRegistration);

export const approveKennelChangeOwnerRequest = async (workflowGuid: string, requestId: number): Promise<object> =>
    await approveWorkflow(workflowGuid, requestId, WorkflowPath.KennelChangeOwners);

export const approveLitterRequest = async (workflowGuid: string, requestId: number): Promise<object> =>
    await approveWorkflow(workflowGuid, requestId, WorkflowPath.LitterRegistration);

export const approveCreateAffiliateRequest = async (workflowGuid: string, requestId: number): Promise<object> =>
    await approveWorkflow(workflowGuid, requestId, WorkflowPath.AffiliateRegistration);

export const approveRenewAffiliateRequest = async (workflowGuid: string, requestId: number): Promise<object> =>
    await approveWorkflow(workflowGuid, requestId, WorkflowPath.AffiliateRenewals);

export const approveKennelRequest = async (workflowGuid: string, requestId: number, approvedName: string): Promise<object> => {    
    const { data } = await api.post<object>(`/Admin/kennels/registrations/${requestId}/approve`, { workflowGuid, requestId, approvedName });
    return data;
};

// ---- DENY ----

export const denyDogRegistration = async (workflowGuid: string, requestId: number, denialReason: string) =>
    await denyWorkflow(workflowGuid, requestId, denialReason, WorkflowPath.DogRegistration);

export const denyKennelRequest = async (workflowGuid: string, requestId: number, denialReason: string): Promise<object> =>
    await denyWorkflow(workflowGuid, requestId, denialReason, WorkflowPath.KennelRegistration);

export const denyKennelChangeOwnerRequest = async (workflowGuid: string, requestId: number, denialReason: string): Promise<object> =>
    await denyWorkflow(workflowGuid, requestId, denialReason, WorkflowPath.KennelChangeOwners);

export const denyLitterRequest = async (workflowGuid: string, requestId: number, denialReason: string): Promise<object> =>
    await denyWorkflow(workflowGuid, requestId, denialReason, WorkflowPath.LitterRegistration);

export const denyCreateAffiliateRequest = async (workflowGuid: string, requestId: number, denialReason: string): Promise<object> =>
    await denyWorkflow(workflowGuid, requestId, denialReason, WorkflowPath.AffiliateRegistration);

export const denyRenewAffiliateRequest = async (workflowGuid: string, requestId: number, denialReason: string): Promise<object> =>
    await denyWorkflow(workflowGuid, requestId, denialReason, WorkflowPath.AffiliateRenewals);

export default {
    getQTDogRegistrationsList,
    getKennelRegistrationsList,
    getKennelChangeOwnerRequestsList,
    getLitterRegistrationsList,
    getLeaseRegistrationsList,
    getCreateAffiliateRequestList,
    getRenewAffiliateRequestList,
    getQTDogRegistrationRequestById,
    getKennelRegistrationRequestById,
    getKennelChangeOwnerRequestById,
    getLitterRegistrationById,
    getLeaseRegistrationById,
    getCreateAffiliateRequestById,
    getRenewAffiliateRequestById,
    signDogRegistrationSignatureByAdmin,
    signKennelRequestSignatureByAdmin,
    signKennelChangeOwnerSignatureByAdmin,
    signLitterRequestSignatureByAdmin,
    signLeaseSignatureByAdmin,
    signCreateAffiliateSignatureByAdmin,
    signRenewAffiliateSignatureByAdmin,
    markDogPaidByAdmin,
    markKennelPaidByAdmin,
    markKennelChangeOwnerPaidByAdmin,
    markLitterPaidByAdmin,
    markLeasePaidByAdmin,
    markCreateAffiliatePaidByAdmin,
    markRenewAffiliatePaidByAdmin,
    approveDogRegistration,
    approveKennelRequest,
    approveKennelChangeOwnerRequest,
    approveLitterRequest,
    approveCreateAffiliateRequest,
    approveRenewAffiliateRequest,
    denyDogRegistration,
    denyKennelRequest,
    denyKennelChangeOwnerRequest,
    denyLitterRequest,
    denyCreateAffiliateRequest,
    denyRenewAffiliateRequest,
}