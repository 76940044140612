
import Vue from "vue";
import kennelService from "@/services/admin/kennelService";

export default Vue.extend({
  name: "AdminKennelSearchDialog",
  data: function () {
    return {
      showDialog: this.$props.kennelSearchDialog,
      hasSearched: false,
      searchResults: [] as any[],    
      kennelName: "",
      kennelId: "",
      headers: [
        { text: "ID", align: "left", value: "kennelId" },
        { text: "Name", align: "left", value: "name" },
      ],
      isLoading: false,
    };
  },
  watch: {
    kennelSearchDialog: function (newVal: boolean) {
      if (!newVal) {
        this.kennelName = "";
        this.kennelId = "";
        this.hasSearched = false;
        this.searchResults = [];
      }
      this.showDialog = newVal;
    },
  },
  methods: {
    search: function () {
      this.isLoading = true;
      this.hasSearched = false;
      kennelService
        .adminSearchKennels({
          kennelId: this.kennelId,
          name: this.kennelName, 
          page: 0
        })
        .then((response: any) => {
            //todo: cleanup once we confirm this is the desired design of this component, 
            //either create dedicated API action for simple search
            //or extend current search to allow selecting all records
            kennelService
                .adminSearchKennels({
                    kennelId: this.kennelId,
                    name: this.kennelName, 
                    pageSize: response.totalCount,
                    page: 0
                })
                .then((response: any) => {
                    this.searchResults = response.data;
                    this.isLoading = false;
                    this.hasSearched = true;
                });
        });
    },
    addKennel(row: any) {
      this.$emit("kennelAdded", { kennel: row });
      this.showDialog = false;
    },
  },
  props: {
    kennelSearchDialog: Boolean,
  },
});
