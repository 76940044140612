export const myPossibleRequiredPosistions = ["President", "Affiliate Rep", "Primary Show Coordinator"];

export function availablePositions(affiliatePeople: any) {
  // unlimited directors & members
  let options = ["Director", "Member"];

  // only one president
  if (
    !affiliatePeople.some(
      (person: { position: string }) => person.position === "President"
    )
  )
    options.push("President");

  // only one affiliate rep
  if (
    !affiliatePeople.some(
      (person: { position: string }) => person.position === "Affiliate Rep"
    )
  )
    options.push("Affiliate Rep");

  // only one vice president
  if (
    !affiliatePeople.some(
      (person: { position: string }) => person.position === "Vice President"
    )
  )
    options.push("Vice President");

  // only one second VP, only show second VP if vice president has been chosen
  if (
    !affiliatePeople.some(
      (person: { position: string }) => person.position === "Second VP"
    ) &&
    affiliatePeople.some(
      (person: { position: string }) => person.position === "Vice President"
    )
  )
    options.push("Second VP");

  // only one secretary, only if no secretary & treasurer
  if (
    !affiliatePeople.some(
      (person: { position: string }) => person.position === "Secretary"
    ) &&
    !affiliatePeople.some(
      (person: { position: string }) =>
        person.position === "Secretary & Treasurer"
    )
  )
    options.push("Secretary");

  // only one treasurer, only if no secretary & treasurer
  if (
    !affiliatePeople.some(
      (person: { position: string }) => person.position === "Treasurer"
    ) &&
    !affiliatePeople.some(
      (person: { position: string }) =>
        person.position === "Secretary & Treasurer"
    )
  )
    options.push("Treasurer");

  // only one secretary & treasurer, only show if no separate secretary or treasurer
  if (
    !affiliatePeople.some(
      (person: { position: string }) => person.position === "Treasurer"
    ) &&
    !affiliatePeople.some(
      (person: { position: string }) => person.position === "Secretary"
    ) &&
    !affiliatePeople.some(
      (person: { position: string }) =>
        person.position === "Secretary & Treasurer"
    )
  )
    options.push("Secretary & Treasurer");

  // only one primary show coordinator
  if (
    !affiliatePeople.some(
      (person: { position: string }) =>
        person.position === "Primary Show Coordinator"
    )
  )
    options.push("Primary Show Coordinator");

  // only 2 other show coordinators, only show if there is a primary show coordinator
  if (
    affiliatePeople.filter(
      (person: { position: string }) => person.position === "Show Coordinator"
    ).length < 2 &&
    affiliatePeople.some(
      (person: { position: string }) =>
        person.position === "Primary Show Coordinator"
    )
  )
    options.push("Show Coordinator");

  return options.sort();
}

export function isValid(affiliatePeople: any) {
  // at least 10 unique people?
  if ([...new Set(affiliatePeople.map((p: any) => p.personId))].length < 10)
    return false;

  // everyone has a position selected?
  if (
    affiliatePeople.some(
      (person: { position: string }) => person.position === ""
    )
  )
    return false;

  // at least 1 president, affiliate rep, VP, secretary, treasurer, and show coordinator?
  if (
    !affiliatePeople.some(
      (person: { position: string }) => person.position === "President"
    )
  )
    return false;
  if (
    !affiliatePeople.some(
      (person: { position: string }) => person.position === "Affiliate Rep"
    )
  )
    return false;
  if (
    !affiliatePeople.some(
      (person: { position: string }) => person.position === "Vice President"
    )
  )
    return false;
  if (
    !affiliatePeople.some(
      (person: { position: string }) => person.position === "Secretary"
    ) &&
    !affiliatePeople.some(
      (person: { position: string }) =>
        person.position === "Secretary & Treasurer"
    )
  )
    return false;
  if (
    !affiliatePeople.some(
      (person: { position: string }) => person.position === "Treasurer"
    ) &&
    !affiliatePeople.some(
      (person: { position: string }) =>
        person.position === "Secretary & Treasurer"
    )
  )
    return false;
  if (
    !affiliatePeople.some(
      (person: { position: string }) =>
        person.position === "Primary Show Coordinator"
    )
  )
    return false;

  if (affiliatePeople.filter(
    (person: { position: string }) => person.position.includes("Show Coordinator")
  ).length > 3)
    return false;

  // user submitting request is one of the members in the list?
  // if (
  //   !affiliatePeople.some(
  //     (person: { personId: number }) =>
  //       person.personId === userInfo.personId
  //   )
  // )
  //   return false;

  // all conditions met
  return true;
}
