import { api } from "../api";
import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";

export const getLittersList = async (
    options: PaginationOptions,
    params: object,
): Promise<PaginatedResponse<object>> => {  
    const { page, itemsPerPage, sortBy, sortDesc } = options;

    const sort = !!sortBy.length ? sortBy[0] : 'requestId';
    const asce = !!sortDesc.length ? !sortDesc[0] : true;

    const { data } = await api.get<PaginatedResponse<object>>(`/Admin/litters?pageSize=${itemsPerPage}&page=${page}&ascending=${asce}&sort=${sort}`, { params });

    return data;
};

export const getLitterById = async (litterId: number): Promise<any> => {
    const { data } = await api.get<object>(`/Admin/litters/${litterId}`);
  
    return data;
};

  export const getOverlappingLeasesForDam = async (damId: string, whelpingDate: any): Promise<any> => {
      const { data } = await api.get<object>(`/Admin/litters/leases/${whelpingDate}?damId=${encodeURIComponent(damId)}`);
    
      return data;
};

export const getKennelsCoownedBy = async (litterPersonIds: any[]): Promise<any> => {     
    const params = litterPersonIds.join("&LitterOwnersPersonIds=");
    const { data } = await api.get<object>(`/Admin/litters/kennels?LitterOwnersPersonIds=${params}`);
    return data;
};

export const validateLitterInfoEdit = async (request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/litters/validate`, request);
    return data;
};

export const editLitterById = async (litterId: string, request: any): Promise<any> => {
    if(litterId){
    const { data } = await api.post<object>(`/Admin/litters/${litterId}`, request);
    return data;
    } else {
        const { data } = await api.post<object>(`/Admin/litters`, request);
        return data;

    }
};

export const editLitterPuppies = async (litterId: string, puppies: any): Promise<any> => {
    const request = {
        litterId: litterId,
        puppies: puppies
    };
    const { data } = await api.post<object>(`/Admin/litters/${litterId}/puppies`, request);
    return data;
};

export default {
    getLittersList,
    getLitterById,
    getOverlappingLeasesForDam,
    getKennelsCoownedBy,
    validateLitterInfoEdit,
    editLitterById,
    editLitterPuppies,
};
