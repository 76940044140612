import { api } from "../api";

export const registerAffiliate = async (request: any): Promise<any> => {
  try {
    const { data } = await api.post<object>(`/Affiliate/register`, request);
    return data;
  } catch (err) {
    return err;
  }
};

export const renewAffiliate = async (request: any): Promise<any> => {
  try {
    const { data } = await api.post<object>(`/Affiliate/renew`, request);
    return data;
  } catch (err) {
    return err;
  }
};

export const getMatchingAffiliateNames = async (
  affiliateName: string
): Promise<any> => {
  try {
    const { data } = await api.get<string[]>(
      `/Affiliate/search/${affiliateName}`
    );
    return data;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const getAffiliateMemberTypes = async (): Promise<object> => {
  const { data } = await api.get<object>(`/Affiliate/member/types`);
  return data;
};

export default {
  registerAffiliate,
  renewAffiliate,
  getMatchingAffiliateNames,
  getAffiliateMemberTypes,
};
