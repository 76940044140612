
import Vue from "vue";
import CoownersList, { OwnershipType } from "@/components/common/CoownersList.vue";
import personService from "@/services/portal/personService";
import { UserInfo } from "@/types/User";

export default Vue.extend({
  name: "MyKennels",
  components: {
    CoownersList,
  },
  data: function () {
    return {
      kennels: [],
    };
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    kennelOwnershipType: function() {
      return OwnershipType.Kennel;
    },
  },
  methods: {
      changeKennelOwnersRequest: function(kennel: any) {
        this.$router.push({path: `/changekennelowners/${kennel.kennelId}`});
      },
      registerKennel: function() {
          this.$router.push({path: '/registration/kennel'});
      }
  },
  created: function () {
    if (this.userInfo) {
      personService
        .getKennelsByOwner(this.userInfo.personId)
        .then((res) => {
          this.kennels = res;
        })
        .catch((e) => console.log(e));
    }
  },
  watch: {
    userInfo: function (newVal: object) {
      personService
        .getKennelsByOwner(this.userInfo.personId)
        .then((res) => {
          this.kennels = res;
        })
        .catch((e) => console.log(e));
    }
  },
});
