import { PersonTasksSummary, ContactInfo, AddressInfo } from '@/types/portal/Person';

import { api } from "../api";

export const getContactInformation = async (personId: number): Promise<any> => {    
  const { data } = await api.get<ContactInfo>(`/Person/${personId}/contact`);

  return data;
};

export const changeAddressInformation = async (personId: number, addressInfo: AddressInfo): Promise<any> => {    
  const request = addressInfo as any;
  request.personId = personId;

  const { data } = await api.post<any>(`/Person/${personId}/address`, request);

  return data;
};

export const changeContactInformation = async (personId: number, contactInfo: ContactInfo): Promise<any> => {    
  const request = contactInfo as any;
  request.personId = personId;

  const { data } = await api.post<any>(`/Person/${personId}/contact`, request);

  return data;
};

export const changePassword = async (personId: number, currentPassword: string, newPassword: string): Promise<any> => {    
  const request = {personId, currentPassword, newPassword}

  const { data } = await api.post<any>(`/Person/${personId}/changePassword`, request);

  return data;
};

export const getTasksSummary = async (personId: number): Promise<PersonTasksSummary> => {    
  const { data } = await api.get<PersonTasksSummary>(`/Person/${personId}/tasks`);

  return data;
};

export const getCommunicationPreferences = async (personId: number): Promise<any> => {    
    const { data } = await api.get<any>(`/Person/${personId}/communication`);

    return data;
};

export const changeCommunicationPreferences = async (personId: number, email: boolean, sms: boolean): Promise<any> => {    
    const { data } = await api.post<any>(`/Person/${personId}/communication`, {personId, email, sms});

    return data;
};

export const getDogsByOwner = async (params: any): Promise<any> => {
  const { data } = await api.get<object>(`/Person/dogs`, {params: params});

  return data;
};

export const getKennelsByOwner = async (personId: number): Promise<any> => {
  const { data } = await api.get<object>(`/Person/${personId}/kennels`);

  return data;
};

export const getMyPayments = async (personId: number): Promise<any> => {
  const { data } = await api.get<object>(`/Person/${personId}/payments`);

  return data;
};

export const getUserConfirmation = async (value: string, isEmail: boolean): Promise<any> => {
  try {
    const { data } = await api.post<any>(`/Person/confirmation`, { value, isEmail })
    return {...data, success: true}
  } catch (err) {
    console.error('error', err);

    if ((err as any).status === 500) {
      return {error: (err as any).data.error, success: false};
    }

    return {error: err, success: false};
}
}

export const forgotPassword = async (personId: number, newPassword: string): Promise<any> => {    
  const request = {personId, newPassword}

  const { data } = await api.post<any>(`/Person/${personId}/forgotPassword`, request);

  return data;
};
  
export default {
  getTasksSummary,
  getCommunicationPreferences,
  changeCommunicationPreferences,
  getDogsByOwner,
  getKennelsByOwner,
  getContactInformation,
  changeAddressInformation,
  changeContactInformation,
  changePassword,
  getMyPayments,
  getUserConfirmation,
  forgotPassword,
};
