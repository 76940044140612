
    import Vue from "vue";
    import adminTitlingService from "@/services/admin/titlingService";
    import { UserInfo } from "@/types/User";
    import { debounce } from "throttle-debounce";
    import { MetaNames } from "@/constants";

    export default Vue.extend({
        name: "TitlingReview",
        props: {
            readyToApproveOnly: String,
            program: String,
            propsDogId: String
        },
        components: {
        },
        data: function () {
            return {
                title: MetaNames.AdminTitlingReview,
                dogId: this.$props.propsDogId ?? null,
                allCertificates: [] as any[],
                calculatedCertificates: Array<object>(),
                filteredCertificates: Array<object>(),
                search: '',
                isLoading: false,
                filteredResults: [] as any[],
                highlightedResults: [] as any[],
                showResults: [] as any[],
                sourceCertificates: [] as any[],
                filteredSourceCertificates: [] as any[],
                highlightResults: false,
                certificatesHeaders: [
                    { text: "Title", align: "left", value: "titleName" },
                    { text: "DB Show Date", align: "left", value: "dbShowDate" },
                    { text: "Generated On", align: "left", value: "genDate" },
                    { text: "Printed On", align: "left", value: "printDate" },
                    { text: "Show ID", align: "left", value: "finalShowId" },
                    { text: "Show Date", align: "left", value: "finalShowDate" },
                    { text: "Filter", align: "left", value: "relatedResultsIds" },
                ],
                sourceCertificatesHeaders: [
                    { text: "Title", align: "left", value: "title" },
                    { text: "Number", align: "left", value: "certNumber" },
                    { text: "Show Date", align: "left", value: "showDate" },
                    { text: "Program", align: "left", value: "program" },
                    { text: "Generated On", align: "left", value: "generatedOn" },
                    { text: "Certificates", align: "left", value: "id", groupable: false },
                ],
                showResultsHeaders: [
                    { text: "Entry Date", align: "left", value: "entryDate", width: "100px" },
                    { text: "Show Date", align: "left", value: "showDate", width: "100px" },
                    { text: "Show ID", align: "left", value: "showId", width: "100px" },
                    { text: "Descr.", align: "left", value: "description", width: "100px" },
                    { text: "Category", align: "left", value: "category", width: "50px" },
                    { text: "Division", align: "left", value: "division", width: "50px" },
                    { text: "Apply to", align: "left", value: "applyTo", width: "50px" },
                    { text: "Score", align: "left", value: "score", width: "50px" },
                    { text: "Judge", align: "left", value: "judge" },
                    { text: "CH to ACE", align: "left", value: "transferredToAce" },
                    { text: "Certificates", align: "left", value: "resultId", groupable: false },
                    { text: "Handler", align: "left", value: "handler" },
                ],
                colors: [
                    "#e51c23", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#5677fc", "#03a9f4", "#00bcd4",
                    "#009688", "#259b24", "#8bc34a", "#afb42b", "#ff9800", "#ff5722", "#795548", "#607d8b",
                ],
                dogCertsInDB: [] as any[],
                selectedCategory: '',
                currentRTCHprogressionStatus: null as any,
                currentATCHprogressionStatus: null as any,
                currentACEprogressionStatus: null as any,
                highlightRTCHresults: false,
                highlightUnusedAgilityPoints: false,
                titleKey: this.$props.program ?? null,
                dogName: "",
                generatedDogName: "",
                unusedAgilityPointsForTransfer: [] as any[],
                resultsSelectedForTransfer: [] as any[],
                allSelected: false,
                unusedSelected: false,
                errorMessage: '',
                resultsSelectedForTitlePath: [] as any[],
                unappliedUtilityResults: [] as any[],
                highlightUnappliedUDResults: false,
                allSelectedOb: false,
                unappliedSelectedOb: false,
               
            };
        },        
        created() {
            if(this.$props.program && this.$props.propsDogId){
                this.loadTitlesSelect(this.$props.program);
            }
        },
        methods: {
            transferToACE(){
                this.$store.dispatch("changeLoaderStatus",true);
                var resultsSelectedForTransfer = this.resultsSelectedForTransfer.map(r => r.resultId);
                adminTitlingService.transferUnusedPointsToACE(this.dogId, this.unusedAgilityPointsForTransfer, resultsSelectedForTransfer).then((res) =>{
                    this.$store.dispatch("changeLoaderStatus",false);
                    this.loadTitlesSelect(this.$props.program);
                });
            },
            revertTransferToACE(){
                this.$store.dispatch("changeLoaderStatus",true);
                adminTitlingService.untransferUnusedPointsFromACE(this.dogId).then((res) =>{
                    this.$store.dispatch("changeLoaderStatus",false);
                    this.loadTitlesSelect(this.$props.program);
                });
            },
            row_classes(item: any) {
                if (this.selectedCategory == 'O') {
                    if (!this.highlightUnappliedUDResults) {
                        return '';
                    }
                    if (this.highlightUnappliedUDResults && this.unappliedUtilityResults.map((r: any) => r.resultId).includes(item.resultId)) {
                        return 'orange  lighten-4';

                    }
                }
                else {
                    if (!this.highlightRTCHresults && !this.highlightUnusedAgilityPoints)
                        return '';
                    if (this.currentRTCHprogressionStatus
                        && this.currentRTCHprogressionStatus.relatedResultsIds.includes(item.resultId)) {
                        return 'orange  lighten-4';
                    }
                    if (this.highlightUnusedAgilityPoints && this.unusedAgilityPointsForTransfer.map((r: any) => r.resultId).includes(item.resultId)) {
                        return 'orange  lighten-4';
                    }
                }
                
            },
            getCertDBDate(selectedCert: string) {
                const cert = this.dogCertsInDB.find(({ title }) => title === selectedCert.toString());
                return cert ? cert.showDate : cert;
            },
            getCertGeneratedDBDate(selectedCert: string) {
                const cert = this.dogCertsInDB.find(({ title }) => title === selectedCert.toString());
                return cert ? cert.generatedOn : cert;
            },
            getCertPrintedDBDate(selectedCert: string) {
                const cert = this.dogCertsInDB.find(({ title }) => title === selectedCert.toString());
                return cert ? cert.printedOn : cert;
            },
            getCertificatesForResult(resultId: any) {
                var appliedInCerts = this.filteredCertificates.filter((cert: any) =>
                    cert.relatedResultsIds.includes(resultId)
                );
                var certsList = appliedInCerts.map((cert: any) => {
                    return { id: cert.title, name: cert.titleName };
                });
                return certsList;
            },
            getCertificatesForCertificate(id: any) {
                var appliedInCerts = this.filteredCertificates.filter((cert: any) =>
                    cert.relatedCertIds.includes(id)
                );
                var certsList = appliedInCerts.map((cert: any) => {
                    return { id: cert.title, name: cert.titleName };
                });
                return certsList;
            },
            getTitleColor(titleName: string, titleId: number) {
                const text = `123${titleName}123`;//`${titleId}${titleName}${titleId}`
                var hash = 0;
                if (text.length === 0) return hash;
                for (var i = 0; i < text.length; i++) {
                    hash = text.charCodeAt(i) + ((hash << 3) - hash);
                    hash = hash & hash;
                }
                hash = ((hash % this.colors.length) + this.colors.length) % this.colors.length;
                return this.colors[hash];
            },
            loadtitles: function (titlesFunction: Function, selectedCategory: string) {
                this.selectedCategory = selectedCategory;
                this.dogCertsInDB = [];
                this.showResults = [];
                this.filteredResults = [];
                this.sourceCertificates = [];
                this.filteredSourceCertificates = [];
                this.calculatedCertificates = [];
                this.currentRTCHprogressionStatus = null;
                this.currentATCHprogressionStatus = null;
                this.currentACEprogressionStatus = null;
                this.unusedAgilityPointsForTransfer = [];
                this.unappliedUtilityResults = [];

                var calculatingCerts = true;
                var loadingResults = true;
                var loadingCerts = true;

                this.isLoading = true;

                adminTitlingService
                    .getCertificatesForADog(this.dogId, selectedCategory)
                    .then((res) => {
                        this.dogCertsInDB = res;

                        loadingCerts = false;
                        if (!calculatingCerts && !loadingResults && !loadingCerts) {
                            this.isLoading = false;
                        }
                    
                        titlesFunction(this.dogId)
                            .then((res: any) => {
                                if(res.messages){
                                    this.errorMessage = res.messages.join(',');
                                    calculatingCerts = false;
                                    if (!calculatingCerts && !loadingResults && !loadingCerts) {
                                        this.isLoading = false;
                                    }
                                } else {
                                    this.errorMessage = ''
                                    this.calculatedCertificates = res.certs;
                                    if (res.rtcH_CurrentProgression) {
                                        this.currentRTCHprogressionStatus = res.rtcH_CurrentProgression;
                                    }
                                    if (res.atchStatus) {
                                        this.currentATCHprogressionStatus = res.atchStatus;
                                    }
                                    if (res.aceStatus) {
                                        this.currentACEprogressionStatus = res.aceStatus;
                                    }
                                    if (res.unusedPoints) {
                                        this.unusedAgilityPointsForTransfer = res.unusedPoints;
                                        const unusedResultIds = res.unusedPoints.map((r: any) => r.resultId);
                                        this.showResults.forEach(sr => {
                                            sr.canBetransferredToAce = unusedResultIds && unusedResultIds.includes(sr.resultId);
                                        });
                                    }
                                   
                                    this.filteredCertificates = res.certs;

                                    calculatingCerts = false;
                                    if (!calculatingCerts && !loadingResults && !loadingCerts) {
                                        this.isLoading = false;
                                    }
                                }
                            })
                            .catch((e: any) => console.log(e));
                    })
                    .catch((e: any) => console.log(e));

                if(selectedCategory !== 'SP'){
                    adminTitlingService
                        .getShowResultsForADog(this.dogId, selectedCategory)
                        .then((res: any) => {
                            this.sourceCertificates = [];
                            this.filteredSourceCertificates = [];
                            this.showResults = res.data;
                            this.filteredResults = res.data;
                            if (this.selectedCategory == 'O') {
                                for (let i = 0; i < res.data.length; i++) {

                                    if (res.data[i].applyTo === '' && res.data[i].category.trim() == "UD") {

                                        this.unappliedUtilityResults.push(res.data[i]);

                                    }
                                }
                            }
                            loadingResults = false;
                            if (!calculatingCerts && !loadingResults && !loadingCerts) {
                                this.isLoading = false;
                            }
                        })
                        .catch((e: any) => console.log(e));
                } else {
                    adminTitlingService
                        .getSourceCertificatesForADogSpecialty(this.dogId)
                        .then((res: any) => {
                            this.showResults = [];
                            this.filteredResults = [];
                            console.log('sourceCertificates')
                            console.log(res)
                            this.sourceCertificates = res;
                            this.filteredSourceCertificates = res;

                            loadingResults = false;
                            if (!calculatingCerts && !loadingResults && !loadingCerts) {
                                this.isLoading = false;
                            }
                        })
                        .catch((e: any) => console.log(e));
                }

                this.getDogName();
            },
            filterResults(certId: any, ids: any) {
                this.filteredResults = this.showResults.filter((sr) =>
                    ids.includes(sr.resultId)
                );
                this.filteredCertificates = this.calculatedCertificates.filter((cert: any) =>
                    cert.title == certId
                );
            },
            filterCertificates(certId: any, ids: any) {
                this.filteredSourceCertificates = this.sourceCertificates.filter((cert) =>
                    ids.includes(cert.id)
                );
                this.filteredCertificates = this.calculatedCertificates.filter((cert: any) =>
                    cert.title == certId
                );
            },
            clearFilters() {
                this.filteredResults = this.showResults;
                this.filteredSourceCertificates = this.sourceCertificates;
                this.filteredCertificates = this.calculatedCertificates;
            },
            loadTitlesSelect(titleType: any) {
                this.resetUI();
                if (titleType == "Rally")
                    this.loadtitles(this.getRallyTitlesForADogFn, 'R');
                if (titleType == "Obedience")
                    this.loadtitles(this.getObedienceTitlesForADogFn, 'O');
                if (titleType == "Stock")
                    this.loadtitles(this.getStockTitlesForADogFn, 'ST');
                if (titleType == "Agility")
                    this.loadtitles(this.getAgilityTitlesForADogFn, 'A');
                if (titleType == "Conformation")
                    this.loadtitles(this.getConformationTitlesForADogFn, 'C');
                if (titleType == "Tracking")
                    this.loadtitles(this.getTrackingTitlesForADogFn, 'T');
                if (titleType == "Farm Trial")
                    this.loadtitles(this.getFarmTrialTitlesForADogFn, 'FT');
                if (titleType == "Ranch Trial")
                    this.loadtitles(this.getRanchTrialTitlesForADogFn, 'RT');
                if (titleType == "Specialty")
                    this.loadtitles(this.getSpecialtyTitlesForADogFn, 'SP');                
            },
            csvExport: function (type: number) {
                var filename = this.dogId.trim() + "_" + this.titleKey + "_results.csv";
                var content = this.filteredResults;
                if (type != 1) {
                    filename = this.dogId.trim() + "_" + this.titleKey + "_certs.csv";
                    content = this.certificatesList;
                }
                const keys = Object.keys(content[0]);

                const commaSeparatedString = [keys.join(","), content.map(row => keys.map(key => row[key]).join(",")).join("\n")].join("\n")

                const csvBlob = new Blob([commaSeparatedString])
                var link = document.createElement("a");
                var url = URL.createObjectURL(csvBlob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            getDogName() {
                adminTitlingService.getGeneratedNameForDog(this.dogId)
                    .then((res: any) => {
                        this.generatedDogName = res.generatedDogName;

                    })
                    .catch((e: any) => console.log(e));

                adminTitlingService.getNameForDog(this.dogId)
                    .then((res: any) => {
                        this.dogName = res.generatedDogName;

                    })
                    .catch((e: any) => console.log(e));

            },
            applyObedienceUtilitiesToTitle(titlePath: string) {
                this.$store.dispatch("changeLoaderStatus", true);
                var resultsSelected = this.resultsSelectedForTitlePath.map(r => r.resultId);
                if (titlePath === '') {
                    resultsSelected = this.showResults.map(r => r.resultId);
                }
                adminTitlingService.applyUtilityResultsToTitle(this.dogId, titlePath, resultsSelected).then((res) => {
                    this.$store.dispatch("changeLoaderStatus", false);
                    this.loadTitlesSelect(this.$props.program);
                });
            },
            resetUI: function () {
                this.highlightUnappliedUDResults = false;
                this.allSelectedOb = false;
                this.unappliedSelectedOb = false;
                this.currentATCHprogressionStatus = false;
                this.currentRTCHprogressionStatus = false;
                this.currentACEprogressionStatus = false;
                this.allSelected = false;
                this.unusedSelected = false;
            },
          
        },
       
        watch: {
            dogId: {
                handler: debounce(
                    1500,
                    function(this: any, newValue, oldValue){ 
                    if(newValue && newValue !== oldValue && this.titleKey){
                        this.$router.push({ path: `/admin/titling/review/${this.titleKey}?dogId=${encodeURIComponent(newValue.trim())}` }).catch(() => {});
                        this.loadTitlesSelect(this.titleKey);
                    }
                }),
            },
            titleKey: function(newValue, oldValue){     
                if(newValue && newValue !== oldValue && this.dogId){
                    this.$router.push({ path: `/admin/titling/review/${newValue}?dogId=${encodeURIComponent(this.dogId.trim())}` }).catch(() => {});
                }
            },
            unusedSelected: function (newValue) {
               if(newValue){
                    var unusedPoints = this.unusedAgilityPointsForTransfer.map((r: any) => r.resultId);
                    var selected = this.showResults.filter(r => unusedPoints.includes(r.resultId));
                    this.resultsSelectedForTransfer = selected;
                } else {
                    this.resultsSelectedForTransfer = [];
                    
                }
            },
            unappliedSelectedOb: function (newValue) {
                this.resultsSelectedForTitlePath = [];
                if (newValue) {
                    this.resultsSelectedForTitlePath = this.unappliedUtilityResults;
                }
            },
            allSelected: function (newValue) {
                if(newValue){
                    this.resultsSelectedForTransfer = this.showResults;
                } else {
                    this.resultsSelectedForTransfer = [];
                    
                }
            },
            allSelectedOb: function (newValue) {
                this.resultsSelectedForTitlePath = [];
                if (newValue) {
                    this.resultsSelectedForTitlePath = this.showResults;
                }

            },
           
               
            
        },
        computed: {
            getRallyTitlesForADogFn(): Function {
                return adminTitlingService.getRallyTitlesForADog;
            },
            getObedienceTitlesForADogFn(): Function {
                return adminTitlingService.getObedienceTitlesForADog;
            },
            getAgilityTitlesForADogFn(): Function {
                return adminTitlingService.getAgilityTitlesForADog;
            },
            getStockTitlesForADogFn(): Function {
                return adminTitlingService.getStockTitlesForADog;
            },
            getConformationTitlesForADogFn(): Function {
                return adminTitlingService.getConformationTitlesForADog;
            },
            getTrackingTitlesForADogFn(): Function {
                return adminTitlingService.getTrackingTitlesForADog;
            },
            getFarmTrialTitlesForADogFn(): Function {
                return adminTitlingService.getFarmTrialTitlesForADog;
            },
            getRanchTrialTitlesForADogFn(): Function {
                return adminTitlingService.getRanchTrialTitlesForADog;
            },
            getSpecialtyTitlesForADogFn(): Function {
                return adminTitlingService.getSpecialtyTitlesForADog;
            },
            userInfo(): UserInfo {
                return this.$store.state.userInfo;
            },
            missingCertificates(): any[] {
                var calculatedCertsTitles = this.calculatedCertificates.map((c: any) => { return c.titleName; });
                var missingCerts = this.dogCertsInDB.filter((cert: any) => {
                    return !calculatedCertsTitles.includes(cert.title);
                });
                return missingCerts;
            },
            certificatesList(): any[] {
                var allCertificates = this.calculatedCertificates;
                allCertificates.forEach((cert: any) => {
                    cert.dbShowDate = this.getCertDBDate(cert.titleName);
                    cert.genDate = this.getCertGeneratedDBDate(cert.titleName);
                    cert.printDate = this.getCertPrintedDBDate(cert.titleName);
                });
                if (!!this.missingCertificates && this.missingCertificates.length > 0) {
                    this.missingCertificates.forEach(dbCert => {
                        allCertificates.push({
                            titleName: dbCert.title,
                            relatedResultsIds: [],
                            dbShowDate: this.getCertDBDate(dbCert.title),
                            genDate: this.getCertGeneratedDBDate(dbCert.title),
                            printDate: this.getCertPrintedDBDate(dbCert.title),
                        });
                    });
                }
                return allCertificates;
            },
          
        },
    });
