<template>
  <div class="filter-pill-container">
    <v-chip
      v-for="(item, index) in pills"
      :key="index + '-pill'"
      @click="toggle(index)"
      class="mr-3"
      v-bind:class="item.active ? 'active-pill' : 'filter-pill'"
      >{{ item.name }}
      <v-tooltip bottom v-if="item.tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="darkGray" class="ml-1" dark v-bind="attrs" v-on="on">
            mdi-information
          </v-icon>
        </template>
        <span>{{item.tooltip}}</span>
      </v-tooltip></v-chip
    >
  </div>
</template>

<script>
export default {
  name: "FilterPills",
  props: {
    pills: {
      type: Array,
      default: () => [{ name: "link", active: false }],
    },
    toggle: { type: Function },
  },
};
</script>
