
import Vue from "vue";

export default Vue.extend({
  name: "RequestStatusesSelectMenu",
  props: { menu: Boolean },
  data() {
    return {
      statuses: ["RequestFinalized"]
    }
  },
  watch: {
    statuses() {
      this.$emit('updateStatuses', this.statuses);
    }
  }
});
