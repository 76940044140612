
import Vue from 'vue';
import FooterText from '@/components/common/FooterText.vue';
import VerifyCodeDialog from '@/components/common/VerifyCodeDialog.vue';

import userService from '@/services/portal/userService';
import memberService from'@/services/portal/memberService';
import { VerificationType } from "@/services/codeVerificationService";
import { UserInfo } from "@/types/User";

const logoImage = require('@/assets/logo.svg');

export default Vue.extend({
  components: { FooterText, VerifyCodeDialog },
  name: 'LinkToMembershipByCode',
  data() {
    return {
      logoImage,
      isProcessing: false,
      errorMessage: '',
      showAuthorizeCodeModal: false,
      isValid: false,
      memberId: '',
    };
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    memberVerificationType: function () {
      return VerificationType.Membership;
    }
  },
  methods: {
    processMemberId: async function() {
      this.showAuthorizeCodeModal = false;
      this.isProcessing = true;

      try {
        const response = await memberService.linkToMembership(this.memberId);

        this.isProcessing = false;
        this.errorMessage = '';

        if (response.success) {
          const newUserInfo = await userService.getProfile();

          this.$store.commit('loadUserInfo', newUserInfo);
          this.$router.push(response.expired ? '/expired-membership' : '/profile');
        } else this.errorMessage = response.errors ? response.errors.join(',') : 'An Unknown Error Occured';
      } catch (ex) {
        console.log(ex);
      }

      this.isProcessing = false;
    },
    toRegister() {
      this.$router.push('/registration/member');
    },
  },
});
