
import Vue from "vue";
import * as _ from "lodash";
import { find } from "lodash";

import DatepickerFormatted from "@/components/common/inputs/DatepickerFormatted.vue";
import AdminDogSearchDialog from "@/components/Admin/AdminDogSearchDialog.vue";

import dogService from "@/services/admin/dogService";
import juniorHandlerService from "@/services/admin/juniorHandlerService";

import { TitledDogs } from "@/types/admin/JuniorHandler";

export default Vue.extend({
  name: "TitledDogs",
  props: {
    personId: { type: Number },
    titlesTypes: { type: [] as any[] },
  },
  components: {
    DatepickerFormatted,
    AdminDogSearchDialog,
  },
  data: function () {
    return {
      emptyRow: {} as TitledDogs,
      showDeleteConfirmationDialog: false,
      deletedItem: null as any,
      showResults: [] as TitledDogs[],
      persistedShowResults: [] as TitledDogs[],
      errorMessage: "" as string,
      infoMessage: "" as string,
      titles: [] as any[]
    };
  },
  created: function () {
    this.emptyRow = Object.assign({}, {} as TitledDogs);
    if (this.personId) {
      this.reloadData();
    }
  },
  watch: {
    personId: function (newVal: String) {
      this.reloadData();
    },
    emptyRow: {
      deep: true,
      handler(val, oldVal) {
        if (!val.dogId) return;
        dogService.getDogById(val.dogId)
          .then((res) => {
            this.titles = res.dogCerts
            this.showResults = this.showResults.map(r => r.dogId === val.dogId ? {...r, dogCerts: res.dogCerts }: r);
          })
          .catch(err => {
            this.errorMessage = "Error with loading dog information."
            console.error(err.message)
          })
      },
    }
  },
  methods: {
    updateShowDate: function (v: string) {
      let cert = find(this.titles, t => t.title === v);
      if (!cert) return;
      this.emptyRow.showDate = cert.showDate;
    },
    updateShowDateByDogId: function (dogId: string, certTitle: string) {
      const result = find(this.showResults, r => r.dogId === dogId);
      if (!result) return;
      let cert = find((result as any).dogCerts, t => t.title === certTitle);
      if (!cert) return;    
      this.showResults = this.showResults.map(r => r.dogId === dogId ? {...r, showDate: cert.showDate } : r)
    },
    reloadData: function () {
      juniorHandlerService
        .getTitledDogsByJuniorPersonId(this.personId)
        .then((res) => {
          this.showResults = res;
          this.persistedShowResults = _.cloneDeep(res);
          this.emptyRow = this.emptyShowResultRow();
        });
    },
    emptyShowResultRow: function () {
      return {
        id: null,
        dogId: '',
        dogName: '',
        showDate: null,
        categoryId: '',
        juniorPersonId: this.personId
      }
    },
    updateDates: function (val: any, key: string, item: any) {
      item[key] = val?.date;
      //workaround to force rerendering after setting up formatted date
      this.$set(this, "showResults", this.showResults);
    },
    row_classes: function (item: any, index: number) {
      if (!!item.id) {
        const index = this.showResults.findIndex((i) => i.id === item.id);
        if (
          _.isEqual(this.showResults[index], this.persistedShowResults[index])
        ) {
          return "";
        }
      }
      return "orange  lighten-4";
    },
    saveShowResult: function (item: TitledDogs) {
      this.$store.dispatch("changeLoaderStatus", true);
      const isAddingNewRow = !item.id;

      juniorHandlerService.updateTitledDogEntry(item)
        .then((res: any) => {
          if (!res.success) {
            this.errorMessage = res.messages.join(", ");
            if (isAddingNewRow) {
              const input = document.querySelector('#srEmptyDogId') as HTMLElement | null;

              if (input != null) {
                input.focus();
              }
            }
          } else {
            if (isAddingNewRow) {
              this.emptyRow.id = res.id;
              this.showResults.unshift({...this.emptyRow, dogCerts: this.titles } as any);
              this.persistedShowResults = _.cloneDeep(this.showResults);

              this.emptyRow = Object.assign({}, this.emptyShowResultRow());
              this.$set(this, "emptyRow", this.emptyRow);

              const input = document.querySelector('#srEmptyDogId') as HTMLElement | null;

              if (input != null) {
                input.focus();
              }
            } else {
              this.persistedShowResults = _.cloneDeep(this.showResults);
            }
            this.errorMessage = "";
          }
        })
        .catch((e) => {
          this.errorMessage = "Changes couldn't be saved. Ensure there are no validation errors before retrying.";
        })
        .finally(() => { this.$store.dispatch("changeLoaderStatus", false); });
    },
    deleteShowResult: function (item: TitledDogs) {
      if (item.id) {
        //if that item has been persisted already
        this.deletedItem = item;
        this.showDeleteConfirmationDialog = true;
      }
    },
    confirmDelete: function () {
      if (this.deletedItem == null) {
        return;
      }
      juniorHandlerService.deleteTitledDogEntry(this.deletedItem.id)
        .then((res: any) => {
          if (!res.success) {
            this.errorMessage = res.messages.join(", ");
          } else {
            this.deletedItem = null;
            this.reloadData();
          }
        })
        .finally(() => { this.showDeleteConfirmationDialog = false });
    },
  },
});
