import { render, staticRenderFns } from "./RegisterKennel.vue?vue&type=template&id=8f3316ca"
import script from "./RegisterKennel.vue?vue&type=script&lang=ts"
export * from "./RegisterKennel.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports