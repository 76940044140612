
import Vue from "vue";
import Datepicker from "@/components/common/inputs/DatepickerFormatted.vue";
import SignatureStatusList from "@/components/common/SignatureStatusList.vue";
import PaginationSelect from "@/components/common/PaginationSelect.vue";

import dogService from "@/services/admin/dogService";

import { UserInfo } from "@/types/User";
import { MetaNames } from "@/constants";
import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";

export default Vue.extend({
  name: "PuppyReleaseList",
  components: {
    Datepicker,
    SignatureStatusList,
    PaginationSelect
  },
  data: function () {
    return {
      title: MetaNames.AdminPuppyReleases,
      loading: false,
      isError: false,
      errorMessage: "",
      paginatedResponse: {} as PaginatedResponse<object>,
      isEmailModalVisible: false,
      puppyReleaseId: null as number | null,
      emailToSend: "",
      firstNameToSend: "",
      lastNameToSend: "",
      searchParams: {
        litterId: "",
        dogId: "",
        releasePersonEmail: "",
        releasePersonName: "",
        releaseDateFrom: "",
        releaseDateTo: "",
        registrationStatus: null as any,
      },
      options: {} as PaginationOptions,
      headers: [
        { text: "", align: "left", value: "litterId", width: '8%' },
        { text: "Body color & gender", align: "left", value: "puppyInfo", width: '7%' },
        { text: "Dog ID", align: "left", value: "dogId", width: '7%' },
        { text: "", align: "left", value: "releaseDate", width: '15%' },
        { text: "", align: "left", value: "releasedPerson", width: '18%' },
        { text: "Signature Status", align: "left", value: "signatureStatus", width: '25%' },
        { text: "", align: "left", value: "registrationStatus", width: '10%' },
        { text: "", align: "left", value: "actionButton", width: '10%' },
      ],
      footerProps: { 'items-per-page-options': [10, 20, 30, 40, 50, 100] },
      currentCheckboxIdx: 0,
    };
  },
  created: function() {
    this.load();
  },
  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    }
  },
  methods: {
    clearAllFilters: function () {
      this.options.page = 1;
      this.options.itemsPerPage = 10;
      this.searchParams = {
        litterId: "",
        dogId: "",
        releasePersonEmail: "",
        releasePersonName: "",
        releaseDateFrom: "",
        releaseDateTo: "",
        registrationStatus: null,
      };
    },
    toggleCheckbox: function() {
      var states = [null, true, false];
      var next = (++this.currentCheckboxIdx) % states.length;
      this.currentCheckboxIdx = next;
      this.searchParams.registrationStatus = states[next];
    },
    updateDates: function (val: any, key: string) {
      (this as any).searchParams[key] = val.date;
    },
    updateDateSearch: async function(val: any, key: string) {
      await this.updateDates(val, key);
      this.applyFilter();
    },
    load: async function () {
      this.loading = true;

      const {
        page,
        itemsPerPage,
      } = this.options;

      try {
        const response = await dogService.getPuppyReleaseList({
          ...this.options,
          page: (page ?? 1) - 1,
          itemsPerPage: itemsPerPage ?? 10
        }, this.searchParams);

        this.loading = false;
        this.paginatedResponse = response;
      } catch (ex: any) {
        console.log(ex.response.data.errors)

        this.loading = false;
        this.isError = true;
        
        const descriptiveMessage = ex.response.data.errors
          ? `Error(s): ${Object.values(ex.response.data.errors).join(" & ")}`
          : null

        this.errorMessage = descriptiveMessage ?? ex.message ?? "Unknown error occurred when retrieving data.";
      }
    },
    applyFilter() {
      this.options.page = 1;
      this.options.itemsPerPage = 10;

      this.load();
    },
    sendEmail() {
      if (this.puppyReleaseId == null) return;
      dogService.resendReleaseEmail({
        puppyReleaseId: this.puppyReleaseId,
        email: this.emailToSend,
        firstName: this.firstNameToSend,
        lastName: this.lastNameToSend,
      }).then(r => {
        if (r.errorMessage) return console.error(r.errorMessage);
        if (!r.success) return console.error("Uknown failure");
        this.hideEmailModal();
      }).catch(e=> {
        console.error(e.message)
        this.hideEmailModal()
      })

    },
    showEmailModal(email: string, first: string, last: string, puppyReleaseId: number){
      this.isEmailModalVisible = true;
      this.emailToSend = email ?? "";
      this.firstNameToSend = first ?? "";
      this.lastNameToSend = last ?? "";
      this.puppyReleaseId = puppyReleaseId;
    },
    hideEmailModal(){
      this.isEmailModalVisible = false;
      this.emailToSend = "";
      this.firstNameToSend = "";
      this.lastNameToSend = "";
      this.puppyReleaseId = null;
    }
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
});
