
import Vue from "vue";
import QTDogRegistrationRequest from "@/components/Portal/MyRequest/QTDogRegistrationRequest.vue";
import RegistrationRequestLayout from "@/components/common/RegistrationRequestLayout.vue";
import workflowService from "@/services/admin/workflowService";

export default Vue.extend({
  name: "QTDogRequestCard",
  props: {
    requestId: { type: Number },
  },
  components: {
    RegistrationRequestLayout,
    QTDogRegistrationRequest,
  },
  data: function () {
    return {
      registration: {
        workflowGuid: "",
        requestId: 0,
      } as any,
    };
  },
  computed: {
    workflowService() {
      return workflowService;
    },
  },
  methods: {
    onRequestProcessed: function (requestId: number) {
      this.$router.push({ path: '/admin/registration/qtdogs' });
    },
  },
});
