
import Vue from "vue";
import router from "@/router";

import FooterText from "@/components/common/FooterText.vue";
import SimpleFeeTable from "@/components/common/SimpleFeeTable.vue";
import AddLesseeModal from "@/components/common/AddLesseeModal.vue";
import Datepicker from "@/components/common/inputs/DatepickerFormatted.vue";
import CoownersList, { OwnershipType } from "@/components/common/CoownersList.vue";
import AdminEditableCoownersList from "@/components/Admin/AdminEditableCoownersList.vue";

import adminService from '@/services/adminService';
import leaseService from '@/services/admin/leaseService';
import workflowService from "@/services/portal/workflowService";
import registerLeaseService from "@/services/portal/registerLeaseService";

export default Vue.extend({
  components: { AddLesseeModal, SimpleFeeTable, FooterText, CoownersList, Datepicker, AdminEditableCoownersList },
  name: "AdminRegisterLease",
  data: function () {
    return {
        lesseeDialog: false,
        dogId: '',
        dogInfo: {
          owners: [] as any[]
        } as any,
        lesseeInfo: [] as any[],
        lessees: [] as any[],
        feesTable: {} as any,
        primaryLessee: 0,
        paymentPaid: false,
        errorMessage: '',
        startDate: '',
        endDate: '',
        submissionDate: '',
        signatories: [] as any[],
        paymentNotes: '',
        fee: null,
        paymentTypes: [] as any[],
        selectedPaymentType: null,
        pendingLeaseRegistrationsUrl: '/admin/registration/leases',   
    };
  },
  computed: {
    dogOwnershipType: function() {
      return OwnershipType.Dog;
    },
    leaseOwnershipType: function() {
      return OwnershipType.Lease;
    },    
    userInfo: function() {
      return this.$store.state.userInfo;
    },
    isValid: function() {
      return this.$data.dogId  && this.$data.startDate && this.$data.endDate && this.$data.submissionDate && this.$data.lessees && this.$data.lessees.length > 0;
    }
  },
  created() {
    workflowService.getRegisterLeaseFees().then((res) => {
      this.feesTable = res;
    });
    adminService.getPaymentTypes().then((res) => {
      this.paymentTypes = res;
      this.selectedPaymentType = res[0];
    });
  },
  watch: {
    primaryLessee: function(newValue) {
      workflowService.getLeaseFeeForPerson(newValue).then((res: any) => {
        this.fee = res.fee;
      });      
    }
  },
  methods: {
    updateDates: function (val: any, key: string) {
      (this as any)[key] = val.date;
    },
    updateSelectedPeople: function (val: any) {
      this.lessees = val.newPeople;
    },
    submit() {
        this.$store.dispatch("changeLoaderStatus",true);
        this.errorMessage = '';
        const primaryOwner = this.dogInfo.owners.filter((l: any) => l.isPrimary);
        const request = {
            adminPersonId: this.userInfo.personId,
            requesterPersonId: primaryOwner[0].personId,
            dogId: this.dogId,
            startDate: this.startDate,
            endDate: this.endDate,
            submissionDate: this.submissionDate,
            lesees: this.lessees.map((l: any) => <object>{
                personId: l.personId,
                isPrimary: l.isPrimary,
            }),
            signatories: this.signatories,
            fee: this.fee,
            paymentInfo: this.paymentPaid ? {
                paymentType: this.selectedPaymentType,
                adminNotes: this.paymentNotes,
                amount: this.fee,
                isCaptured: this.paymentPaid

            } : {
                paymentType: 1,
                adminNotes: this.paymentNotes,
                amount: this.fee,
                isCaptured: this.paymentPaid
            } 
        };
        leaseService.registerNewLease(request).then((res)=>{
          if(!res.success){
            this.errorMessage = res.errorMessage;
            this.$store.dispatch("changeLoaderStatus",false);
          } else {            
            this.$store.dispatch("loadPersonTasks", this.userInfo.personId);
            this.$store.dispatch("loadAdminTasks", this.userInfo.personId);
            router.push({ path: this.pendingLeaseRegistrationsUrl });
            this.$store.dispatch("changeLoaderStatus",false);
          }
        });
    },
    loadDogInfo(){
        registerLeaseService.getLeasedDog(this.dogId).then((res) => {
            if(!res){
              this.errorMessage = "Dog information reuquired for lease not found.";  
              this.dogInfo = {} as any;        
            } else {
              this.signatories = [] as any[];
              this.dogInfo = res;
              this.errorMessage ='';
              res.owners.map((l: any)=>{
                if(!this.signatories.find(s => s.personId == l.personId)){
                  this.signatories.push(<object>{
                      personId: l.personId,
                      firstName: l.firstName,
                      lastName: l.lastName,
                      hasSigned: false
                    })
                }
              });
              this.lessees.map((l: any)=>{
                if(!this.signatories.find(s => s.personId == l.personId)){
                  this.signatories.push(<object>{
                    personId: l.personId,
                    firstName: l.firstName,
                    lastName: l.lastName,
                    hasSigned: false
                  })
                }
              });
            }
        }).catch((e:any) => {
          this.errorMessage = "Dog information reuquired for lease not found.";  
          this.dogInfo = {} as any;   
        });
    },
    changePrimaryLessee(personId: number) {
      this.primaryLessee = personId;
      const index = this.lessees.map((c) => c.personId).indexOf(personId);
      this.lessees.forEach((co) => (co.isPrimary = false));
      this.lessees[index].isPrimary = true;
      this.lesseeInfo = this.lessees;
    },
    changeLesseeInfo: function(lessees: any[]) {
      if(lessees.length === 0){
        this.primaryLessee = 0;
      } else {
        if(this.primaryLessee === 0){
          this.primaryLessee = lessees[0].personId;
          lessees[0].isPrimary = true;
        }
      }
      //add missing lessees to the list of signatories
      lessees.map((l: any)=>{
        if(!this.signatories.find(s => s.personId == l.personId)){
          this.signatories.push(<object>{
              personId: l.personId,
              firstName: l.firstName,
              lastName: l.lastName,
              hasSigned: false
            })
        }
      });
      //remove removed lessees from the list of signatories, if they're not owners
      this.signatories.map((s: any)=>{
        if(this.lessees[0] && this.lessees.find(l => l.personId == s.personId) && this.dogInfo.owners[0] && !this.dogInfo.owners.find((o: any) => o.personId == s.personId)){
          this.signatories = this.signatories.filter(x => x.personId != s.personId);
        }
      });
      this.lessees = lessees;
      this.lesseeInfo = lessees;
    },
    toggleRules: function () {
      this.$data.rulesDialog = !this.$data.rulesDialog;
    },
    toggleLesseeModal: function () {
      this.$data.lesseeDialog = !this.$data.lesseeDialog;
    },
  },
});
