
import Vue from "vue";
import juniorHandlerService from "../../services/admin/juniorHandlerService";

export default Vue.extend({
    name: "JuniorTotalPoint",
    props: {
      personId: { type: Number },
      classes: { type: [] as any[] },
      scoreType: { type: Number },
      fromPreviousDate: { type: Boolean },
    },
    data: function () {
      return {
        totalPoints: 0,
        selectedClass: null,
      };
    },
    created: function () {
      if (this.personId) {
        this.selectedClass = (this.classes as any)[0].id;
        this.reloadData();
      }
    },
    watch: {
      personId: function () {
        if (this.personId) { this.reloadData(); }
      },
      selectedClass: function () {
        this.reloadData();
      }
    },
    methods: {
      reloadData: function () {
        let year = new Date().getFullYear();

        if (this.fromPreviousDate) year -= 1;

        juniorHandlerService
          .getJuniorHandlerTotalPointsForYear(
            this.personId,
            this.scoreType,
            this.selectedClass!,
            year,
          ).then((data) => {
            this.totalPoints = data;
          });
      }
    }
});
