
import Vue from "vue";
import axios from "axios";
import { UserInfo } from "@/types/User";

export default Vue.extend({
  name: "AppNavbar",
  data() {
    return {
      items: [],
    };
  },
  props: {
    showToggle: Boolean
  },
  methods: {
    signUserOut: function () {
      axios.defaults.headers.common["Authorization"] = null;
      localStorage.removeItem("userToken");
      this.$store.commit("loadUserInfo", null);
      this.$router.push("/logout");
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
});
