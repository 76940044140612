
  import Vue from "vue";
  import { UserInfo } from "@/types/User";
  import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";
  import workflowService from '@/services/portal/workflowService';
  
  export default Vue.extend({
    name: "QTDogRegistrationSignatures",
    props: {
      readyToApproveOnly: String,
    },
    data: function () {
      return {
        loading: false,
        paginatedResponse: {} as PaginatedResponse<object>,
        menu: false,
        search: '',
        options: {} as PaginationOptions,
        headers: [
          { text: "#ID", align: "left", value: "signatureId" },
          { text: "Submitted at", align: "left", value: "submittedAt" },
          { text: "Dog Id", align: "left", value: "dogId" },
          { text: "Start date", align: "left", value: "startDate" },
          { text: "End date", align: "left", value: "endDate" },
        ],
        footerProps: { 'items-per-page-options': [10, 20, 30, 40, 50] }
      };
    },
    created() { this.loadList(); },
    watch: {
      options: {
        handler() {
          this.loadList();
        },
        deep: true,
      }
    },
    methods: {
      applyFilters: function () {
        this.menu = false;
        this.loadList();
      },
      loadList: async function () {
        this.loading = true;
        const {
          page,
          itemsPerPage,
        } = this.options;
  
        const response = await workflowService.getLeaseRegistrationSignatureRequests({
          ...this.options,
          page: (page ?? 1) - 1,
          itemsPerPage: itemsPerPage ?? 10
        });
  
        this.loading = false;
        this.paginatedResponse = response;
      }
    },
    computed: {
      userInfo(): UserInfo {
        return this.$store.state.userInfo;
      },
    },
  });
  