
import Vue from 'vue';
import FooterText from '../../../components/common/FooterText.vue';

export default Vue.extend({
    components: { FooterText },
    name: 'ActivatedJunior',
    data() {
        const parentVerification = this.$store.state.parentVerification;

        return {
            firstName: parentVerification.firstName,
            lastName: parentVerification.lastName,
        };
    },
    methods: {
        onReturn() { this.$router.push('/login'); }
    }
});
