
import Vue from "vue";
import { debounce } from "throttle-debounce";
import { uniqBy } from "lodash";
import registerLeaseService from "@/services/portal/registerLeaseService";
import { LesseeResult } from "@/types/portal/Lease";

export default Vue.extend({
  name: "AddPersonModal",
  data: function () {
    return {
      showDialog: this.$props.affiliatePeopleDialog,
      hasSearched: false,
      searchResults: [] as any[],
      uniqueSelectedAffiliatePeoples: [] as any[],
      selectedAffiliatePeoples: [...this.$props.affiliatePeople],
      membershipID: "",
      headers: [
        { text: "Name", align: "left", value: "name" },
        { text: "Membership ID", align: "left", value: "memberId" },
      ],
    };
  },
  watch: {
    affiliatePeopleDialog: function (newVal: boolean) {
      if (!newVal) {
        this.membershipID = "";
        this.hasSearched = false;
        this.searchResults = [] as LesseeResult[];
      }
      this.showDialog = newVal;
    },
    showDialog: function (newVal: boolean) {
      //this is handling situation when the user closes dialog
      //by clicking outside of it
      if (!newVal) {
        this.membershipID = "";
        this.hasSearched = false;
        this.searchResults = [] as LesseeResult[];
        (this.selectedAffiliatePeoples = [...this.$props.affiliatePeople]),
          this.toggleAffiliatePeopleDialog();
      }
    },
    membershipID: {
      handler: debounce(
        1000,
        function (this: any, newVal: string, oldVal: string) {
          if (newVal === oldVal || !newVal) return;
          this.search();
        }
      ),
      deep: true,
    },
    selectedAffiliatePeoples: function (){
      this.uniqueSelectedAffiliatePeoples = uniqBy(this.selectedAffiliatePeoples, mem => (mem as any).personId);
    }
  },
  methods: {
    search: function () {
      this.hasSearched = false;
      registerLeaseService
        .searchLessee(this.membershipID)
        .then((response: LesseeResult[]) => {
          this.hasSearched = true;
          this.searchResults = response;
        });
    },
    isValidCoOwner(coowner: LesseeResult) {
      return coowner.canBeCoowner;
    },
    isValidOfficer(coowner: LesseeResult) {
      return coowner.memberType === 1 || coowner.memberType === 2 || coowner.memberType === 6 || coowner.memberType === 4
    },
    addAffiliatePeople(row: LesseeResult) {
      if (!row.canBeCoowner) return;
      this.searchResults = [] as LesseeResult[];
      this.hasSearched = false;
      this.membershipID = "";
      let item = {
        firstName: row.firstName,
        lastName: row.lastName,
        memberId: row.memberId,
        personId: row.personId,
        position: row.memberType === 5 ? "Member" : "",
        memberType: row.memberType,
      };
      this.selectedAffiliatePeoples.push(item);
    },
    removeAffiliatePeople(row: LesseeResult) {
      this.selectedAffiliatePeoples.splice(this.selectedAffiliatePeoples.indexOf(row), 1);
      this.changeAffiliatePeople(this.selectedAffiliatePeoples);
    },
    submit() {
      this.changeAffiliatePeople(this.selectedAffiliatePeoples);
      this.showDialog = false;
    },
  },
  props: {
    changeAffiliatePeople: Function,
    affiliatePeopleDialog: Boolean,
    toggleAffiliatePeopleDialog: Function,
    affiliatePeople: Array,
  },
});
