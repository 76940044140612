
import Vue from "vue";
export default Vue.extend({
  name: "DatepickerFormatted",
  props: {
    date: String,
    label: String,
    disabled: Boolean,
    disableClearable: Boolean,
    rules: Array,
  },
  created: function () {
    this.internalFormattedDate = this.formatDate(this.date);    
  },
  data: function () {
    return {
      menu: false,
      internalDate: this.$props.date,
      internalFormattedDate: ''
    };
  },
  computed: {
    century() { return new Date().getFullYear().toString().slice(0, 2); },
    parsedRules: function () {
      if (!!this.rules && !!this.rules.length) {
        return this.rules;
      } else {
        return [];
      }
    },
  },
  methods: {
    validateDate(v: string) {
      if (v === undefined || v === "" || v === null) return true;
      const [month, day, year] = v.split("/");

      let valid =
        !isNaN(Date.parse(v)) &&
        !!year &&
        !!month &&
        !!day &&
        (year.length === 2 || year.length === 4);
      return valid || "Invalid Date Format";
    },
    formatDate(date: String) {
      if (!date) return "";
      if (date.includes("T")) date = date.split("T")[0];

      const [rawYear, month, day] = date.split("-");

      let year = rawYear;
      if (year.length === 2) {
        year = `${this.century}${rawYear}`;
      }

      return `${month}/${day}/${year}`;
    },
    parseDate(date: String) {
      if (!date) return "";

      const [month, day, rawYear] = date.split("/");

      let year = rawYear;
      if (year.length === 2) year = `${this.century}${rawYear}`;

      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getUpdateData() {
      return {
        formattedDate: this.internalFormattedDate,
        date: !this.internalDate ? null : this.internalDate,
      };
    },
    onEnter: async function() {
      this.$emit("enter", this.getUpdateData());
    }
  },
  watch: {
    date(val) {
      this.internalDate = val;
      this.internalFormattedDate = this.formatDate(val);
    },
    internalDate(val) {
      this.internalFormattedDate = this.formatDate(val);
      this.$emit("updateDates", this.getUpdateData());
    }
  },
});
