import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueHtmlToPaper from "vue-html-to-paper";
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faCog,
    faBell,
    faChevronDown,
    faCheck,
    faLongArrowAltLeft,
    faTimes,
    faPlus,
    faMinus,
    faSignature,
    faFilter,
    faSortAmountDown,
    faCreditCard,
    faAward,
    faIdCard,
    faPencilAlt,
    faPaw,
    faInfoCircle,
    faEnvelope,
    faGlobe,
    faPhone,
    faFileDownload,
    faNewspaper,
    faChevronUp,
    faLink,
    faSearch,
    faArrowRight,
    faArrowAltCircleRight,
    faArrowCircleRight,
    faRecycle,
    faIdBadge,
    faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dateUtils from "./helpers/date-utils";

import Default from "@/components/Layouts/Default.vue";
import NoSidebars from "@/components/Layouts/NoSidebars.vue";
import LeftSidebar from "@/components/Layouts/LeftSidebar.vue";
import Empty from "@/components/Layouts/Empty.vue";
import { env } from './environment/environment';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';

Vue.prototype.$dateUtils = dateUtils;

library.add(
    faCog,
    faBell,
    faChevronDown,
    faChevronUp,
    faCheck,
    faLongArrowAltLeft,
    faTimes,
    faPlus,
    faMinus,
    faPencilAlt,
    faFacebookSquare,
    faYoutube,
    faSignature,
    faFilter,
    faSortAmountDown,
    faCreditCard,
    faAward,
    faIdCard,
    faPaw,
    faInfoCircle,
    faPhone,
    faGlobe,
    faEnvelope,
    faFileDownload,
    faNewspaper,
    faLink,
    faSearch,
    faArrowRight,
    faArrowAltCircleRight,
    faArrowCircleRight,
    faRecycle,
    faIdBadge,
    faTrashAlt,
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.component("default-layout", Default);
Vue.component("no-sidebar-layout", NoSidebars);
Vue.component("left-sidebar-layout", LeftSidebar);
Vue.component("empty-layout", Empty);
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline);
import "./scss/main.scss";

Vue.config.productionTip = false;

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
    `${env.VUE_APP_REDIRECT_URL}/printstyle.css`,
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};

Vue.use(VueHtmlToPaper, options);
Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.filter('truncate', function (text: string, stop: number, clamp?: string) {
	return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
