
import Vue from 'vue';
import PeopleList from "./Searches/PeopleList.vue";
import AdminCoownersList from "./AdminCoownersList.vue";

import peopleService from "@/services/admin/peopleService";

export default Vue.extend({
  name: 'AdminPersonSearchDialog',
  props: {
    personId: { type: Number },
    isEditMode: { type: Boolean },
    hideSearch: { type: Boolean },
    hideOwners: { type: Boolean },
    label: { type: String },
  },
  components: {
    AdminCoownersList,
    PeopleList
  },
  data: function() {
      return {
        showPersonSearchDialog: false,
        personInfo: {} as any,
        selectedPersonId: this.$props.personId
      };
  },
  created: function () {
    if(!!this.selectedPersonId){
      this.loadPersonInfo();
    }
  },
  methods: { 
    onPersonSelected: function({personId}: any){
        this.showPersonSearchDialog = false;
        this.selectedPersonId = personId;
        this.loadPersonInfo();
    },
    loadPersonInfo: function() {
        this.$store.dispatch("changeLoaderStatus",true);
        peopleService.getPersonById(this.selectedPersonId).then((res: any) => {
          if(res){
            this.personInfo = res;
          } else {
            this.personInfo = {};
          }
        }).catch(e => {
          console.log(e); 
          this.personInfo = {};
        })
        .finally(() => {
          this.$store.dispatch("changeLoaderStatus",false);
        });
    },
  },
  watch: {
    personId: function(newVal, oldVal){
      if(newVal != oldVal)
        if(newVal){
          this.selectedPersonId = newVal;
          this.loadPersonInfo();
        } else {
          this.selectedPersonId = newVal;
          this.personInfo = {};        
        }       
    },
    selectedPersonId: function(newVal, oldVal){      
      this.$emit('update:personId', newVal);
    }
  }
});
