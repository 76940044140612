
import Vue from "vue";
import * as _ from "lodash";
import DatepickerFormatted from "../../../common/inputs/DatepickerFormatted.vue";
import showService from "@/services/admin/showService";

export default Vue.extend({
  name: "StockResultsSummaryEntry",
  components: {
    DatepickerFormatted
  },
  data: function() {
    return {
      showDeleteConfirmationDialog: false,
      deletedItem: null as any,
      showResults: [] as any[],
      persistedShowResults: [] as any[],
      errorMessage: null as any,
      emptyRow: null as any,
    };
  },
  methods: {    
    emptyShowResultRow: function(lastPersistedRow: any, showResults: any[]) {
      if (!lastPersistedRow && !!showResults && showResults.length > 0) {
        lastPersistedRow = Object.assign({}, showResults[showResults.length - 1]);
      }

      return !!lastPersistedRow ?
        {
          showDate: lastPersistedRow.showDate,
          showTime: lastPersistedRow.showTime,
          categoryId: null,
          countOfDogs: null,
          showId: this.showId,
        }
      :
        {
          showDate: null,
          showTime: null,
          categoryId: null,
          countOfDogs: null,
          showId: this.showId,
        };
    },  
    reloadData: function() {
      if (this.showId) {
        this.$store.dispatch("changeLoaderStatus",true);
        showService
            .getStockResultsSummaryList(this.showId)
            .then((res) => {
                this.showResults = _.cloneDeep(res);
                this.persistedShowResults = _.cloneDeep(res);
                this.emptyRow = Object.assign({}, this.emptyShowResultRow(null, res));
            })
            .catch((e) => console.log(e))
            .finally(() => { 
                this.$store.dispatch("changeLoaderStatus",false);
            }); 
      }
    }, 
    rowClasses: function(item: any, index: number){
      if (!!item.id)
      {
        const index = this.showResults.findIndex(i => i.id === item.id);
        if(_.isEqual(this.showResults[index], this.persistedShowResults[index])){
          return '';
        }
      } 
      return 'orange  lighten-4';
    },
    saveShowResult: function(item: any, index: any) {
      this.$store.dispatch("changeLoaderStatus",true);
      const isAddingNewRow = !item.id;

      showService.updateStockResultsSummary(this.showId, item)
        .then((res) => {
          if(!res.success){
            this.errorMessage = res.messages.join(", ");
            if(isAddingNewRow){
              const input = document.querySelector('#srEmptyDate') as HTMLElement | null;

              if (input != null) {
                  input.focus();
              }
            }
          } else {
            if(isAddingNewRow){
              this.emptyRow.id = res.id;
              this.showResults.unshift(this.emptyRow);
              this.persistedShowResults = _.cloneDeep(this.showResults);

              this.emptyRow = Object.assign({}, this.emptyShowResultRow(this.emptyRow, this.showResults));
              const input = document.querySelector('#srEmptyDate') as HTMLElement | null;

              if (input != null) {
                  input.focus();
              }
            } else {
              this.persistedShowResults = _.cloneDeep(this.showResults);
            }
            this.errorMessage = null;
          }
        })
        .catch((e) => {
          this.errorMessage = "Changes couldn't be saved. Ensure there are no validation errors before retrying.";
        })
        .finally(() => { this.$store.dispatch("changeLoaderStatus",false);}); 
    },
    deleteShowResult: function(item: any) {
      if (item.id) {//if that item has been persisted already
        this.deletedItem = item;
        this.showDeleteConfirmationDialog = true;
      } else {
        const index = this.showResults.indexOf(item);
        this.showResults.splice(index, 1);
      }
    },
    confirmDelete: function() {
      if(this.deletedItem == null) return;

      showService.deleteStockResultsSummary(this.showId, this.deletedItem)
      .then((res) => {
        if(!res.success){
          const index = this.showResults.map(sr => sr.id).indexOf(this.deletedItem.id);
          this.showResults[index].errors = res.messages.join(", ");
          this.deletedItem = null;
        } else {
          const index = this.showResults.map(sr => sr.id).indexOf(this.deletedItem.id);
          this.showResults.splice(index, 1);
          this.persistedShowResults.splice(index, 1);
        }   
      })
      .finally(() => {this.showDeleteConfirmationDialog = false});
    },
    updateDates: function (val: any, key: string, item: any) {
      item[key] = val?.date;
      //workaround to force rerendering after setting up formatted date
      this.$set(this, 'showResults', this.showResults);
    },
  },    
  created: function() {
    this.reloadData();
  },
  watch: {
    showId: function(newVal: String, oldVal: String) {
      if(newVal && newVal != oldVal){
        this.reloadData();
      }
    },
  },
  props: {
    showId: { type: String },
    stockResultCategories: { type: [] as any[]}
  },
});
