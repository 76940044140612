import { api } from "../api";
import { AxiosResponse } from "axios";

import DataResponse from "@/types/DataReponse";
import { AdminMemberInfo } from '@/types/admin/Person';

export const generateMemberId = async (request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/members/generateId`, request);
    return data;
};

export const searchLinkableMember = async (request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/members/linkable/search`, request);
    return data;
};

export const linkToMember = async (request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/members/link`, request);
    return data;
};

export const getMembershipTypes = async (): Promise<any> => {
    const { data } = await api.get<object[]>(`/Admin/members/types`);

    return data;
};

export const getMemberByPersonId = async (personId: Number): Promise<AdminMemberInfo> => {
    const { data } = await api.get<AdminMemberInfo>(`/Admin/members/details/${personId}`);

    return data;
};

export const validateMemberEdit = async (request: AdminMemberInfo): Promise<object> => {
    const { data } = await api.post<object>(`/Admin/members/details/validate`, request);
    return data;
};

export const editMember = async (request: AdminMemberInfo): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/members/details/edit`, request);
    return data;
};

export const updateMembersOnlyInfo = async (html: string) => {
    const { data } = await api.post<{ html: string }, AxiosResponse<DataResponse<string>>>(`/Admin/members-only`, { html });
    return data;
};

export default {
    getMemberByPersonId,
    validateMemberEdit,
    editMember,
    getMembershipTypes,
    generateMemberId,
    searchLinkableMember,
    linkToMember,
    updateMembersOnlyInfo,
}