import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        blue: '#0072AE',
        accent: '#B2CCE5',
        orange: '#EA6A35',
        primary: '#0072AE',
        darkBlue: '#143655',
        navy: '#0072AE',
        limeGreen: '#8DC319',
        green: '#4CAF50',
        black: '#000000',
        darkGray: '#222222',
        lightGray: '#666666',
        white: '#FFFFFF',
        red: '#BE1D1D',
        background: '#FAFAFA'
      },
    },
  },
});
