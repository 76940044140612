
import Vue from "vue";
import LeaseRequestForm1 from "./Steps/LeaseRequestForm1.vue";
import LeaseRequestForm2 from "./Steps/LeaseRequestForm2.vue";
import LeaseRequestForm3 from "./Steps/LeaseRequestForm3.vue";

import { UserInfo } from "@/types/User";
import AuthorizeNetResponseData from "@/types/AuthorizeNetResponseData";

import registerLeaseService from "@/services/portal/registerLeaseService";
import workflowService from "@/services/portal/workflowService";

const registerDogImage = require('@/assets/register-dog.png');

export default Vue.extend({
  name: "LeaseRequest",
  data: function () {
    return {
      registerDogImage,
      dogInfo: {} as any,
      validationInfo: {} as any,
      currentPage: 1,
      lessees: [] as any[],
      primaryLessee: 0,
      dates: ["", ""],
      priceInfo: { fullMemberPrice: 0, serviceMemberPrice: 0 },
      signature: "",
      status: {},
    };
  },
  components: {
    LeaseRequestForm1,
    LeaseRequestForm2,
    LeaseRequestForm3,
  },
  methods: {
    changePage(page: number) {
      this.currentPage = page;
    },
    changeLessees(lessees: any[]) {
      if(lessees.length === 0){
        this.primaryLessee = 0;
      } else {
        if(this.primaryLessee === 0){
          this.primaryLessee = lessees[0].personId;
          lessees[0].isPrimary = true;
        }
      }
      this.lessees = lessees;
    },
    changePrimaryLessee(personId: number) {
      this.primaryLessee = personId;
      const index = this.lessees.map((c) => c.personId).indexOf(personId);
      this.lessees.forEach((co) => (co.isPrimary = false));
      this.lessees[index].isPrimary = true;
    },
    updateSignature(signature: string) {
      this.signature = signature;
    },
    changeDates(dates: string[]) {
      if (Date.parse(dates[1]) < Date.parse(dates[0])) {
        dates = dates.reverse();
      }
      this.dates = dates;
      this.$nextTick(() => {
        if (dates[1] !== "" && dates[1] && dates[0] !== "" && dates[0]) {
          registerLeaseService
            .validateLeaseDates(
              this.dogInfo.dogId,
              dates[0],
              dates[1],
              this.userInfo.personId
            )
            .then((res) => {
              this.$data.validationInfo = res;
            });
        }
      });
    },
    submitRequest(paymentInfo: AuthorizeNetResponseData, signature: string) {
      const lessees = this.lessees;
      let req = {
        requesterPersonId: this.userInfo.personId,
        dogId: this.dogInfo.dogId,
        startDate: this.dates[0],
        endDate: this.dates[1],
        lesees: lessees,
        requesterSignatureText: signature,
        fee: this.validationInfo.fee,
        paymentInfo
      };
      registerLeaseService.submitLeaseRequest(req).then((res) => {
        this.status = res;
      });
    },
  },
  created() {
    registerLeaseService.getLeasedDog(this.$props.dogId).then((res) => {
      this.$data.dogInfo = res;
    });
    workflowService.getRegisterLeaseFees().then((res) => {
      this.$data.priceInfo = res;
    });
  },
  props: {
    dogId: String,
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
});
