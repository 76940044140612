
import Vue from "vue";
import { debounce } from "throttle-debounce";
import { uniqBy } from "lodash";

import AddPersonModal from "@/components/common/AddPersonModal.vue";
import CountryDropdown from '@/components/common/inputs/CountryDropdown.vue';
import StateDropdown from '@/components/common/inputs/StateDropdown.vue';
import DatepickerFormatted from "@/components/common/inputs/DatepickerFormatted.vue";
import InternationalPhoneNumberInput from '@/components/common/inputs/InternationalPhoneNumberInput.vue';

import showService from "@/services/admin/showService";
import affiliateService from "@/services/admin/affiliateService";
import registerLeaseService from "@/services/portal/registerLeaseService";
import registerAffiliateService from "@/services/portal/registerAffiliateService";

import validateEmail from '@/helpers/validate-email';
import { Messages } from "@/constants";

import { LesseeResult } from "@/types/portal/Lease";
import { PointSchedule } from "@/types/admin/Show";

// TODO: Once time allows debounce the email validation here
export default Vue.extend({
  name: "AffiliateDetails",
  components: {
      AddPersonModal,
      DatepickerFormatted,
      CountryDropdown,
      StateDropdown,
      InternationalPhoneNumberInput
  },
  props: {
    affiliateId: { type: String },
  },
  data: function () {
      return {
          isAddressValid: true,
          affiliateInfo: {} as any,
          isEditMode: false,
          showValidationDialog: false,
          dialog: false,
          dialogDelete: false,
          validationResult: {} as any,
          showMembersDialog: false,
          affiliatePeopleDialog: false,
          affiliateMemberTypes: {},
          hasSearched: false,
          searchResults: [] as any[],
          membershipID: "",
          affiliatePeople: [] as any,
          selectedMembers: [] as any[],
          uniqueSelectedMembers: [] as any[],
          pointSchedules: [] as PointSchedule[],
          pointScheduleIds: [] as any[],
          positionOptions: [ "", "President", "Affiliate Rep", "Vice President", "Second VP", "Director", "Secretary", "Treasurer", "Show Coordinator", "Member" ],
          headers: [
              { text: 'Position', align: 'start', sortable: true, value: 'position', width: '30%' },
              { text: 'Name', value: 'name', align:'center' },
              { text: 'Member Id', value: 'memberId' },
          ],
          isLoaded: false,
          errorMessage: '',
          infoMessage: '',
          showErrorMessage: false,
          showInfoMessage: false
      };
  },
  created: function () {
      this.reloadData();
      registerAffiliateService.getAffiliateMemberTypes().then((res: any) => {
          this.affiliateMemberTypes = res.memberTypes;
      });
      showService.getPointSchedules().then((res: any) => {
          this.pointSchedules = res;
          this.pointScheduleIds.push("");
          for (let i = 0; i < this.pointSchedules.length; i++) {
              this.pointScheduleIds.push(this.pointSchedules[i].pointScheduleId);
          }
      });
  },
  watch: {
      affiliateId: function (newVal: String) {
          affiliateService
              .getAffiliateById(this.$props.affiliateId)
              .then((res) => {
                  this.affiliateInfo = res;
              });
      },
      showMembersDialog: function (newVal: boolean) {
          //this is handling situation when the user closes dialog
          //by clicking outside of it
          if (!newVal) {
              this.membershipID = "";
              this.hasSearched = false;
              this.searchResults = [] as LesseeResult[];
              
          }
      },
      membershipID: {
          handler: debounce(
              1000,
              function (this: any, newVal: string, oldVal: string) {
                  if (newVal === oldVal || !newVal) return;
                  this.search();
              }
          ),
          deep: true,
      },
      selectedMembers: function (){
        this.uniqueSelectedMembers = uniqBy(this.selectedMembers, mem => (mem as any).personId);
      }
  },
  methods: {
      reloadData: function () {
          this.isLoaded = false;
          if (this.affiliateId) {
              affiliateService
                  .getAffiliateById(this.affiliateId)
                  .then((res) => {
                      this.affiliateInfo = res;
                      this.isEditMode = false;
                      this.fillSelectedMembers();
                      this.isLoaded = true;
                  });
          }
          else {
              this.affiliateInfo = {} as any;
              this.affiliateInfo.affiliateContact = {} as any;
              this.affiliateInfo.affiliateRep = {} as any;
              this.isEditMode = true;
              this.selectedMembers = [] as any[];
          }

          this.showValidationDialog = false;
          this.errorMessage = '';
          this.showErrorMessage = false;
          this.validationResult = {};
      },
      validateAndConfirmSavingChanges: function () {
          if (this.isEverythingValid()) {
              affiliateService
                  .validateAffiliateEdit(this.affiliateInfo)
                  .then((res: any) => {
                      this.validationResult = res;
                      if (this.validationResult.errors.length > 0 || this.validationResult.warnings.length > 0) {
                          this.showValidationDialog = true;
                      }
                      else {
                          this.saveChanges();
                          this.validationResult = {};
                      }
                  })
          }
          else {
              this.isEditMode = false; //will toggle it back to edit mode
          }
          
      },      
      toggleEditMode: function () {
          if (this.isEditMode) {
              this.validateAndConfirmSavingChanges();
          }

          this.isEditMode = !this.isEditMode;
      },
      saveChanges: function () {
          this.errorMessage = "";
          this.showErrorMessage = false;
          this.showValidationDialog = false;
          this.$store.dispatch("changeLoaderStatus", true);

          affiliateService
              .editAffiliateById(this.affiliateInfo)
              .then((res) => {
                  if (res.success) {
                      if (res.id && res.id != this.$props.affiliateId) {
                          this.$router.push({ path: `/admin/affiliates/${res.id}` }).catch(() => { });
                      }
                      this.reloadData();
                      this.isEditMode = false;
                      this.infoMessage = Messages.SaveSuccessful;
                      this.showInfoMessage = true;
                  } else {              
                      this.errorMessage = Messages.SaveError + " " + res.messages.join(', ');
                      this.showErrorMessage = true;
                  }
              })
              .catch((e) => {                  
                this.errorMessage = Messages.SaveError;
                  this.showErrorMessage = true;
              })
              .finally(() => this.$store.dispatch("changeLoaderStatus", false));


      },
      updateDates: function (val: any, key: string) {
          this.$data.affiliateInfo[key] = val?.date;
          this.$data.affiliateInfo = Object.assign({}, this.$data.affiliateInfo);
      },
      updateText: function (key: string, event: string) {
          this.$data.affiliateInfo[key] = event;
      },
      toggleMembersDialog: function () {
          this.showMembersDialog = !this.showMembersDialog;
          if (this.showMembersDialog) {
              this.fillSelectedMembers();
          }
      },      
      fillSelectedMembers() {
          this.selectedMembers = [] as any;
          if (this.affiliateInfo.members) {
              for (let i = 0; i < (this as any).affiliateInfo.members.length; i++) {
                  this.selectedMembers.push({
                      position: (this as any).affiliateInfo.members[i].position,
                      isPrimary: (this as any).affiliateInfo.members[i].isPrimary,
                      memberId: (this as any).affiliateInfo.members[i].memberId,
                      personId: (this as any).affiliateInfo.members[i].personId,
                      firstName: (this as any).affiliateInfo.members[i].firstName,
                      lastName: (this as any).affiliateInfo.members[i].lastName,
                      name: (this as any).affiliateInfo.members[i].name,
                      positionId: (this as any).affiliateInfo.members[i].positionId,
                  });
              }
          }  
      },
      addSelectedMember(row: LesseeResult) {
          if (!row.canBeCoowner) return;
          this.searchResults = [] as LesseeResult[];
          this.hasSearched = false;
          
          let item = {
              firstName: row.firstName,
              lastName: row.lastName,
              memberId: row.memberId,
              personId: row.personId,
              position: row.memberType === 5 ? "Member" : "",
              memberType: row.memberType,
              name: row.firstName + ' ' + row.lastName,
              isPrimary: false,
          };
          this.selectedMembers.push(item);
      },
      removeSelectedMember(row: LesseeResult) {
          this.selectedMembers.splice(this.selectedMembers.indexOf(row), 1); 
      },
      saveMemberChanges() {
          if (this.affiliateInfo.members) {
              while (this.affiliateInfo.members.length > 0) {
                  this.affiliateInfo.members.pop();
              }
          }
          this.affiliateInfo.members = [] as any[];

          if (this.selectedMembers.length) {
              for (let i = 0; i < (this as any).selectedMembers.length; i++) {
                  this.affiliateInfo.members.push({
                      position: (this as any).selectedMembers[i].position,
                      isPrimary: (this as any).selectedMembers[i].isPrimary,
                      memberId: (this as any).selectedMembers[i].memberId,
                      personId: (this as any).selectedMembers[i].personId,
                      firstName: (this as any).selectedMembers[i].firstName,
                      lastName: (this as any).selectedMembers[i].lastName,
                      name: (this as any).selectedMembers[i].name,
                      positionId: (this as any).selectedMembers[i].positionId,
                  });


              }
          }

          this.showMembersDialog = false;

      },
      isValidNumber(value: string) {
          if (!value) {
              return true;
          }
          if (!isNaN(parseFloat(value))) return true;

          return 'Must be valid number';
          
      },
      isValidCoOwner(coowner: LesseeResult) {
          return coowner.canBeCoowner;
      },
      isValidOfficer(coowner: LesseeResult) {
          return coowner.memberType === 1 || coowner.memberType === 2 || coowner.memberType === 6 || coowner.memberType === 4
      },
      search: function () {
          this.hasSearched = false;
          registerLeaseService
              .searchLessee(this.membershipID)
              .then((response: LesseeResult[]) => {
                  this.hasSearched = true;
                  this.searchResults = response;
              });
      },
      submit() {
          this.saveMemberChanges();
          this.showMembersDialog = false;
      },
      warn(message: string) {
          this.showErrorMessage = true;
          this.errorMessage = message
      },
      isEverythingValid() {
          this.errorMessage = "";
          this.showErrorMessage = false;
          
          if (!this.affiliateInfo.members) {
              this.warn("Affiliate President and Rep are required.");
              return false;
          }

          var emptyList = this.affiliateInfo.members.find((x: any) => x.position == "");
          var presidentList = this.affiliateInfo.members.filter((x: any) => x.position == "President");

          if (emptyList) {
              this.warn("All members must have an assigned position");
              return false;
          }

          if (!presidentList.length) {
              this.warn("Must include a president");
              return false;
          } else if (presidentList.length > 1)
          {
              this.warn("Can't have more than one president");
              return false;
          }

          if (this.affiliateInfo.membershipExpiryDate == null) {
              this.warn("Affiliate Club expiration date is required.");
              return false;
          }
          for (let i = 0; i < (this as any).affiliateInfo.members.length; i++) {
              let position = (this as any).affiliateInfo.members[i].position;
              let filteredPosition = this.$data.affiliateMemberTypes.filter(
                  (obj: { memberTypeDescription: any; }) => {
                      return obj.memberTypeDescription === position;
                  }
              );
              this.affiliateInfo.members[i].positionId = filteredPosition[0].memberType;
          }

          if (this.affiliateInfo.homeState && this.affiliateInfo.homeState.length > 2) {
              this.warn("Home State is too long.");
              return false;
          }

          if (this.affiliateInfo.affiliateName && this.affiliateInfo.affiliateName.length > 50) {
              this.warn("Affiliate Name is too long.");
              return false;
          } else if (!this.affiliateInfo.affiliateName) {
              this.warn("Affiliate Name is required.");
              return false;
          }
        
          if (this.affiliateInfo.email && this.affiliateInfo.email.length > 255) {
              this.warn("Email is too long.");
              return false;
          }
        
          if (this.affiliateInfo.termOfOffice && isNaN(parseFloat(this.affiliateInfo.termOfOffice))) {
              this.warn("Invalid Term Of Office, must be a number.");
              return false;
          }

          if (this.affiliateInfo.region && isNaN(parseFloat(this.affiliateInfo.region))) {
              this.warn("Invalid Region, must be a number.");  
              return false;
          }

          if (this.affiliateInfo.termOfOffice && isNaN(parseFloat(this.affiliateInfo.termOfOffice))) {
              this.warn("Invalid Term Of Office, must be a number.");
              return false;
          }

          if (this.affiliateInfo.bitchCount && isNaN(parseFloat(this.affiliateInfo.dogCount))) {
              this.warn("Invalid Count, must be a number.");
              return false;
          }

          if (this.affiliateInfo.dogCount && isNaN(parseFloat(this.affiliateInfo.bitchCount))) {
              this.warn("Invalid Count, must be a number."); 
              return false;
          }

          if (this.affiliateInfo.altDogCount && isNaN(parseFloat(this.affiliateInfo.altDogCount))) {
              this.warn("Invalid Count, must be a number.");  
              return false;
          }

          if (this.affiliateInfo.altBitchCount && isNaN(parseFloat(this.affiliateInfo.altBitchCount))) {
              this.warn("Invalid Count, must be a number.");
              return false;
          }
          
          if (!!this.affiliateInfo.affiliateContact.international && (!this.affiliateInfo.affiliateContact.street || !this.affiliateInfo.affiliateContact.country)) {
              this.warn("Must provide a valid international mailing address: international, street, country");
              return false;
          } else if (!this.affiliateInfo.affiliateContact.international && (
              !this.affiliateInfo.affiliateContact.city
              || !this.affiliateInfo.affiliateContact.stateAbbreviation
              || !this.affiliateInfo.affiliateContact.zip
              || !this.affiliateInfo.affiliateContact.country
          )) {
              this.warn("Must provide a valid mailing address: city, state/province, zip, country");
              return false;
          }
        
          return true;
      },
      validateEmail(v: string) {
          return (
              validateEmail(v) ||
              'E-mail must be valid'
          );
      },
      validateWWWUrl(v: string) {
          if (!(/^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(v))) {
              return 'Url must be valid';
          } else if ((v).length >= 255) {
              return 'Affiliate Website is limited to 255 chars';
          }

          return true;
      },
      getUniqueMembersCount(members: any[]) {
        return (uniqBy(members, mem => (mem as any).personId)).length;
      }
  },
});
