
import Vue from "vue";
import Signature from "@/components/common/inputs/Signature.vue";
import LitterFeeTable from "@/components/Portal/LitterFeeTable.vue";
import LitterSignature from "@/components/common/LitterSignature.vue";
import FooterText from "@/components/common/FooterText.vue";
import HostedRegistrationPayment from "@/components/common/HostedRegistrationPayment.vue";

import router from "@/router";
import { UserInfo } from "@/types/User";

const thumbsUpImage = require('@/assets/thumbs-up-duotone.svg');

export default Vue.extend({
  name: "RegisterLitterForm3",
  components: {
    Signature,
    LitterFeeTable,
    LitterSignature,
    FooterText,
    HostedRegistrationPayment,
  },
  data: function () {
    return {
      thumbsUpImage,
      dialog: false,
      dialogError: false,
      showAllCoOwners: false,
      isValid: true,
      processingRequest: false,
      sig: this.$props.signature,
      availableKennels: this.$props.damInfo.ownersKennels,
      errorMessage: "",
    };
  },
  props: {
    changePage: Function,
    sendRegistrationRequest: Function,
    status: Object,
    damInfo: Object,
    updateSignature: Function,
    signature: String,
    sireInfo: Array,
    puppies: Object,
    fee: Number,
    selectedKennels: Array,
    priceInfo: Array,
  },
  watch: {
    status: function (newVal: any, oldVal: any) {
      if (newVal.success) {
        this.openModal();
      } else if (newVal.data.error) {            // Actual descriptive message received from the API
        this.errorMessage = newVal.data.error;
        this.openErrorModal();
      } else if (newVal.message) {                        // Likely "Request failed with status code 500" or something, but definitely an error
        this.errorMessage = newVal.message;
        this.openErrorModal();
      } else {                                            // Something really weird happened in this case
        this.errorMessage = "An unknown error occurred.";
        this.openErrorModal();
      }
    },
    dialog: function (newVal: boolean, oldVal: boolean) {
      if (!newVal) {
        this.$store.dispatch("loadPersonTasks", this.userInfo.personId);
        router.push({ path: "/" });
      }
    },
    dialogError: function (newVal: boolean, oldVal: boolean) {
      if (!newVal && this.status?.data?.paymentIssue) {
        this.processingRequest = false;
        this.$store.dispatch("loadPersonTasks", this.userInfo.personId);
        router.push({ path: "/" });
      } else if (!newVal) {
        this.processingRequest = false;
      }
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    litterOwners() {
      if (!!this.damInfo.lease) {
        return this.damInfo.lease.lesees;
      } else {
        return this.damInfo.primaryOwners;
      }
    },
  },
  methods: {
    goBack: function() {
      this.updateSignature(this.sig);
      this.changePage(2);
    },
    toggleShowAll: function () {
      this.$data.showAllCoOwners = !this.$data.showAllCoOwners;
    },
    openModal: function () {
      this.$data.dialog = true;
    },
    openErrorModal: function () {
      this.$data.dialogError = true;
    },
  },
});
