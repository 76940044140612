const reducePeopleToAffiliateMembers = (affiliatePeople: any[], affiliateMemberTypes: any): any[] => {
    const affiliateMembers: any[] = [];

    affiliatePeople.forEach((person: { position: string; memberId: string; }) => {
        if (
          person.position !== "Primary Show Coordinator" &&
          person.position !== "Secretary & Treasurer"
        ) {
          let filteredPosition = affiliateMemberTypes.filter(
            (obj: { memberTypeDescription: any; }) => {
              return obj.memberTypeDescription === person.position;
            }
          );
          affiliateMembers.push({
            ...person,
            memberId: person.memberId,
            positionId: filteredPosition[0].memberType,
            position: filteredPosition[0].memberTypeDescription,
          });
        } else if (person.position === "Primary Show Coordinator") {
          let filteredPosition = affiliateMemberTypes.filter(
            (obj: { memberTypeDescription: string; }) => {
              return obj.memberTypeDescription === "Show Coordinator";
            }
          );
          affiliateMembers.push({
            ...person,
            memberId: person.memberId,
            positionId: filteredPosition[0].memberType,
            isPrimary: true,
            position: filteredPosition[0].memberTypeDescription,
          });
        } else if (person.position === "Secretary & Treasurer") {
          let secretary = affiliateMemberTypes.filter((obj: { memberTypeDescription: string; }) => {
            return obj.memberTypeDescription === "Secretary";
          });
          affiliateMembers.push({
            ...person,
            memberId: person.memberId,
            positionId: secretary[0].memberType,
            position: secretary[0].memberTypeDescription,
          });
          let treasurer = affiliateMemberTypes.filter((obj: { memberTypeDescription: string; }) => {
            return obj.memberTypeDescription === "Treasurer";
          });
          affiliateMembers.push({
            ...person,
            memberId:person.memberId,
            positionId: treasurer[0].memberType,
            position: treasurer[0].memberTypeDescription,
          });
        }
    });

    return affiliateMembers;
};

export default reducePeopleToAffiliateMembers;