
import Vue from "vue";
import { validatePasswordLength, validatePasswordUpperChar, validatePasswordNumber } from '@/helpers/validate-password';

export default Vue.extend({
  name: "PasswordRules",
  props: {
    password: String
  },
  computed: {
    lengthValid() {
      return validatePasswordLength(this.password);
    },
    upperCharValid() {
      return validatePasswordUpperChar(this.password);
    },
    numberValid() {
      return validatePasswordNumber(this.password);
    }
  }
});
