
import Vue from "vue";
export default Vue.extend({
    name: "CookiePrompt", methods: {
        cookieClickedAccept(e: any) {
        },
        cookieClickedDecline(e: any) {
        },
        cookieClickedPostpone(e: any) {
        },
        cookieRemovedCookie(e: any) {
        },
        cookieStatus(e: any) {
        },
    }
});
