
import Vue from "vue";
import authenticationService from "../../services/authenticationService";
import jwt_decode from "jwt-decode";

export default Vue.extend({
  name: "Login",
  data: function () {
    return {
      username: "",
      password: "",
      rememberLogin: false,
      isValid: true,
      isProcessing: false,
      errorMessage: "",
      isError: false,

      isFrozen: false,
      frozenResponse: {},
    };
  },
  created() {
    const rememberMe = JSON.parse(localStorage.getItem("rememberMe")||"");
    this.rememberLogin = rememberMe;
  },
  methods: {
    activateAccount: function () {
      this.$store.commit('changeParentVerification', {
        juniorId: (this.frozenResponse as any).personId,
        firstName: (this.frozenResponse as any).firstName,
        lastName: (this.frozenResponse as any).lastName
      });

      this.$router.push('/parent-member');
    },
    processLogin: async function (res: any) {
      const decoded: any = jwt_decode(res.token);
      const event = new CustomEvent("userLoaded", {
        detail: { personId: decoded.nameid, token: res.token },
      });
      window.dispatchEvent(event);

      localStorage.setItem("userToken", res.token);
      this.$router.push("/");
    },
    submit: function () {
      this.errorMessage = '';
      this.isError = false;
      this.isProcessing = true;
      localStorage.setItem("rememberMe", JSON.stringify(this.rememberLogin));
      authenticationService
        .login({
          username: this.username,
          password: this.password,
          rememberLogin: this.rememberLogin,
        })
        .then((res) => {
          this.isProcessing = false;
          if(!res){            
            this.errorMessage = "There was a connection error, try again. If the problem persists, please contact the ASCA team.";
            this.isError = true;
          }
          else {
              if (res.status === 401) {
              this.isError = true;
              this.errorMessage = "Username and/or password invalid"
            } else if (res && res.stack && res.message) {
              this.isError = true;
              this.errorMessage = res.message;
            } else if (res.token) {
              this.processLogin(res);
            } else if (res.frozen) {
              this.isFrozen = true;
              this.frozenResponse = res;
            }
          }
        })
        .catch((e) => {
          if (!!e && e.message) {
            this.errorMessage = e.message;
            this.isError = true;
          } else {
            this.errorMessage = "An unknown error occurred.";
            this.isError = true;
          }
        });
    },
  },
});
