import { api } from "../api";

export const getTitleCategories = async (): Promise<any> => {
    const { data } = await api.get<any>(`/Admin/categories`);

    return data;
}

export const createCategory = async(req: object): Promise<any> => {
    const { data } = await api.post<any>(`/Admin/categories/create`, req);

    return data;
}

export const updateCategory = async(req: object): Promise<any> => {
    const { data } = await api.post<any>(`/Admin/categories/update`, req);

    return data;
}

export const deleteCategory = async(req: object): Promise<any> => {
    const { data } = await api.post<any>(`/Admin/categories/delete`, req);

    return data;
}

export default {
    getTitleCategories,
    deleteCategory,
    createCategory,
    updateCategory,
};