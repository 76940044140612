
import Vue from "vue";

import AppNavbar from "@/components/Layouts/AppNavbar.vue";
import RegisterLitterForm1 from "./Steps/RegisterLitterForm1.vue";
import RegisterLitterForm2 from "./Steps/RegisterLitterForm2.vue";
import RegisterLitterForm3 from "./Steps/RegisterLitterForm3.vue";

import {
  searchDam,
  submitLitterRegistrationRequest,
  validateWhelpingDate,
} from "@/services/portal/registerLitterService";
import lookupsService from "@/services/lookupsService";
import workflowService from "@/services/portal/workflowService";

import { UserInfo } from "@/types/User";
import AuthorizeNetResponseData from "@/types/AuthorizeNetResponseData";

const registerDogImage = require('@/assets/register-dog.png');

export default Vue.extend({
  name: "RegisterLitter",
  components: {
    AppNavbar,
    RegisterLitterForm1,
    RegisterLitterForm2,
    RegisterLitterForm3,
  },
  data: function () {
    return {
      registerDogImage,
      currentPage: 1,
      picker: "",
      minDate: null as any,
      maxDate: null as any,
      damInfo: {} as any,
      sireInfo: [] as any[],
      puppies: {} as any[],
      registrationRequestStatus: {},
      colors: {},
      priceInfo: [],
      selectedKennels: [] as any[],
      signature: "",
      isArtificialInsemination: false,
      whelpingDateExplanation: "",
      validationInfo: {} as any,
      requestResponse: {},
    };
  },
  methods: {
    changePage(page: number) {
      this.currentPage = page;
    },
    changeDate(date: any) {
      this.picker = date;
    },
    searchDam(dogId: string, date: Date) {
      searchDam(dogId, date).then((res) => {
        this.damInfo = res.data;
      });
    },
    validateWhelpingDate(requesterPersonId: number, damId: string, date: Date) {
      validateWhelpingDate(requesterPersonId, damId, date).then((res) => {
        this.validationInfo = res;
        this.whelpingDateExplanation = "";
      });
    },
    changeSireInfo(sireInfo: any[]) {
      this.sireInfo = sireInfo;
      sireInfo.forEach((s) => {
        if (!this.puppies[s.dogId]) {
          this.$set(this.puppies, s.dogId, []);
        }
      });
    },
    changePuppyInfo(puppies: object[], sireId: string, deletePuppies: boolean) {
      if (deletePuppies) {
        Vue.delete(this.puppies, sireId);
      } else {
        this.$set(this.puppies, sireId, puppies);
      }
    },
    updateSignature(signature: string) {
      this.signature = signature;
    },
    changeArtificialInsemination(val: boolean) {
      this.isArtificialInsemination = val;
    },
    changeWhelpingDateExplanation(value: string) {
      this.whelpingDateExplanation = value;
    },
    changeKennels(e: any, k: any) {
      if (e) {
        this.selectedKennels.push(k.kennelId);
      } else {
        const idx = this.selectedKennels.indexOf(k.kennelId);
        if (idx !== -1) {
          this.selectedKennels.splice(idx, 1);
        }
      }
    },
    sendRegistrationRequest(paymentInfo: AuthorizeNetResponseData, signatureText: string) {
      let req = {} as any;

      req.paymentInfo = paymentInfo;
      req.requesterSignatureText = signatureText;
      req.whelpingDate = this.picker;
      req.requesterPersonId = this.userInfo.personId;
      req.requiredKennelIds = this.selectedKennels;
      req.isArtificialInsemination = this.isArtificialInsemination;
      req.whelpingDateExplanation = this.whelpingDateExplanation;
      req.fee = this.validationInfo.fee * this.sireInfo.length;

      // dam
      const damOwners = [] as any[];
      if (!!this.damInfo.lease) {
        this.damInfo.lease.lesees.forEach((o: any) =>
          damOwners.push({PersonId: o.personId, IsPrimary: o.isPrimary})
        );
      } else {
        this.damInfo.primaryOwners.forEach((o: any) =>
          damOwners.push({PersonId: o.personId, IsPrimary: o.isPrimary})
        );
      }

      req.dam = {
        dogId: this.damInfo.dogId,
        owners: damOwners,
        leaseInfo: this.damInfo.lease,
      };

      // sire
      req.sires = this.sireInfo;
      req.isMultiSire = this.sireInfo.length > 1 ? true : false;

      // puppies
      let puppies = [];
      for (const sire in this.puppies) {
        for (
          let puppyIdx = 0;
          puppyIdx < this.puppies[sire].length;
          puppyIdx++
        ) {
          puppies.push({ ...this.puppies[sire][puppyIdx], sireId: sire });
        }
      }
      req.puppies = puppies;

      submitLitterRegistrationRequest(req).then((res) => {
        this.registrationRequestStatus = res;
      });
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
  created() {
    if (
      this.$route.query.start_date &&
      typeof this.$route.query.start_date === "string"
    ) {
      this.minDate = this.$dateUtils.formatDateISO(
        this.$route.query.start_date
      );
      this.picker = this.$dateUtils.formatDateISO(this.$route.query.start_date);
    }

    this.maxDate = new Date().toISOString().substr(0, 10);

    lookupsService.getPuppyAttributes().then((res) => {
      this.colors = res;
    });

    workflowService.getRegisterLitterFees().then((res) => {
      this.priceInfo = res;
    });
  },
  props: {
    dogId: String,
  },
});
