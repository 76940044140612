
import Vue from 'vue';
import { debounce } from "throttle-debounce";

import kennelService from "@/services/admin/kennelService";

export default Vue.extend({
  name: 'KennelSimilarNamesDialog',
  props: {
    kennelSearchPhrase: { type: String },
  },
  components: {
  },
  data: function() {
      return {
        searchResults: [],
        showDialog: false,
        searchPhrase: this.$props.kennelSearchPhrase,
        hasSearched: false,
      };
  },
  methods: { 
    showSimilarNames: function() {
      this.showDialog = true;
      this.search();
    },
    search: function(){
      this.$store.dispatch("changeLoaderStatus",true);
      kennelService.getSimilarKennelNames(this.searchPhrase).then((res: any) => {
        this.searchResults = res;
        this.hasSearched = true;
        this.$store.dispatch("changeLoaderStatus",false);
      }).catch(e => console.log(e));
    },
  },
  watch: {
    showDialog: function (newVal: boolean) {
      if (!newVal) {
        this.hasSearched = false;
        this.searchResults = [];
      }
      this.showDialog = newVal;
    },
    searchPhrase: {
      handler: debounce(1500,  function(this: any, newValue, oldValue){ this.search();})
    },
    kennelSearchPhrase: function(newVal, oldVal){
      if(newVal != oldVal){
        this.searchPhrase = newVal;
      }
    }
  }
});
