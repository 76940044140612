
import Vue from "vue";
import Registration from "@/components/Admin/Details/Dogs/Registration.vue";
import ShowResults from "@/components/Admin/Details/Dogs/ShowResults.vue";
import Titles from "@/components/Admin/Details/Dogs/Titles.vue";
import ReportViewer from "@/components/Admin/Reports/ReportViewer.vue";

import dogService from "@/services/admin/dogService";

export default Vue.extend({
  name: "DogDetails",
  data: function () {
    return {
      dogInfo: {} as any,
      Report_Name: 'Asca.Api.Reports.rpt3210_Dog_Reg_Certificate.rdlx',
      Report_URL: '`/reports/registration/${dogId}`',
      showReportDialog: false,
      tab: null,
    };
  },  
  props: {
    dogId: { type: String },
  },
  components: {
    Registration,
    ShowResults,
    Titles,
    ReportViewer
  },
  methods: {
    showRegCardDialog: function (){   
      this.Report_Name = "Asca.Api.Reports.rpt3210_Dog_Reg_Certificate.rdlx";
      this.Report_URL = `/reports/registration/${encodeURIComponent(this.dogId.trim())}`;
      this.showReportDialog = true;
    },
    showPedigreeDialog: function (){
      this.Report_Name = "Asca.Api.Reports.rpt3240_Dog_Pedigree_Report.rdlx";
      this.Report_URL = `/reports/pedigree/${encodeURIComponent(this.dogId.trim())}`;
      this.showReportDialog = true;
    }, 
  },
  created: function () {
    if(this.$props.dogId){
      dogService
        .getDogById(this.$props.dogId)
        .then((res) => {
          this.dogInfo = res;
        })
        .catch((e) => console.log(e));
    }
  },
  watch: {
    dogId: function (newVal: String) {
      if(newVal){
        dogService
          .getDogById(this.$props.dogId)
          .then((res) => {
            this.dogInfo = res;
          })
          .catch((e) => console.log(e));
      }
    },
  },
});
