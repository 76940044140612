var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.showLoader}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":70,"width":7}})],1),_c('div',{staticClass:"profile-content"},[_c('h1',{staticClass:"mb-3 text-center"},[_vm._v("Affiliate Listings")]),_c('div',[_c('v-select',{attrs:{"items":_vm.regions,"label":"Select Region","multiple":"","outlined":"","dense":"","hint":"Select regions to narrow down the list","persistent-hint":""},model:{value:(_vm.selectedRegions),callback:function ($$v) {_vm.selectedRegions=$$v},expression:"selectedRegions"}}),_c('v-row',[_c('v-col',{attrs:{"md":"6","sm":"12","cols":"12"}},_vm._l((_vm.filteredAffiliates.filter(function (
              element,
              index,
              array
            ) {
              return index % 2 === 0;
            })),function(affiliate){return _c('div',{key:affiliate.affiliateId},[_c('affiliate-card',{attrs:{"affiliate":affiliate}})],1)}),0),_c('v-col',{attrs:{"md":"6","sm":"12","cols":"12"}},_vm._l((_vm.filteredAffiliates.filter(function (
              element,
              index,
              array
            ) {
              return index % 2 === 1;
            })),function(affiliate){return _c('div',{key:affiliate.affiliateId},[_c('affiliate-card',{attrs:{"affiliate":affiliate}})],1)}),0)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }