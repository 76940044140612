import { RouteConfig } from "vue-router";

// PROFILE
import UserProfile from "../views/Portal/Profile/UserProfile.vue";
import MyMembershipInfo from "../views/Portal/Profile/MyMembershipInfo.vue";
import MyKennels from "../views/Portal/Profile/MyKennels.vue";
import MyLitters from "../views/Portal/Profile/MyLitters.vue";

// REGISTRATION
import RegisterQTDog from "../views/Portal/Registrations/QTDog/RegisterQTDog.vue";
import RegisterKennel from "../views/Portal/Registrations/Kennel/RegisterKennel.vue";
import ChangeKennelOwners from "../views/Portal/Registrations/ChangeKennelOwners/ChangeKennelOwners.vue";
import RegisterLitter from "../views/Portal/Registrations/Litter/RegisterLitter.vue";
import RegisterAffiliate from "../views/Portal/Registrations/Affiliate/RegisterAffiliate.vue";
import RenewAffiliate from "../views/Portal/Registrations/Affiliate/RenewAffiliate.vue";
import MemberRegistration from "../views/Portal/Registrations/Member/MemberRegistration.vue";
import MemberRenewal from "../views/Portal/Registrations/Member/MemberRenewal.vue";
import AussieTimesRenewal from "../views/Portal/Registrations/Member/AussieTimesRenewal.vue";
import ExpiredMembership from "../views/Portal/Registrations/Member/ExpiredMembership.vue";
import LinkOrRegister from "../views/Portal/Registrations/Member/LinkOrRegister.vue";
import LinkToMembershipByCode from "../views/Portal/Registrations/Member/LinkToMembershipByCode.vue";
import LeaseRequest from "../views/Portal/Registrations/Lease/LeaseRequest.vue";
import RegisterPuppy from "../views/Portal/Registrations/RegisterPuppy.vue";
import ReleasePuppy from "../views/Portal/Registrations/ReleasePuppy.vue";

// SIGNATURES
import KennelRegistrationSignatures from "../views/Portal/Signatures/KennelRegistrationSignatures.vue";
import ChangeKennelOwnerSignatures from "../views/Portal/Signatures/ChangeKennelOwnerSignatures.vue";
import LitterRegistrationSignatures from "../views/Portal/Signatures/LitterRegistrationSignatures.vue";
import LeaseRegistrationSignatures from "../views/Portal/Signatures/LeaseRegistrationSignatures.vue";
import AffiliateRegistrationSignatures from "../views/Portal/Signatures/AffiliateRegistrationSignatures.vue";
import AffiliateRenewalSignatures from "../views/Portal/Signatures/AffiliateRenewalSignatures.vue";
import QTDogRegistrationSignatures from "../views/Portal/Signatures/QTDogRegistrationSignatures.vue";

// SIGNATURE DETAILS
import NewKennelSignature from "../views/Portal/Signature/NewKennelSignature.vue";
import ChangeKennelOwnerSignature from "../views/Portal/Signature/ChangeKennelOwnerSignature.vue";
import LitterRegistrationSignature from "../views/Portal/Signature/LitterRegistrationSignature.vue";
import LeaseSignature from "../views/Portal/Signature/LeaseSignature.vue";
import PuppyReleaseSignature from "../views/Portal/Signature/PuppyReleaseSignature.vue";
import PuppyReleaseKennelSignature from "../views/Portal/Signature/PuppyReleaseKennelSignature.vue";
import AffiliateRenewalSignature from "../views/Portal/Signature/AffiliateRenewalSignature.vue";
import AffiliateRegistrationSignature from "../views/Portal/Signature/AffiliateRegistrationSignature.vue";
import QTDogRegistrationSignature from "../views/Portal/Signature/QTDogRegistrationSignature.vue";

// REQUESTS
import MyKennelRegistrationRequests from "../views/Portal/MyRequests/MyKennelRegistrationRequestList.vue";
import MyChangeKennelOwnerRequests from "../views/Portal/MyRequests/MyChangeKennelOwnerRequestList.vue";
import MyLitterRegistrationRequests from "../views/Portal/MyRequests/MyLitterRegistrationRequestList.vue";
import MyLeaseRegistrationRequests from "../views/Portal/MyRequests/MyLeaseRegistrationRequestList.vue";
import MyAffiliateRegistrationRequests from "../views/Portal/MyRequests/MyAffiliateRegistrationRequestList.vue";
import MyRenewAffiliateRequests from "../views/Portal/MyRequests/MyRenewAffiliateRequestList.vue";
import MyQTDogRequests from "../views/Portal/MyRequests/MyQTDogRequestList.vue";

// REQUEST DETAILS
import MyKennelRegistrationRequest from "../views/Portal/MyRequest/MyKennelRegistrationRequest.vue";
import MyChangeKennelOwnerRequest from "../views/Portal/MyRequest/MyChangeKennelOwnerRequest.vue";
import MyLitterRegistrationRequest from "../views/Portal/MyRequest/MyLitterRegistrationRequest.vue";
import MyLeaseRequest from "../views/Portal/MyRequest/MyLeaseRequest.vue";
import MyAffiliateRegistrationRequest from "../views/Portal/MyRequest/MyAffiliateRegistrationRequest.vue";
import MyRenewAffiliateRequest from "../views/Portal/MyRequest/MyRenewAffiliateRequest.vue";
import MyQTDogRequest from "../views/Portal/MyRequest/MyQTDogRequest.vue";

// OTHER
import Home from "../views/Portal/Home.vue";
import DogInfo from "../views/Portal/DogInfo.vue";
import ShowResultsPage from "../views/Portal/ShowResultsPage.vue";
import LitterInfo from "../views/Portal/LitterInfo.vue";
import AffiliateList from "../views/Portal/AffiliateList.vue";
import AffiliateInfo from "../views/Portal/AffiliateInfo.vue";
import MyPayments from "../views/Portal/MyPayments.vue";

import AussieTimes from "../views/AussieTimes.vue";
import MembersOnlyView from "../views/MembersOnlyView.vue";
import InsufficientPermissions from "../views/InsufficientPermissions.vue";

import { MetaNames } from "@/constants";

const decodeURIComponentIfNotEmpty = (uri: any) => { 
  if (uri){
    return decodeURIComponent(uri);
  }
  return uri; 
}; 

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { name: MetaNames.DefaultLanding },
  },
  {
    path: '/profile',
    name: 'My Profile',
    component: UserProfile
  },
  {
    path: '/profile/membership',
    name: 'My Membership',
    component: MyMembershipInfo
  },
  {
    path: '/profile/payments',
    name: 'My Payments',
    component: MyPayments
  },
  {
    path: '/profile/kennels',
    name: 'My Kennels',
    component: MyKennels
  },
  {
    path: '/profile/litters',
    name: 'My Litters',
    component: MyLitters
  },
  {
    path: '/registration/qtdog',
    name: 'Register QT Dog',
    component: RegisterQTDog,
    meta: {layout: 'no-sidebar'}
  },
  {
    path: '/registration/kennel',
    name: 'Register Kennel',
    component: RegisterKennel,
    meta: {layout: 'no-sidebar'}
  },
  {
    path: '/changekennelowners/:kennelId',
    name: 'Change Kennel Owners',
    component: ChangeKennelOwners,
    meta: {layout: 'no-sidebar'},
    props: true
  },
  {
    path: '/registration/affiliate',
    name: 'Register Affiliate',
    component: RegisterAffiliate,
    meta: {layout: 'no-sidebar'}
  },
  {
    path: '/renew/affiliate/:affiliateId',
    name: 'Renew Affiliate',
    component: RenewAffiliate,
    meta: {layout: 'no-sidebar'},
    props: true,
  },
  {
    path: '/affiliate/:affiliateId',
    name: 'Affiliate Info',
    component: AffiliateInfo,
    props: true,
  },
  {
    path: '/affiliate-list',
    name: 'AffiliateList',
    component: AffiliateList,
    meta: {layout: 'left-sidebar'}
  },
  {
    path: '/registration/member',
    name: 'MemberRegistration',
    component: MemberRegistration,
    meta: {layout: 'empty'}
  },
  {
    path: '/renew/member',
    name: 'MemberRenewal',
    component: MemberRenewal,
    meta: {layout: 'empty'}
  },
  {
    path: '/renew/member/aussietimes',
    name: 'AussieTimesRenewal',
    component: AussieTimesRenewal,
    meta: {layout: 'empty'}
  },
  {
    path: '/signup/success',
    name: 'NewAccountHolder',
    component: LinkOrRegister,
    meta: {layout: 'empty'}
  },
  {
    path: '/gain-membership',
    name: "GainMembership",
    component: LinkOrRegister,
    meta: {layout: 'empty'}
  },
  {
    path: '/expired-membership',
    name: 'ExpiredMembership',
    component: ExpiredMembership,
    meta: {layout: 'empty'}
  },
  {
    path: '/link-member',
    name: 'LinkToMembershipByCode',
    component: LinkToMembershipByCode,
    meta: {layout: 'empty'}
  },
  {
    path: '/registration/litter/:dogId',
    name: 'Register Litter',
    component: RegisterLitter,
    meta: {layout: 'no-sidebar'},
    props: true
  },
  {
    path: '/litters/:litterId',
    name: 'Litter Info',
    component: LitterInfo,
    props: route => ({ litterId: Number(route.params.litterId) }),
  },
  {
    path: '/litters/:litterId/register/:puppyId',
    name: 'Register Puppy',
    component: RegisterPuppy,
    props: route => ({ litterId: Number(route.params.litterId), puppyId: Number(route.params.puppyId) }),
  },
  {
    path: '/litters/:litterId/release/:puppyId',
    name: 'Release Puppy',
    component: ReleasePuppy,
    props: route => ({ litterId: Number(route.params.litterId), puppyId: Number(route.params.puppyId)  }),
  },
  {
    path: '/signature/KennelRegistration',
    name: 'My Pending Kennel Registration Signatures',
    component: KennelRegistrationSignatures,
  },
  {
    path: '/signature/KennelRegistration/:signatureId',
    name: 'Sign Kennel Registration',
    component: NewKennelSignature,
    props: route => ({ signatureId: Number(route.params.signatureId) })
  },
  {
    path: '/signature/LitterRegistration/:signatureId',
    name: 'Sign Litter Registration',
    component: LitterRegistrationSignature,
    props: route => ({ signatureId: Number(route.params.signatureId) })
  },
  {
    path: '/signature/LitterRegistration',
    name: 'My Pending Litter Registration Signatures',
    component: LitterRegistrationSignatures,
  },
  {
    path: '/signature/PuppyRelease/:signatureId',
    name: 'Sign Puppy Release',
    component: PuppyReleaseSignature,
    props: route => ({ signatureId: Number(route.params.signatureId) })
  },
  {
    path: '/signature/PuppyReleaseKennel/:signatureId',
    name: 'Sign Puppy Release Kennel Usage',
    component: PuppyReleaseKennelSignature,
    props: route => ({ signatureId: Number(route.params.signatureId) })
  },
  {
    path: '/signature/LeaseRegistration',
    name: 'My Pending Lease Signatures',
    component: LeaseRegistrationSignatures,
  },
  {
    path: '/signature/LeaseRegistration/:signatureId',
    name: 'Sign Lease',
    component: LeaseSignature,
    props: route => ({ signatureId: Number(route.params.signatureId) })
  },
  {
    path: '/signature/AffiliateRenewal',
    name: 'My Pending Affiliate Renewal Signatures',
    component: AffiliateRenewalSignatures,
  },
  {
    path: '/signature/AffiliateRenewal/:signatureId',
    name: 'Sign Affiliate Renewal',
    component: AffiliateRenewalSignature,
    props: route => ({ signatureId: Number(route.params.signatureId) })
  },
  {
    path: '/signature/AffiliateRegistration',
    name: 'My Pending Affiliate Registration Signatures',
    component: AffiliateRegistrationSignatures,
  },
  {
    path: '/signature/AffiliateRegistration/:signatureId',
    name: 'Sign Affiliate Registration',
    component: AffiliateRegistrationSignature,
    props: route => ({ signatureId: Number(route.params.signatureId) })
  },
  {
    path: '/signature/KennelOwnerChange',
    name: 'My Pending Change Kennel Owner(s) Signatures',
    component: ChangeKennelOwnerSignatures,
  },
  {
    path: '/signature/KennelOwnerChange/:signatureId',
    name: 'Sign Change Kennel Owners Registration',
    component: ChangeKennelOwnerSignature,
    props: route => ({ signatureId: Number(route.params.signatureId) })
  },
  {
    path: '/signature/QTRegistration',
    name: 'My Pending QT Dog Registration Signatures',
    component: QTDogRegistrationSignatures,
  },
  {
    path: '/signature/QTRegistration/:signatureId',
    name: 'Sign QT Dog Registration',
    component: QTDogRegistrationSignature,
    props: route => ({ signatureId: Number(route.params.signatureId) })
  },
  {
    path: '/requests/ChangeKennelOwners/:workflowGuid',
    props: route => ({ kennelId: String(route.params.kennelId) }),
  },
  {
    path: '/requests/KennelOwnersChange',
    name: 'My Change Kennel Owners Requests',
    component: MyChangeKennelOwnerRequests,
  },
  {
    path: '/requests/KennelOwnersChange/:workflowGuid',
    name: 'My Change Kennel Owners Request',
    component: MyChangeKennelOwnerRequest,
    props: true
  },
  {
    path: '/requests/AffiliateRegistration',
    name: 'My Affiliate Registration Requests',
    component: MyAffiliateRegistrationRequests,
  },
  {
    path: '/requests/AffiliateRegistration/:workflowGuid',
    name: 'My Affiliate Registration Request',
    component: MyAffiliateRegistrationRequest,
    props: true
  },
  {
    path: '/requests/AffiliateRenewal',
    name: 'My Renew Affiliate Requests',
    component: MyRenewAffiliateRequests,
  },
  {
    path: '/requests/AffiliateRenewal/:workflowGuid',
    name: 'My Renew Affiliate Request',
    component: MyRenewAffiliateRequest,
    props: true
  },
  {
    path: '/requests/LitterRegistration',
    name: 'My Litter Registration Requests',
    component: MyLitterRegistrationRequests,
  },
  {
    path: '/requests/LitterRegistration/:workflowGuid',
    name: 'My Litter Registration Request',
    component: MyLitterRegistrationRequest,
    props: true
  },
  {
    path: '/requests/LeaseRegistration',
    name: 'My Lease Registration Requests',
    component: MyLeaseRegistrationRequests,
  },
  {
    path: '/requests/LeaseRegistration/:workflowGuid',
    name: 'My Lease Request',
    component: MyLeaseRequest,
    props: true
  },
  {
    path: '/requests/KennelRegistration',
    name: 'My Kennel Registration Requests',
    component: MyKennelRegistrationRequests,
  },
  {
    path: '/requests/KennelRegistration/:workflowGuid',
    name: 'My Kennel Registration Request',
    component: MyKennelRegistrationRequest,
    props: true
  },
  {
    path: '/requests/QTRegistration',
    name: 'My New QT Dog Requests',
    component: MyQTDogRequests,
  },
  {
    path: '/requests/QTRegistration/:workflowGuid',
    name: 'My New QT Dog Request',
    component: MyQTDogRequest,
    props: true
  },
  {
    path: '/insufficientpermissions',
    name: 'Insufficent Permissions',
    component: InsufficientPermissions,
    meta: {layout: 'empty'}
  },
  {
    path: '/search/:searchText',
    name: 'OmniSearch',
    component: Home,
    props: true,
  },
  {
    path: '/showresults',
    name: 'Show Results',
    component: ShowResultsPage,
    meta: {layout: 'left-sidebar'}
  },
  {
    path: '/dogs/:dogId',
    name: 'Dog Info',
    component: DogInfo,
    props: route => ({ dogId: decodeURIComponentIfNotEmpty(route.params.dogId) }),
  },
  {
    path: '/leaserequest/:dogId',
    name: 'Lease Request',
    component: LeaseRequest,
    meta: {layout: 'no-sidebar'},
    props: route => ({ dogId: String(route.params.dogId) }),
  },
  {
    path: '/aussietimes',
    name: 'Aussie Times',
    component: AussieTimes,
    props: true,
    meta: {isAussieTimes: true}
  },
  {
    path: '/members-only',
    name: 'MembersOnlyView',
    component: MembersOnlyView,
  },
];

export default routes.map(route => {
  return { ...route, meta: {...route.meta, isPublic: false } };
});