
import Vue from 'vue';
import PeopleList from "./Searches/PeopleList.vue";
import AdminCoownersList from "./AdminCoownersList.vue";

import peopleService from "@/services/admin/peopleService";

export default Vue.extend({
  name: 'AdminPersonSearchDialog',
  props: {
    judgeId: { type: String },
    initialJudgeId: { type: String },
    isEditMode: { type: Boolean },
    hideSearch: { type: Boolean },
    hideOwners: { type: Boolean },
    label: { type: String },
  },
  components: {
    AdminCoownersList,
    PeopleList
  },
  data: function() {
      return {
        showPersonSearchDialog: false,
        personInfo: {} as any,
        selectedJudgeId: this.$props.judgeId
      };
  },
  created: function () {
    if(!!this.selectedJudgeId){
      this.loadPersonInfo();
    }
  },
  methods: { 
    onPersonSelected: function({judgeId}: any){
        this.showPersonSearchDialog = false;
        if(!!judgeId){
            this.selectedJudgeId = judgeId;
            this.loadPersonInfo();
        } else {
            this.selectedJudgeId = null;
            this.personInfo = {};
        }
    },
    loadPersonInfo: function() {
        this.$store.dispatch("changeLoaderStatus",true);
        peopleService.getPersonByJudgeId(this.selectedJudgeId).then((res: any) => {
          if(res){
            this.personInfo = res;
          } else {
            this.personInfo = {};
          }
        }).catch(e => {
          console.log(e); 
          this.personInfo = {};
        })
        .finally(() => {
          this.$store.dispatch("changeLoaderStatus",false);
        });
    },
  },
  watch: {
    judgeId: function(newVal, oldVal){
      if(newVal != oldVal){
        this.selectedJudgeId = newVal;
      }
    },  
    initialJudgeId: function(newVal, oldVal){
      if(newVal != oldVal) {
        this.loadPersonInfo(); 
      }   
    },
    selectedJudgeId: function(newVal, oldVal){      
      this.$emit('update:judgeId', newVal);
    }
  }
});
