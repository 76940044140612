import { api } from "./api";

interface GetShowResultsRequest {
  dateString?: string;
  showTypeId?: string;
}

export const getShowResults = async ({
  dateString,
  showTypeId,
}: GetShowResultsRequest): Promise<any> => {
  const params = new URLSearchParams();
  if (dateString) params.append("date", encodeURIComponent(dateString));
  if (showTypeId) params.append("showTypeId", encodeURIComponent(showTypeId));
  const url = `/showresults`;
  const { data } = await api.get<object>(
    params ? `${url}?${params.toString()}` : url
  );

  return data;
};

export default {
  getShowResults,
};
