
import Vue from "vue";
import CoownersList, { OwnershipType } from "@/components/common/CoownersList.vue";

const defaultImage = require('@/assets/photouploadblue.png');

export default Vue.extend({
  name: "LeaseRequest",
  components: {
    CoownersList,
  },
  props: {
    registration: { type: Object },
  },
  data() {
    return {
      defaultImage,
    };
  },
  computed: {
    dogOwnershipType: function() {
      return OwnershipType.Dog;
    },
    leaseOwnershipType: function() {
      return OwnershipType.Lease;
    },
  },
});
