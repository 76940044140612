
import Vue from 'vue';
import ChangeKennelOwners from '@/components/Portal/MyRequest/ChangeKennelOwners.vue';
import RegistrationRequestLayout from "@/components/common/RegistrationRequestLayout.vue";
import workflowService from "@/services/admin/workflowService";

export default Vue.extend({
  name: 'ChangeKennelOwnerCard',
  props: {
    requestId: { type: Number },
  },
  components: {
    RegistrationRequestLayout,
    ChangeKennelOwners
  },
  data: function() {
      return {
          registration: {
              workflowGuid: "",
              requestId: 0
          } as any,
      };
  },
  computed: {
    workflowService() {
      return workflowService;
    },
  },
  methods: {
    onRequestProcessed: function (requestId: number) {
      this.$router.push({ path: `/admin/changekennelowners` });
    },
  }
});
