
import Vue from "vue";
import FooterText from "@/components/common/FooterText.vue";
import AddSireModal from "@/components/Portal/AddSireModal.vue";
import PuppyTable from "@/components/Portal/PuppyTable.vue";

export default Vue.extend({
  name: "RegisterLitterForm2",
  components: {
    AddSireModal,
    PuppyTable,
    FooterText,
  },
  data: function () {
    return {
      sireDialog: false,
    };
  },
  methods: {
    toggleSireModal: function () {
      this.$data.sireDialog = !this.$data.sireDialog;
    },
  },
  props: {
    changePage: Function,
    changeSireInfo: Function,
    changePuppyInfo: Function,
    damInfo: Object,
    sireInfo: Array,
    puppies: Object,
    colors: Object,
  },
  computed: {
    isValid: function () {
      return Object.values(this.puppies).reduce(
        (acc: any[], val: any) => acc.concat(val),
        []
      ).length;
    },
  },
});
