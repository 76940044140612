
import Vue from 'vue';

export default Vue.extend({
  name: 'UniqueUsernameDialog',
  props: {
    showDialog: {type: Boolean},
    onClose: {type: Function},
    onCheck: {type: Function},
    username: {type: String},
    loggedIn: {type: Boolean},
  },
  data() {
    return {
      newUsername: '',
    }
  },
});
