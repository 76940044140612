
import Vue from "vue";
import DatepickerFormatted from "@/components/common/inputs/DatepickerFormatted.vue";

import lookupsService from "@/services/admin/lookupsService";
import affiliateService from "@/services/admin/affiliateService";
import { PointScheduleLookupInfo } from '@/types/admin/Lookup';

export default Vue.extend({
  name: "PointSchedulesMaintenance",
  components: {
    DatepickerFormatted
  },
  data: function() {
    return {
        errorMessage: null as any,
        successMessage: null as any,
        startDate: null as any,
        endDate: null as any,
        luItems: [] as PointScheduleLookupInfo[],
        dialog: false,
        dialogDelete: false,
        headers: [
            { text: "ID", align: "left", value: "pointScheduleId" },
            { text: "Dog Count Min", align: "left", value: "dogCntMin" },
            { text: "Dog Count Max", align: "left", value: "dogCntMax" },
            { text: "Dog Count Lvl1", align: "left", value: "dogCntLvl1" },
            { text: "Points Lvl1", align: "left", value: "pointsLvl1" },
            { text: "Dog Count Lvl2", align: "left", value: "dogCntLvl2" },
            { text: "Points Lvl2", align: "left", value: "pointsLvl2" },
            { text: "Dog Count Lvl3", align: "left", value: "dogCntLvl3" },
            { text: "Points Lvl3", align: "left", value: "pointsLvl3" },
            { text: "Dog Count Lvl4", align: "left", value: "dogCntLvl4" },
            { text: "Points Lvl4", align: "left", value: "pointsLvl4" },
            { text: "Dog Count Lvl5", align: "left", value: "dogCntLvl5" },
            { text: "Points Lvl5", align: "left", value: "pointsLvl5" },
            { text: "Actions", value: "actions", sortable: false },
        ],
        editedIndex: -1,
        editedItem: {
            pointScheduleId: "",
            dogCntMin: 0,
            dogCntMax: 0,
            dogCntLvl1: 0,
            dogCntLvl2: 0,
            dogCntLvl3: 0,
            dogCntLvl4: 0,
            dogCntLvl5: 0,
            pointsLvl1: 1,
            pointsLvl2: 2,
            pointsLvl3: 3,
            pointsLvl4: 4,
            pointsLvl5: 5,
            canDelete:true,

        },
        defaultItem: {
            pointScheduleId: "",
            dogCntMin: 0,
            dogCntMax: 0,
            dogCntLvl1: 0,
            dogCntLvl2: 0,
            dogCntLvl3: 0,
            dogCntLvl4: 0,
            dogCntLvl5: 0,
            pointsLvl1: 1,
            pointsLvl2: 2,
            pointsLvl3: 3,
            pointsLvl4: 4,
            pointsLvl5: 5,
            canDelete: true,
            
        },              
        isValid: true,
        errorValidationMessage: "",
        isLoading: false,
        saveErrorMessage: "",
    };
  },
    created: function () {
        var currentDate = new Date();
        var firstFebLastYear = new Date(currentDate.getFullYear() - 1, 1, 1);
        this.startDate = this.$dateUtils.formatDateISO(firstFebLastYear);
        var lastJanThisYear = new Date(currentDate.getFullYear(), 0, 31);
        this.endDate = this.$dateUtils.formatDateISO(lastJanThisYear);

        this.reloadItems();
    },
    methods: {
        affiliatesGeneratePointSchedules: function () {
            this.$store.dispatch("changeLoaderStatus", true);
            affiliateService.affiliatesGeneratePointSchedules(this.startDate, this.endDate)
                .then((res) => {
                    if (!res.success) {
                        this.errorMessage = res.messages.join(', ')
                    } else {
                        this.successMessage = res.messages.join(', ')
                    }
                })
                .catch(() => { this.errorMessage = "Operation failed." })
                .finally(() => { this.$store.dispatch("changeLoaderStatus", false); });
        },
        reloadItems: function () {
            this.$data.isLoading = true;
            lookupsService.getPointScheduleList().then((res: any) => {
                this.luItems = res;
                this.$data.isLoading = false;

            }).catch(() => { this.errorMessage = "Unable to add/update Point Schedule." })
                .finally(() => { this.$store.dispatch("changeLoaderStatus", false); });

            this.$data.isLoading = false;
        },
        initializeItem() {
           
            this.dialog = true;

        },

        editItem(item: PointScheduleLookupInfo) {
            this.editedIndex = this.luItems.indexOf(item)
            this.editedItem = Object.assign({}, item);
            
            this.dialog = true;
        },

        deleteItem(item: PointScheduleLookupInfo) {
            this.editedIndex = this.luItems.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },
        deleteItemConfirm() {
            this.errorValidationMessage = "";
            this.saveErrorMessage = "";
            if (!this.editedItem.canDelete) {
                this.closeDelete();
            }
            lookupsService.deletePointSchedule(this.editedItem.pointScheduleId).then((res) => {
                if (res.success == false) {
                    this.saveErrorMessage = res.messages.join(', ')

                }
                else {
                    this.luItems.splice(this.editedIndex, 1);
                }

                this.reloadItems(); //in case something was changed after that pull

            }).catch((e: any) => {
                this.saveErrorMessage = "Unable to delete the point schedule.";   
            });

            this.closeDelete();


        },

        close() {
            this.errorValidationMessage = "";
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            this.errorValidationMessage = "";
            this.saveErrorMessage = "";
            this.editedItem.pointScheduleId = this.editedItem.pointScheduleId.trim();
            if (this.editedItem.pointScheduleId.length == 0) {
                this.errorValidationMessage = "Please enter valid values."
                return;
            }

            if (!/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/i.test(this.editedItem.pointScheduleId)) {
                this.errorValidationMessage  = "Please enter valid values."
                return;
            }

            if (this.editedItem.pointScheduleId.includes(' ')) {
                this.errorValidationMessage  = "Please enter valid values."
                return;
            }

            if (this.editedIndex == -1) {
                var exists = this.luItems.find((x: any) => x.pointScheduleId.trim() == this.editedItem.pointScheduleId);
                if (exists != null) {
                    this.errorValidationMessage  = "This Id already exists.";
                    return;
                }
               
            }
            
            lookupsService.editPointSchedule(this.editedItem)
                .then((res) => { 
                    if (res.success) {
                        if (this.editedIndex > -1) { 
                            Object.assign(this.luItems[this.editedIndex], this.editedItem)
                        }
                        else {                       
                            if (this.luItems) {
                                this.luItems.unshift(this.editedItem) 
                            }
                            
                        }
                        this.close();
                    }
                    else {
                        this.errorValidationMessage = res.messages.join(', ')
                        this.reloadItems(); //in case something was changed after that pull
                    }
                })
                .catch((e) => {
                    console.log(e);
                    this.errorValidationMessage = "Invalid values, please enter only numbers.";
                })
                .finally(() => this.$store.dispatch("changeLoaderStatus", false));

        },
        

    },
    computed: {
        formTitle() {
            return this.$data.editedIndex === -1 ? "New Item" : "Edit Item";
        },
    },
});
