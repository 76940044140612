import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { MetaNames } from "@/constants";

import privateRoutes from "@/router/private";
import publicRoutes from "@/router/public";
import adminRoutes from "@/router/admin";

import store from "@/store";
import { env } from '../environment/environment';
import jwt_decode from "jwt-decode";

Vue.use(VueRouter);

let allRoutes: Array<RouteConfig> = [];
allRoutes = allRoutes.concat(privateRoutes, publicRoutes, adminRoutes);

const router: VueRouter = new VueRouter({
  mode: "history",
  base: env.BASE_URL,
  routes: allRoutes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("userToken");
    const decoded: any = token ? jwt_decode(token) : null;
    document.title = MetaNames.DefaultTitle;
    if (to.meta != null) {
        if (to.meta.name != null) {
            document.title = to.meta?.name?.toString();
        }
    }

    if (to.matched.some(record => !record.meta.isPublic)) {
        // not a public page or token has expired - redirect to login
        if (!token || (Date.now() >= (Number(decoded.exp) * 1000))) {
            next({ name: 'Login' })
        }
        else if (to.matched.some(record => record.meta.isAdmin)) {
            if (decoded.role.includes("Admin")) {
                next()
            } else {
                next({ path: '/insufficientpermissions' })
            }
        } else {
            next()
        }
    } else {
        next()
    }
})
export default router;

