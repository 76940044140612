
import Vue from "vue";
import userService from "@/services/portal/userService";

export default Vue.extend({
  name: "MembersOnlyEdit",
  data: function () {
    return {
      errors: [] as string[],
      content: null
    };
  },
  methods: {
  },
  created: function () {
    userService
      .getMembersOnlyInfo()
      .then((res) => {
        this.content = res.data;
      })
      .catch((e) => {
        console.log(e)
      });
  },
});
