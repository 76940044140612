<template>
  <v-card outlined shaped tile class="mr-3 mt-3 px-2 py-2">
    <v-row class="mt-1">
      <v-col cols="12">
        <span class="alt-header">{{ title }}</span>
      </v-col>
    </v-row>
    <div v-for="(item, index) in items" :key="`${index}`" class="link-list">
      <v-row class="mb-1">
        <v-col class="my-auto" cols="12">
          <router-link v-if="!item.external" v-bind:to="item.link" :target="item.newPage ? '_blank' : ''" class="ml-2 link">{{ item.text }}</router-link>
          <a v-else :href="item.link" :target="item.newPage ? '_blank' : ''" class="ml-2 link">{{ item.text }}</a>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'LinkSummary',
  props: {
    image: String,
    title: String,
    newPage: Boolean,
    external: Boolean,
    items: {
      type: Array,
      default: () => [{ text: '', link: '' }],
    },
  },
};
</script>
