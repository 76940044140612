
import Vue from "vue";
import { quillEditor } from 'vue-quill-editor'
import { isEmpty } from "lodash";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import memberService from "@/services/admin/memberService";
import userService from "@/services/portal/userService";

export default Vue.extend({
  name: "MembersOnlyEdit",
  components: {
    quillEditor
  },
  data: function () {
    return {
      errors: [] as string[],
      content: null as string | null,
      initContent: null as string | null,
      editorOption: {
        // Some Quill options...
      },
      isSaving: false,
      isLoading: false,
    };
  },
  methods: {
    save: function () {
      if (!this.content) return;
      this.isSaving = true;
      memberService
        .updateMembersOnlyInfo(this.content)
        .then((res) => {
          this.content = res.data;
          this.isSaving = false;
          this.initContent = this.content;
          })
          .catch((e) => {
            this.isSaving = false;
            console.log(e)
          });
    },
  },
  created: function () {
    this.isLoading = true;
    userService
      .getMembersOnlyInfo()
      .then((res) => {
        this.content = res.data;
        this.initContent = this.content;
        this.isLoading = false;
      })
      .catch((e) => {
        this.isLoading = false;
        console.log(e)
      });
  },
  computed: {
    disableSave(): boolean {
      const emptyQuill = '<h5><br></h5>' //this is the 'empty' state for quill. https://github.com/quilljs/quill/issues/1235
      // check for isEmpty, but under normal circumstance, quill doesn't have a truely empty string state.
      return this.content === emptyQuill || this.initContent === this.content || isEmpty(this.content)
    }
  }
});
