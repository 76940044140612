
import Vue from 'vue';
import lookupsService from "@/services/lookupsService";
import litterService from "@/services/admin/litterService";

export default Vue.extend({
  name: 'AdminLitterPuppySearchDialog',
  props: {
    litterId: { type: String },
    isEditMode: { type: Boolean },
    showPuppiesSearchDialog: { type: Boolean },
    onPuppySelected: { type: Function },
  },
  data: function() {
      return {
        searchResults: [] as any[],
        selectedLitterId: this.$props.litterId,
        headers: [
          { text: "Puppy ID", value: "id" },
          { text: "Sex", value: "gender" },
          { text: "Main Color", value: "mainColor" },
          { text: "Is Dead/Culled", value: "isDead" },
          { text: "Dog ID", value: "dogId" },
        ],
        genders: [] as any[],
        mainColors: [] as any[],
        showDialog: this.$props.showPuppiesSearchDialog,
      };
  },
  created: function () {
    lookupsService.getPuppyAttributes().then((res: any) => {
        this.genders = res.genders;
        this.mainColors = res.mainColors;
    });

    if(!!this.selectedLitterId){
      this.loadPuppiesInfo();
    }
  },
  methods: { 
    onRowSelected: function(item: any){
        this.showDialog = false;
        this.onPuppySelected(item);
    },
    loadPuppiesInfo: function() {
        this.$store.dispatch("changeLoaderStatus",true);
        if (this.selectedLitterId) {
          litterService
            .getLitterById(this.selectedLitterId)
            .then((res) => {
              if(res){
              this.searchResults = res.puppies;
            } else {
              this.searchResults = [];
            }
            })
            .catch((e) => {            
              this.searchResults = [];
            })
          .finally(() => {
            this.$store.dispatch("changeLoaderStatus",false);
          });
      }
    },
  },
  watch: {
    litterId: function(newVal, oldVal){
      if(newVal != oldVal)
        if(newVal){
        this.selectedLitterId = newVal;
        this.loadPuppiesInfo();
        } else {
        this.selectedLitterId = newVal;
        this.searchResults = [];  
        }     
    },
    showPuppiesSearchDialog: function (newVal: boolean) {
      this.showDialog = newVal;
    },
    showDialog: function(newVal, oldVal){      
      this.$emit('update:showPuppiesSearchDialog', newVal);
    }
  }
});
