
import Vue from 'vue'
import CountryDropdown from '@/components/common/inputs/CountryDropdown.vue';
import StateDropdown from '@/components/common/inputs/StateDropdown.vue';
import DatepickerFormatted from "../../common/inputs/DatepickerFormatted.vue";
import InternationalPhoneNumberInput from '@/components/common/inputs/InternationalPhoneNumberInput.vue';

import peopleService from "@/services/admin/peopleService";

import { Messages } from "@/constants";
import { AdminPersonInfo, AdminPersonNotes } from '@/types/admin/Person';

export default Vue.extend({
  name: "GeneralPersonDetails",
  components: {
    CountryDropdown,
    StateDropdown,
    DatepickerFormatted,
    InternationalPhoneNumberInput,
  },
  props: {
      personId: { type: Number },
  },
  data: function () {
      return {
          personInfo: { memberInfo: {} } as AdminPersonInfo,
          isEditMode: false,
          isMemberEditMode: false,
          showValidationDialog: false,
          validationResult: {
              warnings: [],
              errors: []
          } as any,
          isValid: false,
          personNote: {} as AdminPersonNotes,
          errorMessage: '',
          infoMessage: '',
          showErrorMessage: false,
          showInfoMessage: false
      }
  },
  created: function () {
      if (this.personId) {
          this.personNote.personId = this.personId;
          this.personNote.newNote = "";
          this.personNote.notes = "";
          peopleService
              .getPersonById(this.personId)
              .then((res) => {
                  this.personInfo = res;

              });
      }
      else {
          this.isEditMode = true;               
      }
  },
  watch: {
      personId: function (newVal: String) {
          peopleService
              .getPersonById(this.personId)
              .then((res) => {
                  this.personInfo = res;
                  
              });
      },
  },
  methods: {
      reloadData: function () {
          if (this.personId) {
              peopleService
                  .getPersonById(this.personId)
                  .then((res) => {
                      this.personInfo = res;

                  });
          }

          this.showValidationDialog = false;
          this.errorMessage = '';
          this.showErrorMessage = false;
          this.validationResult = {};
      },
      saveChanges: function () {
          this.errorMessage = "";
          this.showValidationDialog = false;
          this.showErrorMessage = false;
          this.$store.dispatch("changeLoaderStatus", true);

          peopleService
              .editPersonById(this.personInfo)
              .then((res) => {
                  if (res.success) {
                      if (res.id) {
                          if (this.personId) {
                              this.reloadData();
                              window.location.reload();
                          }
                          else {
                              this.reloadData();
                              this.$router.push({ path: `/admin/people/${res.id}` }).catch(() => { });
                          }
                      }
                      
                      this.isEditMode = false;
                      this.infoMessage = Messages.SaveSuccessful;
                      this.showInfoMessage = true;
                  } else {              
                      this.errorMessage = Messages.SaveError + " " + res.messages.join(', ');
                      this.showErrorMessage = true;    
                  }})
                  .catch((e) => {                  
                      this.errorMessage = Messages.SaveError;
                      this.showErrorMessage = true;
                  })
              .finally(() => this.$store.dispatch("changeLoaderStatus", false));
      },
      updateDates: function (val: any, key: string) {
          this.$data.personInfo[key] = val?.date;
          this.$data.personInfo = Object.assign({}, this.$data.personInfo);
      },
      updateText: function (key: string, event: string) {
          this.$data.personInfo[key] = event;
      },
      togglePersonEditMode: function () {
          if (this.isEditMode) {
              this.validateAndConfirmSavingChanges();
          }
          this.isEditMode = !this.isEditMode;
      },
      addNote() {
          if (this.personNote.newNote) {
              peopleService
                  .editPersonAddNote(this.personNote)
                  .then((res) => {
                      let personNotes = res as AdminPersonNotes;
                      this.personInfo.notes = personNotes.notes;
                      this.personNote.newNote = "";
                      this.personNote.notes = "";
                  })
                  .catch((e) => console.log(e))
                  .finally(() => this.$store.dispatch("changeLoaderStatus", false));
          }
      },
      validateAndConfirmSavingChanges: function () {
          if (this.validateFields()) {
              peopleService
                  .validatePersonEdit(this.personInfo)
                  .then((res: any) => {
                      this.validationResult = res;
                      if (this.validationResult.errors.length > 0 || this.validationResult.warnings.length > 0) {
                          this.showValidationDialog = true;
                      }
                      else {
                          this.saveChanges();
                          this.validationResult = {};
                      }
                  })
          }
          else {
              this.isEditMode = false; //this will toggle it to true
          }
      },
      validateFields() {
          this.errorMessage = "";
          if (!this.personInfo) {
              this.errorMessage = "Missing required fields";
              return false;
          }
          if (this.personInfo.firstName == null || this.personInfo.lastName == null) {
              this.errorMessage = "First and Last Name are required.";
              return false;
          }
          if (this.personInfo.city) {
              if (this.personInfo.city.length > 50) {
                  this.errorMessage = "City is limited to 50 chars.";
                  return false;
              }
          }
          if (this.personInfo.country) {
              if (this.personInfo.country.length > 50)
              {
                  this.errorMessage = "Country is limited to 50 chars.";
                  return false;
              }
          }
          if (this.personInfo.international) {
              if (this.personInfo.international.length > 50)
              {
                  this.errorMessage = "International Area is limited to 50 chars.";
                  return false;
              }
          }
          if (this.personInfo.street) {
              if (this.personInfo.street.length > 50)
              {
                  this.errorMessage = "Address is limited to 50 chars.";
                  return false;
              }
          }
          if (this.personInfo.namePrefix) {
              if (this.personInfo.namePrefix.length > 10) {
                  this.errorMessage = "Prefix is limited to 10 chars.";
                  return false;
              }
          }
          if (this.personInfo.nameSuffix) {
              if (this.personInfo.nameSuffix.length > 10) {
                  this.errorMessage = "Suffix is limited to 10 chars.";
                  return false;
              }
          }
          if (this.personInfo.phoneNumber) {
              if (this.personInfo.phoneNumber.length > 50) {
                  this.errorMessage = "Phone is limited to 50 chars.";
                  return false;
              }
          }
          if (this.personInfo.zip) {
              if (this.personInfo.zip.length > 10) {
                  this.errorMessage = "Zip is limited to 10 chars.";
                  return false;
              }
          }
          if (this.personInfo.email) {
              if (this.personInfo.email.length > 1024) {
                  this.errorMessage = "Email is limited to 1024 chars.";
                  return false;
              }
          }
          if (this.personInfo.stateAbbreviation) {
              if (this.personInfo.stateAbbreviation.length > 2) {
                  this.errorMessage = "State Abbreviation is limited to 2 chars.";
                  return false;
              }
          }
          if (this.personInfo.nsfReason) {
              if (this.personInfo.nsfReason.length > 50) {
                  this.errorMessage = "NSF Reason is limited to 50 chars.";
                  return false;
              }
          }

          return true;
      },
      isCheckboxDisabled: function (key: string) {
          if (!this.isEditMode) {
              return true;
          }
          if (key == "member") {
              if (!this.personInfo.isMember) {
                  return false;
              }
              if (!this.personInfo.memberInfo) {
                  return false;

              }
              if (!this.personInfo.memberInfo.memberId) {
                  return false;
              }
          }
          else if (key == "judge") {
              if (!this.personInfo.isJudge) {
                  return false;
              }
              if (this.personInfo.judgeId == null) {
                  return false;
              }
          }
          else if (key == "juniorHandler") {
              if (!this.personInfo.isJuniorHandler) {
                  return false;
              }
              if (!this.personInfo.juniorHandlerId) {
                  return false;
              }
          }

          return true;
      },
  },
})
