
import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';

import CountryDropdown from '@/components/common/inputs/CountryDropdown.vue';
import StateDropdown from '@/components/common/inputs/StateDropdown.vue';
import DatepickerFormatted from '@/components/common/inputs/DatepickerFormatted.vue';
import UniqueEmailDialog from '@/components/common/UniqueEmailDialog.vue';
import InternationalPhoneNumberInput from '@/components/common/inputs/InternationalPhoneNumberInput.vue';

import authenticationService from '@/services/authenticationService';
import memberService from "@/services/portal/memberService";

import { UserInfo } from "@/types/User";
import { MemberType } from "@/types/Member";
import { Membership } from '@/types/portal/Member';

import { isJunior } from '@/helpers/validate-junior-age';
import validateEmail from '@/helpers/validate-email';

// TODO: Once time allows debounce the email validation here
export default Vue.extend({
  components: { DatepickerFormatted, CountryDropdown, StateDropdown, UniqueEmailDialog, InternationalPhoneNumberInput },
  name: 'MemberForm',
  props: {
    isRenewal: { type: Boolean }
  },
  mounted: function() {
    const userInfo = this.$store.state.userInfo;
    if (!!userInfo) this.initialize();
  },
  data: function () {
    return {
      isProcessing: false,
      isJunior: false,

      showLoader: false,
      showNonUniqueEmail: false,
      
      isPrimaryValid: true,
      isSecondaryValid: true,

      makeChanges: false,
      addSecondary: false,
      addJuniors: false,
      addAnotherJunior: false,
      
      showResults: [] as any[],

      primary: {
        memberId: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        country: "UNITED STATES",
        address: "",
        international: "",
        city: "",
        stateProvince: "",
        zipPostal: "",
      },
      secondary: {
        memberId: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
      juniors: [
        {
          key: uuidv4(),
          isValid: false,
          memberId: '',
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          birthDate: null,
        }
      ],
      plans: [] as Membership[],
    };
  },
  watch: {
    isPrimaryValid: function(newValue) {
      if (!newValue && !this.makeChanges) {
        this.makeChanges = true;
        (this.$refs.primaryForm as any).validate();
      }
    },
    addJuniors: async function(newValue) {
      if (!newValue) {
        await Vue.nextTick();

        // Rerender the juniors after removing one of them - keep the vuelidate blank space away
        this.juniors = this.juniors.map(j => ({ ...j, key: uuidv4() }));
      }
    },
    userInfo: async function(newValue) {
      if (!!newValue) this.initialize();
    },
    selectedPlan: function (newValue) {
      if (!!newValue) {
        const plan: Membership = newValue;

        switch(plan.memberType) {
          case MemberType.Dual:
          case MemberType.LifetimeDual:
            this.isJunior = false;
            this.addSecondary = true;  
            break;
          case MemberType.Junior:
            this.isJunior = true;
            this.addSecondary = false;
            break;
          default:
            this.isJunior = false;
            this.addSecondary = false;

            // Reset when selecting non-dual member type
            if (!this.secondary.memberId) {
              this.secondary = {
                memberId: "",
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
              };
            }
            break;
        }
      }
    }
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    selectedPlan(): Membership | null {
      const plan = this.plans.find((p) => p.checked);
      return plan ?? null;
    },
  },
  methods: {
    // INITIALIZATION
    initialize: async function () {
      this.isProcessing = true;

      const {
        memberId,
        firstName,
        lastName,
        email,
        phone,
        country,
        stateProvince,
        address,
        international,
        city,
        zipPostal,
        isJuniorHandler
      } = this.userInfo;

      const primary = {
        memberId,
        firstName,
        lastName,
        email,
        phone,
        country,
        address,
        international,
        city,
        stateProvince,
        zipPostal,
      };

      this.primary = primary;
      this.isJunior = isJuniorHandler;

      await this.getPlans();
      await this.getAuxMembers();

      this.isProcessing = false;
    },
    getPlans: async function () {
      const isJunior = this.isJunior;
      const memberId = (this.primary as any).memberId;

      const fees = await memberService.getMemberFees();

      if (!!fees) {
        this.plans = fees.memberships.map(p => ({
          ...p,
          key: uuidv4(),
          checked: !!memberId
            ? p.memberType == parseInt(memberId[0])
            : isJunior
        })) as any;

        this.$store.commit('changeRegisterRenewMembershipInfo',
        {
          aussieTimeFees: {
            surfacePostageCanada: fees.surfacePostageCanada,
            surfacePostageOther: fees.surfacePostageOther,
            firstClassNorthAmericas: fees.firstClassNorthAmericas,
            firstClassOther: fees.firstClassOther,
          }
        });
      }
    },
    getAuxMembers: async function() {
      if (this.isRenewal) {
        try {
          const axm = await memberService.getAuxiliaryMembers();

          if (!!axm.secondary) {
            this.secondary = { ...axm.secondary };
          }

          if (!!axm.juniors && !!axm.juniors.length) {
            this.addJuniors = true;
            this.juniors = axm.juniors.map((j: any) => ({
              ...j,
              key: uuidv4(),
              isValid: true,
            }));
          }
        } catch (ex) {
          console.log(ex);
        }
      }
    },
    // VALIDATION
    validateEmail(v: string) {
      return (
        validateEmail(v) ||
        "E-mail must be valid"
      );
    },
    validateDualEmail(v: string) {
      return this.secondary.email !== this.primary.email || "E-mail can't be the same as the primary E-mail";
    },
    validateIsJunior(v: string) {
      return (
        (!!v && isJunior(new Date(v)))
        || 'Must be under 18 years old'
      )
    },
    validateCity(v: string) {
      return this.primary.country === 'UNITED STATES'
        ? !!v || 'City is required'
        : true
    },
    validateZip(v: string) {
      return this.primary.country === 'UNITED STATES'
        ? !!v || 'Zip/Postal is required'
        : true
    },
    canSubmit() {
      if (this.isJunior) {
        return this.isPrimaryValid && this.plans.some((p: any) => p?.checked === true) as any;
      } else if (this.addSecondary) {
        return this.isPrimaryValid
          && this.plans.some((p: any) => p?.checked === true) as any
          && (this.addSecondary && !this.secondary.memberId ? this.isSecondaryValid : true)
          && (this.addJuniors ? !(this.juniors.some((j) => !(j as any).memberId && (!(j as any).isValid || !(j as any).birthDate))) : true);  
      } else {
        return this.isPrimaryValid
          && this.plans.some((p: any) => p?.checked === true) as any
          && (this.addJuniors ? !(this.juniors.some((j) => !(j as any).memberId && (!(j as any).isValid || !(j as any).birthDate))) : true);  
      }
    },
    checkEmail: async function(newEmail?: string) {
      if (this.primary.email === this.userInfo.email) return true;

      if (!!newEmail) this.primary.email = newEmail;

      const response = await authenticationService.uniqueEmail(this.primary.email);

      this.showNonUniqueEmail = !response.unique;

      return response.unique;
    },
    // MODIFICATION
    updateJuniorBirthDate: function (val: any, juniorIndex: number) {
      this.juniors[juniorIndex].birthDate = val.date;
      //workaround to force rerendering after setting up formatted date
      this.$set(this, "showResults", this.showResults);
    },
    updateCountry: async function (v: string) {
      this.primary.country = v;

      await Vue.nextTick();

      (this.$refs.primaryForm as any).validate();
    },
    selectPlan: async function (planToSelect: number, update: boolean = false) {
      if (this.plans.length <= 1) return;

      this.plans = this.plans.map((p: any, i: number) => ({
        ...p,
        checked: i == planToSelect
          ? update
            ? !p?.checked
            : p?.checked
          : false
      }));
    },
    addJunior() {
      this.addAnotherJunior = false;
      this.juniors.push({
        key: uuidv4(),
        isValid: false,
        memberId: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        birthDate: null,
      });
    },
    removeJunior: async function (index: number) {
      this.juniors = this.juniors.filter((j, i) => i !== index);

      await Vue.nextTick();

      // Rerender the juniors after removing one of them - keep the vuelidate blank space away
      this.juniors = this.juniors.map(j => ({ ...j, key: uuidv4() }));
    },
    submit: async function() {
      this.isProcessing = true;

      const uniqueEmail = await this.checkEmail();

      if (uniqueEmail && this.canSubmit()) {
        const selectedPlan = this.selectedPlan;

        this.isProcessing = false;
        this.$store.commit('changeRegisterRenewMembershipInfo',
        {
          page: selectedPlan!.memberType === MemberType.Service ? 3 : 2,
          isRenew: this.isRenewal,
          isAussieRenewal: false,
          subscribe: false,
          firstClass: false,
          plan: selectedPlan,
          primary: {
            ...this.primary,
          },
          secondary: this.addSecondary && !this.secondary.memberId ? this.secondary : null,
          juniors: this.addJuniors ? this.juniors.filter((j) => !j.memberId) : [],
        });
      }

      this.isProcessing = false;
    },
  },
});
