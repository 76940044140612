
import Vue from "vue";
import lookupsService from "@/services/admin/lookupsService";
import { MemberTypeLookupInfo } from "@/types/admin/Lookup";

export default Vue.extend({
    name: "MemberTypeMaintenance",
    data: function () {
        return {
            tab: null,
            luItems: [] as MemberTypeLookupInfo[],
            tdialog: false,
            dialog: false,
            dialogDelete: false,
            headers: [
                { text: "Description", align: "left", value: "description" },
                { text: "Next ID", align: "left", value: "nextId" },
                { text: "Actions", value: "actions", sortable: false },
            ],
            editedIndex: -1,
            editedItem: {
                description: "",
                nextId: 0,
                id: 0,

            },  
            isLoading: false,
            errorMessage: "",

        };
    },
    created: function () {
        this.refresh();
    },
    methods: {
        refresh: function () {
            this.$data.isLoading = true;
            this.errorMessage = "";
            lookupsService.getMemberTypeInfo().then((res: MemberTypeLookupInfo[]) => {
                this.luItems = res;
                this.$data.isLoading = false;
            });
        },
        editItem(item: MemberTypeLookupInfo) {
            this.errorMessage = "";
            this.editedIndex = this.luItems.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        incrementId(item: MemberTypeLookupInfo) {
            item.nextId++;

        },
        close() {
            this.dialog = false
            
        },
        save() {               
            this.errorMessage = "";
            lookupsService.editMemberType(this.editedItem).then((res: any) => {
                if (res.success) {
                    
                    Object.assign(this.luItems[this.editedIndex], this.editedItem)
                }
                else {
                    this.errorMessage = "Unable to increment id.";
                    
                }
            }).catch(
                (e) => {
                    console.log(e);
                    this.errorMessage = "Unable to increment id.";
                });

            this.close();
            
        },
        
    }, 
    watch: {
        dialog(val) {
            val || this.close()
        },
        
    },
    
});
