
import Vue from "vue";
import SignatureLayout from "@/components/common/SignatureLayout.vue";
import ASCAArtIncLink from "@/components/common/ASCAArtIncLink.vue";
import BylawsLink from "@/components/common/BylawsLink.vue";

import { UserInfo } from "@/types/User";
import workflowService from "@/services/portal/workflowService";

export default Vue.extend({
  name: "AffiliateRegistrationSignatureForm",
  components: {
    SignatureLayout,
    ASCAArtIncLink,
    BylawsLink,
  },
  props: { signatureId: Number },
  data: function () {
    return {
      signatureText: "",
      errorMessage: "",
      registration: Object,
      isLoading: false,
      found: false,
    };
  },
  created() {
    this.load();
  },
  watch: {
    signatureId: function (newValue) { this.load(); }
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    load: async function () {
      this.isLoading = true;

      const response = await workflowService.getAffiliateRenewalDetailsForSignature(this.signatureId);

      this.errorMessage = "";
      this.found = response.found;

      if (response.found && (!response.errors || response.errors.length == 0)) {
        this.registration = response.data;
      } else if (!!response.errors) this.errorMessage = response.errors ? response.errors.join(',') : 'An Unknown Error Occured'

      this.isLoading = false;
    },
    sign: function (workflowGuid: String, signatureId: number, personId: number, text: String) {
      workflowService
        .signAffiliateRenewal(workflowGuid, signatureId, personId, text)
        .then((res) => {
          this.$store.dispatch("loadPersonTasks", this.userInfo.personId);
          this.$router.push("/");
        }).catch((e) => this.$router.push("/"));
    },
  },
});
