
import Vue from "vue";
import router from "@/router";

import OwnerInformation from "./Steps/OwnerInformation.vue";
import DogInformation from "./Steps/DogInformation.vue";
import SummaryPayment from "./Steps/SummaryPayment.vue";

import registerQTService from "@/services/portal/registerQTService";
import workflowService from "@/services/portal/workflowService";

import { UserInfo } from "@/types/User";
import { CoOwnerResult } from "@/types/portal/Registration";
import AuthorizeNetResponseData from "@/types/AuthorizeNetResponseData";

const thumbsUpImage = require('@/assets/thumbs-up-duotone.svg');
const registerDogImage = require('@/assets/register-dog.png');

export default Vue.extend({
  name: "RegisterQTDog",
  components: {
    OwnerInformation,
    DogInformation,
    SummaryPayment,
  },
  mounted: function() {
    const userInfo = this.$store.state.userInfo;
    if (!!userInfo) this.initialize();

    this.getFees();
  },
  data: function () {
    return {
      thumbsUpImage,
      registerDogImage,
      isProcessing: false,
      currentPage: 1,

      dialog: false,
      dialogError: false,
      errorMessage: "",

      registerMember: false,
      renewMember: false,

      coOwnerInformation: [] as CoOwnerResult[],
      ownerInformation: {
        memberId: '',
        name: '',
        email: '',
        phoneNumber: '',
        address: {},
        signature: '',
      },
      dogInformation: {
        officialName: '',
        callName: '',
        gender: null,
        birthDate: null,
        breed: '',
        previousPointsEarned: '',
      },
      price: 20,
      responseStatus: {},
    }
  },
  methods: {
    initialize: async function() {
      this.isProcessing = true;

      const {
        hasNeverBeenMember,
        isActiveMember,
        memberId,
        firstName,
        lastName,
        email,
        phone,
        country,
        stateProvince,
        city,
        address,
        zipPostal,
        international
      } = this.userInfo;

      if (hasNeverBeenMember) this.registerMember = true;
      else if (!isActiveMember) this.renewMember = true;

      this.ownerInformation = {
        memberId,
        name: `${firstName} ${lastName}`,
        email,
        phoneNumber: phone,
        address: {
          address,
          international,
          city,
          stateProvince,
          zipPostal,
          country,
        },
        signature: '',
      };

      this.isProcessing = false;
    },
    getFees: async function() {
      this.isProcessing = true;

      const fee = await workflowService.getRegisterQTFees();

      if (!!fee && !isNaN(fee)) {
        this.price = fee;
      }

      this.isProcessing = false;
    },
    changePage(page: number) {
      this.currentPage = page;
    },
    changeCoOwner(coOwnerInfo: CoOwnerResult[]) {
      this.coOwnerInformation = coOwnerInfo;
    },
    submit: async function(paymentInfo: AuthorizeNetResponseData) {
      this.isProcessing = true;

      const response = await registerQTService.registerQTDog({
        paymentInfo,
        fee: this.price,
        signature: this.ownerInformation.signature,
        coOwnersPersonIds: this.coOwnerInformation.map(coi => coi.personId),
        dogInformation: this.dogInformation as any,
      });

      this.responseStatus = response;
      this.isProcessing = false;
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
  watch: {
    userInfo: function (newValue) {
      if (!!newValue) this.initialize();
    },
    responseStatus: function (newValue) {
      if (newValue.success) {
        this.dialog = true;
      } else if (newValue.data.error) { // Actual descriptive message received from the API
        this.dialogError = true;
        this.errorMessage = newValue.data.error;
      } else if (newValue.message) { // Likely "Request failed with status code 500" or something, but definitely an error
        this.dialogError = true;
        this.errorMessage = newValue.message;
      } else { // Something really weird happened in this case
        this.dialogError = true;
        this.errorMessage = "An unknown error occurred.";
      }
    },
    dialog: function (newValue) {
      if (!newValue) {
        this.isProcessing = false;

        this.$store.dispatch("loadPersonTasks", this.userInfo.personId);
        router.push({ path: "/" });
      }
    },
    dialogError: function (newValue) {
      if (!newValue && (this.responseStatus as any).data.paymentIssue) {
        this.isProcessing = false;
        this.$store.dispatch("loadPersonTasks", this.userInfo.personId);
        router.push({ path: "/" });
      } else if (!newValue) this.isProcessing = false;
    }
  }
});
