
import Vue from "vue";
import ReportList from '@/components/Admin/Reports/ReportList.vue';
import Viewer from '@/components/Admin/Reports/ReportViewer.vue';
import adminReportsService from "@/services/admin/reportsService";

export default Vue.extend({
  name: "TitlingProcess",
  components: {   
    ReportList,
    Viewer
  },
  data: function () {
    return {            
      reportList: [],
      selectedReport: "" as String
    };
  },
  beforeCreate() {
    adminReportsService.getReports()
      .then(result => {
        if(result.length > 0){
          //this.$data.selectedReport = result[0];
          this.$data.reportList = result;
        }
      })
      .catch(e => { console.log(e); });
  },
  methods: {
    selectReport(reportName: String) {
      this.selectedReport = reportName;
    }
  }
});
