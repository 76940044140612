
import Vue from 'vue';
export default Vue.extend({
  name: 'CallToAction',
  props: {
    title: String,
    link: String,
    buttonText: {
        type: String,
        default: 'Go To Action'
    },
  },
});
