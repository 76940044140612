var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.visible)?_c('div',{staticClass:"ml-3"},[(_vm.image)?_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-img',{staticClass:"profile-picture",attrs:{"src":_vm.image,"min-width":"25px","max-width":"40px","contain":""}})],1),_c('v-col',{staticClass:"link-header",attrs:{"cols":"10"}},[_c('p',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.expandable)?_c('v-badge',{staticClass:"mr-5 ml-1",attrs:{"color":"primary","content":`${_vm.items ? _vm.items.length : 0}`}}):_vm._e(),(_vm.expandable)?_c('font-awesome-icon',{attrs:{"icon":_vm.expanded ? 'chevron-up' : 'chevron-down',"color":"primary","size":"xs"},on:{"click":_vm.toggleExpanded}}):_vm._e()],1)])],1):_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.image)?_c('h2',[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.expandable)?_c('v-badge',{staticClass:"mr-5 ml-1",attrs:{"color":"primary","content":`${_vm.items ? _vm.items.length : 0}`}}):_vm._e(),(_vm.expandable)?_c('font-awesome-icon',{attrs:{"icon":_vm.expanded ? 'chevron-up' : 'chevron-down',"color":"primary","size":"xs"},on:{"click":_vm.toggleExpanded}}):_vm._e()],1):_vm._e()])],1),(!_vm.expandable || (_vm.expandable && _vm.expanded))?_c('div',_vm._l((_vm.items),function(item,index){return _c('div',{key:`${index}`,staticClass:"link-list"},[(_vm.visibleItem(item.forMembers))?_c('v-row',{staticClass:"mb-1"},[(item.image)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-img',{staticClass:"profile-picture",attrs:{"src":item.image,"min-width":"25px","max-width":"40px","contain":""}})],1):_vm._e(),_c('v-col',{staticClass:"my-auto",attrs:{"cols":item.image ? 10 : 12}},[(item.icon)?_c('font-awesome-icon',{attrs:{"icon":item.icon}}):_vm._e(),(item.link)?_c('router-link',{staticClass:"link",class:{
              'styled-link': item.icon || item.image,
              bold: item.image,
            },attrs:{"to":item.link}},[_vm._v(" "+_vm._s(item.text)+_vm._s("  ")+" "),(item.count > 0)?_c('v-badge',{attrs:{"color":"primary","content":item.count}}):_vm._e()],1):(item.to)?_c('a',{staticClass:"link",class:{
              'styled-link': item.icon || item.image,
              bold: item.image,
            },attrs:{"href":item.to,"target":"_blank"}},[_vm._v(" "+_vm._s(item.text)+_vm._s("  ")+" "),(item.count > 0)?_c('v-badge',{attrs:{"color":"primary","content":item.count}}):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(item.text)+_vm._s("  ")+" "),(item.count > 0)?_c('v-badge',{attrs:{"color":"primary","content":item.count}}):_vm._e()],1)],1)],1):_vm._e()],1)}),0):_vm._e(),_c('hr',{staticClass:"mt-4",attrs:{"color":"lightGray"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }