
import Vue from "vue";
import AffiliateCard from "@/components/Portal/AffiliateCard.vue";
import affiliateService from "@/services/portal/affiliateService.ts";

export default Vue.extend({
  components: { AffiliateCard },
  name: "AffiliateList",
  data: function () {
    return {
      affiliates: [] as any [],
      regions: [] as string [],
      selectedRegions: [] as string [],
      showLoader: false,
    };
  },
  created: function () {
    this.showLoader = true;
    affiliateService.getAffiliates().then((res: any) => {
      this.$data.affiliates = res.affiliates;
      this.$data.regions = [
        ...this.$data.regions,
        ...res.regions,
      ];
      this.showLoader = false;
    });
  },
  computed: {
    filteredAffiliates() {
      if (this.selectedRegions.length === 0) return this.$data.affiliates;
      return this.$data.affiliates.filter((affiliate: { region: string }) =>
        this.selectedRegions.includes(affiliate.region)
      );
    },
  },
});
