import { api } from "../api";

export const getAffiliateById = async (
  affiliateId: string,
  requesterId: Number
): Promise<object> => {
  const { data } = await api.get<object>(
    `/Affiliate/details/${affiliateId}/${requesterId}`
  );
  return data;
};

export const getAffiliates = async (): Promise<object> => {
  const { data } = await api.get<object>(`/Affiliate`);
  return data;
};

export const editAffiliateById = async (
  affiliateId: string,
  requesterId: Number,
  email: string,
  phoneNumber: string,
  address: string,
  city: string,
  state: string,
  zipCode: string,
  clubSite: string
): Promise<object> => {
  const { data } = await api.post<object>(
    `/Affiliate/edit`, {affiliateId, requesterId, email, phoneNumber, address, city, state, zipCode, clubSite}
  );
  return data;
};

export default {
  getAffiliateById,
  getAffiliates,
  editAffiliateById,
};
