
import Vue from "vue";
import Datepicker from "@/components/common/inputs/DatepickerFormatted.vue";

import puppyReleaseService from "@/services/puppyReleaseService";
import litterService from "@/services/portal/litterService";

import validateEmail from "@/helpers/validate-email";

// TODO: Once time allows debounce the email validation here
export default Vue.extend({
  name: "ReleasePuppy",
  components: {
    Datepicker,
  },
  data: function () {
    return {
      litterInfo: null,
      puppy: null,
      mayBreed: false,
      requireCoowner: false,
      kennels: [],
      transferDateFormatted: "",
      transferDate: new Date().toISOString(),
      firstName: "",
      lastName: "",
      email: "",
      signature: "",
      isValid: false,
      isProcessing: false,
      errorMessage: "",
      isError: false,
    };
  },
  created: function () { this.loadInfo() },
  watch: {
    '$store.state.userInfo.personId': {
      handler() { this.loadInfo() }
    }
  },
  methods: {
    loadInfo: function () {
      if (this.litterId) {
        litterService
          .getLitterById(this.litterId)
          .then((res) => {
            this.litterInfo = res;
            this.puppy = res.puppies.find(({ id }: { id: number }) => id === this.puppyId)
          })
          .catch((e) => console.log(e));
      }
    },
    updateDates: function (val: any, key: string) {
      (this as any)[key + "Formatted"] = val.formattedDate;
      (this as any)[key] = val.date;
    },
    validateEmail(v: string) {
      return (
        validateEmail(v) ||
        'E-mail must be valid'
      );
    },
    submit: function () {
      this.errorMessage = '';
      this.isProcessing = true;
      puppyReleaseService
        .releasePuppy(
          this.puppyId,
          this.mayBreed,
          this.requireCoowner,
          this.kennels,
          this.transferDate,
          this.firstName,
          this.lastName,
          this.email,
          this.signature
        )
        .then((res) => {
          this.isProcessing = false;
          if (!res.success) {
            this.errorMessage = res.message;
            this.isError = true;
            return;
          }
          this.$router.push(`/litters/${this.litterId}`)
        })
        .catch((e) => {
          this.isError = true;
          this.isProcessing = false;
          if (e.message) {
            this.errorMessage = e.message;
          } else {
            this.errorMessage = "An unknown error occurred.";
          }
        });
    },
  },
  props: {
    puppyId: { type: Number },
    litterId: { type: Number },
  },
});
