import { api } from "../api";
import { JuniorHandler, TitledDogs, JuniorHandlingScores, JuniorHandlingScoresResponse } from "@/types/admin/JuniorHandler";

export const getJuniorHandlerById = async (personId: Number): Promise<JuniorHandler> => {
    const { data } = await api.get<JuniorHandler>(`/Admin/juniorhandlers/${personId}`);

    return data;
};

export const validateJuniorHandlerEdit = async (request: JuniorHandler): Promise<object> => {
    const { data } = await api.post<object>(`/Admin/juniorhandlers/details/validate`, request);
    return data;
};

export const editJuniorHandler = async (request: JuniorHandler): Promise<object> => {
    const { data } = await api.post<object>(`/Admin/juniorhandlers/details/edit`, request);
    return data;
};

export const searchJuniorHandlerParent = async (params: object): Promise<any> => {
    const { data } = await api.get<object[]>(`/Admin/juniorhandlers/search/parent`,
        {
            params: params
        }
    );
    return data;
};

export const getJuniorHandlerAgeGroups = async (): Promise<any> => {
    const { data } = await api.get<object[]>(`/Admin/juniorhandlers/agegroups`);

    return data;
};

export const getJuniorHandlerRegions = async (): Promise<any> => {
    const { data } = await api.get<object[]>(`/Admin/juniorhandlers/regions`);

    return data;
};

export const getJuniorHandlerProgramClasses = async (programId: number): Promise<TitledDogs[]> => {
    const { data } = await api.get<any[]>(`/JuniorHandler/program/classes?programId=${programId}`);
    return data;
}

export const getTitledDogsByJuniorPersonId = async (personId: number): Promise<TitledDogs[]> => {
    const { data } = await api.get<TitledDogs[]>(`/JuniorHandler/titleddogs/${personId}`);
    return data;
}

export const updateTitledDogEntry = async (item: TitledDogs): Promise<object> => {
    const { data } = await api.post<object>(`/JuniorHandler/titleddogs`, item);
    return data;
}

export const deleteTitledDogEntry = async (resultId: number): Promise<object> => {
    const { data } = await api.delete<object>(`/JuniorHandler/titleddogs/${resultId}`);
    return data;
}

export const getJuniorHandlingResultsByJuniorPersonId = async (personId: number, type: number): Promise<JuniorHandlingScoresResponse> => {
    const { data } = await api.get<JuniorHandlingScores[]>(`/JuniorHandler/handlingscores/${personId}/${type}`);
    return { scores: data };
}

export const getJuniorHandlerTotalPointsForYear = async (
    personId: number,
    scoreType: number,
    classType: number,
    year: number): Promise<number> => {
    const { data } = await api.get<{ totalPoints: number }>(`/JuniorHandler/totalpoints/${personId}/${scoreType}/${classType}/${year}`);
    return data.totalPoints;
}

export const updateHandlingScoreEntry = async (handlingProgramType: string, item: JuniorHandlingScores): Promise<object> => {
    const { data } = await api.post<object>(`/JuniorHandler/handlingscores/${handlingProgramType}`, item);
    return data;
}

export const updateObedienceScoreEntry = async (item: any): Promise<object> => {
    const { data } = await api.post<object>(`/JuniorHandler/handlingscores/obedience`, item);
    return data;
}

export const deleteHandlingScoreEntry = async (resultId: number): Promise<object> => {
    const { data } = await api.delete<object>(`/JuniorHandler/handlingscores/${resultId}`);
    return data;
}

export const updateStockdogOpenScoreEntry = async (item: any): Promise<object> => {
    const { data } = await api.post<object>(`/JuniorHandler/handlingscores/stockdogOpen`, item);
    return data;
}

export const updateStockdogStandardScoreEntry = async (item: any): Promise<object> => {
    const { data } = await api.post<object>(`/JuniorHandler/handlingscores/stockdogStandard`, item);
    return data;
}

export const updateRallyScoreEntry = async (item: any): Promise<object> => {
    const { data } = await api.post<object>(`/JuniorHandler/handlingscores/rally`, item);
    return data;
}

export const updateTrackingScoreEntry = async (item: any): Promise<object> => {
    const { data } = await api.post<object>(`/JuniorHandler/handlingscores/tracking`, item);
    return data;
}

export default {
    getJuniorHandlerById,
    validateJuniorHandlerEdit,
    editJuniorHandler,
    searchJuniorHandlerParent,
    getJuniorHandlerAgeGroups,
    getJuniorHandlerRegions,
    getJuniorHandlerProgramClasses,
    getTitledDogsByJuniorPersonId,
    updateTitledDogEntry,
    deleteTitledDogEntry,
    getJuniorHandlingResultsByJuniorPersonId,
    getJuniorHandlerTotalPointsForYear,
    updateHandlingScoreEntry,
    updateObedienceScoreEntry,
    deleteHandlingScoreEntry,
    updateStockdogOpenScoreEntry,
    updateStockdogStandardScoreEntry,
    updateRallyScoreEntry,
    updateTrackingScoreEntry,
}