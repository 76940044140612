
import Vue from "vue";
import AdminEditableCoownersList, { OwnershipType } from "@/components/Admin/AdminEditableCoownersList.vue";
import AdminDogSearchDialog from "@/components/Admin/AdminDogSearchDialog.vue";
import AdminKennelSearchDialog from "@/components/Admin/AdminKennelSearchDialog.vue";
import DatepickerFormatted from "@/components/common/inputs/DatepickerFormatted.vue";
import LitterStatusDropdown from "@/components/common/inputs/LitterStatusDropdown.vue"

import litterService from "@/services/admin/litterService";

import { Messages } from "@/constants";
import { UserInfo } from "@/types/User";

export default Vue.extend({
  name: "LitterEntry",
  components: {
    AdminEditableCoownersList,
    AdminDogSearchDialog,
    DatepickerFormatted,
    AdminKennelSearchDialog,
    LitterStatusDropdown,
  },
  data: function() {
    return {
      litterInfo: {
        litterId: null, 
        owners: [] as any,
        kennels: [] as any,
        whelpingDate: null,
        isMultiSire: false,
        registrationDate: (new Date()).toISOString(),
        damId: null,
        sireId: null,
        adminNotes: '',
        notes: '',
        status: null,
      } as any,      
      persistedLitterInfo: {
        litterId: null, 
        owners: [] as any,
        kennels: [] as any,
        whelpingDate: null,
        isMultiSire: false,
        registrationDate: (new Date()).toISOString(),
        damId: null,
        sireId: null,
        adminNotes: '',
        notes: '',
        status: null,
      } as any,
      isEditMode: false,
      showFormDialog: false,
      showValidationDialog: false, 
      showSearchKennelDialog: false,
      validationResult: {
        warnings: [],
        errors: []
      },     
      overlappingDamLeases: [],
      litterKennelsHeaders:  [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      errorMessage: '',
      infoMessage: '',
      showErrorMessage: false,
      showInfoMessage: false
    };
  },
  methods: {
    toggleKennelSearchDialog: function () {
      this.showSearchKennelDialog = !this.showSearchKennelDialog;
    },
    deleteKennel: function(kennel: any){
      const index = this.litterInfo.kennels.map((k: any) => k.kennelId).indexOf(kennel.kennelId);
      (this as any).litterInfo.kennels.splice(index, 1);
    },
    toggleEditMode: function () {
      if (this.isEditMode) {
        this.validateAndConfirmSavingChanges();
      } else {
        this.isEditMode = true;
      }
    },
    validateAndConfirmSavingChanges: function () {
      const litterData = {
          litterId: this.litterInfo.litterId,
          damId: this.litterInfo.damId,
          sireId: this.litterInfo.sireId,
          whelpingDate: this.litterInfo.whelpingDate,
          owners: this.litterInfo.owners,
          kennelIds: this.litterInfo.kennels.map((k: any) => k.id),
          adminNotes: this.litterInfo.adminNotes,
          isMultiSire: this.litterInfo.isMultiSire,
          registrationDate: this.litterInfo.registrationDate
        };
      litterService
        .validateLitterInfoEdit(litterData)
        .then((res: any) => {
          this.validationResult = res;
          if(res.hasValidationIssues)
          {
            this.showValidationDialog = true;
          } else {
            this.saveChanges();
          }
        })  
    },
    saveChanges: function () {
      this.showValidationDialog = false;
      this.$store.dispatch("changeLoaderStatus",true);
      const litterData = {
          litterId: this.litterInfo.litterId,
          damId: this.litterInfo.damId,
          sireId: this.litterInfo.sireId,
          whelpingDate: this.litterInfo.whelpingDate,
          owners: this.litterInfo.owners,
          kennelIds: this.litterInfo.kennels.map((k: any) => k.id),
          adminNotes: this.litterInfo.adminNotes,
          isMultiSire: this.litterInfo.isMultiSire,
          registrationDate: this.litterInfo.registrationDate,
          status: this.litterInfo.status,
        };
      litterService
                .editLitterById(this.litterInfo.litterId, litterData)
                .then((res: any) => {
                  if(res.success){
                    if(res.id && res.id != this.litterId){
                      this.$router.push({ path: `/admin/litters/${encodeURIComponent(res.id.trim())}` }).catch(() => {});
                    }
                    this.isEditMode = false;
                    this.reloadData();
                    this.infoMessage = Messages.SaveSuccessful;
                    this.showInfoMessage = true;
                  } else {              
                    this.errorMessage = Messages.SaveError + " " + res.messages.join(', ')
                    this.showErrorMessage = true;
                  }
                })
                .catch((e: any) => {
                  this.errorMessage = Messages.SaveError;
                  this.showErrorMessage = true;
                })
                .finally(() => this.$store.dispatch("changeLoaderStatus",false));
    },
    handleKennelAdded: function(val :any){
      let kennels = this.litterInfo.kennels;
      const kennelsIds = this.litterInfo.kennels.map((c: any) => c.id);
      const index = kennelsIds.indexOf(val.kennel.kennelId);
      if(index < 0){
        kennels.push({id: val.kennel.kennelId, name: val.kennel.name });
      }
      this.litterInfo.kennels = kennels;
    },
    getKennelsForPeople: function(ids: any[]) {      
      litterService.getKennelsCoownedBy(ids)
        .then((res) => {
          let kennels = this.litterInfo.kennels;
          const kennelsIds = this.litterInfo.kennels.map((c: any) => c.id);

          res.data.forEach((k: any) => {
            const index = kennelsIds.indexOf(k.id.trim());
            if(index < 0){
              kennels.push(k);
            }
          });
          this.litterInfo.kennels = kennels;
        });
    },
    handleLitterCoownerAdded: function (val: any) {
      this.getKennelsForPeople([val.personId]);
    },
    updateSelectedPeople: function (val: any) {
      //workaround for setting up the primary owner
      //and issues with reactivity in Vue https://v2.vuejs.org/v2/guide/reactivity.html?redirect=true#For-Arrays
      if(val.newPeople && (val.newPeople.length === 1 || (val.newPeople.length > 0 && val.newPeople.findIndex((own: any) => own.isPrimary) == -1)))
      {
        val.newPeople[0].isPrimary = true;
      }
      if(!!this.litterInfo.owners && val.newPeople && val.newPeople.length > 0)
      {
        this.litterInfo.owners.splice(val.newPeople.length);
      }
      this.$set(this.litterInfo, 'owners', val.newPeople);
    },
    reloadData: function() {
      this.showValidationDialog = false;
      this.validationResult = {
        warnings: [],
        errors: []
      };
      this.errorMessage = '';
      if (this.litterId) {
        litterService
          .getLitterById(this.litterId)
          .then((res) => {
            this.litterInfo = res;
            this.persistedLitterInfo = res;
            this.getOverlappingDamLeases(false);
          });
      } else { //make the screen editable if it's create new
        this.isEditMode = true;
      }
    }, 
    updateDates: function (val: any, key: string) {
      this.litterInfo[key] = val.date;
      this.litterInfo = Object.assign({}, this.litterInfo);
    },
    getOverlappingDamLeases(updateOwners: boolean) {
      if(this.litterInfo.damId && this.litterInfo.whelpingDate){
        this.$store.dispatch("changeLoaderStatus",true);
        litterService
                .getOverlappingLeasesForDam(this.litterInfo.damId, this.litterInfo.whelpingDate)
                .then((res: any) => {
                  this.overlappingDamLeases = res.data;
                  if (updateOwners) this.updateOwnersFromODLs();
                })
                .catch((e: any) => console.log(e))
                .finally(() => this.$store.dispatch("changeLoaderStatus",false));

      } else {
        this.overlappingDamLeases = [];
      }
    },
    updateOwners: function(owners: any[], leaseOwners?: boolean) {
      if (this.isEditMode && ((this.overlappingDamLeases.length && leaseOwners) || !this.overlappingDamLeases.length)) {
        this.litterInfo.owners = owners;
        if (owners.length) this.getKennelsForPeople(owners.map((o) => o.personId));
      }
    },
    updateOwnersFromODLs: function() {
      let owners: any[] = [];

      for (let lease of this.overlappingDamLeases) {
        owners = owners.concat([...(lease as any).lessees]);
      }

      this.updateOwners(owners, true);
    },
  },
  created: function() {
    this.reloadData();
  },
  watch: {
    litterId: function(newVal: String) {
      this.reloadData();
    },
    "litterInfo.whelpingDate": function(newVal: String) {
      this.getOverlappingDamLeases(true);
    },
    "litterInfo.damId": function(newVal: String) {
      this.getOverlappingDamLeases(true);
    },
  },
  props: {
    litterId: { type: Number },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    dogOwnershipType: function() {
      return OwnershipType.Dog;
    },
    litterOwnershipType: function() {
      return OwnershipType.Litter;
    },
    leaseOwnershipType: function() {
      return OwnershipType.Lease;
    },
  },
});
