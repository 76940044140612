
import Vue from "vue";
import { UserInfo } from "@/types/User";
import litterService from "@/services/portal/litterService";

export default Vue.extend({
  name: "MyLitters",
  data: function () {
    return {
      litters: [],
      isLoading: false,
    };
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
  methods: {},
  created: function () {
    if (this.userInfo) {
      this.isLoading = true;
      litterService
        .getLittersByOwner(this.userInfo.personId)
        .then((res) => {
          this.litters = res.data;
          this.isLoading = false;
        })
        .catch((e) => console.log(e));
    }
  },
  watch: {
    userInfo: function (newVal: object) {
      this.isLoading = true;
      litterService
        .getLittersByOwner(this.userInfo.personId)
        .then((res) => {
          this.litters = res.data;
          this.isLoading = false;
        })
        .catch((e) => console.log(e));
    },
  },
});
