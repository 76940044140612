
import Vue from "vue";
import lookupsService from "@/services/admin/lookupsService";
import { DogTypeLookupInfo } from "@/types/admin/Lookup";

export default Vue.extend({
    name: "DogTypeMaintenance",
    data: function () {
        return {
            tab: null,
            luItems: [] as DogTypeLookupInfo[],
            tdialog: false,
            dialog: false,
            dialogDelete: false,
            headers: [
                { text: "ID", align: "left", value: "id" },
                { text: "Description", align: "left", value: "description" },
                { text: "Next ID", align: "left", value: "nextId" },
                { text: "Actions", value: "actions", sortable: false },
            ],
            editedIndex: -1,
            editedItem: {
                id: "",
                description: "",
                nextId: 1,
                canDelete:true,
                
            },
            defaultItem: {
                id: "",
                description: "",
                nextId: 1,
                canDelete: true,

            },
            errorMessage: "",
            isValid: true,
            search: "",
            isLoading: false,
            saveErrorMessage:"",

        };
    },
    created: function () {
        this.reloadItems();

    },
    methods: {
        reloadItems: function () {
            this.$data.isLoading = true;
            lookupsService.getDogTypeInfo().then((res: DogTypeLookupInfo[]) => {
                this.luItems = res;
                this.$data.isLoading = false;
            });
        },
        editItem(item: DogTypeLookupInfo) {
            this.editedIndex = this.luItems.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item: DogTypeLookupInfo) {
            this.editedIndex = this.luItems.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            
            this.saveErrorMessage = "";
            if (!this.editedItem.canDelete) {
                this.closeDelete();
            }
            lookupsService.deleteDogType(this.editedItem.id).then((res) => {
                if (res.success == false) {
                    this.saveErrorMessage = res.messages.join(', ')

                }
                else {
                    this.luItems.splice(this.editedIndex, 1);
                }

                this.reloadItems(); //in case something was changed after that pull

            }).catch((e: any) => {
                this.saveErrorMessage = "Unable to delete the dog type.";
            });

            this.closeDelete();
        },

        close() {
            this.errorMessage = "";
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            this.errorMessage = "";
            if (this.editedItem.id.trim().length == 0 || this.editedItem.description.trim().length == 0) {
                this.errorMessage = "Please enter valid values."
                return;
            }
            if (!/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/i.test(this.editedItem.id)) {
                this.errorMessage = "Please enter valid values."
                return;
            }
            this.editedItem.id = this.editedItem.id.trim().toUpperCase();
            if (this.editedItem.id.includes(' ')) {
                this.errorMessage = "Please enter valid values."
                return;
            }

            if (this.luItems) {
                //kp note: we aren't allowing add right now but leaving this check in case they request it in the future
                if (this.editedIndex == -1) {
                    var exists = this.luItems.find((x: any) => x.id.toLowerCase() == this.editedItem.id.toLowerCase()
                        || x.description.toLowerCase() == this.editedItem.description.toLowerCase());
                    if (exists != null) {
                        this.errorMessage = "This Id or Description already exists.";
                        return;
                    }
                }
                else {
                    var exists = this.luItems.find((x: any) => x.description.toLowerCase() == this.editedItem.description.toLowerCase() && x.id != this.editedItem.id);
                    if (exists != null) {
                        this.errorMessage = "This Description already exists.";
                        return;
                    }
                }
            }
            lookupsService.editDogType(this.editedItem).then((res: any) => {
                if (res.success) {
                    if (this.editedIndex > -1) {
                        Object.assign(this.luItems[this.editedIndex], this.editedItem)

                    }
                    else {
                        if (this.luItems) {
                            this.luItems.unshift(this.editedItem);

                        }
                    }
                    this.close();
                }
                else {
                    this.errorMessage = "Unable to edit the dog type.";
                    return;
                }
            }).catch(
                (e) => {
                    console.log(e);
                    this.errorMessage = "Unable to edit dog type.";
                });
            
        },
        incrementId(item: DogTypeLookupInfo) {
            item.nextId++;

        },
    },
    computed: {
        formTitle() {
            return this.$data.editedIndex === -1 ? "New Item" : "Edit Item";
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    
});
