
import Vue from "vue";
export default Vue.extend({
  name: "CountryDropdown",
  props: {
    selectedCountry: String,
    disabled: Boolean,
    classNames: String,
  },
  data: function () {
    return {
      countries: [
        { value: "UNITED STATES", text: "United States of America" },
        { value: "AFGHANISTAN", text: "Afghanistan" },
        { value: "ALBANIA", text: "Albania" },
        { value: "ALGERIA", text: "Algeria" },
        { value: "ANDORRA", text: "Andorra" },
        { value: "ANGOLA", text: "Angola" },
        { value: "ANTIGUABARBUDA", text: "Antigua and Barbuda" },
        { value: "ARGENTINA", text: "Argentina" },
        { value: "ARMENIA", text: "Armenia" },
        { value: "AUSTRALIA", text: "Australia" },
        { value: "AUSTRIA", text: "Austria" },
        { value: "AZERBAIJAN", text: "Azerbaijan" },
        { value: "BAHAMAS", text: "Bahamas" },
        { value: "BAHRAIN", text: "Bahrain" },
        { value: "BANGLADESH", text: "Bangladesh" },
        { value: "BARBADOS", text: "Barbados" },
        { value: "BELARUS", text: "Belarus" },
        { value: "BELGIUM", text: "Belgium" },
        { value: "BELIZE", text: "Belize" },
        { value: "BENIN", text: "Benin" },
        { value: "BHUTAN", text: "Bhutan" },
        { value: "BOLIVIA", text: "Bolivia" },
        { value: "BOSNIAHERZEGOVINA", text: "Bosnia and Herzegovina" },
        { value: "BOTSWANA", text: "Botswana" },
        { value: "BRAZIL", text: "Brazil" },
        { value: "BRUNEI", text: "Brunei" },
        { value: "BULGARIA", text: "Bulgaria" },
        { value: "BURKINAFASO", text: "Burkina Faso" },
        { value: "BURUNDI", text: "Burundi" },
        { value: "CABOVERDE", text: "Cabo Verde" },
        { value: "CAMBODIA", text: "Cambodia" },
        { value: "CAMEROON", text: "Cameroon" },
        { value: "CANADA", text: "Canada" },
        { value: "CENTRALAFRICANREPUBLIC", text: "Central African Republic" },
        { value: "CHAD", text: "Chad" },
        { value: "CHILE", text: "Chile" },
        { value: "CHINA", text: "China" },
        { value: "COLOMBIA", text: "Colombia" },
        { value: "COMOROS", text: "Comoros" },
        { value: "REPCONGO", text: "Congo, Republic of the" },
        { value: "DEMREPCONGO", text: "Congo, Democratic Republic of the" },
        { value: "COSTARICA", text: "Costa Rica" },
        { value: "COTED'IVOIRE", text: "Cote d'Ivoire" },
        { value: "CROATIA", text: "Croatia" },
        { value: "CUBA", text: "Cuba" },
        { value: "CYPRUS", text: "Cyprus" },
        { value: "CZECHREPUBLIC", text: "Czech Republic" },
        { value: "DENMARK", text: "Denmark" },
        { value: "DJIBOUTI", text: "Djibouti" },
        { value: "DOMINICA", text: "Dominica" },
        { value: "DOMINICANREPUBLIC", text: "Dominican Republic" },
        { value: "ECUADOR", text: "Ecuador" },
        { value: "EGYPT", text: "Egypt" },
        { value: "ELSALVADOR", text: "El Salvador" },
        { value: "EQUATORIALGUINEA", text: "Equatorial Guinea" },
        { value: "ERITREA", text: "Eritrea" },
        { value: "ESTONIA", text: "Estonia" },
        { value: "ETHIOPIA", text: "Ethiopia" },
        { value: "FIJI", text: "Fiji" },
        { value: "FINLAND", text: "Finland" },
        { value: "FRANCE", text: "France" },
        { value: "GABON", text: "Gabon" },
        { value: "GAMBIA", text: "Gambia" },
        { value: "GEORGIA", text: "Georgia" },
        { value: "GERMANY", text: "Germany" },
        { value: "GHANA", text: "Ghana" },
        { value: "GREECE", text: "Greece" },
        { value: "GRENADA", text: "Grenada" },
        { value: "GUATEMALA", text: "Guatemala" },
        { value: "GUINEA", text: "Guinea" },
        { value: "GUINEA", text: "Guinea" },
        { value: "GUYANA", text: "Guyana" },
        { value: "HAITI", text: "Haiti" },
        { value: "HONDURAS", text: "Honduras" },
        { value: "HUNGARY", text: "Hungary" },
        { value: "ICELAND", text: "Iceland" },
        { value: "INDIA", text: "India" },
        { value: "INDONESIA", text: "Indonesia" },
        { value: "IRAN", text: "Iran" },
        { value: "IRAQ", text: "Iraq" },
        { value: "IRELAND", text: "Ireland" },
        { value: "ISRAEL", text: "Israel" },
        { value: "ITALY", text: "Italy" },
        { value: "JAMAICA", text: "Jamaica" },
        { value: "JAPAN", text: "Japan" },
        { value: "JORDAN", text: "Jordan" },
        { value: "KAZAKHSTAN", text: "Kazakhstan" },
        { value: "KENYA", text: "Kenya" },
        { value: "KIRIBATI", text: "Kiribati" },
        { value: "KOSOVO", text: "Kosovo" },
        { value: "KUWAIT", text: "Kuwait" },
        { value: "KYRGYZSTAN", text: "Kyrgyzstan" },
        { value: "LAOS", text: "Laos" },
        { value: "LATVIA", text: "Latvia" },
        { value: "LEBANON", text: "Lebanon" },
        { value: "LESOTHO", text: "Lesotho" },
        { value: "LIBERIA", text: "Liberia" },
        { value: "LIBYA", text: "Libya" },
        { value: "LIECHTENSTEIN", text: "Liechtenstein" },
        { value: "LITHUANIA", text: "Lithuania" },
        { value: "LUXEMBOURG", text: "Luxembourg" },
        { value: "MACEDONIA", text: "Macedonia" },
        { value: "MADAGASCAR", text: "Madagascar" },
        { value: "MALAWI", text: "Malawi" },
        { value: "MALAYSIA", text: "Malaysia" },
        { value: "MALDIVES", text: "Maldives" },
        { value: "MALI", text: "Mali" },
        { value: "MALTA", text: "Malta" },
        { value: "MARSHALLISLANDS", text: "Marshall Islands" },
        { value: "MAURITANIA", text: "Mauritania" },
        { value: "MAURITIUS", text: "Mauritius" },
        { value: "MEXICO", text: "Mexico" },
        { value: "MICRONESIA", text: "Micronesia" },
        { value: "MOLDOVA", text: "Moldova" },
        { value: "MONACO", text: "Monaco" },
        { value: "MONGOLIA", text: "Mongolia" },
        { value: "MONTENEGRO", text: "Montenegro" },
        { value: "MOROCCO", text: "Morocco" },
        { value: "MOZAMBIQUE", text: "Mozambique" },
        { value: "MYANMAR", text: "Myanmar (Burma)" },
        { value: "NAMIBIA", text: "Namibia" },
        { value: "NAURU", text: "Nauru" },
        { value: "NEPAL", text: "Nepal" },
        { value: "NETHERLANDS", text: "Netherlands" },
        { value: "NEWZEALAND", text: "New Zealand" },
        { value: "NICARAGUA", text: "Nicaragua" },
        { value: "NIGER", text: "Niger" },
        { value: "NIGERIA", text: "Nigeria" },
        { value: "NORTHKOREA", text: "North Korea" },
        { value: "NORWAY", text: "Norway" },
        { value: "OMAN", text: "Oman" },
        { value: "PAKISTAN", text: "Pakistan" },
        { value: "PALAU", text: "Palau" },
        { value: "PALESTINE", text: "Palestine" },
        { value: "PANAMA", text: "Panama" },
        { value: "PAPUANEWGUINEA", text: "Papua New Guinea" },
        { value: "PARAGUAY", text: "Paraguay" },
        { value: "PERU", text: "Peru" },
        { value: "PHILIPPINES", text: "Philippines" },
        { value: "POLAND", text: "Poland" },
        { value: "PORTUGAL", text: "Portugal" },
        { value: "QATAR", text: "Qatar" },
        { value: "ROMANIA", text: "Romania" },
        { value: "RUSSIA", text: "Russia" },
        { value: "RWANDA", text: "Rwanda" },
        { value: "STKITTSNEVIS", text: "St. Kitts and Nevis" },
        { value: "STLUCIA", text: "St. Lucia" },
        {
          value: "STVINCENTGRENADINES",
          text: "St. Vincent and The Grenadines",
        },
        { value: "SAMOA", text: "Samoa" },
        { value: "SANMARINO", text: "San Marino" },
        { value: "SAOTOMEPRINCIPE", text: "Sao Tome and Principe" },
        { value: "SAUDIARABIA", text: "Saudi Arabia" },
        { value: "SENEGAL", text: "Senegal" },
        { value: "SERBIA", text: "Serbia" },
        { value: "SEYCHELLES", text: "Seychelles" },
        { value: "SIERRALEONE", text: "Sierra Leone" },
        { value: "SINGAPORE", text: "Singapore" },
        { value: "SLOVAKIA", text: "Slovakia" },
        { value: "SLOVENIA", text: "Slovenia" },
        { value: "SOLOMONISLANDS", text: "Solomon Islands" },
        { value: "SOMALIA", text: "Somalia" },
        { value: "SOUTHAFRICA", text: "South Africa" },
        { value: "SOUTHKOREA", text: "South Korea" },
        { value: "SOUTHSUDAN", text: "South Sudan" },
        { value: "SPAIN", text: "Spain" },
        { value: "SRILANKA", text: "Sri Lanka" },
        { value: "SUDAN", text: "Sudan" },
        { value: "SURINAME", text: "Suriname" },
        { value: "SWAZILAND", text: "Swaziland" },
        { value: "SWEDEN", text: "Sweden" },
        { value: "SWITZERLAND", text: "Switzerland" },
        { value: "SYRIA", text: "Syria" },
        { value: "TAIWAN", text: "Taiwan" },
        { value: "TAJIKISTAN", text: "Tajikistan" },
        { value: "TANZANIA", text: "Tanzania" },
        { value: "THAILAND", text: "Thailand" },
        { value: "TIMOR", text: "Timor" },
        { value: "TOGO", text: "Togo" },
        { value: "TONGA", text: "Tonga" },
        { value: "TRINIDADTOBAGO", text: "Trinidad and Tobago" },
        { value: "TUNISIA", text: "Tunisia" },
        { value: "TURKEY", text: "Turkey" },
        { value: "TURKMENISTAN", text: "Turkmenistan" },
        { value: "TUVALU", text: "Tuvalu" },
        { value: "UGANDA", text: "Uganda" },
        { value: "UKRAINE", text: "Ukraine" },
        { value: "UNITEDARABEMIRATES", text: "United Arab Emirates" },
        { value: "UNITEDKINGDOM", text: "United Kingdom (UK)" },
        { value: "URUGUAY", text: "Uruguay" },
        { value: "UZBEKISTAN", text: "Uzbekistan" },
        { value: "VANUATU", text: "Vanuatu" },
        { value: "VATICANCITY", text: "Vatican City (Holy See)" },
        { value: "VENEZUELA", text: "Venezuela" },
        { value: "VIETNAM", text: "Vietnam" },
        { value: "YEMEN", text: "Yemen" },
        { value: "ZAMBIA", text: "Zambia" },
        { value: "ZIMBABWE", text: "Zimbabwe" },
      ],
    };
  },
});
