import { api } from "../api";
import { DogInfo } from '@/types/admin/Dog';
import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";
import CommandResult from "@/types/CommandResult";
import { AxiosResponse } from "axios";

export const getDogsList = async (
    options: PaginationOptions,
    params: object,
): Promise<PaginatedResponse<object>> => {  
    const { page, itemsPerPage, sortBy, sortDesc } = options;

    const sort = !!sortBy.length ? sortBy[0] : 'requestId';
    const asce = !!sortDesc.length ? !sortDesc[0] : true;

    const { data } = await api.get<PaginatedResponse<object>>(`/Admin/dogs?pageSize=${itemsPerPage}&page=${page}&ascending=${asce}&sort=${sort}`, { params });

    return data;
};

export const getDogById = async (dogId: string): Promise<DogInfo> => {
    const { data } = await api.get<DogInfo>(`/Admin/dogs/details?dogId=${encodeURIComponent(dogId)}`);

    return data;
};

export const getDogTitlesInfoById = async (dogId: string): Promise<DogInfo> => {
    const { data } = await api.get<DogInfo>(`/Admin/dogs/titles?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};

export const getDogRegistrationInfoById = async (dogId: string): Promise<DogInfo> => {
    const { data } = await api.get<DogInfo>(`/Admin/dogs/registration?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};

export const validateDogRegistrationInfoEdit = async (request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/dogs/validate`, request);
    return data;
};

export const generateDogId = async (request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/dogs/generateId`, request);
    return data;
};

export const editDogById = async (dogId: string, request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/dogs?dogId=${encodeURIComponent(dogId.trim())}`, request);
    return data;
};


export const editManualPrefixSuffix = async (prefix: string, suffix: string, dogId: string): Promise<any> => {
    const { data } = await api.post<any>(`/Admin/dogs/updateManualPrefixSuffix`, { prefix, suffix, dogId });

    return data;
}

export const updatePrefixSuffix = async (dogId: string, prefix: string, suffix: string): Promise<any> => {
    const { data } = await api.post<any>(`/Admin/dogs/updatePrefixSuffix`, { dogId, prefix, suffix });

    return data;
}

export const updateDogDnaStatus = async (dogId: string, dnaInfo: any): Promise<any> => {
    const { data } = await api.post<any>(`/Admin/dogs/dna?dogId=${encodeURIComponent(dogId.trim())}`, dnaInfo);

    return data;
}

export const updateDogHofStatus = async (dogId: string, hofInfo: any): Promise<any> => {
    const { data } = await api.post<any>(`/Admin/dogs/hof?dogId=${encodeURIComponent(dogId.trim())}`, hofInfo);

    return data;
}

export const updateDogCertificate = async(newCert: object): Promise<any> => {
    const { data } = await api.post<any>(`/Admin/dogs/certificates/update`, newCert);

    return data;
}

export const deleteDogCertificate = async (req: object): Promise<any> => {
    const { data } = await api.post<any>(`/Admin/dogs/certificates/delete`, req);

    return data;
}

export const createDogCertificate = async (newCert: object): Promise<any> => {
    const { data } = await api.post<any>(`/Admin/dogs/certificates/create`, newCert);

    return data;
}

export const getPuppyRegistrationInfoById = async (puppyId: string): Promise<DogInfo> => {
    const { data } = await api.get(`/Admin/puppies/${puppyId}/registration`);

    return data;
};

export const getPuppyReleaseList = async (
    options: PaginationOptions,
    params: object
): Promise<PaginatedResponse<object>> => {  
    const { page, itemsPerPage, sortBy, sortDesc } = options;

    const sort = !!sortBy.length ? sortBy[0] : 'requestId';
    const asce = !!sortDesc.length ? !sortDesc[0] : true;

    const { data } = await api.get<PaginatedResponse<object>>(`/Admin/puppyreleases?pageSize=${itemsPerPage}&page=${page}&ascending=${asce}&sort=${sort}`, { params });

   return data;
};

interface ResendPuppyRelease { puppyReleaseId: number; email: string; firstName: string; lastName: string; }
export const resendReleaseEmail = async (request: ResendPuppyRelease) => {
    const { data } = await api.post<ResendPuppyRelease, AxiosResponse<CommandResult>>(`/Admin/puppyreleases/resend-release`, request);

    return data;
};

export default {
    getPuppyRegistrationInfoById,
    getPuppyReleaseList,
    getDogsList,
    getDogById,
    getDogRegistrationInfoById,
    getDogTitlesInfoById,
    validateDogRegistrationInfoEdit,
    editDogById,
    generateDogId,
    editManualPrefixSuffix,
    updateDogCertificate,
    deleteDogCertificate,
    createDogCertificate,
    updatePrefixSuffix,
    updateDogDnaStatus,
    updateDogHofStatus,
    resendReleaseEmail,
};