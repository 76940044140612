
import { isEmpty } from "lodash";
import Vue from "vue";
import SignatureLayout from "@/components/common/SignatureLayout.vue";
import puppyReleaseService from '@/services/puppyReleaseService';
import { UserInfo } from "@/types/User";

export default Vue.extend({
    name: 'PuppyReleaseSignatureForm',
    components: {
        SignatureLayout
    },
    data: function () {
        return {
            signatureText: "",
            registration: {} as Object,
            isLoading: false,
            errors: [] as string[]
        };
    },
    props: {
        signatureId: { type: Number },
    },
    methods: {
        isEmpty,
        signPuppyRelease: function (workflowGuid: String, signatureId: number, personId: number, text: String) {
            puppyReleaseService.signPuppyRelease(signatureId, text)
                .then(res => {
                    console.log(res)
                    if (!res.data.success) {
                        this.errors = [res.data.errorMessage]
                        return;
                    }
                    this.$router.push('/');
                })
                .catch(e => {
                    this.errors = [e.message];
                });
        }
    },
    created() {
        this.isLoading = true;
        puppyReleaseService.getPuppyReleaseDetailsForSignature(this.signatureId)
            .then(res => {
                this.isLoading = false;
                if (!isEmpty(res.data.errors)) {
                    this.errors = res.data.errors
                    return;
                }
                this.registration = res.data.data;
            })
            .catch(e => {
                this.errors = [e.message];
                this.isLoading = false;
            });
    },
    watch: {
        signatureId: function (newValue) {
            this.isLoading = true;
            puppyReleaseService.getPuppyReleaseDetailsForSignature(this.signatureId)
                .then(res => {
                    this.isLoading = false;
                    if (!isEmpty(res.data.errors)) {
                        this.errors = res.data.errors
                        return;
                    }
                    this.registration = res.data.data;
                })
                .catch(e => {
                    this.errors = [e.message];
                    this.isLoading = false;
                });
        }
    },
    computed: {
        userInfo(): UserInfo {
            return this.$store.state.userInfo;
        },
    }
});
