
import Vue from "vue";
import MyRequestLayout from "@/components/Portal/MyRequest/MyRequestLayout.vue";
import CoownersList, { OwnershipType } from "@/components/common/CoownersList.vue";

import workflowService from "@/services/portal/workflowService";

import { UserInfo } from "@/types/User";
import AuthorizeNetResponseData from "@/types/AuthorizeNetResponseData";

export default Vue.extend({
  name: "MyNewKennelRequest",
  components: {
    MyRequestLayout,
    CoownersList,
  },
  data: function () {
    return {
      registration: Object as any,
      isLoading: false,
      paymentStatus: null,
      errorMessage: "",
      showErrorMessage: false,
    };
  },
  props: {
    workflowGuid: { type: String },
  },
  methods: {
    load() {
      this.isLoading = true;
        workflowService
          .getKennelRequestDetails(this.workflowGuid)
          .then((res) => {
            this.isLoading = false;
            this.registration = res;
            console.log(res);
          });
    },
    authorizePayment: function (paymentInfo: AuthorizeNetResponseData) {
      var req = {
        paymentInfo,
        personId: this.userInfo.personId,
        workflowGuid: this.registration.workflowGuid,
      };
      workflowService.submitKennelCCAuthorization(req).then((res) => {
        if (res.error) {
          this.errorMessage = res.error;
          this.showErrorMessage = true;
        }
        else {
          this.paymentStatus = res;
          this.load();
        }
      });
    },
    makePayment: function (paymentInfo: AuthorizeNetResponseData) {
      var req = {
        paymentInfo,
        personId: this.userInfo.personId,
        workflowGuid: this.registration.workflowGuid,
      };

      workflowService.submitKennelCCPayment(req).then((res) => {
        if (res.error) {
          this.errorMessage = res.error;
          this.showErrorMessage = true;
        }
        else {
          this.paymentStatus = res;
        }

        this.load();
      });
    },
    cancelRequest: function () {
      console.log("cancelling request");
    },
  },
  created() {
    this.load();
  },
  watch: {
    workflowGuid: function (newValue) {
      this.load();
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    kennelOwnershipType: function () {
      return OwnershipType.Kennel;
    },
  },
});
