
import Vue from "vue";
import CoownersList, { OwnershipType } from "@/components/common/CoownersList.vue";
import Datepicker from "@/components/common/inputs/DatepickerFormatted.vue";
import PaginationSelect from "@/components/common/PaginationSelect.vue";

import kennelService from "@/services/admin/kennelService";

import { MetaNames } from "@/constants";
import { UserInfo } from "@/types/User";
import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";

export default Vue.extend({
  name: "KennelList",
  components: {
    CoownersList,
    Datepicker,
    PaginationSelect
  },
  data: function () {
    return {
      title: MetaNames.AdminKennels,
      loading: false,
      isError: false,
      errorMessage: "",
      paginatedResponse: {} as PaginatedResponse<object>,
      searchParams: {
        kennelId: "",
        kennelName: "",
        ownerName: "",
        memberId: "",
        isHOF: null as any,
        registrationDateFrom: "",
        registrationDateTo: "",  
        expiryDateFrom: "",
        expiryDateTo: "",       
      },
      options: {} as PaginationOptions,
      headers: [
        { text: "Kennel ID", align: "left", value: "kennelId" , width: "20%", sortable: false },
        { text: "Owners", align: "left", value: "owners", width: "25%", sortable: false },
        { text: "Hall of Fame", align: "left", value: "isHOF", width: "15%", sortable: false },
        { text: "Registration date", align: "left", value: "registrationDate", width: "20%", sortable: false },       
        { text: "Expiry date", align: "left", value: "expiryDate", width: "20%", sortable: false },
      ],
      footerProps: { 'items-per-page-options': [10, 20, 30, 40, 50, 100] },
      currentIsHOFIdx: 0,
    };
  },
  created: function () {
    this.load();
  },
  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    }
  },
  methods: {
    createNewKennel: function() {
      this.$router.push({path: '/admin/kennels'});
    },
    toggleHofCheckbox: function() {
      var states = [null, true, false];
      var next = ++this.currentIsHOFIdx % states.length;
      this.currentIsHOFIdx = next;
      this.searchParams.isHOF = states[next];
    },
    clearAllFilters: function () {
      this.options.page = 1;
      this.options.itemsPerPage = 10;
      this.searchParams = {
        kennelId: "",
        kennelName: "",
        ownerName: "",
        memberId: "",
        isHOF: null,
        registrationDateFrom: "",
        registrationDateTo: "", 
        expiryDateFrom: "",
        expiryDateTo: "",       
      };
    },
    load: async function () {
      this.loading = true;

      const {
        page,
        itemsPerPage,
      } = this.options;

      try {
        const response = await kennelService.getKennelsList({
          ...this.options,
          page: (page ?? 1) - 1,
          itemsPerPage: itemsPerPage ?? 10
        }, this.searchParams);

        this.loading = false;
        this.paginatedResponse = response;
      } catch (ex: any) {
        console.log(ex.response.data.errors)

        this.loading = false;
        this.isError = true;
        
        const descriptiveMessage = ex.response.data.errors
          ? `Error(s): ${Object.values(ex.response.data.errors).join(" & ")}`
          : null

        this.errorMessage = descriptiveMessage ?? ex.message ?? "Unknown error occurred when retrieving data.";
      }
    },
    applyFilter() {
      this.options.page = 1;
      this.options.itemsPerPage = 10;

      this.load();
    },
    updateDates: function (val: any, key: string) {
      (this as any).searchParams[key] = val.date;
    },
    updateDateSearch: async function(val: any, key: string) {
      await this.updateDates(val, key);
      this.applyFilter();
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    kennelOwnershipType: function () {
      return OwnershipType.Kennel;
    },
  },
});
