
import Vue from "vue";
import CoownersList, { OwnershipType } from "@/components/common/CoownersList.vue";
import Datepicker from "@/components/common/inputs/DatepickerFormatted.vue";
import PaginationSelect from "@/components/common/PaginationSelect.vue";

import leaseService from "@/services/admin/leaseService";

import { MetaNames } from "@/constants";
import { UserInfo } from "@/types/User";
import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";

export default Vue.extend({
  name: "LeaseList",
  components: {
    CoownersList,
    Datepicker,
    PaginationSelect
  },
  data: function () {
    return {
      title: MetaNames.AdminLeases,
      loading: false,
      isError: false,
      errorMessage: "",
      paginatedResponse: {} as PaginatedResponse<object>,
      searchParams: {
        dogId: "",
        dogName: "",
        lesseeName: "",
        registrationDateFrom: "",
        registrationDateTo: "",
        startDateFrom: "",
        startDateTo: "",
        endDateFrom: "",
        endDateTo: "",
      },
      options: {} as PaginationOptions,
      headers: [
        { text: "#ID", align: "left", value: "leaseId" },
        { text: "Dog ID", align: "left", value: "dogId" },
        {
          text: "Registration date",
          align: "left",
          value: "registrationDate",
          width: "160px",
        },
        {
          text: "Start Date",
          align: "left",
          value: "startDate",
          width: "160px",
        },
        { text: "End Date", align: "left", value: "endDate"},
        { text: "Lessees", align: "left", value: "lessees", },
        {
          text: "Cancelled?",
          align: "left",
          value: "isCancelled",
          width: "50px",
        },        
        { text: "", align: "left", value: "actionButton", sortable:false },
      ],
      footerProps: { 'items-per-page-options': [10, 20, 30, 40, 50, 100] },
    };
  },
  created: function () {
    this.load();
  },
  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    }
  },
  methods: {
    createNewLease: function () {
      this.$router.push({ path: '/admin/leases' });
    },
    clearAllFilters: function () {
      this.options.page = 1;
      this.options.itemsPerPage = 10;
      this.searchParams = {
        dogId: "",
        dogName: "",
        lesseeName: "",
        registrationDateFrom: "",
        registrationDateTo: "",
        startDateFrom: "",
        startDateTo: "",
        endDateFrom: "",
        endDateTo: "",
      };
    },
    showRegistration: function (id: any, _: any) {
      this.$router.push({ path: `/admin/registration/lease/${id}` });
    },
    cancelLease: function (id: any, _: any) {
      this.loading = true;
      leaseService
        .cancelLease(id)
        .then((_) => {
          this.load();
        })
        .catch((e) => console.log(e));
    },
    load: async function () {
      this.loading = true;

      const {
        page,
        itemsPerPage,
      } = this.options;

      try {
        const response = await leaseService.getLeasesList({
          ...this.options,
          page: (page ?? 1) - 1,
          itemsPerPage: itemsPerPage ?? 10
        }, this.searchParams);

        this.loading = false;
        this.paginatedResponse = response;
      } catch (ex: any) {
        console.log(ex.response.data.errors)

        this.loading = false;
        this.isError = true;
        
        const descriptiveMessage = ex.response.data.errors
          ? `Error(s): ${Object.values(ex.response.data.errors).join(" & ")}`
          : null

        this.errorMessage = descriptiveMessage ?? ex.message ?? "Unknown error occurred when retrieving data.";
      }
    },
    applyFilter() {
      this.options.page = 1;
      this.options.itemsPerPage = 10;

      this.load();
    },
    updateDates: function (val: any, key: string) {
      (this as any).searchParams[key] = val.date;
    },
    updateDateSearch: async function(val: any, key: string) {
      await this.updateDates(val, key);
      this.load();
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    dogOwnershipType: function () {
      return OwnershipType.Dog;
    },
    leaseOwnershipType: function () {
      return OwnershipType.Lease;
    },
  },
});
