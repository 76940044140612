
import Vue from "vue";
import CategoryManagement from "@/components/Admin/CategoryManagement.vue";
import CertProgramMaintenance from "@/components/Admin/Lookups/CertProgramMaintenance.vue";
import ShowTypesMaintenance from "@/components/Admin/Lookups/ShowTypesMaintenance.vue";
import DivisionsMaintenance from "@/components/Admin/Lookups/DivisionsMaintenance.vue";
import DNAStatusMaintenance from "@/components/Admin/Lookups/DNAStatusMaintenance.vue";
import BasicLUMaintenance from "@/components/Admin/Lookups/BasicLUMaintenance.vue";
import PointSchedulesMaintenance from "@/components/Admin/Lookups/PointSchedulesMaintenance.vue";

export default Vue.extend({
  name: "LookupsMaintenance",
  components: {
      CategoryManagement,
      DivisionsMaintenance,
      BasicLUMaintenance,
      DNAStatusMaintenance,
      PointSchedulesMaintenance,
      CertProgramMaintenance,
      ShowTypesMaintenance,
},
  data: function() {
    return {
      tab: null,
    };
  },
  methods: {
  }
});
