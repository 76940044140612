
import Vue from "vue";

import { UserInfo } from "@/types/User";
import { availablePositions, myPossibleRequiredPosistions, isValid } from "@/helpers/validate-affiliate";

import AddPersonModal from "@/components/common/AddPersonModal.vue";
import FooterText from "@/components/common/FooterText.vue";

export default Vue.extend({
  name: "AffiliateMembers",
  components: {
    AddPersonModal,
    FooterText,
  },
  props: {
    title: String,
    changePage: Function,
    affiliatePeople: Array,
    changeAffiliatePeople: Function,
    membershipTypes: Object,
    showObligations: Boolean,
    showAscaObligations: Boolean,
  },
  data: function () {
    return {
      ascaObligationsDialog: false,
      ascaAffiliateObligationsDialog: false,
      affiliatePeopleDialog: false,
      memberRuleDialog: false,
    };
  },
  methods: {
    toggleAscaObligations: function () {
      this.$data.ascaObligationsDialog = !this.$data.ascaObligationsDialog;
    },
    toggleAscaAffiliateObligations: function () {
      this.$data.ascaAffiliateObligationsDialog =
        !this.$data.ascaAffiliateObligationsDialog;
    },
    toggleAffiliatePeopleDialog: function () {
      this.$data.affiliatePeopleDialog = !this.$data.affiliatePeopleDialog;
    },
    toggleMemberRuleDialog: function () {
      this.$data.memberRuleDialog = !this.$data.memberRuleDialog;
    },
  },
  computed: {
    userInfo(): UserInfo {
      return (this as any).$store.state.userInfo;
    },
    availablePositions() {
      return availablePositions((this as any).$props.affiliatePeople);
    },
    isValid() {
      const myPosistions = (this as any).$props.affiliatePeople.filter(
          (person: { personId: number }) => person.personId === (this as any).userInfo.personId);

      // user submitting request is one of the members in the list and is assigned one of the possible required posistions?
      if (!myPosistions.length || !(myPosistions.some((mp: any) => !!myPossibleRequiredPosistions.find(rp => rp === mp.position))))
        return false;

      return isValid(this.$props.affiliatePeople);
    },
  },
});
