
import Vue from "vue";
import * as _ from "lodash";
import AdminDogSearchDialog from "../../AdminDogSearchDialog.vue";
import AdminJudgeSearchDialog from "../../AdminJudgeSearchDialog.vue";
import AdminPersonSearchDialog from "../../AdminPersonSearchDialog.vue";
import DatepickerFormatted from "../../../common/inputs/DatepickerFormatted.vue";

import showService from "@/services/admin/showService";

import { Messages } from "@/constants";

export default Vue.extend({
  name: "ConformationShowResultsSummaryEntry",
  components: {
    AdminDogSearchDialog,
    AdminJudgeSearchDialog,
    AdminPersonSearchDialog,
    DatepickerFormatted,
 },
  data: function() {
    return {
      selectedCombination: null as any,
      enteredCombinations: [] as any[],
      existingCombinationSelected: false,
      editedInfo: { showDate: null } as any,
      persistedInfo: { showDate: null } as any,
      errorMessage: '',
      infoMessage: '',
      showErrorMessage: false,
      showInfoMessage: false,
      calculatedPoints: null as any,
    };
  },
  methods: {
    updateDates: function (val: any, key: string) {
      this.$data.editedInfo[key] = val.date;
    },
    reloadData: function() {
      this.$store.dispatch("changeLoaderStatus",true);
      showService.getConformationShowResultsSummaryList(this.showId)
        .then((res) => { 
          this.enteredCombinations = res; 
          this.selectedCombination = null;
          this.editedInfo = this.emptyEditedInfo();
          this.persistedInfo = this.emptyEditedInfo();
          this.errorMessage = '';
          this.infoMessage = '';
          this.showInfoMessage = false;
          this.showErrorMessage = false;
        }
      ).finally(() => { this.$store.dispatch("changeLoaderStatus",false); });
    }, 
    reloadDetails: function(selectedCombination: any){
      this.$store.dispatch("changeLoaderStatus",true);
      showService.getConformationShowResultsSummary(this.showId, selectedCombination.showDate, selectedCombination.showTime, selectedCombination.showType)
        .then((res) => { 
          this.editedInfo = _.cloneDeep(res);
          this.persistedInfo = _.cloneDeep(res);
        }
      ).finally(() => { this.$store.dispatch("changeLoaderStatus",false); });  
    },
    updateEntry: function(){
      this.$store.dispatch("changeLoaderStatus",true);
      showService.updateConformationShowResultsSummary(this.showId, this.editedInfo)
        .then((res) => {
          if(res.success) {
            this.reloadDetails(this.selectedCombination);
            this.infoMessage = Messages.SaveSuccessful;
            this.showInfoMessage = true;
          } else {
            this.errorMessage = res.messages.join(', ');
            this.showErrorMessage = true;
          }
        }
      ).finally(() => { this.$store.dispatch("changeLoaderStatus",false); });  
    },
    deleteEntry: function(){
      this.$store.dispatch("changeLoaderStatus",true);
      showService.deleteConformationShowResultsSummary(this.showId, this.selectedCombination.showDate, this.selectedCombination.showTime, this.selectedCombination.showType)
        .then((res) => {
          if(res.success) {
            this.reloadData();
            this.infoMessage = "Entry successfully deleted."
            this.showInfoMessage = true;
          } else {
            this.errorMessage = res.messages.join(', ');
            this.showErrorMessage = true;
          }
        }
      ).finally(() => { this.$store.dispatch("changeLoaderStatus",false); });  
    },
    checkIfExistingCombinationSelected: function(showDate: any, showTime: any, showType: any) {
        if(!showDate || !showTime || !showType) return;

        const idx = this.enteredCombinations.findIndex((e: any) => 
               this.$dateUtils.formatDate(e.showDate) == this.$dateUtils.formatDate(showDate) 
            && e.showTime?.trim() == showTime?.trim()
            && e.showType == showType);

        if (idx >= 0) {
          this.existingCombinationSelected = true;
          if(!this.selectedCombination) {
            this.errorMessage = 'Existing combination of date, time and type of show selected. Select combination from the dropdown to edit that entry.'
            this.showErrorMessage = true;
          }
        } else {
          this.existingCombinationSelected = false;
          this.errorMessage = '';
          this.showErrorMessage = false;
        }
    },
    calculatePoints: function() {
      this.$store.dispatch("changeLoaderStatus",true);
      showService.calculatePointsForConformationShowResultsSummary(this.showId, this.editedInfo)
        .then((res: any) =>{ this.calculatedPoints = res;})
        .finally(() => { this.$store.dispatch("changeLoaderStatus",false); });  
    },
    emptyEditedInfo: function() {
      return {
        winnerDogId: null as any,
        winnerBitchId: null as any,
        bestOfBreedId: null as any,
        winnerDogPoints: 0,
        winnerBitchPoints: 0,
        bestOfOppositeSexId: null as any,
        reserveWinnerDogId: null as any,
        reserveWinnerBitchId: null as any,
        dogJudgeId: null as any,
        specialDogsCount: 0,
        classDogsCount: 0,
        bitchJudgeId: null as any,
        specialBitchesCount: 0, 
        classBitchesCount:0,
        showType: "I",
        showDate: null,
        showTime: null,
        showId: this.showId,
        bestJuniorHandlerPersonId: null as any,
        reserveJuniorHandlerPersonId: null as any,
      };
    }
  },    
  created: function() {
    this.reloadData();
  },
  watch: {
    "selectedCombination": function(newVal: String, oldVal: String) {
      if(!newVal){
        this.reloadData();
        this.$set(this, 'editedInfo', this.editedInfo);
      } else if(newVal && newVal != oldVal){
        this.reloadDetails(newVal);
        this.errorMessage = '';
      }
    },
    "editedInfo.showDate": function(newVal: any, oldVal: any){
      if (newVal) {
        this.checkIfExistingCombinationSelected(newVal, this.editedInfo.showTime, this.editedInfo.showType);
      }
    },
    "editedInfo.showTime": function(newVal: any, oldVal: any){        
      if (newVal) {
        this.checkIfExistingCombinationSelected(this.editedInfo.showDate, newVal, this.editedInfo.showType);
      }
    },
    "editedInfo.showType": function(newVal: any, oldVal: any){
      if (newVal) {
        this.checkIfExistingCombinationSelected(this.editedInfo.showDate, this.editedInfo.showTime, newVal);
      }
    }
  },
  props: {
    showId: { type: String },
  },
});
