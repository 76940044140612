export const getJuniorExpirationDate = (birthDate: Date) => {
  const eighteenAt: Date = new Date(birthDate);
  eighteenAt.setFullYear(eighteenAt.getFullYear() + 18);

  const year: number = eighteenAt.getFullYear();

  const showStart: Date = new Date(year, 5, 1);
  const showEnd: Date = new Date((year + 1),  4, 31);

  const expirationDate: Date = eighteenAt;

  // if the junior user turns 18 within a show year(6 / 1 - 5 / 31) they can remain a junior until the following year
  if (eighteenAt >= showStart && eighteenAt < showEnd)
  {
    expirationDate.setFullYear(eighteenAt.getFullYear() + 1);
  }

  return expirationDate;
}

export const isJunior = (birthDate: Date | string) => {
  const expirationDate = getJuniorExpirationDate(new Date(birthDate));

  return expirationDate > new Date();
}

export const isUnder16 = (birthDate: Date | string) => {
  const sixteenAt: Date = new Date(birthDate);
  sixteenAt.setFullYear(sixteenAt.getFullYear() + 16);

  return sixteenAt > new Date();
}

export const isUnder18 = (birthDate: Date | string) => {
  const eighteenAt: Date = new Date(birthDate);
  eighteenAt.setFullYear(eighteenAt.getFullYear() + 18);

  return eighteenAt > new Date();
}

export default {
  getJuniorExpirationDate,
  isJunior,
  isUnder16,
  isUnder18,
};