import { api } from "./api";

export const getDogById = async (dogId: string, personId: number): Promise<any> => {    
    const { data } = await api.get<object>(`/Dog?dogId=${encodeURIComponent(dogId.trim())}&personId=${personId}`);

    return data;
};

export const getDogShowResultsById = async (dogId: string, personId: number): Promise<any> => {    
    const { data } = await api.get<object>(`/Dog/showresults?dogId=${encodeURIComponent(dogId.trim())}&personId=${personId}`);

    return data;
};

export const getDogLeases = async (dogId: string): Promise<any> => {    
    const { data } = await api.get<object>(`/Dog/leases?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};

export const changeDogFavoriteStatus = async (dogId: string, personId: number): Promise<any> => {
    const { data } = await api.post<object>(`/Dog/updateDogFavoriteStatus`, { dogId, personId } );

    return data;
}

export const getDogTitlesById = async (dogId: string): Promise<any> => {    
    const { data } = await api.get<object>(`/Dog/titles?dogId=${encodeURIComponent(dogId.trim())}`);

    return data;
};
  
export default {
    getDogById,
    getDogShowResultsById,
    getDogLeases,
    changeDogFavoriteStatus,
    getDogTitlesById,
};