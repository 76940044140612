
import Vue from "vue";
import ReportViewer from "../../../Admin/Reports/ReportViewer.vue";
import Datepicker from "../../../common/inputs/DatepickerFormatted.vue";

import dogService from "@/services/dogService";
import lookupsService from "@/services/lookupsService";

import adminDogService from "@/services/admin/dogService";
import categoryService from "@/services/admin/categoryService";
import adminTitlingService from "@/services/admin/titlingService";

import { UserInfo } from "@/types/User";
import { Messages } from "@/constants";

export default Vue.extend({
  name: "Titles",
    props: {
        dogId: String
    },
  components: {
    ReportViewer,
    Datepicker,
  },
  data: function () {
    return {
      Report_Name: 'Asca.Api.Reports.rpt8110_Title_Certificate.rdlx',
      Report_URL: '`/Admin/reports/certificate/${selectedTitle}`',
      showReportDialog: false,
      dialog: false,
      dialogDelete: false,
      titles: [] as any,
      dnaStatuses: [] as any,
      certificatesHeaders: [
        { text: "Program", align: "left", value: "programName" },
        { text: "Title", align: "left", value: "categoryId" },
        { text: "Description", align: "left", value: "description" },
        { text: "Show Date", align: "left", value: "showDate" },
        { text: "Printed On", align: "left", value: "printedDate" },
        {
          text: "Certificate Number",
          align: "left",
          value: "certificateNumber",
        },
        { text: "Actions", value: "actions", sortable: false, width: "10%" },
      ],
      colors: [
        "#e51c23",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#5677fc",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#259b24",
        "#8bc34a",
        "#afb42b",
        "#ff9800",
        "#ff5722",
        "#795548",
        "#607d8b",
      ],
      search: "",
      selectedTitle: "",
      dogInfo: {
        dnaDate: null,
        hofDate: null
      } as any,
      dogName: {
        prefix: '',
        suffix: '',
        dogName: '',
      } as any,
      isEditNames: false,
      isEditingHof: false,
      isEditingDna: false,
      isRegeneratingName: false,
      editedIndex: -1,
      editedItem: {
        programName: "",
        title: "",
        description: "",
        showDate: null,
        printedDate: null,
        certificateNumber: "",
      },
      defaultItem: {
        programName: "",
        title: "",
        description: "",
        showDate: null,
        printedDate: null,
        certificateNumber: "",
      },
      titleChoices: [],
      snackbar: false,
      snackbarColor: "green",
      snackbarText: "",
    };
  },
  created() {
    this.loadDogData();
  },
   methods: { 
    showTitleDialog: function (){   
      this.Report_Name = "Asca.Api.Reports.rpt8110_Title_Certificate.rdlx";
      this.Report_URL = "/Admin/reports/certificate/"+this.selectedTitle;
      this.showReportDialog = true;
    },
    showTitleDialogFull: function (){
      this.Report_Name = "Asca.Api.Reports.rpt8111_Title_Certificate_Full.rdlx";
      this.Report_URL = "/Admin/reports/certificate/"+this.selectedTitle;
      this.showReportDialog = true;
    },
      loadDogData() {        
        lookupsService.getDnaStatuses().then((res: any) => {
          this.dnaStatuses = res;
        });
        adminDogService
          .getDogTitlesInfoById(this.dogId)
          .then((res: any) => {
            this.dogInfo = res;
            this.dogName = {
              prefix: res.prefix,
              suffix: res.suffix,
              dogName: res.name,
            };
            this.titles = res.titles;
          });
        categoryService.getTitleCategories().then((res) => {
          this.titleChoices = res.certificateCategories;      
        });
      },
    updateDogDnaStatus() {
      this.$store.dispatch("changeLoaderStatus",true);
      adminDogService.updateDogDnaStatus (
        this.dogId, 
        {
          dogId: this.dogId,
          dnaStatusId: this.dogInfo.dnaStatus,
          dnaDate: this.dogInfo.dnaDate ? this.dogInfo.dnaDate : null
        }
      ).then((res: any) => {
                if (res.success) {
                  this.$data.snackbar = true;
                  this.$data.snackbarColor = "green";
                  this.$data.snackbarText = Messages.SaveSuccessful;
                  this.loadDogData();
                  this.isEditingDna = false;
                } else {
                  this.$data.snackbar = true;
                  this.$data.snackbarColor = "red";
                  this.$data.snackbarText = Messages.SaveError + " " + res.messages.join(', ');
                }
          })
          .catch((e: any) => {
              this.$data.snackbar = true;
              this.$data.snackbarColor = "red";
              this.$data.snackbarText = e.message;
          })
          .finally(() => this.$store.dispatch("changeLoaderStatus",false));
    },
    updateDogHofStatus() {
      this.$store.dispatch("changeLoaderStatus",true);
      adminDogService.updateDogHofStatus(
        this.dogId, 
        {
          dogId: this.dogId,
          isHof: this.dogInfo.isHof,
          hofDate: this.dogInfo.hofDate ? this.dogInfo.hofDate : null
        }
      ).then((res: any) => {
                if (res.success) {
                  this.$data.snackbar = true;
                  this.$data.snackbarColor = "green";
                  this.$data.snackbarText = Messages.SaveSuccessful;
                  this.loadDogData();
                  this.isEditingHof = false;
                } else {
                  this.$data.snackbar = true;
                  this.$data.snackbarColor = "red";
                  this.$data.snackbarText = Messages.SaveError + res.messages.join(', ');
                }
          })
          .catch((e: any) => {
              this.$data.snackbar = true;
              this.$data.snackbarColor = "red";
              this.$data.snackbarText = e.message;
          })
          .finally(() => this.$store.dispatch("changeLoaderStatus",false)); 
    },
      generateDogName() {
          adminTitlingService.getGeneratedNameForDog(this.dogId)
            .then((res: any) => {
                this.dogName = res;
                this.isRegeneratingName = true;
          })
          .catch((e: any) => {            
              this.$data.snackbar = true;
              this.$data.snackbarColor = "red";
              this.$data.snackbarText = e.message;
          });
      },
      updateprefixSuffix() { 
        this.$store.dispatch("changeLoaderStatus",true);
        adminDogService.updatePrefixSuffix(this.dogId, this.$data.dogName.prefix, this.$data.dogName.suffix)
            .then((res: any) => {
                if (res.success) {
                  this.$data.snackbar = true;
                  this.$data.snackbarColor = "green";
                  this.$data.snackbarText = Messages.SaveSuccessful;
                  this.isRegeneratingName = false;
                  this.loadDogData();
                  this.$emit('update:dogNamePrefix', this.$data.dogName.prefix);
                  this.$emit('update:dogNameSuffix', this.$data.dogName.suffix);
                } else {
                  this.$data.snackbar = true;
                  this.$data.snackbarColor = "red";
                  this.$data.snackbarText = Messages.SaveError;
                }
          })
          .catch((e: any) => {
              this.$data.snackbar = true;
              this.$data.snackbarColor = "red";
              this.$data.snackbarText = Messages.SaveError;;
          })
          .finally(() => this.$store.dispatch("changeLoaderStatus",false));
      },
    updateManualPrefixSuffix() {
      this.$store.dispatch("changeLoaderStatus",true);
      adminDogService
        .editManualPrefixSuffix(
          this.$data.dogInfo.manualPrefix,
          this.$data.dogInfo.manualSuffix,
          this.$props.dogId
        )
        .then((res) => {
          if (res.success) {
            this.$data.snackbar = true;
            this.$data.snackbarColor = "green";
            this.$data.snackbarText = Messages.SaveSuccessful;
          } else {
            this.$data.snackbar = true;
            this.$data.snackbarColor = "red";
            this.$data.snackbarText = Messages.SaveError;
          }
        })
        .catch((e: any) => {
              this.$data.snackbar = true;
              this.$data.snackbarColor = "red";
              this.$data.snackbarText = Messages.SaveError;
        })
        .finally(() => this.$store.dispatch("changeLoaderStatus",false));

      this.$data.isEditNames = !this.$data.isEditNames;
    },
    filterCategoriesByDisplayText(item: any, queryText: string, itemText: string) {
        return itemText.toLowerCase().indexOf(queryText.toLowerCase()) > -1;
    },
    changeProgramAndDesc(e: any) {
      let idx = this.$data.titleChoices.findIndex(
        (x: any) => x.categoryId === e
      );
      if(idx !== -1){
        this.$data.editedItem.programName =
          this.$data.titleChoices[idx].programName;
        this.$data.editedItem.description =
          this.$data.titleChoices[idx].description;
        this.$data.editedItem = Object.assign({}, this.$data.editedItem);
      }
    },
    getTitleColor(titleName: string, titleId: number) {
      const text = `123${titleName}123`; //`${titleId}${titleName}${titleId}`
      var hash = 0;
      if (text.length === 0) return hash;
      for (var i = 0; i < text.length; i++) {
        hash = text.charCodeAt(i) + ((hash << 3) - hash);
        hash = hash & hash;
      }
      hash =
        ((hash % this.$data.colors.length) + this.$data.colors.length) %
        this.$data.colors.length;
      return this.$data.colors[hash];
    },
    toggleEditNames() {
      this.isEditNames = !this.isEditNames;
    },
    toggleEditingHof() {
      this.isEditingHof = !this.isEditingHof;
    },
    toggleEditingDna() {
      this.isEditingDna = !this.isEditingDna;
    },
    updateDogDates: function (val: any, key: string) {
      this.dogInfo[key] = val.date;
      this.dogInfo = Object.assign({}, this.dogInfo);
    },
    updateDates: function (val: any, key: string) {
      this.$data.editedItem[key] = val.date;
      this.$data.editedItem = Object.assign({}, this.$data.editedItem);
    },
    editItem(item: any) {
      var temp = Object.assign({}, item);
      this.$data.editedIndex = (this as any).titles.indexOf(item);
      this.$data.editedItem = Object.assign({}, temp);
      this.$data.dialog = true;
    },
    deleteItem(item: any) {
      this.$data.editedIndex = (this as any).titles.indexOf(item);
      this.$data.editedItem = Object.assign({}, item);
      this.$data.dialogDelete = true;
    },
    deleteItemConfirm() {
      adminDogService
        .deleteDogCertificate({
          dogId: this.$props.dogId,
          certId: this.$data.titles[this.$data.editedIndex].certificateId,
        })
        .then((res) => {
          if (res.success) {
            this.$data.titles = res.dogTitles;
            this.$data.snackbar = true;
            this.$data.snackbarColor = "green";
            this.$data.snackbarText = Messages.SaveSuccessful;
          } else {
            this.$data.snackbar = true;
            this.$data.snackbarColor = "red";
            this.$data.snackbarText = Messages.SaveError;
          }
        })
        .catch((e: any) => {
          this.$data.snackbar = true;
          this.$data.snackbarColor = "red";
          this.$data.snackbarText = Messages.SaveError;
        });
      (this as any).closeDelete();
    },

    close() {
      this.$data.dialog = false;
      this.$data.editedItem = Object.assign({}, this.$data.defaultItem);
      this.$data.editedIndex = -1;
    },

    save() {
      if (this.$data.editedIndex > -1) {
        Object.assign(
          (this as any).titles[this.$data.editedIndex],
          this.$data.editedItem
        );
        adminDogService
            .updateDogCertificate({
              ...this.$data.editedItem,
              dogId: this.$props.dogId,
              certId: this.$data.editedItem.certificateId,
            })
            .then((res) => {
              if (res.success) {
                this.$data.titles = res.dogTitles;
                this.$data.snackbar = true;
                this.$data.snackbarColor = "green";
                this.$data.snackbarText = Messages.SaveSuccessful;
              } else {
                this.$data.snackbar = true;
                this.$data.snackbarColor = "red";
                this.$data.snackbarText = Messages.SaveError;
              }
            })
            .catch((e: any) => {
              this.$data.snackbar = true;
              this.$data.snackbarColor = "red";
              this.$data.snackbarText = e.message;
        });
      } else {
        adminDogService
            .createDogCertificate({
              ...this.$data.editedItem,
              dogId: this.$props.dogId,
            })
            .then((res) => {
              if (res.success) {
                this.$data.titles = res.dogTitles;
                this.$data.snackbar = true;
                this.$data.snackbarColor = "green";
                this.$data.snackbarText = Messages.SaveSuccessful;
              } else {
                this.$data.snackbar = true;
                this.$data.snackbarColor = "red";
                this.$data.snackbarText = Messages.SaveError;
              }
            })
            .catch((e: any) => {
              this.$data.snackbar = true;
              this.$data.snackbarColor = "red";
              this.$data.snackbarText = e.message;
            });
      }
      (this as any).close();
    },
    closeDelete() {
      this.$data.dialogDelete = false;
      this.$data.editedItem = Object.assign({}, this.$data.defaultItem);
      this.$data.editedIndex = -1;
      },
  },
  watch: {
    dogId: function (newVal: string) {
      dogService
        .getDogTitlesById(this.dogId)
        .then((res) => {
          this.$data.titles = res;
        });
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    isNewTitle(): boolean {
      return this.$data.editedIndex === -1;
    },
  },
});

