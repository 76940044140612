
import Vue from "vue";
import Datepicker from "@/components/common/inputs/DatepickerFormatted.vue";

import showResultsService from "@/services/showResultsService";
import showTypesService from "@/services/showTypesService";
import { ShowType } from "@/types/admin/Show";

const noneOption = [{ showTypeId: "", description: 'All' }] as ShowType[];

export default Vue.extend({
  name: "ShowResultsPage",
  components: { Datepicker },
  data: function () {
    return {
      showResultsHeaders: [
        { text: "Show Date", align: "left", value: "showDate" },
        { text: "Dog Name", align: "left", value: "dogName" },
        { text: "Descr.", align: "left", value: "description" },
        { text: "City, State", align: "left", value: "cityState" },
        { text: "Country", align: "left", value: "country" },
        { text: "Category", align: "left", value: "category" },
        { text: "Time", align: "left", value: "time" },
        { text: "Division", align: "left", value: "division" },
        { text: "Score", align: "left", value: "score" },
        { text: "Place", align: "left", value: "place" },
        { text: "Hit", align: "left", value: "hit" },
        { text: "Pt Sched.", align: "left", value: "cpSched" },
        { text: "# Competing", align: "left", value: "nCompeting" },
        { text: "Handler", align: "left", value: "handler" },
        { text: "Judge", align: "left", value: "judge" },
      ],
      results: [],
      search: "",
      showTypeId: "",
      date: new Date().toISOString().slice(0, 10),
      showTypes: [] as ShowType[],
    };
  },
  methods: {
    toItemText: function (item: ShowType) {
      return item.showTypeId ? `${item.showTypeId}: ${item.description}` : item.description
    },
    updateDate: function (val: any) {
      this.date = val?.date;
    },
  },
  created: function () {
    this.$store.dispatch("changeLoaderStatus", true);
    showResultsService
      .getShowResults({ dateString: this.date, showTypeId: this.showTypeId })
      .then((res) => {
        this.results = res.data;
      })
      .finally(this.$store.dispatch("changeLoaderStatus", false));
    showTypesService.getShowTypes()
      .then((data) => {
        this.showTypes = noneOption.concat(data)
      })
      .catch(console.log);
  },
  watch: {
    date(d: string) {
      this.$store.dispatch("changeLoaderStatus", true);
      showResultsService
        .getShowResults({ dateString: d, showTypeId: this.showTypeId })
        .then((res) => {
          this.results = res.data;
        })
       .finally(this.$store.dispatch("changeLoaderStatus", false));
    },
    showTypeId(s: string) {
      this.$store.dispatch("changeLoaderStatus", true);
      showResultsService
        .getShowResults({ dateString: this.date, showTypeId: s })
        .then((res) => {
          this.results = res.data;
        })
       .finally(this.$store.dispatch("changeLoaderStatus", false));
    },
  },
});
