import { api } from "../api";
import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";

export const getLeasesList = async (
    options: PaginationOptions,
    params: object,
): Promise<PaginatedResponse<object>> => {  
    const { page, itemsPerPage, sortBy, sortDesc } = options;

    const sort = !!sortBy.length ? sortBy[0] : 'requestId';
    const asce = !!sortDesc.length ? !sortDesc[0] : true;

    const { data } = await api.get<PaginatedResponse<object>>(`/Admin/leases?pageSize=${itemsPerPage}&page=${page}&ascending=${asce}&sort=${sort}`, { params });

    return data;
};

export const getLeaseById = async (leaseId: number): Promise<any> => {
    const { data } = await api.get<object>(`/Admin/leases/details/${leaseId}`);
    return data;
};

export const validateLeaseEdit = async (request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/leases/details/validate`, request);
    return data;
};

export const registerNewLease = async (
    request: object
  ): Promise<any> => {
      const { data } = await api.post<object>(`/Admin/leases/registrations`, request);
      return data;
};

export const editLeaseById = async (request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/leases/details/edit`, request);
    return data;
};

export const cancelLease = async (leaseId: number): Promise<object> => {    
    const { data } = await api.post<object>(`/Admin/leases/${leaseId}/cancel`, { } );

    return data;
};

export default {
    getLeaseById,
    editLeaseById,
    validateLeaseEdit,
    registerNewLease,
    cancelLease,
    getLeasesList,
}