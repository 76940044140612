import { api } from "../api";
import { JudgeInfo } from '@/types/admin/Judge';



export const getJudgeClassTypes = async (): Promise<any> => {
    const { data } = await api.get<object[]>(`/Admin/judges/classtypes`);

    return data;
};

export const getJudgeById = async (personId: Number): Promise<JudgeInfo> => {
    const { data } = await api.get<JudgeInfo>(`/Admin/judge/${personId}`);

    return data;
};

export const editJudge = async (request: JudgeInfo): Promise<any> => { 
    const { data } = await api.post<object>(`/Admin/judges/details/edit`, request);
    return data;
};

export const validateJudgeEdit = async (request: JudgeInfo): Promise<object> => {
    const { data } = await api.post<object>(`/Admin/judges/details/validate`, request);
    return data;
};

export default {
    getJudgeById,
    validateJudgeEdit,
    editJudge,
    getJudgeClassTypes,
};