
import Vue from "vue";
import FilterPills from "@/components/Layouts/FilterPills.vue";
import PaginationSelect from "@/components/common/PaginationSelect.vue";

import peopleService from "@/services/admin/peopleService";

import { MetaNames } from "@/constants";
import { UserInfo } from "@/types/User";
import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";

export default Vue.extend({
  name: "PeopleList",
  components: {
    FilterPills,
    PaginationSelect
  },
  props: {
    useForSelection: { type: Boolean },
    onRowSelected: { type: Function },
  },
  data: function () {
    return {
      title: MetaNames.AdminPeople,
      loading: false,
      isError: false,
      errorMessage: "",
      paginatedResponse: {} as PaginatedResponse<object>,
      searchParams: {
        name: "",
        memberId: "",
        address: "",
        isMember: null,
        isAffiliateOfficer: null,
        isNationalOfficer: null,
        isNationalDirector: null,
        isJudge: null,
        isJuniorHandler: null,
        membershipType: null,
        membershipStatus: null as any,
        judgeId: "",
        phone: "",
        email: "",
        username: "",
      },
      options: {} as PaginationOptions,
      headers: [
        { text: "", align: "left", value: "name", width: "20%" },
        { text: "", align: "left", value: "email", width: "20%" },
        { text: "", align: "left", value: "memberId", width: "10%" },
        { text: "", align: "left", value: "affiliationType", width: "15%" },
        { text: "", align: "left", value: "memberType", width: "10%" },
        { text: "", align: "left", value: "formattedAddress", width: "25%" },
      ],
      footerProps: { 'items-per-page-options': [10, 20, 30, 40, 50, 100] },
      memberTypes: [
        { name: "Single", value: 1 },
        { name: "Dual", value: 2 },
        { name: "Junior", value: 4 },
        { name: "Service", value: 5 },
        { name: "Lifetime", value: 6 },
        { name: "Lifetime Dual", value: 7 },
      ],
      currentCheckboxIdx: 0,
    };
  },
  created: function () {
    this.load();
  },
  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    }
  },
  methods: {
    createNewPerson: function () {
      this.$router.push({ path: "/admin/people" });
    },
    clearAllFilters: function () {
      this.options.page = 1;
      this.options.itemsPerPage = 10;
      this.searchParams = {
        name: "",
        address: "",
        memberId: "",
        isMember: null,
        isAffiliateOfficer: null,
        isNationalOfficer: null,
        isNationalDirector: null,
        isJudge: null,
        isJuniorHandler: null,
        membershipType: null,
        membershipStatus: null,
        judgeId: "",
        phone: "",
        email: "",
        username: "",
      };
    },
    changeFilters: function (index: number) {
      (this as any).searchParams[this.affiliationFilters[index].var] = !(
        this as any
      ).searchParams[this.affiliationFilters[index].var];
    },
    toggleCheckbox: function () {
      var states = [null, true, false];
      var next = ++this.currentCheckboxIdx % states.length;
      this.currentCheckboxIdx = next;
      this.searchParams.membershipStatus = states[next];
    },
    load: async function () {
      this.loading = true;

      const {
        page,
        itemsPerPage,
      } = this.options;

      try {
        const response = await peopleService.getPeopleList({
          ...this.options,
          page: (page ?? 1) - 1,
          itemsPerPage: itemsPerPage ?? 10
        }, this.searchParams);

        this.loading = false;
        this.paginatedResponse = response;
      } catch (ex: any) {
        console.log(ex.response.data.errors)

        this.loading = false;
        this.isError = true;
        
        const descriptiveMessage = ex.response.data.errors
          ? `Error(s): ${Object.values(ex.response.data.errors).join(" & ")}`
          : null

        this.errorMessage = descriptiveMessage ?? ex.message ?? "Unknown error occurred when retrieving data.";
      }
    },
    applyFilter() {
      this.options.page = 1;
      this.options.itemsPerPage = 10;

      this.load();
    }
  },
  computed: {
    affiliationFilters(): any {
      return [
        {
          name: "Member",
          var: "isMember",
          active: this.$data.searchParams.isMember,
        },
        {
          name: "Affiliate Officer",
          var: "isAffiliateOfficer",
          active: this.$data.searchParams.isAffiliateOfficer,
        },
        {
          name: "National Officer",
          var: "isNationalOfficer",
          active: this.$data.searchParams.isNationalOfficer,
        },
        {
          name: "National Director",
          var: "isNationalDirector",
          active: this.$data.searchParams.isNationalDirector,
        },
        {
          name: "Judge",
          var: "isJudge",
          active: this.$data.searchParams.isJudge,
        },
        {
          name: "Junior Handler",
          var: "isJuniorHandler",
          active: this.$data.searchParams.isJuniorHandler,
        },
      ];
    },
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
});
