import { api } from "../api";
import {
    LookupInfo,
    NextIdsLookupInfo,
    MemberTypeLookupInfo,
    DogTypeLookupInfo,
    PointScheduleLookupInfo,
    DNALookupInfo,
    DivisionLookupInfo,
    ProgramLookupInfo,
    ShowTypeLookupInfo,
} from '@/types/admin/Lookup';

enum LookupPath {
    DogTypes = 'dogtypes',
    MemberTypes = 'membertypes',
    NextIds = 'nextids',
    PointSchedules = 'pointschedules',
    ShowTypes = 'showtypes',
    Divisions = 'divisions',
    CertPrograms = 'certprograms',
    TrimColors = 'trimcolors',
    BodyColors = 'bodycolors',
    EyeColors = 'eyecolors',
    Breeds = 'breeds',
    DNAStatuses = 'dnastatuses',
}

const getLookupInfo = async (path: LookupPath): Promise<any> =>
    (await api.get<any>(`/Admin/lookups/${path}`)).data;

const editLookupInfo = async (request: Object, path: LookupPath): Promise<any> =>
    (await api.post<object>(`/Admin/lookups/${path}`, request)).data;

export const deleteLookupInfo = async (id: string | number, path: LookupPath): Promise<any> =>
    (await api.delete<object>(`/Admin/lookups/${path}/${id}`)).data;

export const getProgramTitlesTypes = async (programSymbol: String): Promise<any> => {
    const { data } = await api.get<object>(`/Lookups/titles?program=${programSymbol}`);  
    return data;
};

export const getDogTypeInfo = async (): Promise<any> => await getLookupInfo(LookupPath.DogTypes);
export const getMemberTypeInfo = async (): Promise<any> => await getLookupInfo(LookupPath.MemberTypes);
export const getNextIdInfo = async (): Promise<any> => await getLookupInfo(LookupPath.NextIds);
export const getPointScheduleList = async (): Promise<any> => await getLookupInfo(LookupPath.PointSchedules);
export const getShowTypesList = async (): Promise<any> => await getLookupInfo(LookupPath.ShowTypes);
export const getDivisionsList = async (): Promise<any> => await getLookupInfo(LookupPath.Divisions);
export const getCertProgramList = async (): Promise<any> => await getLookupInfo(LookupPath.CertPrograms);
export const getTrimColors = async (): Promise<LookupInfo[]> => await getLookupInfo(LookupPath.TrimColors);
export const getBodyColors = async (): Promise<any> => await getLookupInfo(LookupPath.BodyColors);
export const getEyeColors = async (): Promise<any> => await getLookupInfo(LookupPath.EyeColors);
export const getBreeds = async (): Promise<any> => await getLookupInfo(LookupPath.Breeds);
export const getDnaStatusList = async (): Promise<any> => await getLookupInfo(LookupPath.DNAStatuses);

export const editDogType = async (request: DogTypeLookupInfo): Promise<any> => await editLookupInfo(request, LookupPath.DogTypes);
export const editMemberType = async (request: MemberTypeLookupInfo): Promise<any> => await editLookupInfo(request, LookupPath.MemberTypes);
export const editNextIds = async (request: NextIdsLookupInfo): Promise<any> => await editLookupInfo(request, LookupPath.NextIds);
export const editPointSchedule = async (request: PointScheduleLookupInfo): Promise<any> => await editLookupInfo(request, LookupPath.PointSchedules);
export const editShowType = async (request: ShowTypeLookupInfo): Promise<any> => await editLookupInfo(request, LookupPath.ShowTypes);
export const editDivision = async (request: DivisionLookupInfo): Promise<any> => await editLookupInfo(request, LookupPath.Divisions);
export const editCertProgram = async (request: ProgramLookupInfo): Promise<any> => await editLookupInfo(request, LookupPath.CertPrograms);
export const editTrimColor = async (request: LookupInfo): Promise<any> => await editLookupInfo(request, LookupPath.TrimColors);
export const editBodyColor = async (request: LookupInfo): Promise<any> => await editLookupInfo(request, LookupPath.BodyColors);
export const editEyeColor = async (request: LookupInfo): Promise<any> => await editLookupInfo(request, LookupPath.EyeColors);
export const editBreed = async (request: LookupInfo): Promise<any> => await editLookupInfo(request, LookupPath.Breeds);
export const editDnaStatus = async (request: DNALookupInfo): Promise<any> => await editLookupInfo(request, LookupPath.DNAStatuses);

export const deleteDogType = async (id: string): Promise<any> => await deleteLookupInfo(id, LookupPath.DogTypes);
export const deletePointSchedule = async (id: string): Promise<any> => await deleteLookupInfo(id, LookupPath.PointSchedules);
export const deleteShowType = async (id: string): Promise<any> => await deleteLookupInfo(id, LookupPath.ShowTypes);
export const deleteDivision = async (id: string): Promise<any> => await deleteLookupInfo(id, LookupPath.Divisions);
export const deleteCertProgram = async (id: number): Promise<any> => await deleteLookupInfo(id, LookupPath.CertPrograms);
export const deleteTrimColor = async (id: string): Promise<any> => await deleteLookupInfo(id, LookupPath.TrimColors);
export const deleteBodyColor = async (id: string): Promise<any> => await deleteLookupInfo(id, LookupPath.BodyColors);
export const deleteEyeColor = async (id: string): Promise<any> => await deleteLookupInfo(id, LookupPath.EyeColors);
export const deleteBreed = async (id: string): Promise<any> => await deleteLookupInfo(id, LookupPath.Breeds);
export const deleteDnaStatus = async (id: number): Promise<any> => await deleteLookupInfo(id, LookupPath.DNAStatuses);

export default {
    getProgramTitlesTypes,
    getDivisionsList,
    getTrimColors,
    deleteTrimColor,
    getEyeColors,
    getBodyColors,
    editTrimColor,
    deleteEyeColor,
    editEyeColor,
    deleteBodyColor,
    editBodyColor,
    getBreeds,
    deleteBreed,
    editBreed,
    getDnaStatusList,
    deleteDnaStatus,
    editDnaStatus,
    deleteDivision,
    editDivision,
    getCertProgramList,
    deleteCertProgram,
    editCertProgram,
    getShowTypesList,
    deleteShowType,
    editShowType,
    getPointScheduleList,
    editPointSchedule,
    deletePointSchedule,
    getDogTypeInfo,
    editDogType,
    getMemberTypeInfo,
    getNextIdInfo,
    deleteDogType,
    editMemberType,
    editNextIds,
};