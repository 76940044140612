var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-col',{staticClass:"register default-slot",attrs:{"md":"7","sm":"12"}},[_c('div',{staticClass:"top-icons"},[_c('div',{staticClass:"top-icons"},[_c('a',{staticClass:"back-icon",on:{"click":function($event){return _vm.changePage(1)}}},[_c('font-awesome-icon',{attrs:{"icon":"long-arrow-alt-left","size":"lg"}}),_vm._v(" Back ")],1)])]),_c('h1',{staticClass:"register-title"},[_vm._v("Dog Information")]),_c('v-form',{staticClass:"mt-5 mx-10",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-text-field',{attrs:{"outlined":"","label":"Official Name","rules":[
          (v) => !!v || 'Official Name is required',
          (v) => !(v.split(' ').filter(s => !!s).length < 2) || 'Official Name must have two words',
          (v) => v.length < 30 || '(30) characters max',
        ]},model:{value:(_vm.dogInfo.officialName),callback:function ($$v) {_vm.$set(_vm.dogInfo, "officialName", $$v)},expression:"dogInfo.officialName"}},[_c('v-tooltip',{attrs:{"slot":"append","dark":"","right":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function({on, attrs}){return [_c('font-awesome-icon',_vm._g(_vm._b({attrs:{"icon":"info-circle","color":"primary"}},'font-awesome-icon',attrs,false),on)),_vm._v("  ")]}}])},[_c('span',[_vm._v("  This is your dog's official name. Your dog's name must include at least two words. ")])])],1)],1),_c('v-row',[_c('v-text-field',{attrs:{"outlined":"","label":"Call Name","rules":[(v) => !!v || 'Call Name is required']},model:{value:(_vm.dogInfo.callName),callback:function ($$v) {_vm.$set(_vm.dogInfo, "callName", $$v)},expression:"dogInfo.callName"}})],1),_c('v-row',[_c('v-col',{attrs:{"lg":10,"md":10,"sm":10}},[_c('v-row',[_c('DatepickerFormatted',{attrs:{"date":_vm.dogInfo.birthDate,"label":"Date of Birth","rules":[
              (v) => !!v || 'Date of birth is required',
              (v) => new Date(v) < new Date() || 'Date of birth cannot be a future date'
            ]},on:{"updateDates":function($event){return _vm.updateBirthDate($event)}}})],1),_c('v-row',[_c('v-autocomplete',{attrs:{"label":"Breed","items":_vm.colors.breeds,"item-text":"description","item-value":"abbreviation","rules":[(v) => !!v || 'Breed is required']},model:{value:(_vm.dogInfo.breed),callback:function ($$v) {_vm.$set(_vm.dogInfo, "breed", $$v)},expression:"dogInfo.breed"}})],1)],1),_c('v-col',{attrs:{"lg":2,"md":2,"sm":2}},[_c('v-radio-group',{attrs:{"label":"Gender","rules":[(v) => !!v || 'Gender is required']},model:{value:(_vm.dogInfo.gender),callback:function ($$v) {_vm.$set(_vm.dogInfo, "gender", $$v)},expression:"dogInfo.gender"}},[_c('v-radio',{key:"M",attrs:{"label":"Male","value":"M"}}),_c('v-radio',{key:"F",attrs:{"label":"Female","value":"F"}})],1)],1)],1),_c('v-row',[_c('v-textarea',{staticClass:"mt-5",attrs:{"outlined":"","auto-grow":"","counter":"","label":"Previous ASCA points earned"},model:{value:(_vm.dogInfo.previousPointsEarned),callback:function ($$v) {_vm.$set(_vm.dogInfo, "previousPointsEarned", $$v)},expression:"dogInfo.previousPointsEarned"}},[_c('v-tooltip',{attrs:{"slot":"append","dark":"","right":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function({on, attrs}){return [_c('font-awesome-icon',_vm._g(_vm._b({attrs:{"icon":"info-circle","color":"primary"}},'font-awesome-icon',attrs,false),on)),_vm._v("  ")]}}])},[_c('span',[_vm._v("  Please enter the Affiliate/Club sponsoring the show, show date, and points earned. ")])])],1)],1)],1),_c('v-btn',{staticClass:"white--text",attrs:{"large":"","color":"primary","dark":_vm.isValid,"disabled":!_vm.isValid},on:{"click":function($event){return _vm.changePage(3)}}},[_vm._v("NEXT")]),_c('p',{staticClass:"steps"},[_vm._v("STEP "),_c('b',[_vm._v("2")]),_vm._v(" OF "),_c('b',[_vm._v("3")])]),_c('footer-text')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }