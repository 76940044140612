
import Vue from "vue";
import Datepicker from "@/components/common/inputs/DatepickerFormatted.vue";
import PaginationSelect from "@/components/common/PaginationSelect.vue";

import showService from "@/services/admin/showService";

import { UserInfo } from "@/types/User";
import { MetaNames } from "@/constants";
import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";

export default Vue.extend({
  name: "ShowList",
  components: {
    Datepicker,
    PaginationSelect
  },
  data: function() {
    return {
      title: MetaNames.AdminShows,
      loading: false,
      isError: false,
      errorMessage: "",
      paginatedResponse: {} as PaginatedResponse<object>,
      searchParams: {
        showId: "",
        affiliateId: "",
        affiliateName: "",
        showTypes: "",
        showDateFrom: "",
        showDateTo: "",
      },
      options: {} as PaginationOptions,
      headers: [
        { text: "", align: "left", value: "showId", width: "15%" },
        { text: "", align: "left", value: "affiliateId", width: "10%" },
        { text: "", align: "left", value: "affiliateName", width: "30%" },  
        { text: "", align: "center", value: "showStartDate", width: "15%" },
        { text: "", align: "center", value: "showEndDate", width: "15%" }, 
        { text: "", align: "left", value: "showType",width: "15%" },       
      ],
      footerProps: { 'items-per-page-options': [10, 20, 30, 40, 50, 100] },
      currentIsAscaIdx: 0,
      ShowTypes: [
        "AGILITY",
        "CONFORMATION",
        "CONFORMATION AND OBEDIENCE",
        "DOCK JUMPING",
        "FARM TRIAL",
        "JUNIORS",
        "JUNIORS & OBEDIENCE",
        "NATIONAL SPECIALTY",
        "NATIONAL SPECIALTY - AGILITY",
        "NATIONAL SPECIALTY - STOCK DOG",
        "NATIONAL SPECIALTY PRE-AGILITY",
        "NATIONAL SPECIALTY PRE-SHOW",
        "NATIONAL SPECIALTY PRE-TRIAL",
        "OBEDIENCE",
        "RALLY",
        "RANCH TRIAL",
        "STOCK TRIAL",
        "TRACKING"
      ],
      Show_Types: [],
      menu: false,
      dates: [] as string[],
    };
  },
  created: function() {
    this.load();
  },
  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    }
  },
  methods: {
    createNewShow: function () {
      this.$router.push({ path: '/admin/shows' });
    },
    clearAllFilters: function() {
      this.options.page = 1;
      this.options.itemsPerPage = 10;
      this.dates = [];
      this.Show_Types= [],
      this.searchParams = {
        showId: "",
        affiliateName:"",
        showTypes: "",
        showDateFrom: "",
          showDateTo: "",
        affiliateId:"",
      };
    },
    updateDates: function (val: any, key: string) {
      (this as any).searchParams[key] = val.date;
    },
    updateDateSearch: async function(val: any, key: string) {
      await this.updateDates(val, key);
      this.applyFilter();
    },
    load: async function () {
      this.loading = true;

      const {
        page,
        itemsPerPage,
      } = this.options;

      try {
        this.searchParams.showTypes = this.Show_Types.join('|');

        const response = await showService.getShowsList({
          ...this.options,
          page: (page ?? 1) - 1,
          itemsPerPage: itemsPerPage ?? 10
        }, this.searchParams);

        this.loading = false;
        this.paginatedResponse = response;
      } catch (ex: any) {
        console.log(ex.response.data.errors)

        this.loading = false;
        this.isError = true;
        
        const descriptiveMessage = ex.response.data.errors
          ? `Error(s): ${Object.values(ex.response.data.errors).join(" & ")}`
          : null

        this.errorMessage = descriptiveMessage ?? ex.message ?? "Unknown error occurred when retrieving data.";
      }
    },
    applyFilter() {
      this.options.page = 1;
      this.options.itemsPerPage = 10;

      this.load();
    }
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
});
