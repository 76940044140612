import { api } from "../api";

export const searchLessee = async (memberId: String): Promise<any> => {
  const { data } = await api.post<any>(
    `/Lease/registration/lessee/`, {memberId: memberId}
  );
  return data;
};

export const getLeasedDogsByOwner = async (personId: number): Promise<any> => {
  const { data } = await api.get<object>(`/Lease/myleases/${personId}`);

  return data;
};

export const getLeasedDog = async (dogId: string): Promise<any> => {
  const { data } = await api.get<object>(`/Lease/registration/dog/${dogId}`);

  return data;
};

export const validateLeaseDates = async (
  dogId: string,
  startDate: string,
  endDate: string,
  requesterPersonId: number
): Promise<any> => {
  try {
    const request = { dogId, startDate, endDate, requesterPersonId };
    const { data } = await api.post<object>(
      `/Lease/registration/validate`,
      request
    );

    return data;
  } catch (err) {
    console.error("error", err);
    return err;
  }
};

export const submitLeaseRequest = async (req: any): Promise<any> => {
  try {
    const { data } = await api.post<object>(`/Lease/registration`, req);
    return data;
  } catch (err) {
    console.error("error", err);
    return err;
  }
};

export default {
  getLeasedDogsByOwner,
  searchLessee,
  getLeasedDog,
  validateLeaseDates,
  submitLeaseRequest,
};
