import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { env } from '../environment/environment';

// following the approach described in this blog post for creating a simple api wrapper
// https://altrim.io/posts/axios-http-client-using-typescript

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
  "Access-Control-Allow-Credentials": true,
  "X-Requested-With": "XMLHttpRequest",
};

const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const token = localStorage.getItem("userToken");

    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  } catch (error: any) {
    throw new Error(error);
  }
};

class Api {
  private instance: AxiosInstance | null = null;

  private get api(): AxiosInstance {    
      return this.instance != null ? this.instance : this.initApi();
  }

  initApi() {
    const api = axios.create({
      baseURL: `${env.VUE_APP_API_URL}/`,
      headers,
      withCredentials: true,
    });

    api.interceptors.request.use(injectToken, (error) => Promise.reject(error));

    api.interceptors.response.use(
      (response: any) =>  {
        return response;
      }, 
      (error: any) => {
        const { response } = error;
        return this.handleError(response);
      }
    );    

    this.instance = api;
    return api;
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.api.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.api.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.api.delete<T, R>(url, config);
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code  
  private handleError(error: any) {
    if(error && error?.status){
      const { status } = error;

      switch (status) {
        case StatusCode.InternalServerError: {
          // Handle InternalServerError
          break;
        }
        case StatusCode.Forbidden: {
          // Handle Forbidden
          break;
        }
        case StatusCode.Unauthorized: {
          if(!window.location.href.includes('/login')){
            window.location.href = '/login';
          }
          break;
        }
        case StatusCode.TooManyRequests: {
          // Handle TooManyRequests
          break;
        }
      }
    }

    return Promise.reject(error);
  }
}

export const api = new Api();
