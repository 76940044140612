
import Vue from "vue";
import DatepickerFormatted from "../../common/inputs/DatepickerFormatted.vue";

import judgeService from "@/services/admin/judgeService";
import showService from "@/services/admin/showService";

import { Messages } from "@/constants";
import { ShowType } from '@/types/admin/Show';
import { JudgeInfo, JudgingApprovals, ClassType } from '@/types/admin/Judge';

export default Vue.extend({
    name: "JudgeDetails",
    components: {
        DatepickerFormatted,
    },
    data: function () {
        return {                
            judgeInfo: {
                personId: this.$props.personId,
                judgingApprovals: [] as JudgingApprovals[]
            } as JudgeInfo,
            isEditMode: false,
            showValidationDialog: false,
            validationResult: {} as any,
            showTypes: [] as ShowType[],
            classTypes: [] as ClassType[],
            editedIndex: -1,
            editedItem: {} as JudgingApprovals,
            defaultItem: {} as JudgingApprovals,
            dialog: false,
            dialogDelete: false,
            showTypeIds: [] as string[],
            classTypeIds: [] as string[],
            reloadPage: false,
            isValid: true,
            headers: [
                { text: "Show Type", align: "left", value: "showTypeId" },
                { text: "Class", align: "left", value: "classId" },
                { text: "Emergency?", align: "left", value: "emergencyStatus" },
                { text: "Approval Date", align: "left", value: "approvalDate" },
                { text: "Tested?", align: "left", value: "testStatus" },
                { text: "Test Date", align: "left", value: "testDate" },
                { text: "Test Score", align: "left", value: "testScore" },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            errorMessage: '',
            infoMessage: '',
            showErrorMessage: false,
            showInfoMessage: false               
        };
    },
    created: function () {
        if (this.personId) {
            judgeService.getJudgeById(this.personId).then((res: JudgeInfo) => {
                if (res) {
                    
                    this.judgeInfo = res;
                    this.reloadPage = false;
                } else {
                    this.judgeInfo = {
                        personId: this.$props.personId,
                        judgingApprovals: [] as JudgingApprovals[]
                    } as JudgeInfo,
                    this.isEditMode = true;
                    this.reloadPage = true;
                }
            }).catch(e => {
                this.judgeInfo = {
                        personId: this.$props.personId,
                        judgingApprovals: [] as JudgingApprovals[]
                    } as JudgeInfo,
                this.reloadPage = true;
            })
        }
        else {
            this.judgeInfo = {
                personId: this.$props.personId,
                judgingApprovals: [] as JudgingApprovals[]
            } as JudgeInfo,
            this.reloadPage = true;
        }
        showService.getJudgeShowTypes().then((res: any) => {
            this.showTypes = res;
            for (let i = 0; i < this.showTypes.length; i++) {
                this.showTypeIds.push(this.showTypes[i].showTypeId);
            }
            
        });

        judgeService.getJudgeClassTypes().then((res: any) => {
            this.classTypes = res;
            this.classTypeIds.push('');
            for (let i = 0; i < this.classTypes.length; i++) {
                this.classTypeIds.push(this.classTypes[i].classId);
            }

        });

    },
    watch: {
        personId: function (newVal: String) {
            judgeService
                .getJudgeById(this.personId)
                .then((res) => {
                    if(res){
                        this.judgeInfo = res;
                    } else {
                        this.judgeInfo = {
                            personId: this.$props.personId,
                            judgingApprovals: [] as JudgingApprovals[]
                        } as JudgeInfo;
                    }
                });
        },
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    computed: {
        formTitle() {
            return this.$data.editedIndex === -1 ? "Add Approval" : "Edit Approval";
        },
    },
    methods: {
        reloadData: function () {
            if (this.personId) {
                judgeService
                    .getJudgeById(this.personId)
                    .then((res) => {
                        if(res){
                            this.judgeInfo = res;
                        } else {
                            this.judgeInfo = {
                                personId: this.$props.personId,
                                judgingApprovals: [] as JudgingApprovals[]
                            } as JudgeInfo;
                        };
                    })
                    .catch((e) => {
                        this.judgeInfo = {
                            personId: this.$props.personId,
                            judgingApprovals: [] as JudgingApprovals[]
                        } as JudgeInfo
                    });
            }
            else {
                this.judgeInfo = {} as JudgeInfo;
                this.isEditMode = true;
            }
            this.showValidationDialog = false;
            this.errorMessage = '';
            this.showErrorMessage = false;
            this.validationResult = {};
            
        },
        saveChanges: function () {
            this.errorMessage = "";
            this.showValidationDialog = false;
            this.showErrorMessage = false;
            this.$store.dispatch("changeLoaderStatus", true);
            this.judgeInfo.personId = this.$props.personId; 
            judgeService
                .editJudge(this.judgeInfo)
                .then((res) => {
                    if (res.success) {
                        this.reloadData();
                        if (this.reloadPage) {
                            window.location.reload();
                        } 
                        this.infoMessage = Messages.SaveSuccessful;
                        this.showInfoMessage = true;
                    } else {              
                        this.errorMessage = Messages.SaveError + " " + res.messages.join(', ');
                        this.showErrorMessage = true;
                    }
                })
                .catch((e) => {                  
                    this.errorMessage = Messages.SaveError;
                    this.showErrorMessage = true;
                })
                .finally(() => this.$store.dispatch("changeLoaderStatus", false));
        },
        updateDates: function (val: any, key: string) {
            this.$data.judgeInfo[`${key}Formatted`] = val.formattedDate;
            this.$data.judgeInfo[key] = val?.date;
            this.$data.judgeInfo = Object.assign({}, this.$data.judgeInfo);
        },
        updateEditedItemDates: function (val: any, key: string) {
            this.$data.editedItem[key] = val?.date;
            this.$data.editedItem = Object.assign({}, this.$data.editedItem);
        },
        updateText: function (key: string, event: string) {
            this.$data.judgeInfo[key] = event;
        },
        validateAndConfirmSavingChanges: function () {
            this.errorMessage = "";
            this.showErrorMessage = false;
            
            if (this.judgeInfo.affiliation) {
                if (this.judgeInfo.affiliation.length > 50) {
                    this.errorMessage = "Affiliation is too long.";
                    this.showErrorMessage = true;
                    this.isEditMode = false; //will toggle it back to edit mode
                    return;
                }
            }
            this.saveChanges();
            this.validationResult = {};
            //Right now there isn't anything to validate on the back end. 
            //Leaving the placeholders there in case we want to add some

        },
        toggleEditMode: function () {
            if (this.isEditMode) {
                this.validateAndConfirmSavingChanges();
            }
            this.isEditMode = !this.isEditMode;
        },
        
        editItem(item: JudgingApprovals) {
            this.editedIndex = this.judgeInfo.judgingApprovals.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item: JudgingApprovals) {
            this.editedIndex = this.judgeInfo.judgingApprovals.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.judgeInfo.judgingApprovals.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.judgeInfo.judgingApprovals[this.editedIndex], this.editedItem)

            }
            else {
                try {
                    this.judgeInfo.judgingApprovals.push(this.editedItem);

                }
                catch (error) {

                    console.log(error);
                }


            }
            this.close();

        },
        isValidNumber(value: string) {
            if (!value) {
                return true;
            }
            if (!isNaN(parseFloat(value))) return true;

            return 'The value has to be numeric';

        },
    },
    props: {
        personId: { type: Number },
    },
});
