import { format, formatDistanceStrict } from 'date-fns';

export const formatDateISO = (date: string) => { 
    return format(new Date(date), "yyyy-MM-dd"); 
}; 

export const formatDate = (date: string) => { 
    try 
    {
        return format(new Date(date), "MM/dd/yyyy"); 
    } catch(err){
        return "";
    }
}; 

export const formatLongDate = (date: string) => {
    try
    {
        return format(new Date(date), "MMMM dd, yyyy");
    } catch(err){
        return "";
    }
}

export const formatDateTime = (dateTime: string) => { 
    return format(new Date(dateTime), 'MMM d yyyy') + " at " + format(new Date(dateTime), 'h:mm a') 
};

export const formatDateTimeConcise = (dateTime: string) => { 
    return format(new Date(dateTime), 'MM/dd/yyyy hh:mm a') 
};

export const formatDurationHumanReadable = (dateTimeFrom: string, dateTimeTo: string) => { 
    return formatDistanceStrict(new Date(dateTimeFrom), new Date(dateTimeTo)) ;
};

export default {
    formatDate,
    formatLongDate,
    formatDateTime,
    formatDateTimeConcise,
    formatDateISO,
    formatDurationHumanReadable
};