import { api } from "./api";
import { env } from '../environment/environment';

export enum VerificationType {
  Email = 1,
  PhoneNumber,
  Membership,
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const startEmailVerification = async(emailAddress: string): Promise<any> => {
  const {data} = await api.post<any>(`${env.VUE_APP_FUNCTIONS_API_URL}/api/StartEmailVerification?emailAddress=${encodeURIComponent(emailAddress)}`);
  return { success: true, errors: null, instanceId: data };
}

export const startPhoneNumberVerification = async(phoneNumber: string): Promise<any> => {
  const {data} = await api.post<any>(`${env.VUE_APP_FUNCTIONS_API_URL}/api/StartPhoneNumberVerification?phoneNumber=${encodeURIComponent(phoneNumber)}`);
  return { success: true, errors: null, instanceId: data };
}

export const startMemberVerification = async(memberId: string): Promise<any> => {
  const response = await api.post<any>(`${env.VUE_APP_FUNCTIONS_API_URL}/api/StartMemberVerification?memberId=${memberId}`);
  return response.data;
}

const getInstanceVerification = async (instanceId: string): Promise<any> => {
  const queryStatusUrl = `${env.VUE_APP_FUNCTIONS_API_URL}/runtime/webhooks/durabletask/instances/${instanceId}?code=${env.VUE_APP_FUNCTIONS_API_KEY}`;
  const response = await api.get<any>(queryStatusUrl);

  if (response.status != 202 && response.status != 200) {
    return {Verified: true, CodeCorrect: false, ErrorMessage: "Code can't be verified. Request new code."};
  } else if (response.data && response.data.customStatus.ErrorMessage) {
    return {Verified: true, CodeCorrect: false, ErrorMessage: response.data.customStatus.ErrorMessage};
  } else if (response.data.customStatus != null && response.data.customStatus.CodeCorrect) {
    return response.data.customStatus;
  }

  return null;
}

export const postVerificationCodeForChallengeType = async(
  instanceId: string,
  code: string,
  challengeType: VerificationType,
): Promise<any> => {   
  console.log('Checking current verification status');
  const verificationResponse = await getInstanceVerification(instanceId);

  if (!!verificationResponse) return verificationResponse;

  let challengeName;

  switch (challengeType) {
    case VerificationType.Email:
      challengeName = 'EmailChallengeResponse';
      break;
    case VerificationType.PhoneNumber:
      challengeName = 'SmsChallengeResponse';
      break;
    case VerificationType.Membership:
      challengeName = 'MemberChallengeResponse';
      break;
  }

  await api.post<any>(`${env.VUE_APP_FUNCTIONS_API_URL}/runtime/webhooks/durabletask/instances/${instanceId}/raiseEvent/${challengeName}?code=${env.VUE_APP_FUNCTIONS_API_KEY}`, { Code: code });

  let timeout = 0;

  while(timeout < 8) {
    await sleep(1500);
    console.log(`Attempt ${timeout + 1}`);
    const firstTry = await getInstanceVerification(instanceId);
    if (!!firstTry) {
      console.log(`Found it on attempt ${timeout + 1}`);
      return firstTry;
    }

    await sleep(2500);
    console.log(`Attempt ${timeout + 2}`);
    const secondTry = await getInstanceVerification(instanceId);
    if (!!secondTry) {
      console.log(`Found it on attempt ${timeout + 2}`);
      return secondTry;
    }

    timeout += 1;
  }

  return { CodeCorrect: false, ErrorMessage: "Failed to verify the code." };
}

export default {
  startEmailVerification,
  startPhoneNumberVerification,
  startMemberVerification,
  postVerificationCodeForChallengeType,
};