
import Vue from "vue";

export enum OwnershipType {
  Dog = 1,
  Lease = 2,
  Litter = 3,
  Kennel = 4,
}

export default Vue.extend({
  name: "CoownersList",
  data: function() {
    return {};
  },
  methods: {
    title(ownershipType: OwnershipType) {
      if (ownershipType == OwnershipType.Lease) {
        return "Lessee";
      } else {
        return "Owner";
      }
    },
    tooltipText(ownershipType: OwnershipType){
        if(ownershipType == OwnershipType.Lease){
            return "Only the primary lessee can register litters, release and register puppies. They will also be a primary contact person for the ASCA office.";
        }
        if(ownershipType == OwnershipType.Dog){
            return "Only the primary owner can register litters, and lease the dog. They will also be a primary contact person for the ASCA office.";
        }
        if(ownershipType == OwnershipType.Kennel){
            return "Only the primary owner can request kennel ownership changes. They will also be a primary contact person for the ASCA office.";
        }
        if(ownershipType == OwnershipType.Litter){
            return "Only the primary owner can register and release puppies from the litter. They will also be a primary contact person for the ASCA office.";
        }
        return "";  
     }
  },
  props: {
    hideHeader: { type: Boolean },
    ownershipType: { type: Number },
    owners: { type: Array },
    hideContactInfo: { type: Boolean },
  },
});
