
import Vue from "vue";
import RequestStatusesSelectMenu from "@/components/common/inputs/RequestStatusesSelectMenu.vue";

import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";
import workflowService from '@/services/admin/workflowService';

export default Vue.extend({
  name: "QTDogRegistrationList",
  components: { RequestStatusesSelectMenu },
  props: {
    readyToApproveOnly: String,
  },
  data: function () {
    return {
      loading: false,
      paginatedResponse: {} as PaginatedResponse<object>,
      menu: false,
      search: '',
      statuses: ["RequestFinalized"],
      options: {} as PaginationOptions,
      headers: [
        { text: "#ID", align: "left", value: "requestId" },
        { text: "Submitted at", align: "left", value: "submittedAt" },
        { text: "Status", align: "left", value: "status" },
        { text: "Official Name", align: "left", value: "officialName", sortable: false },
        { text: "Call Name", align: "left", value: "callName", sortable: false },
        { text: "Breed", align: "left", value: "breed", sortable: false },
        { text: "Date of Birth", align: "left", value: "birthDate", sortable: false },
      ],
      footerProps: { 'items-per-page-options': [10, 20, 30, 40, 50] }
    };
  },
  created() { this.loadList(); },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    }
  },
  methods: {
    applyFilters: function () {
      this.menu = false;
      this.loadList();
    },
    loadList: async function () {
      this.loading = true;
      const {
        page,
        itemsPerPage,
      } = this.options;

      const response = await workflowService.getQTDogRegistrationsList({
        ...this.options,
        page: (page ?? 1) - 1,
        itemsPerPage: itemsPerPage ?? 10
      }, this.$data.statuses);

      this.loading = false;
      this.paginatedResponse = response;
    }
  },
});
