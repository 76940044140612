
import Vue from "vue";
import AuthorizeNetResponseData from "@/types/AuthorizeNetResponseData";
import MyRequestLayout from "@/components/Portal/MyRequest/MyRequestLayout.vue";
import ChangeKennelOwners from "@/components/Portal/MyRequest/ChangeKennelOwners.vue";
import { UserInfo } from "@/types/User";
import workflowService from "@/services/portal/workflowService";

export default Vue.extend({
  name: "MyChangeKennelOwnerRequest",
  components: {
    MyRequestLayout,
    ChangeKennelOwners,
  },
  data: function () {
    return {
      registration: Object as any,
      isLoading: false,
      paymentStatus: null,
      errorMessage: "",
      showErrorMessage: false,
    };
  },
  props: {
    workflowGuid: { type: String },
  },
  methods: {
    load() {
      this.isLoading = true;
      workflowService
        .getChangeKennelOwnerRequestDetails(this.workflowGuid)
        .then((res) => {
          this.isLoading = false;
          this.registration = res;
        });
    },
    authorizePayment: function (paymentInfo: AuthorizeNetResponseData) {
      var req = {
        paymentInfo,
        personId: this.userInfo.personId,
        workflowGuid: this.registration.workflowGuid,
      };
      workflowService.submitKennelOwnerCCAuthorization(req).then((res) => {
        this.paymentStatus = res;
        this.load();
      });
    },
    makePayment: function (paymentInfo: AuthorizeNetResponseData) {
      var req = {
        paymentInfo,
        personId: this.userInfo.personId,
        workflowGuid: this.registration.workflowGuid,
      };

      workflowService.submitKennelOwnerCCPayment(req).then((res) => {
        if (res.error) {
          this.errorMessage = res.error;
          this.showErrorMessage = true;
        }
        else {
          this.paymentStatus = res;
        }

        this.load();
      });
    },
    cancelRequest: function () {
      console.log("cancelling request");
    },
  },
  created() {
    this.load();
  },
  watch: {
    workflowGuid: function (newValue) {
      this.load();
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
});
