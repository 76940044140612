
import Vue from "vue";
import LitterEntry from "@/components/Admin/Entries/LitterEntry.vue";
import LitterPuppies from "@/components/Admin/Details/Litters/LitterPuppies.vue";

import litterService from "@/services/admin/litterService";

import { UserInfo } from "@/types/User";

export default Vue.extend({
  name: "LitterDetails",
  components: {
    LitterEntry,
    LitterPuppies
  },
  data: function() {
    return {
      tab: null,
      litterInfo: {

      }
    };
  },
  methods: {
    reloadData: function() {
      if (this.litterId) {
        litterService
          .getLitterById(this.litterId)
          .then((res) => {
            this.litterInfo = res;
          })
          .catch((e) => console.log(e));
      }
    }, 
  },    
  created: function() {
    this.reloadData();
  },
  watch: {
    litterId: function(newVal: String) {
      this.reloadData();
    },
  },
  props: {
    litterId: { type: Number },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
});
