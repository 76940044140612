var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('signature-layout',{attrs:{"submitSignature":_vm.sign,"signatureId":_vm.signatureId,"isLoading":_vm.isLoading,"foundRequest":_vm.found,"errorMessage":_vm.errorMessage,"workflowGuid":_vm.registration.workflowGuid,"submittedAt":_vm.registration.submittedAt}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Affiliate Renewal")]),(_vm.registration && _vm.registration.affiliateRequestData)?_c('div',{attrs:{"slot":"approvedContent"},slot:"approvedContent"},[_c('div',{staticClass:"mt-5"},[_c('h2',[_c('b',[_vm._v(_vm._s(_vm.registration.affiliateRequestData.name))])]),_c('h3',{staticClass:"mt-5"},[_c('span',[_c('b',[_vm._v("Website: ")])]),_c('a',{staticClass:"no-text-decoration",attrs:{"href":_vm.registration.affiliateRequestData.clubsite,"target":"_blank"}},[_vm._v(_vm._s(_vm.registration.affiliateRequestData.clubsite)+" ")])]),_c('h3',{staticClass:"mt-5"},[_c('span',[_c('b',[_vm._v("Events: ")])]),_c('p',[_vm._v(_vm._s(_vm.registration.affiliateRequestData.eventsAndActivities))])]),(_vm.registration.affiliateRequestData.lastYearShows)?_c('div',{staticClass:"mt-5"},[_c('h2',[_vm._v("Club shows for renewal year:")]),_c('p',[_c('v-simple-table',{attrs:{"fixed-header":"","height":_vm.registration &&
                _vm.registration.affiliateRequestData.lastYearShows
                  ? Math.min(
                      200,
                      (_vm.registration.affiliateRequestData.lastYearShows
                        .length +
                        2) *
                        50
                    )
                  : 100},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Show ID")]),_c('th',{staticClass:"text-left"},[_vm._v("Date")]),_c('th',{staticClass:"text-left"},[_vm._v("Type")])])]),_c('tbody',_vm._l((_vm.registration.affiliateRequestData
                      .lastYearShows),function(show){return _c('tr',{key:show.showId},[_c('td',[_vm._v(_vm._s(show.showId))]),_c('td',[_vm._v(" "+_vm._s(_vm.$dateUtils.formatDate(show.startDate))+" - "+_vm._s(_vm.$dateUtils.formatDate(show.endDate))+" ")]),_c('td',[_vm._v(_vm._s(show.type))])])}),0)]},proxy:true}],null,false,2533941858)})],1)]):_vm._e(),_c('div',{staticClass:"mt-5"},[_c('h2',[_vm._v("Club members:")]),_c('p',[_c('v-simple-table',{attrs:{"fixed-header":"","height":_vm.registration && _vm.registration.affiliateRequestData.affMembers
                  ? Math.min(
                      200,
                      (_vm.registration.affiliateRequestData.affMembers.length +
                        2) *
                        50
                    )
                  : 100},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Position")]),_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Membership ID")])])]),_c('tbody',_vm._l((_vm.registration.affiliateRequestData
                      .affMembers),function(member){return _c('tr',{key:member.personId},[_c('td',[_vm._v(_vm._s(member.position))]),_c('td',[_vm._v(_vm._s(member.firstName)+" "+_vm._s(member.lastName))]),_c('td',[_vm._v(_vm._s(member.memberId))])])}),0)]},proxy:true}],null,false,821050976)})],1)])])]):_vm._e(),_c('div',{attrs:{"slot":"legalText"},slot:"legalText"},[_c('p',{staticClass:"bottom-text"},[_vm._v(" We the undersigned, apply for membership renewal to the Australian Shepherd Club of America as Affiliate Officers of the above named club. As official representatives of that affiliate, we agree that our club will abide by the "),_c('ASCAArtIncLink'),_vm._v(", "),_c('BylawsLink'),_vm._v(", Dispute Rules, ASCA Registry Rules and all ASCA programs. ")],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }