
import Vue from "vue";
import ASCAArtIncLink from "../ASCAArtIncLink.vue";
import BylawsLink from "../BylawsLink.vue";

export default Vue.extend({
  name: "Signature",
  components: { ASCAArtIncLink, BylawsLink },
  data: function () {
    return {
      val: this.signature,
    }
  },
  props: {
    signature: String,
  }
});
