
import Vue from "vue";
import router from "@/router";
import { UserInfo } from "@/types/User";
import FooterText from "@/components/common/FooterText.vue";
import HostedRegistrationPayment from "@/components/common/HostedRegistrationPayment.vue";
import SimpleFeeTable from "@/components/common/SimpleFeeTable.vue";

const thumbsUpImage = require('@/assets/thumbs-up-duotone.svg');

export default Vue.extend({
  components: { FooterText, HostedRegistrationPayment, SimpleFeeTable },
  name: "RegisterKennelForm2",
  data: function () {
    return {
      thumbsUpImage,
      dialog: false,
      dialogError: false,
      isValid: true,
      processingRequest: false,
      errorMessage: "",
    };
  },
  props: {
    changePage: Function,
    coOwnerInfo: Array,
    sendRegistrationRequest: Function,
    status: Object,
    price: Number,
    priceInfo: Object,
  },
  watch: {
    status: function (newVal: any, oldVal: any) {
      if (newVal.success) {
        this.openModal();
      } else if (!!this.status.data && newVal.data.error) {            // Actual descriptive message received from the API
        this.errorMessage = newVal.data.error;
        this.openErrorModal();
      } else if (newVal.message) {                        // Likely "Request failed with status code 500" or something, but definitely an error
        this.errorMessage = newVal.message;
        this.openErrorModal();
      } else {                                            // Something really weird happened in this case
        this.errorMessage = "An unknown error occurred.";
        this.openErrorModal();
      }
    },
    dialog: function (newVal: boolean, oldVal: boolean) {
      if (!newVal) {
        this.$store.dispatch("loadPersonTasks", this.userInfo.personId);
        router.push({ path: "/" });
      }
    },
    dialogError: function (newVal: boolean, oldVal: boolean) {      
      if (!newVal && this.status.data && this.status.data.paymentIssue) {
        this.processingRequest = false;
        this.$store.dispatch("loadPersonTasks", this.userInfo.personId);
        router.push({ path: "/" });
      } else if (!newVal) {
        this.processingRequest = false;
      }
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    openModal: function () {
      this.$data.dialog = true;
    },
    openErrorModal: function () {
      this.$data.dialogError = true;
    },
  },
});
