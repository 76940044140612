import { api } from "../api";
import { CoOwnerResult, RegisterQTDTO } from "@/types/portal/Registration";

export const searchCoOwner = async (membershipID: string): Promise<CoOwnerResult[]> => {
    const { data } = await api.get<CoOwnerResult[]>(`/Person/search/qt/${membershipID}`)
    return data;
}

export const registerQTDog = async(registerDTO: RegisterQTDTO): Promise<any> => {
    try {
        const { data } = await api.post<object>(`/Dog/qt/register`, registerDTO);
        return data;
    } catch (err) {
        console.error('error', err);
        return err;
    }
}

export default {
    searchCoOwner,
    registerQTDog,
}