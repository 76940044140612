
import Vue from "vue";
import { debounce } from "throttle-debounce";
import Signature from "@/components/common/inputs/Signature.vue";
import AddCoOwnerModal from "@/components/Portal/AddCoOwnerModal_Kennel.vue";
import KennelSignatureText from "@/components/common/KennelSignatureText.vue";
import FooterText from "@/components/common/FooterText.vue";

import { UserInfo } from "@/types/User";
import registerKennelService from "@/services/portal/registerKennelService";

export default Vue.extend({
  name: "RegisterKennelForm1",
  components: {
    AddCoOwnerModal,
    Signature,
    KennelSignatureText,
    FooterText,
  },
  data: function () {
    return {
      firstChoiceMatch: null,
      kennelNameChoices: { ...this.kennelNames },
      sig: this.$props.signature,
      secondChoiceMatch: null,
      isValid: true,
      coOwnerDialog: false,
      rulesDialog: false,
    };
  },
  methods: {
    toggleCoOwnerModal: function () {
      this.$data.coOwnerDialog = !this.$data.coOwnerDialog;
    },
    toggleRules: function () {
      this.$data.rulesDialog = !this.$data.rulesDialog;
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    firstChoiceErrorMessage(): string | boolean {
      return this.firstChoiceMatch
        ? `Name is too similar to the following existing kennel(s): ${this.firstChoiceMatch}`
        : true;
    },
    secondChoiceErrorMessage(): string | boolean {
      return this.secondChoiceMatch
        ? `Name is too similar to the following existing kennel(s): ${this.secondChoiceMatch}`
        : true;
    },
  },
  created() {},
  watch: {
    "kennelNameChoices.firstChoice": {
      handler: debounce(
        500,
        function (this: any, newVal: string, oldVal: string) {
          if (newVal === oldVal) return;
          if (newVal) {
            registerKennelService
              .searchKennelName(newVal)
              .then((response: String[]) => {
                if (response.length > 0) {
                  this.firstChoiceMatch = response.join(",").toLowerCase();
                } else {
                  this.firstChoiceMatch = null;
                }
              });
          }
        }
      ),
      deep: true,
    },
    "kennelNameChoices.secondChoice": {
      handler: debounce(
        500,
        function (this: any, newVal: string, oldVal: string) {
          if (newVal === oldVal) return;
          if (newVal) {
            registerKennelService
              .searchKennelName(newVal)
              .then((response: String[]) => {
                if (response.length > 0) {
                  this.secondChoiceMatch = response.join(",").toLowerCase();
                } else {
                  this.secondChoiceMatch = null;
                }
              });
          }
        }
      ),
      deep: true,
    },
  },
  props: {
    changePage: Function,
    searchMemberInfo: Object,
    changeCoOwnerInfo: Function,
    coOwnerInfo: Array,
    changeKennelNames: Function,
    updateSignature: Function,
    signature: String,
    kennelNames: Object,
    price: Number,
  },
});
