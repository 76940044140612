
import Vue from "vue";
import DatepickerFormatted from "@/components/common/inputs/DatepickerFormatted.vue";
import AdminDogSearchDialog from "@/components/Admin/AdminDogSearchDialog.vue";
import AdminEditableCoownersList, { OwnershipType } from "@/components/Admin/AdminEditableCoownersList.vue";

import leaseService from "@/services/admin/leaseService";  

import { UserInfo } from "@/types/User";
import { Messages } from "@/constants";

export default Vue.extend({
    name: "LeaseDetailsForAdmin",
    components: {
        DatepickerFormatted,
        AdminEditableCoownersList,
        AdminDogSearchDialog
    },
    data: function () {
        return {
            leaseInfo: {
                dogId: null as any
            } as any,
            isEditMode: false,
            addNewMode: false,        
            persistedDogId: null as any,
            showValidationDialog: false,
            validationResult: {} as any,
            isCancelled: false,
            errorMessage: '',
            infoMessage: '',
            showErrorMessage: false,
            showInfoMessage: false
        };
    },
    methods: {
        updateText: function (key: string, event: string) {
            this.$data.leaseInfo[key] = event;
        },
        reloadData: function () {
            if (this.leaseId) {
                this.getLeaseInfo();
                this.addNewMode = false;
                this.isEditMode = false;
            }
            else {
                this.addNewMode = true;
                this.isEditMode = true;

            }
            this.showValidationDialog = false;
            this.errorMessage = '';
            this.showErrorMessage = false;
            this.validationResult = {};
        },

        validateAndConfirmSavingChanges: function () {
            this.errorMessage = '';
            if (this.leaseInfo.registrationDate == "") {
                this.leaseInfo.registrationDate = null;
            }

            if (this.isCancelled) {
                this.saveChanges();
            }
            else {
                if (this.validateLeaseDates() == true) {
                    leaseService
                        .validateLeaseEdit(this.leaseInfo)
                        .then((res: any) => {
                            this.validationResult = res;
                            if (this.validationResult.errors.length > 0 || this.validationResult.warnings.length > 0) {
                                this.showValidationDialog = true;
                            }
                            else {
                                this.saveChanges();
                            }

                        })
                }
                else {
                    this.isEditMode = false;
                }
            }
        },
        saveChanges: function () {
            this.errorMessage = "";
            this.showValidationDialog = false;
            this.$store.dispatch("changeLoaderStatus", true);
            this.leaseInfo.isValid = !this.isCancelled;
            if (this.leaseInfo.registrationDate == "") {
                this.leaseInfo.registrationDate = null;
            }
            leaseService
                .editLeaseById(this.leaseInfo)
                .then((res) => {
                    if (res.success) {
                        if (res.id && res.id != this.$props.leaseId) {
                            this.$router.push({ path: `/admin/leases/${res.id}` }).catch(() => { });
                        }
                        this.reloadData();
                        this.infoMessage = Messages.SaveSuccessful;
                        this.showInfoMessage = true;
                    }
                    else {
                        this.errorMessage = Messages.SaveError + " " + res.messages.join(', ')
                        this.showErrorMessage = true;
                    }
                })
                .catch((e) => {
                    this.errorMessage = Messages.SaveError;
                    this.showErrorMessage = true;   
                })
                .finally(() => this.$store.dispatch("changeLoaderStatus", false));
        },
        toggleEditMode: function () {
            if (this.isEditMode) {
                this.validateAndConfirmSavingChanges();
            } else {
                this.isEditMode = true;
            }
        },
        updateDates: function (val: any, key: string) {
            this.$data.leaseInfo[key] = val.date;
            this.$data.leaseInfo = Object.assign({}, this.$data.leaseInfo);
        },
        updateSelectedPeople: function (val: any) {
            this.errorMessage = "";
            //workaround for setting up the primary owner
            //and issues with reactivity in Vue https://v2.vuejs.org/v2/guide/reactivity.html?redirect=true#For-Arrays
            if (val.newPeople && (val.newPeople.length === 1 || (val.newPeople.length > 0 && val.newPeople.findIndex((own: any) => own.isPrimary) == -1))) {
                val.newPeople[0].isPrimary = true;
            }
            if (!!this.leaseInfo.leaseOwners && val.newPeople && val.newPeople.length > 0) {
                this.leaseInfo.leaseOwners.splice(val.newPeople.length);
            }
            this.$set(this.leaseInfo, 'leaseOwners', val.newPeople);
        },
        getLeaseInfo() {
            leaseService
                .getLeaseById(this.leaseId)
                .then((res) => {
                    this.leaseInfo = res;
                    this.persistedDogId = res.dogId;
                    this.addNewMode = false;
                    this.isCancelled = !this.leaseInfo.isValid;
                });
        },
        validateLeaseDates() { 
            this.errorMessage =  "";
            this.showErrorMessage = false;
            let isValid = true;

            //do not validate lease information for leases that are cancelled.
            if (this.isCancelled) {
                return true;
            }

            if (this.leaseInfo.startDate == null || this.leaseInfo.endDate == null) {
                this.errorMessage = "Start Date and End Date are required.";
                this.showErrorMessage = true;
                return false;
            }
            if (this.leaseInfo.leaseOwners == null) {
                this.errorMessage = "At least one primary lease owner is required.";
                this.showErrorMessage = true;
                return false;
            }

            if (this.leaseInfo.startDate == "" || this.leaseInfo.endDate == "") {
                this.errorMessage = "Start Date and End Date are required.";
                this.showErrorMessage = true;
                return false;
            }

            return isValid;
        },
    },
    created: function () {
        this.reloadData();
    },
    watch: {
        leaseId: function (newVal: Number) {
            this.getLeaseInfo();
        },
    },
    props: {
        leaseId: { type: Number },
    },
    computed: {
        userInfo(): UserInfo {
            return this.$store.state.userInfo;
        },
        leaseOwnershipType: function () {
            return OwnershipType.Lease;
        },
        dogOwnershipType: function () {
            return OwnershipType.Dog;
        },
        
    },
});
