
import Vue from "vue";
import parseISO from 'date-fns/parseISO'
import differenceInDays from 'date-fns/differenceInDays'
import ReportViewer from '@/components/Admin/Reports/ReportViewer.vue';
import { UserInfo } from "@/types/User";
import { MemberType } from "@/types/Member";

export default Vue.extend({
  name: "MyMembershipInfo",
  components: {
    ReportViewer
  },
  data: function() {
    return {
      showLoader: false,
      errorMessage: "",
    };
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    canRenew(): boolean {
      return !this.userInfo.hasNeverBeenMember && this.userInfo.memberType != MemberType.Junior;
    },
    shouldRenew(): boolean {
      if (!this.$store.state.userInfo.membershipExpiryDate) return false;
      return differenceInDays(parseISO(this.$store.state.userInfo.membershipExpiryDate), new Date()) <= 30;
    },
    juniorShouldRegister(): boolean {
      return this.userInfo.memberType == MemberType.Junior
        && (!!this.userInfo?.juniorHasToRegister
          || (!!this.$store.state.userInfo.membershipExpiryDate && differenceInDays(parseISO(this.$store.state.userInfo.membershipExpiryDate), new Date()) <= 30));
    },
    reportViewer(): any {
      return this.$refs.reportViewer;
    }
  },
  methods: {
    async print() {
      this.reportViewer.printReport();
    },
    requestCard() {

    },
    gainMembership() {
      this.$router.push('/gain-membership')
    },
    registerMembership() {
      this.$router.push('/registration/member')
    },
    renewMembership() {
      this.$router.push('/renew/member')
    },
    renewAussieTimes() {
      this.$router.push('/renew/member/aussietimes')
    },
  },
});
