
import Vue from "vue";
import AppNavbar from "./AppNavbar.vue";
import Sidebar from "./Sidebar.vue";

export default Vue.extend({
  data () {
    return {
      drawer: false
    }
  },
  components: {
    AppNavbar,
    Sidebar
  },
  methods: {
    toggleDrawer: function () {
      this.drawer = !this.drawer
    }
  }
});
