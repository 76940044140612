import { api } from "../api";

export const validateWhelpingDate = async (
  personId: number,
  dogId: string,
  date: Date
): Promise<any> => {
  const request = {damId: dogId, requesterPersonId: personId, whelpingDate: date};
  const { data } = await api.post<object>(
    `/Litter/registration/validate`, request
  );
  return data;
};

export const searchDam = async (
  dogId: string,
  whelpingDate: Date
): Promise<any> => {
  try {
    const { data } = await api.get<object>(
      `/Litter/registration/dam/${dogId}?whelpingDate=${whelpingDate
        .toISOString()
        .substr(0, 10)}`
    );
    return data;
  } catch (err) {
    console.error("Error searching for dam: ", err);
    return err;
  }
};

export const searchSire = async (dogId: string): Promise<any> => {
  try {
    const { data } = await api.get<object>(
      `/Litter/registration/sire/${dogId}`
    );
    return data;
  } catch (err) {
    console.error("Error searching for sire: ", err);
    return err;
  }
};

export const submitLitterRegistrationRequest = async (
  request: object
): Promise<any> => {
  try {
    const { data } = await api.post<object>(`/Litter/registration`, request);
    return data;
  } catch (err) {
    console.error("error", err);
    return err;
  }
};

export default {
  validateWhelpingDate,
  searchDam,
  searchSire,
  submitLitterRegistrationRequest
};
