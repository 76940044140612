
import Vue from "vue";
import ContactInfo from "@/components/Portal/Profile/ContactInfo.vue";
import ChangePassword from "@/components/Portal/Profile/ChangePassword.vue";
import personService from "@/services/portal/personService";
import { UserInfo } from "@/types/User";

export default Vue.extend({
  name: "UserProfile",
  components: {
    ContactInfo,
    ChangePassword,
  },
  data: function() {
    return {
      showLoader: false,
      errorMessage: "",
      communicationPreferences: {
        email: null as any,
        sms: null as any,
      },
    };
  },
  created: function () {
      personService
      .getCommunicationPreferences(this.userInfo.personId)
      .then((res) => {
        if(res.found) {
          this.$data.communicationPreferences = res.data;
        } else {
          this.$data.errorMessage = "Couldn't load the person communication preferences.";
        }
      })
      .catch((e) => console.log(e));
  },  
  watch: {
    userInfo: function(newValue){
      personService
      .getCommunicationPreferences(newValue.personId)
      .then((res) => {
        if(res.found) {
          this.$data.communicationPreferences = res.data;
        } else {
          this.$data.errorMessage = "Couldn't load the person communication preferences.";
        }
      })
      .catch((e) => console.log(e));
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    async print() {
      await this.$htmlToPaper("print-card");
    },
    requestCard() {

    },
    renewMembership() {

    },
    savePreference() {
      if(!this.communicationPreferences.email && !this.communicationPreferences.sms)
      {
        this.errorMessage = "You need to choose at least one communication channel.";
      } else {
        this.errorMessage = "";
        this.showLoader = true;
        personService
          .changeCommunicationPreferences(
            this.userInfo.personId,
            this.communicationPreferences.email,
            this.communicationPreferences.sms
          )
          .then((res) => {
            if (res.success) {
            } else {
              this.errorMessage = res.errorMessage;
            }
          })
          .finally(() => {
            this.showLoader = false;
          });
      }
    },
  },
});
