
import Vue from "vue";
import { UserInfo } from "@/types/User";
import router from "@/router";
import Signature from "@/components/common/inputs/Signature.vue";
import KennelSignatureText from "@/components/common/KennelSignatureText.vue";
import CoownersList, { OwnershipType } from "@/components/common/CoownersList.vue";
import SimpleFeeTable from "@/components/common/SimpleFeeTable.vue";
import HostedRegistrationPayment from "@/components/common/HostedRegistrationPayment.vue";

const thumbsUpImage = require('@/assets/thumbs-up-duotone.svg');

export default Vue.extend({
  name: "ChangeKennelOwnersForm2",
  data: function () {
    return {
      thumbsUpImage,
      dialog: false,
      dialogError: false,
      paymentInfo: this.paymentInfoProp,
      isValid: true,
      processingRequest: false,
      sig: this.$props.signature,
      errorMessage: ""
    };
  },
  components: {
    Signature,
    KennelSignatureText,
    CoownersList,
    SimpleFeeTable,
    HostedRegistrationPayment
  },
  props: {
    changePage: Function,
    coOwnerInfo: Array,
    submitRequest: Function,
    status: Object,
    paymentInfoProp: Object,
    updatePaymentInfo: Function,
    updateSignature: Function,
    signature: String,
    price: Number,
    priceInfo: Object,
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    kennelOwnershipType: function () {
      return OwnershipType.Kennel;
    },
  },
  watch: {
    status: function (newVal: any, oldVal: any) {
      if (newVal.success) {
        this.openModal();      
      } else if (!!this.status.data && newVal.data.error) {            // Actual descriptive message received from the API
        this.errorMessage = newVal.data.error;
        this.openErrorModal();
      } else if (newVal.message) {                        // Likely "Request failed with status code 500" or something, but definitely an error
        this.errorMessage = newVal.message;
        this.openErrorModal();
      } else {                                            // Something really weird happened in this case
        this.errorMessage = "An unknown error occurred.";
        this.openErrorModal();
      }
    },
    dialog: function (newVal: boolean, oldVal: boolean) {
      if (!newVal) {
        this.$store.dispatch("loadPersonTasks", this.userInfo.personId);
        router.push({ path: "/" });
      }
    },
    dialogError: function (newVal: boolean, oldVal: boolean) {            
      if (!newVal && this.status.data && this.status.data.paymentIssue) {
        this.processingRequest = false;
        this.$store.dispatch("loadPersonTasks", this.userInfo.personId);
        router.push({ path: "/" });
      } else if (!newVal) {
        this.processingRequest = false;
      }
    },
  },
  methods: {
    openModal: function () {
      this.$data.dialog = true;
    },
    openErrorModal: function () {
      this.$data.dialogError = true;
    },
  },
});
