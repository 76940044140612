
import Vue from "vue";
import AdminAddCoownerDialog from "../Admin/AdminAddCoownerDialog.vue";

export enum OwnershipType {
  Dog = 1,
  Lease = 2,
  Litter = 3,
  Kennel = 4,
}

export default Vue.extend({
  name: "AdminEditableCoownersList",
  data: function () {
    return {
      peopleSearchDialog: false,
    };
  },
  components: {
    AdminAddCoownerDialog,
  },
  computed: {
    primaryLessee: function () {
      const idx = this.owners.findIndex((object: any) => {
        return object.isPrimary;
      });
      if (idx >= 0) {
        return (this as any).owners[idx].personId;
      }
      return 0
    },
  },
  methods: {
    changePrimaryLessee(personId: number) {
      const index = this.owners.map((c: any) => c.personId).indexOf(personId);
      this.owners.forEach((co: any) => (co.isPrimary = false));
      (this as any).owners[index].isPrimary = true;
      this.$emit("updateSelectedPeople", { newPeople: this.owners });
    },
    changeSelectedPeopleInfo: function (newPeople: any) {
      this.$emit("updateSelectedPeople", { newPeople: newPeople });
    },
    toggleModal: function () {
      this.$data.peopleSearchDialog = !this.$data.peopleSearchDialog;
    },
    title(ownershipType: OwnershipType) {
      if (ownershipType == OwnershipType.Lease) {
        return "Lessee";
      } else if(ownershipType == OwnershipType.Litter) {
        return "Litter owner";  
      } else {
        return "Owner";
      }
    },
    tooltipText(ownershipType: OwnershipType) {
      if (ownershipType == OwnershipType.Lease) {
        return "Only the primary lessee can register litters, release and register puppies. They will also be a primary contact person for the ASCA office.";
      }
      if (ownershipType == OwnershipType.Dog) {
        return "Only the primary owner can register litters, and lease the dog. They will also be a primary contact person for the ASCA office.";
      }
      if (ownershipType == OwnershipType.Kennel) {
        return "Only the primary owner can request kennel ownership changes. They will also be a primary contact person for the ASCA office.";
      }
      if (ownershipType == OwnershipType.Litter) {
        return "Only the primary owner can register and release puppies from the litter. They will also be a primary contact person for the ASCA office.";
      }
      return "";
    },
  },
  props: {
    isReadOnlyMode: { type: Boolean },
    isEditMode: { type: Boolean },
    hideHeader: { type: Boolean },
    ownershipType: { type: Number },
    owners: { type: Array },
    hideContactInfo: { type: Boolean },
  },
});
