import { api } from "../api";
import { UserInfo } from '@/types/User';
import { SetAgreementsDTO } from '@/types/portal/User';

export const getProfile = async (): Promise<UserInfo> => {
    const { data } = await api.get<UserInfo>('/Users/profile');
    return data;
};

export const setUserAgreements = async (dto: SetAgreementsDTO) => {
    const { data } = await api.put<object>('/Users/agreements', dto);
    return data;
};

export const setPromptedForMembershipAt = async () => {
    await api.put<object>('/Users/update-last-prompted-for-membership-at');
}

export const getMembersOnlyInfo = async (): Promise<any> => {
    const { data } = await api.get<object>(`/Users/members-only`);
    return data;
};
  
export default {
    getProfile,
    setUserAgreements,
    setPromptedForMembershipAt,
    getMembersOnlyInfo,
};