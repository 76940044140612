
import Vue from 'vue';
import Sidebar from './Sidebar.vue';
import UserFeed from './UserFeed.vue';
import AppNavbar from './AppNavbar.vue';

export default Vue.extend({
  name: 'Home',
  props: {
    searchText: String,
  },
  components: {
    Sidebar,
    UserFeed,
    AppNavbar,
  },
  data: function() {
    return {
      drawer: false
    };
  },
  created: function () {
  },
  methods: {
    toggleDrawer: function () {
      this.drawer = !this.drawer
    }
  }
});
