
import Vue from "vue";
import adminService from "@/services/adminService";
import { MetaNames } from "@/constants";
import { UserInfo } from "@/types/User";

export default Vue.extend({
  name: "AdminPayments",
  data: function() {
      return {
        title: MetaNames.AdminPayments,
        payments: Array<object>(),
        paymentsSearch: '',
        headers: [
            { text: "#ID", align: "left", value: "id", width: "50px" },
            { text: "Registration type", align: "left", value: "workflowTypeDesc", width: "160px" },
            { text: "Fee amount", align: "left", value: "transactionAmount", width: "50px" },
            { text: "Requested at", align: "left", value: "requestedAt", width: "100px" },
            { text: "Requested by admin", align: "left", value: "requestedBy", width: "100px" },
            { text: "Paid by", align: "left", value: "paidBy", width: "200px" },
            { text: "Paid at", align: "left", value: "paidAt", width: "100px" },
            { text: "Payment type", align: "left", value: "paymentTypeDesc", width: "100px" },
            { text: "Admin notes", align: "left", value: "adminNotes", width: "200px" },
            { text: "", align: "left", value: "actionButton", width: "200px" },
        ],
      };
  },
  methods: {
  },
  watch: {      
    userInfo: function (newVal: object) { 
        adminService
            .getPayments()
            .then((res) => {
                    this.payments = res;
                })
                .catch((e) => console.log(e));
    },
  },
  created: function () {
    if(this.userInfo){
        adminService
            .getPayments()
            .then((res) => {
                    this.payments = res;
                })
                .catch((e) => console.log(e));
    }
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
});
