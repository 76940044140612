
import Vue from 'vue';

export default Vue.extend({
  name: 'InternationalPhoneNumberInput',
  props: {
    required: Boolean,
    disabled: Boolean,
    inline: Boolean,
    phone: String,
    rules: Array,
    classNames: String,
  },
  data() {
    return {
      number: this.phone ?? "",
      numberData: {},
      dropdownOptions: {
        showDialCodeInSelection: true
      }
    }
  },
  computed: {
    parsedRules: function () {
      if (!!this.rules && !!this.rules.length) {
        return this.rules;
      } else {
        return [];
      }
    }
  },
  watch: {
    phone(newValue: string) {
      if (newValue && newValue !== this.number) this.number = newValue;
    },
    number(newValue: string) { this.$emit('change', newValue); }
  },
  methods: {
    isValidOrPossible(): Boolean {
      if (!this.required && !this.number) return true;

      return (!!this.number
          && !!this.numberData
          && (this.numberData as any).valid)
        || 'Phone Number is not valid';
    },
    onInput(number: string, data: Object) {
      this.numberData = data;
      this.$emit('changeData', data);
    }
  }
})
