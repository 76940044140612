import { api } from "../api";
import { CoOwnerResult, SubmitKennelRequest } from "@/types/portal/Registration";

export const searchKennelName = async (searchText: string): Promise<String[]> => {
    const { data } = await api.get<string[]>(`/Kennel/search/${searchText}`);
    return data;
}

export const searchCoOwner = async (membershipID: string): Promise<CoOwnerResult[]> => {
    const { data } = await api.get<CoOwnerResult[]>(`/Person/search/kennel/${membershipID}`)
    return data;
}

export const submitKennelRegistration = async(request: SubmitKennelRequest): Promise<any> => {
    try {
        const { data } = await api.post<object>(`/Kennel/register`, request);
        return data;
    } catch (err) {
        console.error('error', err);
        return err;
    }
}

export const submitChangeKennelOwnerRequest = async(request: Object): Promise<any> => {
    try {
        const { data } = await api.post<object>(`/Kennel/owners`, request);
        return data;
    } catch (err) {
        console.error('error', err);
        return err;
    }
}

export const getKennelById = async(kennelId: String): Promise<any> => {
    try {
        const { data } = await api.get<object>(`/Kennel/${kennelId}`);
        return data;
    } catch (err) {
        console.error('error', err);
        return err;
    }
}

export default {
    searchKennelName,
    searchCoOwner,
    submitChangeKennelOwnerRequest,
    getKennelById,
}