
import Vue from "vue";
import lookupsService from "@/services/admin/lookupsService";
import { DNALookupInfo } from "@/types/admin/Lookup";

export default Vue.extend({
  name: "DNAStatusMaintenance",
    data: function () {
        return {
            tab: null,
            luItems: [] as DNALookupInfo[],
            catIdList: [] as string[],
            availableCatIds: [] as string[],
            tdialog:false,
            dialog: false,
            dialogDelete: false,
            headers: [
                { text: "Description", align: "left", value: "description" },
                { text: "Category", align: "left", value: "catId" },             
                { text: "Actions", value: "actions", sortable: false },
            ],
            editedIndex: -1,
            editedItem: {
                id: -1,
                description: "",
                canDelete: true,
                catId: "",
                isCertificate: false,

            },
            defaultItem: {
                id: -1,
                description: "",
                canDelete: true,
                catId: "",
                isCertificate: false,
            },
            errorMessage: "",
            isValid: true, 
            search: "",
           isLoading:false,
           
        };
    },
    created: function () {
        this.reloadItems();
    },
    methods: {
        refresh: function () {
            this.reloadItems();
            this.errorMessage = "";
        },
        reloadItems: function () {
            this.$data.isLoading = true;
            lookupsService.getDnaStatusList().then((res: any) => {
                this.luItems = res.dnaStatusList;
                this.catIdList = res.dnaCatIdList;
                this.$data.isLoading = false;
                this.updateCatList();
            });
            this.$data.isLoading = false;
        },
        initializeItem() {
            this.updateCatList();
            this.dialog = true;

        },
        updateCatList() {
            this.availableCatIds = [] as string[];
            for (let i = 0; i < this.catIdList.length; i++) {
                var catId = this.luItems.find((x: DNALookupInfo) => x.catId == this.catIdList[i]);
                if (catId == null) {
                    this.availableCatIds.push(this.catIdList[i]);
                }
            }
        },
        editItem(item: DNALookupInfo) {           
            this.editedIndex = this.luItems.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true;
            if (this.editedItem.canDelete && this.editedItem.catId != null && this.editedItem.catId.length > 0) {
                this.updateCatList();
                this.availableCatIds.push(this.editedItem.catId);
            }
        },

        deleteItem(item: DNALookupInfo) {
            this.editedIndex = this.luItems.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.errorMessage = "";
            var success = true;
            if (!this.editedItem.canDelete) {
                this.closeDelete();
            }
            lookupsService.deleteDnaStatus(this.editedItem.id).then((res) => {
                success = res.success;
                if (res.success == false) {
                    if (res.messages != null && res.message.length > 0) {
                        this.errorMessage = res.messages[0];
                    }
                    else {
                        this.errorMessage = "Unable to delete status"
                    }
                    return;
                }

            }).catch((e: any) => {
                this.errorMessage = "Unable to delete the status.";
                return;
            });

            if (success == true) {

                this.luItems.splice(this.editedIndex, 1);
                this.closeDelete();
            }
            this.updateCatList();
        },

        close() {
            this.errorMessage = "";
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            this.errorMessage = "";

            if (this.editedItem.description.trim().length == 0) {
                this.errorMessage = "Please enter valid description."
                return;
            }

            if (this.editedIndex == -1) {
                this.editedItem.id = this.getNextId();
            }
            lookupsService.editDnaStatus(this.editedItem).then((res: any) => {
                if (res.success == false) {
                    this.errorMessage = "Unable to edit the dna status.";
                    return;
                }
               
            });
          
           
            if (this.editedIndex > -1) {
                Object.assign(this.luItems[this.editedIndex], this.editedItem)
            }
            else {
                if (this.luItems) {
                    this.luItems.unshift(this.editedItem)

                }
            }
          
            this.updateCatList();
            this.close();

        },
        getNextId() {
            if (this.luItems.length == 0) {
                return 1;
            }
            const ids = this.luItems.map(object => {
                return object.id;
            });
            const max = Math.max(...ids);
            return max+1;
        },
    },
    computed: {
        formTitle() {
            return this.$data.editedIndex === -1 ? "New Item" : "Edit Item";
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    props: {
        luType: { type: String },
        maxSize: { type: String},
    },
});
