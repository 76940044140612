
import Vue from "vue";
import StateDropdown from "@/components/common/inputs/StateDropdown.vue";
import InternationalPhoneNumberInput from '@/components/common/inputs/InternationalPhoneNumberInput.vue';

import { UserInfo } from "@/types/User";
import affiliateService from "@/services/portal/affiliateService";

export default Vue.extend({
  name: "AffiliateInfo",
  components: {
    StateDropdown,
    InternationalPhoneNumberInput
  },
  data: function () {
    return {
      affiliateInfo: {} as any,
      originalAffiliateInfo: null as any,
      editMode: false,
      snackbar: false,
      snackbarText: "",
      snackbarColor: "green",
    };
  },
  computed: {
      userInfo(): UserInfo {
          return this.$store.state.userInfo;
      },

      canBeRenewed(): boolean {
          if (!this.affiliateInfo.canRenew) return false;
          return true;
      },
  },
  methods: {
    toggleEditMode() {
      this.$data.originalAffiliateInfo = JSON.parse(JSON.stringify(this.$data.affiliateInfo));
      this.editMode = !this.editMode;
    },
    abortEdit() {
      this.$data.affiliateInfo = JSON.parse(JSON.stringify(this.$data.originalAffiliateInfo));
      this.$data.originalAffiliateInfo = null;
      this.editMode = false;
    },
    saveEdits() {
      affiliateService
        .editAffiliateById(
          this.affiliateId,
          this.userInfo.personId,
          this.affiliateInfo.contact.email,
          this.affiliateInfo.contact.phoneNumber,
          this.affiliateInfo.contact.address,
          this.affiliateInfo.contact.city,
          this.affiliateInfo.contact.state,
          this.affiliateInfo.contact.zipCode,
          this.affiliateInfo.clubSite
        )
        .then((res: any) => {
          if (res.success) {
            this.snackbar = true;
            this.snackbarColor = "green";
            this.snackbarText = "Successfully updated affiliate info";
          } else {
            this.snackbar = true;
            this.snackbarColor = "red";
            this.snackbarText = "Error updating affiliate info"
        }})
        .catch((e) => {
            this.snackbar = true;
            this.snackbarColor = "red";
            const descriptiveMessage = e.response.data.error
                  ? e.response.data.error
                  : null;
          this.snackbarText = descriptiveMessage
                              ? descriptiveMessage
                              : e.message
                              ? e.message
                              : "Error updating affiliate info";
        });

      this.toggleEditMode();
    },
 
  },
  created: function () {
    if (this.affiliateId && this.userInfo) {
      affiliateService
        .getAffiliateById(this.affiliateId, this.userInfo.personId)
        .then((res) => {
          this.$data.affiliateInfo = res;
        })
        .catch((e) => console.log(e));
    }
  },
  watch: {
    affiliateId: function (newVal: String) {
      affiliateService
        .getAffiliateById(this.affiliateId, this.userInfo.personId)
        .then((res) => {
          this.$data.affiliateInfo = res;
        })
        .catch((e) => console.log(e));
    },
    userInfo: function (newVal: object) {
      affiliateService
        .getAffiliateById(this.affiliateId, this.userInfo.personId)
        .then((res) => {
          this.$data.affiliateInfo = res;
        })
        .catch((e) => console.log(e));
    },
  },
  props: {
    affiliateId: { type: String },
  },
});
