
import Vue from "vue";
import HostedRegistrationPayment from "@/components/common/HostedRegistrationPayment.vue";

import { UserInfo } from "@/types/User";
import { MyRequest } from "@/types/portal/Registration";

export default Vue.extend({
  name: "MyRequestLayout",
  components: { HostedRegistrationPayment },
  props: {
    foundRequest: { type: Boolean },
    isLoading: { type: Boolean },
    myRequest: { type: Object as () => MyRequest },
    paymentStatus: { type: Object },
    useAuthorizedPayment: { type: Boolean },
    makePayment: { type: Function },
    authorizePayment: { type: Function },
    showPaymentAuthorizationStatus: { type: Boolean },
  },
  data: function () {
    return {};
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
});
