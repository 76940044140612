
import Vue from "vue";
import { UserInfo } from "@/types/User";
import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";
import workflowService from '@/services/portal/workflowService';

export default Vue.extend({
  name: "AffiliateRegistrationList",
  props: {
    readyToApproveOnly: String,
  },
  data: function () {
    return {
      loading: false,
      paginatedResponse: {} as PaginatedResponse<object>,
      menu: false,
      search: '',
      options: {} as PaginationOptions,
      headers: [
        { text: "#ID", align: "left", value: "requestId" },
        { text: "Submitted at", align: "left", value: "submittedAt" },
        { text: "Status", align: "left", value: "status" },
        { text: "Club Name", align: "left", value: "affiliateName", sortable: false },
      ],
      footerProps: { 'items-per-page-options': [10, 20, 30, 40, 50] }
    };
  },
  created() { this.loadList(); },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    }
  },
  methods: {
    applyFilters: function () {
      this.menu = false;
      this.loadList();
    },
    loadList: async function () {
      this.loading = true;
      const {
        page,
        itemsPerPage,
      } = this.options;

      const response = await workflowService.getAffiliateRegistrationRequests({
        ...this.options,
        page: (page ?? 1) - 1,
        itemsPerPage: itemsPerPage ?? 10
      });

      this.loading = false;
      this.paginatedResponse = response;
    }
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
});
