
import Vue from "vue";
import { UserInfo } from "@/types/User";
import { MemberType } from "@/types/Member";
import canView from "@/helpers/can-view-summary-item";

export default Vue.extend({
  name: "MenuLinkSummary",
  props: {
    forMembers: Object,
    image: String,
    title: String,
    expandable: Boolean,
    count: Object as () => number,
    items: {
      type: Array,
      default: () => [{ text: "", icon: null, link: "", image: null }],
    },
  },
  data: function () {
    return {
      expanded: false,
    };
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    visible(): boolean {
      return canView(
        this.userInfo.memberType,
        this.userInfo.hasNeverBeenMember,
        this.forMembers,
      );
    },
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
    visibleItem(forMembers: boolean | MemberType[]): boolean {
      return canView(
        this.userInfo.memberType,
        this.userInfo.hasNeverBeenMember,
        forMembers,
      );
    }
  },
});
