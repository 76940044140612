
import Vue from "vue";
import adminService from "@/services/adminService";
import categoryService from "@/services/admin/categoryService";
import { UserInfo } from "@/types/User";

export default Vue.extend({
  name: "CategoryManagement",
  data: function () {
    return {
      showReportDialog: false,
      dialog: false,
      dialogDelete: false,
      categories: [] as any,
      programChoices: [] as any,
      categoryHeaders: [
        { text: "Show Type", align: "left", value: "categoryShowType", width: "5%" },
        { text: "Program", align: "left", value: "programName", width: "10%" },
        { text: "Title", align: "left", value: "categoryId" },
        { text: "Prefix/Suffix", align: "left", value: "isSuffix"},
        { text: "Description (printed certificate)", align: "left", value: "description" },
        { text: "Heading (printed certificate)", align: "left", value: "heading" },
        { text: "ASCA Only", align: "left", value: "isAscaOnly" },
        { text: "Manual", align: "left", value: "isManual" },        
        { text: "Show Results Category", align: "left", value: "isShowResultsCategory" },
        { text: "Actions", value: "actions", sortable: false, width: "10%" },
      ],
      colors: [
        "#e51c23",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#5677fc",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#259b24",
        "#8bc34a",
        "#afb42b",
        "#ff9800",
        "#ff5722",
        "#795548",
        "#607d8b",
      ],
      search: "",
      editedIndex: -1,
      editedItem: {
        categoryId: "",
        categoryShowType: "",
        programName: "",
        isManual: false,
        description: "",
        isSuffix: true,
        isAscaOnly: false,
        heading: "",
        canDelete: false,
        isShowResultsCategory: false,
      },
      defaultItem: {
        categoryId: "",
        categoryShowType: "",
        programName: "",
        isManual: false,
        description: "",
        isSuffix: true,
        isAscaOnly: false,
        heading: "",
        canDelete: false,
        isShowResultsCategory: false,
      },
      snackbar: false,
      snackbarColor: "green",
      snackbarText: "",
      showLoader: false,
      isValid: true,
    };
  },
    methods: {
        changeShowType(e: any) {
            let idx = this.$data.programChoices.findIndex(
                (x: any) => x.programName === e
            );
            this.$data.editedItem.categoryShowType = this.$data.programChoices[idx].categoryShowType;
            this.$data.editedItem = Object.assign({}, this.$data.editedItem);
        },
        getTitleColor(titleName: string, titleId: number) {
            const text = `123${titleName}123`; //`${titleId}${titleName}${titleId}`
            var hash = 0;
            if (text.length === 0) return hash;
            for (var i = 0; i < text.length; i++) {
                hash = text.charCodeAt(i) + ((hash << 3) - hash);
                hash = hash & hash;
            }
            hash =
                ((hash % this.$data.colors.length) + this.$data.colors.length) %
                this.$data.colors.length;
            return this.$data.colors[hash];
        },
        editItem(item: any) {
            this.$data.editedIndex = (this as any).categories.indexOf(item);
            this.$data.editedItem = Object.assign({}, item);
            this.$data.dialog = true;
        },

        deleteItem(item: any) {
            this.$data.editedIndex = (this as any).categories.indexOf(item);
            this.$data.editedItem = Object.assign({}, item);
            this.$data.dialogDelete = true;
        },

        deleteItemConfirm() {
            this.$data.showLoader = true;
            categoryService
                .deleteCategory({
                    programName: this.$data.editedItem.programName,
                    categoryId: this.$data.editedItem.categoryId,
                })
                .then((res) => {
                    this.$data.showLoader = false;
                    if (res.success) {
                        this.$data.categories = res.certificateCategories;
                        this.$data.snackbar = true;
                        this.$data.snackbarColor = "green";
                        this.$data.snackbarText = "Category deleted successfully";
                    } else {
                        this.$data.snackbar = true;
                        this.$data.snackbarColor = "red";
                        this.$data.snackbarText = "Error while deleting category";
                    }
                })
                .catch((e: any) => {
                    this.$data.showLoader = false;
                    this.$data.snackbar = true;
                    this.$data.snackbarColor = "red";
                    const descriptiveMessage = e.response.data ? `Error(s): ${Object.values(e.response.data).join(" & ")}` : null
                    this.$data.snackbarText = descriptiveMessage ? descriptiveMessage
                        : e.message ? e.message
                            : "Unknown error occurred when retrieving data.";
                });
            (this as any).closeDelete();
        },

        close() {
            this.$data.dialog = false;
            this.$nextTick(() => {
                this.$data.editedItem = Object.assign({}, this.$data.defaultItem);
                this.$data.editedIndex = -1;
            });
        },

        save() {
            this.$data.showLoader = true;
            if (this.$data.editedIndex > -1) {
                Object.assign(
                    (this as any).categories[this.$data.editedIndex],
                    this.$data.editedItem
                );
                this.$nextTick(() => {
                    categoryService
                        .updateCategory(this.$data.editedItem)
                        .then((res) => {
                            this.$data.showLoader = false;
                            if (res.success) {
                                (this as any).close();
                                this.$data.categories = res.certificateCategories;
                                this.$data.snackbar = true;
                                this.$data.snackbarColor = "green";
                                this.$data.snackbarText = "Category updated successfully";
                            } else {
                                this.$data.snackbar = true;
                                this.$data.snackbarColor = "red";
                                this.$data.snackbarText = "Error while updating category";
                            }
                        })
                        .catch((e: any) => {
                            this.$data.showLoader = false;
                            this.$data.snackbar = true;
                            this.$data.snackbarColor = "red";
                            const descriptiveMessage = e.response.data ? `Error(s): ${Object.values(e.response.data).join(" & ")}` : null
                            this.$data.snackbarText = descriptiveMessage ? descriptiveMessage
                                : e.message ? e.message
                                    : "Unknown error occurred when retrieving data.";
                        });
                });
            } else {
                categoryService
                    .createCategory(this.$data.editedItem)
                    .then((res) => {
                        this.$data.showLoader = false;
                        if (res.success) {
                            (this as any).close();
                            this.$data.categories = res.certificateCategories;
                            this.$data.snackbar = true;
                            this.$data.snackbarColor = "green";
                            this.$data.snackbarText = "Category added successfully";
                        } else {
                            this.$data.snackbar = true;
                            this.$data.snackbarColor = "red";
                            this.$data.snackbarText = "Error while adding category";
                        }
                    })
                    .catch((e: any) => {
                        this.$data.showLoader = false;
                        this.$data.snackbar = true;
                        this.$data.snackbarColor = "red";
                        const descriptiveMessage = e.response.data ? `Error(s): ${Object.values(e.response.data).join(" & ")}` : null
                        this.$data.snackbarText = descriptiveMessage ? descriptiveMessage
                            : e.message ? e.message
                                : "Unknown error occurred when retrieving data.";
                    });
            }
        },

        closeDelete() {
            this.$data.dialogDelete = false;
            this.$nextTick(() => {
                this.$data.editedItem = Object.assign({}, this.$data.defaultItem);
                this.$data.editedIndex = -1;
            });
        },
        refresh: function () {
            this.$data.showLoader = true;
            adminService.getPrograms().then((res) => {
                this.$data.programChoices = res.certificatePrograms;
            });
            categoryService.getTitleCategories().then((res) => {
                this.$data.categories = res.certificateCategories;
                this.$data.showLoader = false;
            });
        },
    },
    created: function () {
        this.refresh();
    },
  computed: {
      userInfo(): UserInfo {
          return this.$store.state.userInfo;
      },
      formTitle() {
          return this.$data.editedIndex === -1 ? "New Category" : "Edit Category";
      },
  },
});
