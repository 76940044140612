
import Vue from "vue";

import FooterText from "@/components/common/FooterText.vue";
import HostedRegistrationPayment from "@/components/common/HostedRegistrationPayment.vue";

export default Vue.extend({
  name: "SummaryPayment",
  components: { FooterText, HostedRegistrationPayment },
  props: {
    changePage: Function,
    submit: Function,
    price: Number,
  },
  data: function () {
    return {}
  },
});
