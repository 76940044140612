
import Vue from "vue";
import litterService from "@/services/portal/litterService";
import { UserInfo } from "@/types/User";

const defaultImage = require('@/assets/photouploadblue.png');

export default Vue.extend({
  name: "LitterInfo",
  data: function () {
    return {
      defaultImage,
      litterInfo: {
        dam: {
          name: "",
        },
        sire: {
          name: "",
        },
        kennels: [],
      },
      headers: [
        { text: "Puppy ID", value: "id" },
        { text: "Sex", value: "gender" },
        { text: "Main Color", value: "mainColor" },
        { text: "Is Dead/Culled", value: "isDead" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  methods: {},
  created: function () {
    if (this.litterId) {
      litterService
        .getLitterById(this.litterId)
        .then((res) => {
          this.litterInfo = res;
        })
        .catch((e) => console.log(e));
    }
  },
  watch: {
    litterId: function (newVal: String) {
      litterService
        .getLitterById(this.litterId)
        .then((res) => {
          this.litterInfo = res;
        })
        .catch((e) => console.log(e));
    },
  },
  props: {
    litterId: { type: Number },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
});
