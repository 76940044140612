
import Vue from "vue";
import ReportViewer from "@/components/Admin/Reports/ReportViewer.vue";
import AdminEditableCoownersList, { OwnershipType } from "@/components/Admin/AdminEditableCoownersList.vue";
import KennelSimilarNamesDialog from "@/components/Admin/KennelSimilarNamesDialog.vue";
import DatepickerFormatted from "@/components/common/inputs/DatepickerFormatted.vue";

import kennelService from "@/services/admin/kennelService";

import { UserInfo } from "@/types/User";
import { Messages } from "@/constants";

export default Vue.extend({
  name: "KennelDetails",
  components: {
    DatepickerFormatted,
    AdminEditableCoownersList,
    ReportViewer,
    KennelSimilarNamesDialog
  },
  data: function () {
    return {
      kennelInfo: {} as any,
      isEditMode: false,
      Report_Name: 'Asca.Api.Reports.rpt3210_Dog_Reg_Certificate.rdlx',
      Report_URL: '`/reports/registration/${dogId}`',
      showReportDialog: false,
      showValidationDialog: false,
      validationResult: {} as any,
      errorMessage: '',
      infoMessage: '',
      showErrorMessage: false,
      showInfoMessage: false
    };
  },
  methods: {
    showRegCertDialog: function (){   
      this.Report_Name = "Asca.Api.Reports.rpt6210_Kennel_Reg_Certificate.rdlx";
      this.Report_URL = "/reports/kennelregistration/"+this.kennelId;
      this.showReportDialog = true;
    },
      reloadData: function () {
          this.isEditMode = false;
      if (this.kennelId) {
        kennelService
          .getKennelById(this.kennelId)
          .then((res) => {
            this.kennelInfo = res;
          });
      } else {
          this.isEditMode = true;
        this.kennelInfo = {};
      }
      
      this.showValidationDialog = false;
      this.errorMessage = '';
      this.showErrorMessage = false;
      this.validationResult= {};
    },
    updateText: function (key: string, event: string) {
      this.$data.kennelInfo[key] = event;
    },
    validateAndConfirmSavingChanges: function() { 
      kennelService
        .validateKennelEdit(this.kennelInfo)
        .then((res: any) => {
          this.validationResult = res;
          this.showValidationDialog = true;
        })     
    },
    saveChanges: function () {
      this.showValidationDialog = false;
      this.$store.dispatch("changeLoaderStatus",true);
      kennelService
                .editKennelById(this.kennelInfo)
                .then((res) => {
                  if(res.success){
                    if(res.id && res.id != this.$props.kennelId){
                      this.$router.push({ path: `/admin/kennels/${res.id}` }).catch(() => {});
                    }
                    this.reloadData();
                    this.infoMessage = Messages.SaveSuccessful;
                    this.showInfoMessage = true;
                  } else {              
                    this.errorMessage = Messages.SaveError + " " + res.messages.join(', ');
                    this.showErrorMessage = true;
                  }
                })
                .catch((e) => {                  
                  this.errorMessage = Messages.SaveError;
                   this.showErrorMessage = true;
                })
                .finally(() => this.$store.dispatch("changeLoaderStatus",false));
    },
    toggleEditMode: function () {
      if (this.isEditMode) {
        this.validateAndConfirmSavingChanges();
      } else {
        this.isEditMode = true;
      }
    },
    updateDates: function (val: any, key: string) {
      this.$data.kennelInfo[key] = val?.date;
      this.$data.kennelInfo = Object.assign({}, this.$data.kennelInfo);
    },
    updateSelectedPeople: function (val: any) {
      //workaround for setting up the primary owner
      //and issues with reactivity in Vue https://v2.vuejs.org/v2/guide/reactivity.html?redirect=true#For-Arrays
      if(val.newPeople && (val.newPeople.length === 1 || (val.newPeople.length > 0 && val.newPeople.findIndex((own: any) => own.isPrimary) == -1)))
      {
        val.newPeople[0].isPrimary = true;
      }
      if(!!this.kennelInfo.owners && val.newPeople && val.newPeople.length > 0)
      {
        this.kennelInfo.owners.splice(val.newPeople.length);
      }
      this.$set(this.kennelInfo, 'owners', val.newPeople);
    },
  },
  created: function () {
    this.reloadData();
  },
  watch: {
    kennelId: function (newVal: String) {
      kennelService
        .getKennelById(this.kennelId)
        .then((res) => {
          this.kennelInfo = res;
        })

        .catch((e) => console.log(e));
    },
  },
  props: {
    kennelId: { type: String },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    kennelOwnershipType: function () {
      return OwnershipType.Kennel;
    },
  },
});
