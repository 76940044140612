
import Vue from "vue";
export default Vue.extend({
  name: "PaginationSelect",
  props: {
    totalCount: Number,
    size: Number,
    page: Number,
  },
  data: function () {
    return {
      selectedPage: this.page ?? 1,
      selectedSize: this.size ?? 20,
    };
  },
  methods: {
    load() {
      this.$emit('load');
    },
  },
  watch: {
    selectedPage(newValue: Number, oldValue: Number) {
      if (!!newValue && newValue != this.page)
      {
        this.$emit('changePage', this.selectedPage);
        this.load();
      }
    },
    selectedSize(newValue: Number, oldValue: Number) {
      if (!!newValue && newValue != this.size) this.$emit('changeSize', this.selectedSize);
    },
    page(newValue: number) {
      if (!!newValue && newValue != this.selectedPage) this.selectedPage = newValue;
    },
    size(newValue: number) {
      if (!!newValue && newValue != this.selectedSize) this.selectedSize = newValue;
    },
  }
});
