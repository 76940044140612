
import Vue from "vue";
import lookupsService from "@/services/admin/lookupsService";
import { NextIdsLookupInfo } from "@/types/admin/Lookup";

export default Vue.extend({
    name: "NextIdsMaintenance",
    data: function () {
        return {
            tab: null,
            luItem: {} as NextIdsLookupInfo,
            errorMessage: "",
            isLoading: false,
            showSuccessMessage: false,

        };
    },
    created: function () {
        this.reloadItems();
    },
    methods: {
        reloadItems() {
            this.$data.isLoading = true;
            lookupsService.getNextIdInfo().then((res: NextIdsLookupInfo) => {
                this.luItem = res;
                this.$data.isLoading = false;
            });
        },
        incrementId(nextId: string) {
            if (nextId == "affiliate") {
                this.luItem.nextAffiliateId++;
            }
            else if (nextId == "litter") {
                this.luItem.nextLitterId++;
            }
            else if (nextId == "kennel") {
                this.luItem.nextKennelId++;
            }
            else if (nextId == "show") {
                this.luItem.nextShowId++;
            } 
            else {
                this.luItem.nextJudgeId++;
            }

        },

        save() {
            this.errorMessage = "";
            this.showSuccessMessage = false;
            lookupsService.editNextIds(this.luItem).then((res: any) => {
                if (res.success) {
                    this.showSuccessMessage = true;
                }
                else {
                    this.errorMessage = "Unable to edit the Next Ids.";
                    return;
                }
            }).catch(
                (e) => {
                    console.log(e);
                    this.errorMessage = "Unable to edit Next Ids.";
                });
            
        },
        cancel() {
            this.errorMessage = "";
            this.showSuccessMessage = false;
            this.reloadItems();
        },
        
    },
    
    
});
