
import Vue from "vue";
import { addAuthorizeScript, removeAuthorizeScript } from "@/helpers/add-authorize-script";
import AuthorizeNetResponseData from "@/types/AuthorizeNetResponseData";
import { env } from "@/environment/environment";
export default Vue.extend({
  name: "HostedRegistrationPayment",
  props: {
    disabled: Boolean,
    buttonText: String,
  },
  created() {
    this.paying = false;
    (window as any).responseHandler = this.responseHandler
    addAuthorizeScript();
  },
  destroyed() {
    this.paying = false;
    removeAuthorizeScript();
  },
  data: function () {
    return {
      paying: false,
      errorMessage: '',
      text: this.buttonText || "Pay",
      loginId: env.VUE_APP_AUTHORIZE_NET_LOGINID,
      publicKey: env.VUE_APP_AUTHORIZE_NET_PUBLIC_KEY,
    }
  },
  methods: {
    onPay() { this.paying = true; },
    responseHandler: function (data: AuthorizeNetResponseData) {
      if (data.messages.resultCode != "Ok") {
        this.errorMessage = data.messages?.message[0]?.text;
        return undefined;
      }

      if (this.paying)
      {
        this.$emit("updatePaymentInfo", data)
        this.paying = false;
      }
    },
  }
});
