import { MembershipFees, UpsertMemberDTO, RenewAussieTimesDTO } from '@/types/portal/Member';

import { api } from "../api";

export const getMemberFees = async (): Promise<MembershipFees | null> => {
  try {
    const { data } = await api.get<any>('/Person/member/fees');
    return data;
  } catch (err) {
    console.error('error', err);
    return null;
  }
}

export const getAuxiliaryMembers = async (): Promise<any> => {
  try {
    const { data } = await api.get<any>('/Person/member/aux-members');
    return data;
  } catch (err) {
    console.error('error', err);
    return null;
  }
}

export const linkToMembership = async (memberId: string): Promise<any> => {
  try {
      const { data } = await api.post<any>(`/Person/member/link`, { memberId });
      return {...data, success: true};
  } catch (err) {
    console.error("error", err);

    if ((err as any)?.status === 400) {
      return { errors: (err as any).data.errors };
    }

    return err;
  }
}

export const registerMembership = async (dto: UpsertMemberDTO): Promise<any> => {
  try {
    const { data } = await api.post<any>(`/Person/member/register`, dto);
    return {...data, success: true};
  }  catch (err) {
      if ((err as any).data) {
        return {...(err as any).data, success: false};
      } else {
        return { errors: ["An Unknown Error Occured"], success: false };
      }
  }
}

export const renewMembership = async (dto: UpsertMemberDTO): Promise<any> => {
  try {
    const { data } = await api.post<any>(`/Person/member/renew`, dto);
    return {...data, success: true};
  }  catch (err) {
      if ((err as any).data) {
        return {...(err as any).data, success: false};
      } else {
        return { errors: ["An Unknown Error Occured"], success: false };
      }
  }
}

export const renewAussieTimes = async (dto: RenewAussieTimesDTO): Promise<any> => {
  try {
    const { data } = await api.post<any>('/Person/member/renew-aussie-times', dto);
    return {...data, success: true};
  }  catch (err) {
      if ((err as any).data) {
        return {...(err as any).data, success: false};
      } else {
        return { errors: ["An Unknown Error Occured"], success: false };
      }
  }
}

export default {
  getMemberFees,
  getAuxiliaryMembers,
  linkToMembership,
  registerMembership,
  renewMembership,
  renewAussieTimes,
};