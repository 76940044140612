
import Vue from "vue";
import CoownersList, { OwnershipType } from "@/components/common/CoownersList.vue";

const defaultImage = require('@/assets/photouploadblue.png');

export default Vue.extend({
  name: 'LitterRegistrationRequest',
  components: { 
    CoownersList     
  },
  props: {
    registration: { type: Object },
  },
  data: function () {
    return {
        defaultImage,
    };
  },
  computed: {
    dogOwnershipType: function() {
      return OwnershipType.Dog;
    },
  }
});
