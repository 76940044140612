
import Vue from "vue";
import GeneralPersonDetails from "@/components/Admin/Details/GeneralPersonDetails.vue";
import MemberDetails from "@/components/Admin/Details/MemberDetails.vue";
import JudgeDetails from "@/components/Admin/Details/JudgeDetails.vue";
import JuniorHandlerDetails from "@/components/Admin/Details/JuniorHandlerDetails.vue";
import PortalUserDetails from "@/components/Admin/Details/PortalUserDetails.vue";
import ReportViewer from '@/components/Admin/Reports/ReportViewer.vue';

import peopleService from "@/services/admin/peopleService";

import { AdminPersonInfo } from '@/types/admin/Person';

export default Vue.extend({
    components: { GeneralPersonDetails, JudgeDetails, JuniorHandlerDetails, PortalUserDetails, ReportViewer, MemberDetails, },
    name: "PersonDetails",
    props: {
        personId: { type: Number },
    },
    data: function () {
        return {
            personInfo: {} as AdminPersonInfo,
            tab: null,
            Report_Name: '',
            Report_URL: '',
            showReportDialog: false,
        };
    },
    created: function () {
        if (this.personId) {
            peopleService
                .getPersonById(this.personId)
                .then((res) => {
                    this.personInfo = res;
                })
                .catch((e) => console.log(e));
        }
    },
    watch: {
        personId: function (newVal: String) {
            peopleService
                .getPersonById(this.personId)
                .then((res) => {
                    this.personInfo = res;
                })
                .catch((e) => console.log(e));
        },
    },
    methods: {
        showPrintMembershipCardDialog: function () {
            this.Report_Name = "Asca.Api.Reports.rpt1220_Person_Membership_Card.rdlx";
            this.Report_URL = `/reports/membershipcard/${this.personInfo.personId}`;
            this.showReportDialog = true;
        },
        showPrintLabelDialog: function () {
            this.Report_Name = "Asca.Api.Reports.rpt1210_Person_Label.rdlx";
            this.Report_URL = `/Admin/reports/people/${this.personInfo.personId}/contact`;
            this.showReportDialog = true;

        }
    }
});
