
import Vue from "vue";
import { UserInfo } from "@/types/User";
import adminTitlingService from "@/services/admin/titlingService";
import FilterPills from "@/components/Layouts/FilterPills.vue";
import Viewer from '@/components/Admin/Reports/ReportViewer.vue';
import { MetaNames } from "@/constants";

export default Vue.extend({
  name: "TitlingProcess",
  components: {
    FilterPills,
    Viewer
  },
  data: function () {
      return {
      title:MetaNames.AdminCertificateGeneration,
      Report_Name: 'Asca.Api.Reports.rpt8110_Title_Certificate.rdlx',
      Report_URL: '`/Admin/reports/certificates/${this.displayExecutionId}/suffix"`',    
      displayDogDetails: false,
      displayPrintPreview: false,
      displayExecutionId: null,
      isLoading: false,      
      programsTypes: [
          <object>{code: 'A', name: 'Agility'},
          <object>{code: 'R', name: 'Rally'},
          <object>{code: 'O', name: 'Obedience'},
          <object>{code: 'T', name: 'Tracking'},
          <object>{code: 'ST', name: 'Stockdog'},
          <object>{code: 'FT', name: 'Farm Trial'},
          <object>{code: 'RT', name: 'Ranch Trial'},
          <object>{code: 'C', name: 'Conformation'},
          <object>{code: 'SP', name: 'Specialty'},
        ] as any[],
      selectedProgram: '',
      programInfo: {} as any,
      errorMessage: '',
      certGenSearch: '',
      dogsDetailsSearch: '',
      certGenExecutionInfoId: null,
      certGenInfosList: [] as any[],
      certGenDogsInfosList: [] as any[],
      filteredCertGenDogsInfosList: [] as any[],
      selectedGenInfo: null,
      certGenInfoHeaders: [
        { text: "Program", align: "left", value: "program", width: "50px" },
        { text: "Progress", align: "left", value: "progressDogsCount", width: "20px", groupable: false }, 
        { text: "Results from", align: "left", value: "processedShowResultsEnteredAfter", width: "120px" }, 
        { text: "New", align: "left", value: "processedDogsWithNewCertsCount", width: "20px" }, 
        { text: "Dev", align: "left", value: "processedDogsWithMissingCertsCount", width: "20px" }, 
        { text: "Err", align: "left", value: "processedDogsWithExceptionsCount", width: "20px" }, 
        { text: "", align: "left", value: "id", width: "50px", groupable: false },  
        { text: "", align: "left", value: "canBeApproved", width: "50px", groupable: false },    
        { text: "", align: "left", value: "canBePrinted", width: "50px", groupable: false },      
        { text: "", align: "left", value: "isPrinted", width: "50px", groupable: false },   
        { text: "Processing Duration", align: "left", value: "processingStartedAt", width: "30px", groupable: false },   
        { text: "Requested at", align: "left", value: "requestedAt", width: "80px", groupable: false },
        { text: "Requested by", align: "left", value: "requestedBy", width: "80px" },
        { text: "Processed at", align: "left", value: "processingCompletedAt", width: "80px" },   
        { text: "Errors", align: "left", value: "errors", width: "120px", groupable: false },
      ],
      certGenDogsInfoHeaders: [
        { text: "Dog ID", align: "left", value: "dogId" },
        { text: "Deviations", align: "left", value: "certificatesMissing" }, 
        { text: "New", align: "left", value: "certificatesNew" }, 
        { text: "Data errors", align: "left", value: "certificatesExceptions" },    
      ],
      filters: [
        { name: "New", active: false },
        { name: "Deviations", active: false },
        { name: "Data errors", active: false },
      ],
    };
  },
  props: {
  },
  methods: {
    preview: function (executionId: any) {   
      this.displayPrintPreview = true;
      this.displayDogDetails = false;
      this.displayExecutionId = executionId;
      this.Report_Name = "Asca.Api.Reports.rpt8110_Title_Certificate.rdlx";
      this.Report_URL = "/Admin/reports/certificates/"+this.displayExecutionId+"/suffix";
    },
    previewFull: function (executionId: any) {   
      this.displayPrintPreview = true;
      this.displayDogDetails = false;
      this.displayExecutionId = executionId;
      this.Report_Name = "Asca.Api.Reports.rpt8111_Title_Certificate_Full.rdlx";
      this.Report_URL = "/Admin/reports/certificates/"+this.displayExecutionId+"/prefix";
    },
    previewLabels: function (executionId: any) {
      this.displayPrintPreview = true;
      this.displayDogDetails = false;
      this.displayExecutionId = executionId;
      this.Report_Name = "Asca.Api.Reports.rpt1210_Person_Label.rdlx";
      this.Report_URL = "/Admin/reports/certificates/"+this.displayExecutionId+"/contacts";
    },
    printAgain: function(executionId: any){
      this.displayPrintPreview = true;
      this.displayDogDetails = false;
      this.displayExecutionId = executionId;
      this.Report_Name = "Asca.Api.Reports.rpt8110_Title_Certificate.rdlx";
      this.Report_URL = "/Admin/reports/certificates/"+this.displayExecutionId+"/suffix";
    },
    printAgainFull: function (executionId: any) {   
      this.displayPrintPreview = true;
      this.displayDogDetails = false;
      this.displayExecutionId = executionId;
      this.Report_Name = "Asca.Api.Reports.rpt8111_Title_Certificate_Full.rdlx";
      this.Report_URL = "/Admin/reports/certificates/"+this.displayExecutionId+"/prefix";
    },
    markPrinted: function (executionId: any) {
      this.$store.dispatch("changeLoaderStatus",true);
      adminTitlingService.markCertGenerationPrinted(executionId, this.userInfo.personId).then((res: any)=>{
        this.$store.dispatch("changeLoaderStatus",false);
        if(res.success){          
          adminTitlingService.getCertGenerationList()
            .then((res: any) => {
              this.certGenInfosList = res;
            });
        } else {
          this.errorMessage = res.messages.join(', ')
        }
      });
    },
    changeFilters: function (index: number) {
      this.filters[index].active = !this.filters[index].active;
      this.filteredCertGenDogsInfosList = [];
      if(!this.filters[0].active && !this.filters[1].active && !this.filters[2].active){
        this.filteredCertGenDogsInfosList = this.certGenDogsInfosList;
      }
      else {
        if(this.filters[0].active){
          var dogsWithNewCerts = this.certGenDogsInfosList.filter(x => !!x.certificatesNew);
          this.filteredCertGenDogsInfosList = this.filteredCertGenDogsInfosList.concat(dogsWithNewCerts);
        }
        if(this.filters[1].active){
          var dogsWithMissingCerts = this.certGenDogsInfosList.filter(x => !!x.certificatesMissing);
          this.filteredCertGenDogsInfosList = this.filteredCertGenDogsInfosList.concat(dogsWithMissingCerts);
        }
        if(this.filters[2].active){
          var dogsWithExceptions = this.certGenDogsInfosList.filter(x => !!x.certificatesExceptions);
          this.filteredCertGenDogsInfosList = this.filteredCertGenDogsInfosList.concat(dogsWithExceptions);
        }
      }
    },
    getProgramInfo: function(){
        this.programInfo = {};
        adminTitlingService.getDogsWithUnprocessedResults(this.selectedProgram)
              .then((res: any) => {
                this.programInfo = res;
              });
  
    },
    processShowResults: function(){ 
      this.$store.dispatch("changeLoaderStatus",true);
        adminTitlingService.processShowResults(this.selectedProgram, this.userInfo.personId)
              .then((res: any) => {
                this.certGenExecutionInfoId = res.certGenExecutionInfoId;
                this.$store.dispatch("changeLoaderStatus",false);
                adminTitlingService.getCertGenerationList()
                  .then((res: any) => {
                    this.certGenInfosList = res;
                  });
              });  
    },
    approve: function(id: number){
        this.$store.dispatch("changeLoaderStatus",true);
        adminTitlingService.approveShowResults(id, this.userInfo.personId)
              .then((res: any) => {
                console.log(res)
                this.$store.dispatch("changeLoaderStatus",false);
                adminTitlingService.getCertGenerationList()
                  .then((res: any) => {
                    this.certGenInfosList = res;
                  });
              });
    },
    showDogsDetails(certGenExecutionInfo: any){        
        this.displayDogDetails = true;     
        this.displayPrintPreview = false;
        this.selectedGenInfo = certGenExecutionInfo;
        adminTitlingService.getCertGenerationDogsList(certGenExecutionInfo.id)
              .then((res: any) => {
                this.certGenDogsInfosList = res;
                this.filteredCertGenDogsInfosList = res;
              });
    },
    programName(programSymbol: String){
      switch(programSymbol){
        case 'A':
          return 'Agility';
        case 'C':
          return 'Conformation';
        case 'O':
          return 'Obedience';
        case 'R':
          return 'Rally';
        case 'ST':
          return 'Stock';
        case 'T':
          return 'Tracking';
        case 'FT':
          return 'Farm Trial';
        case 'RT':
          return 'Ranch Trial';
        case 'SP':
          return 'Specialty';
      }
    }
  },
  created() {
    adminTitlingService.getCertGenerationList()
      .then((res: any) => {
        this.certGenInfosList = res;
      });
  },
  watch: {
    selectedProgram: function(){
      this.programInfo = {};
      this.displayDogDetails = false;
      this.displayPrintPreview = false;
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    }
  },
});
