import { api } from "../api";

export const getReports = async (): Promise<object[]> => {    
    const { data } = await api.get<object[]>(`/Admin/reports`);

    return data;
};

export default {
    getReports,
}