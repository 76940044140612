
import Vue from 'vue';
import DogList from "./Searches/DogList.vue";
import AdminCoownersList, { OwnershipType } from "./AdminCoownersList.vue";

import dogService from "@/services/admin/dogService";

export default Vue.extend({
  name: 'AdminDogSearchDialog',
  props: {
    dogId: { type: String },
    initialDogId: { type: String },
    isEditMode: { type: Boolean },
    hideSearch: { type: Boolean },
    showOwners: { type: Boolean },
    returnOwners: { type: Boolean },
    label: { type: String },
  },
  components: {
    AdminCoownersList,
    DogList
  },
  data: function() {
      return {
        showDogSearchDialog: false,
        dogInfo: {} as any,
        selectedDogId: this.$props.dogId
      };
  },
  computed: {
    dogOwnershipType: function() {
      return OwnershipType.Dog;
    },
  },
  methods: { 
    onDogSelected: function(dogId: any){
        this.showDogSearchDialog = false;
        this.selectedDogId = dogId;
        this.loadDogInfo();
    },
    loadDogInfo: function() {
        if(!this.selectedDogId){
          this.dogInfo = {};
          return;
        }
        
        this.$store.dispatch("changeLoaderStatus",true);
        dogService.getDogById(this.selectedDogId).then((res: any) => {
          if(res){
            this.dogInfo = res;
            if (this.returnOwners) this.$emit('update-owners', [...this.dogInfo.owners] ?? []);
          } else {
            this.dogInfo = {};
          }
        }).catch(e => {
          console.log(e); 
          this.dogInfo = {};
        })
        .finally(() => {
          this.$store.dispatch("changeLoaderStatus",false);
        });
    },
  },
  watch: {    
    dogId: function(newVal, oldVal){
      if(newVal != oldVal)
        this.selectedDogId = newVal;    
    },    
    initialDogId: function(newVal, oldVal){
      if(newVal != oldVal) {
        this.loadDogInfo(); 
      }   
    },
    selectedDogId: function(newVal, oldVal){      
      this.$emit('update:dogId', newVal);
    }
  }
});
