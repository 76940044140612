
import Vue from 'vue'
import TextCard from './TextCard.vue';
import MediaCard from './MediaCard.vue';
import CardHeader from './CardHeader.vue';
import CallToAction from './CallToAction.vue';
import CarouselCard from './CarouselCard.vue';

export default Vue.extend({
    name: 'FeedCard',
    props: {
        createdBy: String,
        category: String,
        createdTime: Date,
        image: String,
        title: String,
        body: String,
        type: {
            type: String,
            validator: (val) => ['text', 'carousel', 'media'].includes(val as string)
        },
        // mainLinks are used for calls to action - should be array of objects each with a title, and optionally a link & buttonText (without the last 2 it will just display the text)
        mainLinks: Array,
        miniCards: Array,
        clickFunction: Function,
        changeFavoriteFunction: Function,
        titleLink: String,
    },
    components: {
        TextCard,
        MediaCard,
        CardHeader,
        CallToAction,
        CarouselCard,
    }
})
