import { env } from "../environment/environment";

export const addAuthorizeScript = () => {
  let existingScript = document.getElementById("authorizeui");
  if (existingScript) return; // only add once
  let script = document.createElement("script");
  script.id = "authorizeui";
  script.type = "text/javascript";
  script.src = env.VUE_APP_AUTHORIZE_NET_URL;
  document.head.appendChild(script);
};

export const removeAuthorizeScript = () => {
  let existingScript = document.getElementById("authorizeui");
  if (!existingScript) return; // only remove if present
  existingScript.remove();
};

export default { addAuthorizeScript, removeAuthorizeScript };
