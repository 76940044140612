
import Vue from "vue";
import FooterText from "@/components/common/FooterText.vue";
export default Vue.extend({
  components: { FooterText },
  name: "AffiliateWebsiteAndEvents",
  props: {
    affiliateInfo: Object,
    changePage: Function,
    changeFirstPageInfo: Function,
  },
  methods: {
    validateWebsite(input: string) {
      return (
        /^[-a-zA-Z0-9@:%._/\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
          input
        ) || input === '' || "Website is invalid"
      );
    },
  },
});
