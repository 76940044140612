
import Vue from "vue";
import DogTypeMaintenance from "@/components/Admin/Lookups/DogTypeMaintenance.vue";
import NextIdsMaintenance from "@/components/Admin/Lookups/NextIdsMaintenance.vue";
import MemberTypeMaintenance from "@/components/Admin/Lookups/MemberTypeMaintenance.vue";

export default Vue.extend({
  name: "IdManagement",
  components: {
    DogTypeMaintenance,
    MemberTypeMaintenance,
    NextIdsMaintenance,
  },
  data: function() {
    return {
      tab: null,
    };
  },
});
