
import Vue from "vue";
import lookupsService from "@/services/admin/lookupsService";
import { ProgramLookupInfo } from "@/types/admin/Lookup";

export default Vue.extend({
    name: "CertProgramMaintenance",
    data: function () {
        return {
            tab: null,
            luItems: [] as ProgramLookupInfo[],
            dialog: false,
            dialogDelete: false,
            headers: [
                { text: "Program", align: "left", value: "programName" },
                { text: "Program ID", align: "left", value: "programId" },
                { text: "Is Manual", align: "left", value: "isManual" },
                { text: "Actions", value: "actions", sortable: false },
            ],
            editedIndex: -1,
            editedItem: {
                id: -1,
                isManual: true,
                canDelete: true,
                programId: "",
                programName: "",

            },
            defaultItem: {
                id: -1,
                isManual: true,
                canDelete: true,
                programId: "",
                programName: "",
            },
            errorMessage: "",
            isValid: true,
            search: "",
            isLoading: false,
            selectedProgramId: "",
            checked: true,
            saveErrorMessage: "",

        };
    },
    created: function () {
        this.reloadItems();
    },
    methods: {
        refresh: function () {
            this.reloadItems();
            this.errorMessage = "";
            this.saveErrorMessage = "";
        },
        reloadItems: function () {
            this.$data.isLoading = true;
            lookupsService.getCertProgramList().then((res: any) => {
                this.luItems = res;
                this.$data.isLoading = false;

            });
            this.$data.isLoading = false;
        },
        initializeItem() {
            this.selectedProgramId = "";
            this.editedItem.isManual = true;
            this.dialog = true;

        },

        editItem(item: ProgramLookupInfo) {
            this.editedIndex = this.luItems.indexOf(item)
            this.editedItem = Object.assign({}, item);
            this.selectedProgramId = this.editedItem.programId;
            this.dialog = true;
        },

        deleteItem(item: ProgramLookupInfo) {
            this.editedIndex = this.luItems.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.errorMessage = "";
            this.saveErrorMessage = "";
            if (!this.editedItem.canDelete) {
                this.closeDelete();
            }
            lookupsService.deleteCertProgram(this.editedItem.id).then((res) => {
                if (res.success == false) {
                    this.saveErrorMessage = res.messages.join(', ')
                }
                else {

                    this.luItems.splice(this.editedIndex, 1);
                }

                this.reloadItems(); //in case something was changed after that pull

            }).catch((e: any) => {
                this.saveErrorMessage = "Program is in use. Unable to Delete.";
                return;
            });


            this.closeDelete();

        },

        close() {
            this.errorMessage = "";
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            this.errorMessage = "";
            this.saveErrorMessage = "";
            this.editedItem.programName = this.editedItem.programName.trim();
            if (this.editedIndex == -1) {
                var exists = this.luItems.find((x: any) => x.programName == this.editedItem.programName || x.programId == this.editedItem.programId);
                if (exists != null) {
                    this.errorMessage = "This Program Name/Id already exists.";
                    return;
                }
            }
            else {
                var exists = this.luItems.find((x: any) => x.id != this.editedItem.id && (x.programName == this.editedItem.programName || x.programId == this.editedItem.programId));
                if (exists != null) {
                    this.errorMessage = "This Program Name/Id already exists.";
                    return;
                }
            }


            this.editedItem.isManual = true; //only allowing manual program add/edit

            this.$store.dispatch("changeLoaderStatus", true);
            lookupsService.editCertProgram(this.editedItem)
                .then((res) => {
                    if (res.success) {
                        if (this.editedIndex > -1) {
                            Object.assign(this.luItems[this.editedIndex], this.editedItem)
                        }
                        else {
                            this.editedItem.id = res.id;
                            if (this.luItems) {
                                this.luItems.unshift(this.editedItem)

                            }
                        }
                        this.close();
                    }
                    else {
                        this.saveErrorMessage = res.messages.join(', ')
                        this.reloadItems(); //in case something was changed after that pull
                    }
                })
                .catch((e) => console.log(e))
                .finally(() => this.$store.dispatch("changeLoaderStatus", false));
        },

    },
    computed: {
        formTitle() {
            return this.$data.editedIndex === -1 ? "New Item" : "Edit Item";
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

});
