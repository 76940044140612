import { PaginatedResponse } from "@/types/Pagination";
import { ShowType } from "@/types/admin/Show";
import { api } from "./api";

export const getShowTypes = async () => {
  const { data: { data } } = await api.get<PaginatedResponse<ShowType>>("/showtype");

  return data;
};

export default {
  getShowTypes,
};
