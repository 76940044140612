export class Messages {
    public static SaveSuccessful = "Changes successfully saved.";
    public static SaveError = "Changes couldn't be saved.";
}

export class MetaNames {
    public static DefaultTitle = "ASCA";
    public static DefaultLanding = "ASCA Home";

    public static AdminLitters = "Litters";
    public static AdminLitterRegistrations = "Litter Registrations";
    public static AdminLitterDetails = "Litter Details";

    public static AdminLeases = "Leases";
    public static AdminLeaseRegistrations = "Lease Registrations";
    public static AdminLeaseDetails = "Lease Details";

    public static AdminKennels = "Kennels";
    public static AdminQTDOGRegistrations = "QT Dog Registrations";
    public static AdminKennelRegistrations = "Kennel Registrations";
    public static AdminKennelDetails = "Kennel Details";
    public static AdminKennelChangeOwners = "Kennel Change Owners";

    public static AdminAffiliates = "Affiliates";
    public static AdminAffiliateRegistrations = "Affiliate Registrations";
    public static AdminAffiliateRenewals = "Affiliate Renewals";
    public static AdminAffiliateDetails = "Affiliate Details";

    public static AdminPuppyReleases = "Puppy Releases";

    public static AdminShows = "Shows";
    public static AdminShowDetails = "Show Details";

    public static AdminPeople = "People";
    public static AdminPersonDetails = "Person Details";

    public static AdminDogs = "Dogs";
    public static AdminDogDetails = "Dog Details";

    public static AdminPayments = "Payments";

    public static AdminLookupMaintenance = "Lookup Maintenance";
    public static AdminIdConfig = "ID Config";

    public static AdminTitlingReview = "Titling Review";
    public static AdminCertificateGeneration = "Certificate Generation";
    public static AdminCertificatePrinting = "Certificate Printing";
    public static AdminCategoryManagement = "Category Management";
    public static AdminShowResults = "Show Results";
    public static AdminEvents = "Event Sanctions";
    public static AdminHOF = "HOF Requests"

    public static AdminReports = "Reports";

    //Side Panel Headings
    public static AdminTitlingHeading = "Events & Titling";
    public static AdminRegistrationsHeading = "Registrations";
    public static AdminSearchesHeading = "Data & Searches";
    public static AdminUtilitiesHeading = "Utilities";
    public static AdminMembersOnlyEdit = "Members Only Edit";

}