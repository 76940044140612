
import Vue from "vue";
import lookupsService from "@/services/admin/lookupsService";
import { ShowTypeLookupInfo, ProgramLookupInfo } from '@/types/admin/Lookup';

export default Vue.extend({
    name: "ShowTypesMaintenance",
    data: function () {
        return {
            tab: null,
            luItems: [] as ShowTypeLookupInfo[],
            programs: [] as ProgramLookupInfo[],
            dialog: false,
            dialogDelete: false,
            headers: [
                { text: "ID", align: "left", value: "id" },
                { text: "Description", align: "left", value: "description" },
                { text: "Program", align: "left", value: "programName" },
                { text: "Actions", value: "actions", sortable: false },
            ],
            editedIndex: -1,
            editedItem: {
                id: "",
                description: "",
                canDelete: true,
                programId: "",
                programName: "",

            },
            defaultItem: {
                id: "",
                description: "",
                canDelete: true,
                programId: "",
                programName: "",
            },
            errorMessage: "",
            isValid: true,
            search: "",
            isLoading: false,
            selectedProgramId: "",
            saveErrorMessage: "",

        };
    },
    created: function () {
        this.reloadItems();
    },
    methods: {
        refresh: function () {
            this.reloadItems();
            this.errorMessage = "";
            this.saveErrorMessage = "";
        },
        reloadItems: function () {
            this.$data.isLoading = true;
            lookupsService.getShowTypesList().then((res: any) => {
                this.luItems = res.showTypes;
                this.programs = res.programs;
                this.$data.isLoading = false;

            });
            this.$data.isLoading = false;
        },
        initializeItem() {
            this.selectedProgramId = "";
            this.dialog = true;

        },

        editItem(item: ShowTypeLookupInfo) {
            this.editedIndex = this.luItems.indexOf(item)
            this.editedItem = Object.assign({}, item);
            this.selectedProgramId = this.editedItem.programId;
            this.dialog = true;
        },

        deleteItem(item: ShowTypeLookupInfo) {
            this.editedIndex = this.luItems.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.errorMessage = "";
            this.saveErrorMessage = "";
            if (!this.editedItem.canDelete) {
                this.closeDelete();
            }
            lookupsService.deleteShowType(this.editedItem.id).then((res) => {
                if (res.success == false) {
                    this.saveErrorMessage = res.messages.join(', ')
                    
                }
                else {
                    this.luItems.splice(this.editedIndex, 1);
                }

                this.reloadItems(); //in case something was changed after that pull

            }).catch((e: any) => {
                this.saveErrorMessage = "Unable to delete the ShowType.";
                return;
            });

            this.closeDelete();


        },

        close() {
            this.errorMessage = "";
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            this.errorMessage = "";
            this.saveErrorMessage = "";
            this.editedItem.id = this.editedItem.id.trim();
            if (this.editedItem.description.trim().length == 0 || this.editedItem.id.length == 0) {
                this.errorMessage = "Please enter valid values."
                return;
            }

            if (!/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/i.test(this.editedItem.id)) {
                this.errorMessage = "Please enter valid values."
                return;
            }

            if (this.editedItem.id.includes(' ')) {
                this.errorMessage = "Please enter valid values."
                return;
            }

            if (this.editedIndex == -1) {
                var exists = this.luItems.find((x: any) => x.id == this.editedItem.id);
                if (exists != null) {
                    this.errorMessage = "This Id already exists.";
                    return;
                }
                
            }
            
            if (this.selectedProgramId != null && this.selectedProgramId.length > 0) {
                var program = this.programs.find((x: any) => x.programId == this.selectedProgramId);
                if (program == null) {
                    this.errorMessage = "Invalid Program"; //possible out of sync
                    return;
                }
                this.editedItem.programName = program.programName;
                this.editedItem.programId = program.programId;
            }
            else {
                this.editedItem.programId = this.selectedProgramId;
                this.editedItem.programName = "";
            }
            
            

            lookupsService.editShowType(this.editedItem)
                .then((res) => {
                    if (res.success) {
                        if (this.editedIndex > -1) {
                            Object.assign(this.luItems[this.editedIndex], this.editedItem)
                        }
                        else {
                            if (this.luItems) {
                                this.luItems.unshift(this.editedItem)

                            }
                        }
                        this.close();
                    }
                    else {
                        this.saveErrorMessage = res.messages.join(', ')
                        this.reloadItems(); //in case something was changed after that pull
                    }
                })
                .catch((e) => console.log(e))
                .finally(() => this.$store.dispatch("changeLoaderStatus", false));

        },

    },
    computed: {
        formTitle() {
            return this.$data.editedIndex === -1 ? "New Item" : "Edit Item";
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

});
