export const validatePasswordLength = (password: string): boolean | string => !!password && password.length >= 8;

export const validatePasswordUpperChar = (password: string): boolean | string => !!password && /[A-Z]/.test(password);

export const validatePasswordNumber = (password: string): boolean | string => !!password && /\d/.test(password);

const passwordValidatorsWithMessages: Function[] = [
    (password: string) => !!password || 'Password is required',
    (password: string) => validatePasswordLength(password) || 'New password must contain a minimum of 8 characters',
    (password: string) => validatePasswordUpperChar(password) || 'New password must contain at least one uppercase character',
    (password: string) => validatePasswordNumber(password) || 'New password must contain at least one number',
];

const passwordValidators: Function[] = [
    (password: string) => !!password,
    (password: string) => validatePasswordLength(password),
    (password: string) => validatePasswordUpperChar(password),
    (password: string) => validatePasswordNumber(password),
];

export default passwordValidators;