
import Vue from 'vue';

import MemberForm from './Steps/MemberForm.vue';
import AussieTimeRegistration from './Steps/AussieTimeRegistrationRenewal.vue';
import SummaryPaymentInformation from './Steps/SummaryPaymentInformation.vue';
import FooterText from '@/components/common/FooterText.vue';

const logoImage = require('@/assets/logo.svg');
const createAccountImage = require('@/assets/create-account.png');

export default Vue.extend({
  components: { FooterText, MemberForm, AussieTimeRegistration, SummaryPaymentInformation },
  name: 'MemberRenewal',
  data: function () {
    return {
      logoImage,
      createAccountImage
    };
  },
  computed: {
    page(): number {
      return this.$store.state.registerRenewMembershipInfo.page;
    },
  }
});
