export type Parent = {
    personId?: number,
    firstName: string,
    lastName: string,
    memberId: string,
    isActiveMember: boolean,
}

export type JuniorHandler = {
    state: string,
    ageGroup: string,
    birthdate: Date,
    region: string,
    personId: number,
    adminNotes: string,
    notes: string,
    ageGroupId?: number,
    regionId?: number,
    parent1: Parent,
    parent2: Parent,
}

export type TitledDogs = {
    id: Number | null,
    dogId: string,
    dogName: string,
    showDate: Date | null,
    categoryId: string,
    juniorPersonId: Number | null
}

export type JuniorHandlingScores = {
    id: Number | null,
    juniorPersonId: Number | null,
    showDate: Date | null,
    classId: Number | null,
    numberCompeting: Number | null,
    place: Number | null,
    isBJH: boolean | null,
    isRJH: boolean | null,
    points: Number | null,
}

export type JuniorHandlingScoresResponse = {
    scores: JuniorHandlingScores[],
    currentYearPoints?: any,
    previousYearPoints?: any
}

export enum JrHandlerScoreType {
    Handling = 1,
    Obedience,
    Tracking,
    StockdogStandard,
    StockdogOpen,
    Stockdog,
    Rally
}