
import Vue from "vue";
import AffiliateAddress from "./Steps/AffiliateAddress.vue";
import AffiliateMembers from "./Steps/AffiliateMembers.vue";
import AffiliatePayment from "./Steps/AffiliatePayment.vue";
import AuthorizeNetResponseData from "@/types/AuthorizeNetResponseData";
import { UserInfo } from "@/types/User";
import workflowService from "@/services/portal/workflowService";
import registerAffiliateService from "@/services/portal/registerAffiliateService";
import reducePeopleToAffiliateMembers from "@/helpers/reduce-people-to-affiliate-members";

const registerDogImage = require('@/assets/register-dog.png');

export default Vue.extend({
  name: "RegisterAffiliate",
  components: {
    AffiliateAddress,
    AffiliateMembers,
    AffiliatePayment,
  },
  data: function () {
    return {
      registerDogImage,
      currentPage: 1,
      fee: 0,
      feeInfo: { fullMemberPrice: 0, serviceMemberPrice: 0 },
      clubName: "",
      affiliateWebsite: "",
      address: {
        street: "",
        city: "",
        state: "",
        zip: "",
        international: "",
        country: "",
      },
      termOfOffice: null as any,
      affiliatePeople: [],
      registrationRequestStatus: {},
      affiliateMemberTypes: {},
      membershipTypes: { Single: 1, Dual: 2, Affiliate: 3, Junior: 4, Service: 5, Lifetime: 6 },
    };
  },
  created: function () {
    registerAffiliateService.getAffiliateMemberTypes().then((res: any) => {
      this.affiliateMemberTypes = res.memberTypes;
    });
    workflowService.getRegisterAffiliateFees().then((res) => {
      if (!isNaN(res)) {
        this.feeInfo = { fullMemberPrice: res, serviceMemberPrice: res };
        this.fee = res;
      } else {
        this.feeInfo = res;
      }
    });
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    changePage(page: number) {
      this.currentPage = page;
    },
    changeFirstPageInfo(firstPageInfo: {
      clubName: string;
      address: any;
      termOfOffice: number;
      affiliateWebsite: string;
    }) {
      this.clubName = firstPageInfo.clubName;
      this.address = firstPageInfo.address;
      this.termOfOffice = firstPageInfo.termOfOffice;
      this.affiliateWebsite = firstPageInfo.affiliateWebsite;
    },
    changeAffiliatePeople(affiliatePeople: any) {
      this.affiliatePeople = affiliatePeople;
    },
    submitRequest(paymentInfo: AuthorizeNetResponseData, signature: string) {
      const request = {
        paymentInfo,
        requesterSignatureText: signature,
        requesterPersonId: this.userInfo.personId,
        affiliateName: this.$data.clubName,
        affAddress: this.$data.address,
        fee: this.fee,
        affiliateWebsite: this.$data.affiliateWebsite,
        termOfOffice: parseInt(this.$data.termOfOffice),
        affMembers: reducePeopleToAffiliateMembers(
          this.$data.affiliatePeople,
          this.$data.affiliateMemberTypes
        ),
      };

      registerAffiliateService.registerAffiliate(request).then((response) => {
        this.registrationRequestStatus = response;
      });
    },
  },
});
