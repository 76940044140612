
import Vue from "vue";
import CoownersList, { OwnershipType } from "@/components/common/CoownersList.vue";
import FooterText from "@/components/common/FooterText.vue";
import SimpleFeeTable from "@/components/common/SimpleFeeTable.vue";
import AddCoOwnerModal from "@/components/Portal/AddCoOwnerModal_Kennel.vue";

export default Vue.extend({
  name: "ChangeKennelOwnersForm1",
  data: function () {
    return {
      rulesDialog: false,
      coOwnerDialog: false,
    };
  },
  components: {
    CoownersList,
    AddCoOwnerModal,
    FooterText,
    SimpleFeeTable,
  },
  methods: {
    toggleRules: function () {
      this.$data.rulesDialog = !this.$data.rulesDialog;
    },
    toggleCoOwnerModal: function () {
      this.$data.coOwnerDialog = !this.$data.coOwnerDialog;
    },
  },
  computed: {
    kennelOwnershipType: function () {
      return OwnershipType.Kennel;
    },
  },
  props: {
    selectedKennel: Object,
    changePage: Function,
    searchMemberInfo: Object,
    changeCoOwnerInfo: Function,
    coOwnerInfo: Array,
    primaryOwner: Number,
    changePrimaryOwner: Function,
    priceInfo: Object,
  },
});
