
import Vue from 'vue'
import peopleService from "@/services/admin/peopleService";
import { UserInfo } from "@/types/User";
import { PortalUserInfo } from '@/types/admin/User';

export default Vue.extend({
    name: "PortalUserDetails",
    props: {
        personId: { type: Number },
    },
    data: function () {
      return {
          portalUserInfo: {} as PortalUserInfo,
      }
    },
    created: function () {
        if (this.personId) {
        peopleService
            .getPortalUserById(this.personId)
            .then((res) => {
            this.portalUserInfo = res;
            })
            .catch((e) => console.log(e));
        }
    },
    computed: {
        userInfo(): UserInfo {
            return this.$store.state.userInfo;
        },
    },
    watch: {
        personId: function (newVal: String) {
        peopleService
            .getPortalUserById(this.personId)
            .then((res) => {
                this.portalUserInfo = res;
            })
            .catch((e) => console.log(e));
        },
        
    },
    methods: {
        changePortalUserRole: function () {
            peopleService
                .changePortalUserRole(this.personId)
                .then((res) => {
                    this.portalUserInfo = res;
                })
                .catch((e) => console.log(e));

        }
    },
})
