
import Vue from "vue";
import adminService, {
  AdminSearchCoownersResult,
} from "@/services/adminService";
import { debounce } from "throttle-debounce";

export default Vue.extend({
  name: "AdminAddCoownerDialog",
  data: function () {
    return {
      showDialog: this.$props.peopleSearchDialog,
      hasSearched: false,
      searchResults: [] as any[],
      selectedPeople: this.$props.selectedPeopleList
        ? [...this.$props.selectedPeopleList]
        : [],
      membershipID: "",
      nameSearchText: "",
      judgeId: "",
      headers: [
        { text: "Name", align: "left", value: "name" },
        { text: "Member ID", align: "left", value: "memberId" },
      ],
      on: "",
      attrs: "",
      isLoading: false,
    };
  },
  watch: {
    peopleSearchDialog: function (newVal: boolean) {
      if (!newVal) {
        this.membershipID = "";
        this.hasSearched = false;
        this.searchResults = [] as AdminSearchCoownersResult[];
      }
      this.showDialog = newVal;
    },
    selectedPeopleList: function (newVal: any) {
      this.selectedPeople = newVal;
    },
  },
  methods: {
    search: function () {
      this.isLoading = true;
      this.hasSearched = false;
      adminService
        .adminSearchCoowners({
          memberId: this.$data.membershipID,
          nameSearchText: this.$data.nameSearchText, 
          judgeId: this.$data.judgeId
      })
        .then((response: any) => {
          this.isLoading = false;
          this.hasSearched = true;
          this.searchResults = response;
        });
    },
    isActivemember(coowner: AdminSearchCoownersResult) {
      return coowner.isActiveMember;
    },
    addLessee(row: any) {
      if (this.selectedPeople.length >= 6) return;
      if (this.selectedPeople.some((el) => el.personId === row.personId)) return;
      if (!this.isActivemember(row)) return;
      this.searchResults = [] as AdminSearchCoownersResult[];
      this.hasSearched = false;
      this.membershipID = "";      
      this.$emit("personAdded", { personId: row.personId });
      this.selectedPeople.push(row);
    },
    removeLessee(row: any) {
      this.selectedPeople.splice(this.selectedPeople.indexOf(row), 1);
      this.changeSelectedPeopleInfo(this.selectedPeople);
    },
    submit() {
      this.changeSelectedPeopleInfo(this.selectedPeople);
      this.showDialog = false;
    },
  },
  props: {
    changeSelectedPeopleInfo: Function,
    peopleSearchDialog: Boolean,
    toggleModal: Function,
    selectedPeopleList: Array,
  },
});
