
import Vue from "vue";

import juniorHelpers from "@/helpers/validate-junior-age";

import { UserInfo } from "@/types/User";

import AddCoOwnerModal from "@/components/Portal/AddCoOwnerModal_QTDog.vue";
import FooterText from "@/components/common/FooterText.vue";
import ASCAArtIncLink from "@/components/common/ASCAArtIncLink.vue";
import BylawsLink from "@/components/common/BylawsLink.vue";

export default Vue.extend({
  name: "OwnerInformation",
  components: { FooterText, AddCoOwnerModal, ASCAArtIncLink, BylawsLink },
  props: {
    changePage: Function,
    changeCoOwnerInfo: Function,
    registerMember: Boolean,
    renewMember: Boolean,
    ownerInfo: Object,
    coOwnerInfo: Array,
  },
  data: function () {
    return {
      agreementDate: new Date(),
      coOwnerDialog: false,
    }
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    toggleCoOwnerModal: function () {
      this.$data.coOwnerDialog = !this.$data.coOwnerDialog;
    },
    isValid() {
      return !this.registerMember && !this.renewMember && !!this.ownerInfo.signature;
    },
    isUnder18() {
      return this.userInfo != null
        && this.userInfo.birthDate != null
        && juniorHelpers.isUnder18(this.userInfo.birthDate);
    },
    addComma(targetValue: string | null, afterValue: string | null) {
      if (!targetValue) return '';
      else if (!!afterValue) return `${targetValue},`;
      else return targetValue;
    }
  }
});
