import { MemberType } from '@/types/Member';

const canViewSummaryItem = (memberType: MemberType | null, hasNeverBeenMember: boolean, forMembers?: boolean | MemberType[]): boolean => {
  if (hasNeverBeenMember) return !forMembers;

  return !forMembers || (!!forMembers && typeof forMembers !== 'boolean'
      ? forMembers?.includes(memberType as any)
      : forMembers);
};

export default canViewSummaryItem;