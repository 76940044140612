
import Vue from 'vue';
import LitterRegistrationRequest from "@/components/Portal/MyRequest/LitterRegistrationRequest.vue";
import RegistrationRequestLayout from "@/components/common/RegistrationRequestLayout.vue";
import workflowService from "@/services/admin/workflowService";

export default Vue.extend({
  name: 'LitterRequestCard',
  props: {
    requestId: { type: Number },
  },
  components: {
    LitterRegistrationRequest,
    RegistrationRequestLayout
  },
  data: function() {
      return {
          registration: {
              workflowGuid: "",
              requestId: 0
          } as any,
      };
  },
  computed: {
    workflowService() {
      return workflowService;
    }
  },
  methods: {
    onRequestProcessed: function (requestId: number) {
      this.$router.push({ path: `/admin/registration/litters` });
    },
  }
});
