
import Vue from "vue";
import FilterPills from "./FilterPills.vue";
import DogCard from "@/components/Portal/UserFeedCards/DogCard.vue";
import FeedCard from "@/components/Portal/UserFeedCards/FeedCard.vue";

import dogService from "@/services/dogService";
import leaseService from "@/services/portal/leaseService";
import personService from "@/services/portal/personService";

import { UserInfo } from "@/types/User";
import { DogSearchResult } from "@/types/portal/Person";

const defaultImage = require('@/assets/photouploadblue.png');

type SearchMiniCard = {
  image: string;
  title: string;
  link?: string;
  buttonText: string;
  action: boolean;
  dogId?: string;
  isFavorite?: boolean;
  titleLink?: string;
};

export default Vue.extend({
  name: "UserFeed",
  data: function () {
    return {
      pills: [
        { name: "FAVORITES", active: true, value: "isFavorite" },
        { 
          name: "OWNED", 
          active: false, 
          tooltip:
            "Dog is categorized as owned if you're their primary owner. You can register lease and litter for those dogs, and are the primary contact person for the ASCA office.",
          value: "isOwned" 
        },
        { name: "CO-OWNED", active: false, value: "isCoOwned" },
        {
          name: "INACTIVE",
          active: false,
          tooltip: "Dog is categorized as inactive if the owner informed ASCA it has deceased or if the dog is older than 20 years.",
          value: "isInactive",
        },
        {
          name: "ACTIVELY COMPETING",
          active: false,
          tooltip:
            "Actively competing dogs have show results within the last 2 ASCA show years.",
          value: "isActivelyCompeting",
        },
        { 
          name: "ALL", 
          active: false,
          tooltip:
            "All your dogs: owned and co-owned, including inactive.", 
          value: "all" 
        },
        { name: "DOGS LEASED TO ME", active: false, value: "isLeased" },
        { name: "DOGS BRED BY ME", active: false, value: "isBredByMe" },
      ],
      dogResult: new Array<DogSearchResult>(),
      filteredDogResult: new Array<DogSearchResult>(),
      selectedDog: "",
      snackbar: false,
      snackbarText: "",
      snackbarColor: "green",
      searchText: "",
    };
  },
  computed: {
    dogCards(): Array<SearchMiniCard> {
      return (
        this.filteredDogResult?.map(
          (d) =>
            <SearchMiniCard>{
              image: defaultImage,
              title: `${d.dogId} ${d.name}`,
              dogId: d.dogId,
              action: true,
              buttonText: "View",
              isFavorite: d.isFavorite,
              titleLink: `/dogs/${encodeURIComponent(d.dogId)}`
            }
        ) || new Array<SearchMiniCard>()
      );
    },
    activeValue(): any {
      let value = "";
      this.pills.forEach((element) => {
        if (element.active) value = element.value;
      });
      return value;
    },
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    toggle: function (index: number) {
      this.pills.forEach((element, idx) => {
        if (idx === index) {
          element.active = true;
        } else {
          element.active = false;
        }
      });
    },
    handleSwitchDog: function (value: any) {
      this.selectedDog = value.dogId;
    },
    handleChangeFavorite: function (value: any) {
      dogService
        .changeDogFavoriteStatus(value.dogId, this.userInfo.personId)
        .then((res) => {
          if (res.success) {
            this.snackbar = true;
            this.snackbarColor = "green";
            this.snackbarText = "Successfully updated dog's favorite status";
            const changedIdx = this.dogResult.findIndex(
              (x) => x.dogId === value.dogId
            );
            const tempDogs = this.dogResult;
            this.dogResult[changedIdx].isFavorite =
              !this.dogResult[changedIdx].isFavorite;
            this.dogResult = tempDogs;
          } else {
            this.snackbar = true;
            this.snackbarColor = "red";
            this.snackbarText = res.errorMessage
              ? res.errorMessage
              : "Error updating dog's favorite status";
          }
        });
    },
  },
  created: function () {
    if (this.userInfo) {
      personService
        .getDogsByOwner({
          personId: this.userInfo.personId,
          [this.activeValue]: true,
        })
        .then((res) => {
          this.dogResult = res;
          if (res && res.length > 0) this.selectedDog = res[0].dogId;
        })
        .catch((e) => console.log(e));
    }
  },
  watch: {
    userInfo: function (newVal: object) {
      personService
        .getDogsByOwner({
          personId: this.userInfo.personId,
          [this.activeValue]: true,
        })
        .then((res) => {
          this.dogResult = res;
          if (res[0]) this.selectedDog = res[0].dogId;
        })
        .catch((e) => console.log(e));
    },
    activeValue(newVal) {
      if (newVal === "isLeased") {
        leaseService
          .getLeasedDogsByOwner(this.userInfo.personId)
          .then((res) => {
            console.log(res.data);
            this.dogResult = res.data;
            if (res[0]) this.selectedDog = res[0].dogId;
          })
          .catch((e) => console.log(e));
      } else {
        personService
          .getDogsByOwner({ personId: this.userInfo.personId, [newVal]: true })
          .then((res) => {
            this.dogResult = res;
            if (res[0]) this.selectedDog = res[0].dogId;
          })
          .catch((e) => console.log(e));
      }
    },
    searchText(newVal) {
      const val = newVal.toLowerCase().replace(/ /g, "");
      if (val) {
        this.filteredDogResult = this.dogResult.filter(
          (dog) =>
            dog.name.toLowerCase().replace(/ /g, "").includes(val) ||
            dog.dogId.toLowerCase().replace(/ /g, "").includes(val)
        );
      } else {
        this.filteredDogResult = this.dogResult;
      }
      if (this.filteredDogResult[0]) {
        this.selectedDog = this.filteredDogResult[0].dogId;
      }
    },
    dogResult(newVal) {
      this.filteredDogResult = newVal;
      this.searchText = "";
    },
  },

  components: {
    FilterPills,
    FeedCard,
    DogCard,
  },
});
