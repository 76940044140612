
import Vue from 'vue';

import CountryDropdown from '@/components/common/inputs/CountryDropdown.vue';
import StateDropdown from '@/components/common/inputs/StateDropdown.vue';
import FooterText from '@/components/common/FooterText.vue';
import HostedRegistrationPayment from '@/components/common/HostedRegistrationPayment.vue';

import userService from '@/services/portal/userService';
import memberService from '@/services/portal/memberService';
import AuthorizeNetResponseData from '@/types/AuthorizeNetResponseData';

const logoImage = require('@/assets/logo.svg');

export default Vue.extend({
  components: { FooterText, CountryDropdown, StateDropdown, HostedRegistrationPayment },
  name: 'AussieTimeRegistrationRenewal',
  data() {
    return {
      logoImage,
      isProcessing: false,
      errorMessage: '',
      showSuccess: false,

      country: "UNITED STATES",
      address: "",
      international: "",
      city: "",
      stateProvince: "",
      zipPostal: "",
      aussieTimesDisplayPrice: "",
      isValidAddress: false,
      isValidCard: false,
      subscribe: false,
      firstClass: false,
    }
  },
  watch: {
    membership: function(newValue) {
      if (newValue) {
        const plan = this.plan;

        let aussieTimesDisplayPrice = "";

        if (!!plan.aussieTimesDisplayPrice) {
          aussieTimesDisplayPrice = `(${plan.aussieTimesDisplayPrice})`;
        }

        this.aussieTimesDisplayPrice = aussieTimesDisplayPrice;
      }
    },
    primary: function(newValue) {
      if (newValue) {
        const primary = this.primary;

        this.country = primary.country;
        this.address = primary.address;
        this.international = primary.international;
        this.city = primary.city;
        this.stateProvince = primary.stateProvince;
        this.zipPostal = primary.zipPostal;
      }
    }
  },
  computed: {
    membership() {
      return this.$store.state.registerRenewMembershipInfo;
    },
    primary() {
      return this.$store.state.registerRenewMembershipInfo.primary;
    },
    plan() {
      return this.$store.state.registerRenewMembershipInfo.plan;
    },
    standardPrice() {
      const aussieTimeFees = this.$store.state.registerRenewMembershipInfo.aussieTimeFees;

      if (!!aussieTimeFees && this.country === 'CANADA') {
        return aussieTimeFees.surfacePostageCanada;
      } else if (!!aussieTimeFees && this.country !== 'UNITED STATES') {
        return aussieTimeFees.surfacePostageOther;
      }

      return 0;
    },
    firstClassPrice() {
      const aussieTimeFees = this.$store.state.registerRenewMembershipInfo.aussieTimeFees;

      let price = 25;

      if (!!aussieTimeFees && (this.country === 'UNITED STATES' || this.country === 'CANADA')) {
        return aussieTimeFees.firstClassNorthAmericas;
      } else if (!!aussieTimeFees) {
        return aussieTimeFees.firstClassOther;
      }

      return price;
    },
  },
  methods: {
    standardText() {
      const aussiePrice = `1-Year Subscription to Aussie Times ${!!this.plan.aussieTimesPrice ? `($${this.plan.aussieTimesPrice}/yr)` : ''}`;
      const standardPostage = `Standard Postage ${!!this.standardPrice ? `($${this.standardPrice})` : ''}`;

      return `${aussiePrice} + ${standardPostage}`;
    },
    firstClassText() {
      const aussiePrice = `1-Year Subscription to Aussie Times ${!!this.plan.aussieTimesPrice ? `($${this.plan.aussieTimesPrice}/yr)` : ''}`;
      const firstClassPostage = `First Class Postage ($${this.firstClassPrice})`;

      return `${aussiePrice} + ${firstClassPostage}`;
    },
    validateCity(v: string) {
      return this.country === 'UNITED STATES'
        ? !!v || 'City is required'
        : true
    },
    validateZip(v: string) {
      return this.country === 'UNITED STATES'
        ? !!v || 'Zip/Postal is required'
        : true
    },
    updateCountry: async function (v: string) {
      this.country = v;

      await Vue.nextTick();

      (this.$refs.addressForm as any).validate();
    },
    goBack() {
      this.$store.commit('changeRegisterRenewMembershipInfo', { page: 1 });
    },
    returnHome() {
      this.$router.push('/');
    },
    next: function() {
      const membership = this.$store.state.registerRenewMembershipInfo;

      const data = {
        ...membership.primary,
        country: this.country,
        address: this.address,
        international: this.international,
        city: this.city,
        stateProvince: this.stateProvince,
        zipPostal: this.zipPostal,
      };

      this.$store.commit('changeRegisterRenewMembershipInfo', {
        page: 3,
        subscribe: this.subscribe,
        firstClass: this.firstClass,
        primary: { ...data }
      })
    },
    pay: async function (data: AuthorizeNetResponseData) {
      this.isProcessing = true;

      try {
        const response = await memberService.renewAussieTimes({
          country: this.country,
          address: this.address,
          international: this.international,
          city: this.city,
          stateProvince: this.stateProvince,
          zipPostal: this.zipPostal,
          firstClassSubscription: this.firstClass,
          fee: this.plan.aussieTimesPrice + (this.firstClass ? this.firstClassPrice : this.standardPrice),
          paymentInfo: data
        });

        if (response.success) {
          const newUserInfo = await userService.getProfile();

          this.$store.commit('loadUserInfo', newUserInfo);

          this.isProcessing = false;
          this.showSuccess = true;
        } else {
          this.isProcessing = false;
          this.errorMessage = response.errors ? response.errors.join(',') : 'An Unknown Error Occured';
        }
      } catch (ex) {
        console.log(ex);

        this.isProcessing = false;
        this.errorMessage = 'An Unknown Error Occured';
      }

      this.isProcessing = false;
    },
  },
});
