import { api } from "../api";

export const getLitterById = async (litterId: number): Promise<any> => {
  const { data } = await api.get<object>(`/Litter/litters/${litterId}`);

  return data;
};

export const getLittersByOwner = async (personId: number): Promise<any> => {
  const { data } = await api.get<object>(`/Litter/mylitters/${personId}`);

  return data;
};

export default {
  getLitterById,
  getLittersByOwner,
};
