import Vue from 'vue'
import Vuex from 'vuex'
import adminService, { AdminTasksSummary } from '../services/adminService';
import personService from '../services/portal/personService';
import viewModelsService from '../services/viewModels';

import { PersonTasksSummary } from '@/types/portal/Person';

const vue = Vue.use(Vuex)

enum AgreementFor
{
  NewAccount = "NewAccount",
  VerifyJunior = "VerifyJunior",
  ExistingAccount = "ExistingAccount",
}

export default new Vuex.Store({
  state: {
    userInfo: null as any,
    showAdminPersonalAccount: false,
    adminTasksSummary: <AdminTasksSummary>{},
    personTasksSummary: <PersonTasksSummary>{},
    registrationInfo: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      selectedPlan: -1,
      billingInfo: {
        firstName: '',
        lastName: '',
        cardNumber: '',
        expirationDate: '',
        securityCode: '',
        billingZipCode: '',
        signature: '',
      }
    },
    selectedPlan: -1,
    searchMemberInfo: null,
    selectedKennel: {},
    showLoader: false,
    // User Profile State - TODO: Would like to separate this stuff into different modules
    agreementFor: <AgreementFor>"NewAccount",
    recoverInfo: {
      email: '',
      username: '',
      password: '',
      confirmPasword: ''
    },
    parentVerification: {
      parentId: '',
      juniorId: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      affirm: false,
      acknowledgeSignature: false,
      agreeToAuthorize: false,
      acknowledgeExpiration: false,
    },
    registerRenewMembershipInfo: {
      page: 1,
      isRenew: false,
      isAussieRenewal: true,
      plan: null,
      aussieTimeFees: null,
      subscribe: false,
      firstClass: false,
      primary: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        country: "UNITED STATES",
        address: "",
        international: "",
        city: "",
        stateProvince: "",
        zipPostal: "",
      },
      secondary: null,
      juniors: [],
    },
  },
  mutations: {
    loadUserInfo(state, payload){
      state.userInfo = payload;
    },
    showAdminPersonalAccount(state, payload){
      state.showAdminPersonalAccount = payload.showAdminPersonalAccount;
    },
    changeAdminTasksSummary(state, payload){
      state.adminTasksSummary = payload;
    },
    changePersonTasksSummary(state, payload){
      state.personTasksSummary = payload;
    },
    changeSelectedPlan(state, payload) {
      state.registrationInfo.selectedPlan = payload.newPlan;
    },
    changeSearchMemberInfo(state, payload) {
      state.searchMemberInfo = payload;
    },
    changeRegistrationInfo(state, payload) {
      state.registrationInfo = payload;
    },
    changeLoaderStatus(state, payload) {
      state.showLoader = payload;
    },
    changeParentVerification(state, payload) {
      state.agreementFor = AgreementFor.VerifyJunior;
      state.parentVerification = payload;
    },
    changeRegisterRenewMembershipInfo(state, payload) {
      state.registerRenewMembershipInfo = {
        ...state.registerRenewMembershipInfo,
        ...payload
      };
    },
    resetRegisterRenewMembershipInfo(state) {
      state.registerRenewMembershipInfo = {
        page: 1,
        isRenew: false,
        isAussieRenewal: true,
        plan: null,
        aussieTimeFees: null,
        subscribe: false,
        firstClass: false,
        primary: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          country: "UNITED STATES",
          address: "",
          international: "",
          city: "",
          stateProvince: "",
          zipPostal: "",
        },
        secondary: null,
        juniors: [],
      };
    },
    changeAgreementFor(state, payload) {
      state.agreementFor = payload;
    },
    changeRecoverInfo(state, payload) {
      state.recoverInfo = { ...payload };
    },
  },
  actions: {
    loadAdminTasks: async({ commit }) => {    
      commit('changeLoaderStatus', true);
      let data = await adminService.getTasksSummary();
      let vm = viewModelsService.getMenuLinkSummariesForAdmin(data);
      commit('changeAdminTasksSummary', vm);   
      commit('changeLoaderStatus', false);
    },
    loadPersonTasks: async({ commit }, personId) => {      
      commit('changeLoaderStatus', true);
      let data = await personService.getTasksSummary(personId);
      let vm = viewModelsService.getMenuLinkSummariesForPerson(data);
      commit('changePersonTasksSummary', vm);    
      commit('changeLoaderStatus', false);
    },
    changeLoaderStatus: async({ commit }, status) => {      
      commit('changeLoaderStatus', status);
    },
  },
  modules: {},
});
