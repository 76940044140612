
import Vue from 'vue';
import { UserInfo } from "@/types/User";

export default Vue.extend({
  name: 'RegistrationRequestLayout',
  props: {
    requestId: { type: Number },
    onRequestProcessed: { type: Function },
    denyRegistrationRequest: { type: Function },
    approveRegistrationRequest: { type: Function },
    getRegistrationRequestById: { type: Function },
    setRegistrationData: { type: Function },
    signSignatureByAdminFn: { type: Function },
    markPaidByAdminFn: { type: Function }
  },
  components: {
  },
  data: function() {
      return {
          showDenialReasonDialog: false,
          showProvideSignatureNotes: false,
          showProvidePaymentNotes: false,
          reason: "",
          signatureNotes: "",
          adminPaymentValid: false,
          paymentNotes: "",
          paymentType: 1,
          paymentTypes: [
            {
                id: 1,
                description: "Credit Card Online Payment",
            },
            {
                id: 2,
                description: "Credit Card Office Payment",
            },
            {
                id: 3,
                description: "Check",
            },
            {
                id: 4,
                description: "Wire Transfer",
            },
            {
                id: 5,
                description: "Cash",
            },
            {
                id: 6,
                description: "PayPal",
            },
            {
                id: 7,
                description: "Other",
            }
          ],
          signatureId: null,
          processingRequest: false,
          registration: { } as any,
          isLoading: false,
      };
  },
  created() {
      this.isLoading = true;
      this.getRegistrationRequestById(this.requestId).then((res: { data: any; }) => {
          this.isLoading = false;
          this.registration = res.data;
          this.setRegistrationData(res.data);
      });
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
  watch: {
    requestId: function (newValue) {
      this.isLoading = true;
      this.getRegistrationRequestById(newValue).then((res: any) => {
          this.isLoading = false;
          this.registration = res;
      });
    }
  },
  methods: {
    approveRequest: function() {
      this.processingRequest = true;
      this.approveRegistrationRequest(this.registration.workflowGuid, this.registration.requestId).then((res: any) => {
        this.onRequestProcessed(this.registration.requestId);
        this.processingRequest = false;
      }).catch((e: any) => {
        console.log(e); 
        this.processingRequest = false;
      });
    },
    provideDenialReason: function () {
        this.$data.showDenialReasonDialog = true;
    },
    provideSignatureNotes: function (signatureId: number){
        this.$data.showProvideSignatureNotes = true;
        this.$data.signatureId = signatureId;
    },
    denyRequest: function() {
      this.showDenialReasonDialog = false;
      this.processingRequest = true;
      this.denyRegistrationRequest(this.registration.workflowGuid, this.registration.requestId, this.$data.reason).then((res: any) => {
        this.onRequestProcessed(this.registration.requestId);
        this.processingRequest = false;
      }).catch((e: any) => {
        console.log(e); 
        this.processingRequest = false;
      });
    }, 
    signSignatureByAdmin: function(){
        this.processingRequest = true;

        this.signSignatureByAdminFn(this.registration.workflowGuid, this.signatureId, this.userInfo.personId, this.signatureNotes).then((res: any) => {
            this.$data.signatureNotes = "";
            this.$data.signatureId = null;
            this.$data.showProvideSignatureNotes = false;
            this.getRegistrationRequestById(this.requestId).then((res: { data: any; }) => {
                this.isLoading = false;
                this.registration = res.data;
                this.setRegistrationData(res.data);
            });
        });
        
        this.processingRequest = false;
    },
    markPaidByAdmin: function() {
        this.processingRequest = true;
        
        this.markPaidByAdminFn(this.registration.workflowGuid, this.userInfo.personId, this.paymentNotes, this.paymentType).then((res: any) => {
            this.$data.paymentType = 1;
            this.$data.paymentNotes = "";
            this.$data.adminPaymentValid = false;
            this.$data.showProvidePaymentNotes = false;
            this.getRegistrationRequestById(this.requestId).then((res: { data: any; }) => {
                this.isLoading = false;
                this.registration = res.data;
                this.setRegistrationData(res.data);
            });
        });

        this.processingRequest = false;
    }
  },
});
