
import Vue from "vue";

export default Vue.extend({
  name: "SignatureStatusList",
  methods: {
    tooltipText(isSigned: boolean, isSignedByAdmin: boolean){
        if(isSigned) {
            if(isSignedByAdmin) {
                return "Signature received - signed by admin"
            }
            return "Signature received"
        }
        return "Signature has not been received"
      }
  },
  props: {
    hideHeader: { type: Boolean },
    signatories: { type: Array },
  },
});
