
import Vue from "vue";

export default Vue.extend({
  name: "RegisterPuppy",
  data: function () {
    return {

    };
  },
  props: {
    puppyId: { type: Number },
  },
});
