import { api } from "./api";

export type AdminSearchCoownersResult = {
    personId: number;
    memberId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    membershipLengthInYears: number;
    memberType?: number;
    isActiveMember: boolean;
};

export type AdminTasksSummary = {  
    lepOtherRegistrations: number,
    individualDogRegistrations: number,
    qtDogRegistrations: number,
    kennelRegistrations: number,
    kennelOwnersChangeRegistrations: number,
    litterRegistrations: number,
    leaseRegistrations: number,
    puppyReleases: number,
    eventsSanctions: number,
    affiliateRegistrations: number,
    showResults: number,
    hofRequests: number
}

export type KennelRequest = {
    workflowGuid: string,
    requestId: number,
    firstName: string,
    secondName: string,
    submittedAt: string,
    paymentAuthorized: boolean,
    primaryOwner: object,
    coOwners: object[],
    events: object[],
    status: string
}

export type ValueGroup = {
    id: number,
    description: string,
}

export const adminSearchCoowners = async (params: object): Promise<any> => {
    const { data } = await api.get<object[]>(`/Admin/owners`, 
        { 
            params: params
        }
    );
    return data;
};

export const getPaymentTypes = async (): Promise<any> => {
    const { data } = await api.get<object[]>(`/Admin/payments/types`);

    return data;
};

export const getPayments = async (): Promise<any> => {
    const { data } = await api.get<object[]>(`/Admin/payments`);

    return data;
};

export const getTasksSummary = async (): Promise<AdminTasksSummary> => {    
    const { data } = await api.get<AdminTasksSummary>("/Admin/tasks");

    return data;
};

export const getPrograms = async (): Promise<any> => {
    const { data } = await api.get<any>(`/Admin/programs`);

    return data;
}

export default {
    getTasksSummary,
    getPaymentTypes,
    getPayments,
    getPrograms,
    adminSearchCoowners,
};