
import Vue from "vue";
import dogService from "@/services/dogService";
import { UserInfo } from "@/types/User";
export default Vue.extend({
  name: "ShowResults",
  props: {
    dogId: String,
  },
  data: function () {
    return {
      showResultsHeaders: [
        { text: "Show Date", align: "left", value: "showDate", width: "100px" },
        { text: "Show ID", align: "left", value: "showId", width: "100px" },
        { text: "Descr.", align: "left", value: "description", width: "100px" },
        { text: "Category", align: "left", value: "category", width: "50px" },
        { text: "Division", align: "left", value: "division", width: "50px" },
        { text: "Score", align: "left", value: "score", width: "50px" },
        {
          text: "Transferred to ACE",
          align: "left",
          value: "transferredToAce",
        },
        { text: "Pt Sched.", align: "left", value: "ptSched" },
        { text: "# Competing", align: "left", value: "nCompeting" },
        { text: "Judge", align: "left", value: "judge" },
        { text: "Handler", align: "left", value: "handler" },
      ],
      filteredResults: [],
      search: "",
    };
  },
  methods: {},
  created: function () {
    dogService
      .getDogShowResultsById(this.dogId, this.userInfo.personId)
      .then((res) => {
        this.filteredResults = res.data;
      })
      .catch((e) => console.log(e));
  },
  watch: {
    dogId: function (newVal: string) {
      dogService
        .getDogShowResultsById(newVal, this.userInfo.personId)
        .then((res) => {
          this.filteredResults = res.data;
        })
        .catch((e) => console.log(e));
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
});
