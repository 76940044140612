
import Vue from "vue";
import FooterText from "@/components/common/FooterText.vue";

export default Vue.extend({
  components: { FooterText },
  name: "LeaseRequestForm2",
  data: function () {
    return {};
  },
  methods: {},
  watch: {
    dates: function (newVal: string[], oldVal: string[]) {
      if (newVal === oldVal) return;
      this.$props.changeDates(newVal);
    },
  },
  computed: {
    isDisabled: function () {
      return (
        this.dates[0] === "" ||
        this.dates[1] === "" ||
        !this.validationInfo.success
      );
    },
  },
  props: {
    changePage: Function,
    dates: Array,
    changeDates: Function,
    validationInfo: Object,
  },
});
