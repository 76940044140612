
import Vue from 'vue'
export default Vue.extend({
    name: 'CardHeader',
    props: {
        createdBy: String,
        category: String,
        createdTime: Date,
    }
})
