
import Vue from "vue";
import Signature from "@/components/common/inputs/Signature.vue";
import { UserInfo } from "@/types/User";

export default Vue.extend({
  name: 'SignatureLayout',
  components: { Signature },
  props: {
    signatureId: Number,
    workflowGuid: String,
    submitSignature: Function,
    foundRequest: Boolean,
    isLoading: Boolean,
    submittedAt: String,
    errorMessage: String,
  },
  data: function() {
    return {
      signatureText: ""
    };
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    sign: function() {
      this.submitSignature(
        this.workflowGuid,
        this.signatureId,
        this.userInfo.personId,
        this.$data.signatureText
      );

      this.$store.dispatch("loadPersonTasks", this.userInfo.personId);
    },
  },
});
