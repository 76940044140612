import { api } from "../api";
import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";

export const getKennelsList = async (
    options: PaginationOptions,
    params: object,
): Promise<PaginatedResponse<object>> => {  
    const { page, itemsPerPage, sortBy, sortDesc } = options;

    const sort = !!sortBy.length ? sortBy[0] : 'requestId';
    const asce = !!sortDesc.length ? !sortDesc[0] : true;

    const { data } = await api.get<PaginatedResponse<object>>(`/Admin/kennels?pageSize=${itemsPerPage}&page=${page}&ascending=${asce}&sort=${sort}`, { params });

    return data;
};

export const getKennelById = async (kennelId: string): Promise<any> => {
    const { data } = await api.get<object>(`/Admin/kennels/details/${kennelId}`);
    return data;
};

export const validateKennelEdit = async (request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/kennels/validate`, request);
    return data;
};

export const editKennelById = async (request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/kennels/details/edit`, request);
    return data;
};

export const getSimilarKennelNames = async (searchPhrase: string): Promise<string[]> => {    
    const { data } = await api.get<string[]>(`/Admin/kennels/${searchPhrase}`);
    return data;
};

export const adminSearchKennels = async (params: object): Promise<any> => {
    const { data } = await api.get<object[]>(`/Admin/kennels`, { params: params });
    return data;
};

export default {
    getSimilarKennelNames,
    adminSearchKennels,
    getKennelsList,
    getKennelById,
    validateKennelEdit,
    editKennelById,
}