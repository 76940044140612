
import Vue from "vue";
import SignatureLayout from "@/components/common/SignatureLayout.vue";
import puppyReleaseService from '@/services/puppyReleaseService';
import { UserInfo } from "@/types/User";

export default Vue.extend({
  name: 'PuppyReleaseSignature',
  components: {
      SignatureLayout
  },
  data: function() {
      return {
          signatureText: "",
          registration: {} as Object,
          isLoading: false
      };
  },
  props: {
    signatureId: { type: Number },
  },
  methods: {
      signPuppyRelease: function(workflowGuid: String, signatureId: number, personId: number, text: String) {
          puppyReleaseService.signPuppyRelease(
               signatureId,
               text
          ).then(res => {
            this.$router.push('/');
      }).catch(e => 
            this.$router.push('/'));
      }
  },
  created() {
      this.isLoading = true;
      puppyReleaseService.getPuppyReleaseDetailsForSignature(this.signatureId).then(res => {
          this.isLoading = false;
          this.registration = res.data;
          console.log(res)
      });
  },
  watch: {
    signatureId: function (newValue) {
      this.isLoading = true;
      puppyReleaseService.getPuppyReleaseDetailsForSignature(this.signatureId).then(res => {
          this.isLoading = false;
          this.registration = res.data;
          console.log(res)
      });
    }
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  }
});
