
import Vue from "vue";
import lookupsService from "@/services/admin/lookupsService";
import { LookupInfo } from "@/types/admin/Lookup";

export default Vue.extend({
    name: "BasicLUMaintenance",
    data: function () {
        return {
            tab: null,
            luItems: [] as LookupInfo[],
            tdialog: false,
            dialog: false,
            dialogDelete: false,
            headers: [
                { text: "ID", align: "left", value: "id" },
                { text: "Description", align: "left", value: "description" },
                { text: "Actions", value: "actions", sortable: false },
            ],
            editedIndex: -1,
            editedItem: {
                id: "",
                description: "",
                canDelete: true,
            },
            defaultItem: {
                id: "",
                description: "",
                canDelete: true,
            },
            errorMessage: "",
            isValid: true,
            search: "",
            isLoading: false,

        };
    },
    created: function () {
        this.refresh();
    },
    methods: {
        refresh: function () {
            if (this.luType == 'Eyes') {
                this.$data.isLoading = true;
                lookupsService.getEyeColors().then((res: LookupInfo[]) => {
                    this.luItems = res;
                    this.$data.isLoading = false;
                });

            }
            else if (this.luType == 'Body') {
                this.$data.isLoading = true;
                lookupsService.getBodyColors().then((res: LookupInfo[]) => {
                    this.luItems = res;
                    this.$data.isLoading = false;
                });
            }
            else if (this.luType == 'Trim') {
                this.$data.isLoading = true;
                lookupsService.getTrimColors().then((res: LookupInfo[]) => {
                    this.luItems = res;
                    this.$data.isLoading = false;
                });
            }
            else if (this.luType == 'Breeds') {
                this.$data.isLoading = true;
                lookupsService.getBreeds().then((res: LookupInfo[]) => {
                    this.luItems = res;
                    this.$data.isLoading = false;
                });
            }
        },
        editItem(item: LookupInfo) {
            this.editedIndex = this.luItems.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item: LookupInfo) {
            this.editedIndex = this.luItems.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.errorMessage = "";
            if (this.editedItem.canDelete) {
                if (this.luType == 'Eyes') {
                    lookupsService.deleteEyeColor(this.editedItem.id).then((res) => {
                        if (!res.success) {
                            this.errorMessage = "Unable to delete color"
                            return;
                        }

                    }).catch((e: any) => {
                        this.errorMessage = "Unable to delete the color.";
                        return;
                    });
                }
                else if (this.luType == 'Body') {
                    lookupsService.deleteBodyColor(this.editedItem.id).then((res) => {
                        if (!res.success) {
                            this.errorMessage = "Unable to delete color"
                            return;
                        }

                    }).catch((e: any) => {
                        this.errorMessage = "Unable to delete the color.";
                        return;
                    });

                }
                else if (this.luType == 'Trim') {
                    lookupsService.deleteTrimColor(this.editedItem.id).then((res) => {
                        if (!res.success) {
                            this.errorMessage = "Unable to delete color"
                            return;
                        }

                    }).catch((e: any) => {
                        this.errorMessage = "Unable to delete the color.";
                        return;
                    });

                }
                else if (this.luType == 'Breeds') {
                    lookupsService.deleteBreed(this.editedItem.id).then((res) => {
                        if (!res.success) {
                            this.errorMessage = "Unable to delete breed"
                            return;
                        }

                    }).catch((e: any) => {
                        this.errorMessage = "Unable to delete the breed.";
                        return;
                    });

                }


                this.luItems.splice(this.editedIndex, 1);

            }
            this.closeDelete();
        },

        close() {
            this.errorMessage = "";
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            this.errorMessage = "";
            if (this.editedItem.id.trim().length == 0 || this.editedItem.description.trim().length == 0) {
                this.errorMessage = "Please enter valid values."
                return;
            }
            if (!/^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/i.test(this.editedItem.id)) {
                this.errorMessage = "Please enter valid values."
                return;
            }
            this.editedItem.id = this.editedItem.id.trim().toUpperCase();
            if (this.editedItem.id.includes(' ')) {
                this.errorMessage = "Please enter valid values."
                return;
            }


            this.editedItem.description = this.editedItem.description.trim().toUpperCase();
            if (this.luItems) {
                if (this.editedIndex == -1) {
                    var exists = this.luItems.find((x: any) => x.id == this.editedItem.id);
                    if (exists != null) {
                        this.errorMessage = "This Id already exists.";
                        return;
                    }
                }


            }
            if (this.luType == 'Eyes') {
                lookupsService.editEyeColor(this.editedItem).then((res: any) => {
                    if (res.success) {
                        if (this.editedIndex > -1) {
                            Object.assign(this.luItems[this.editedIndex], this.editedItem)

                        }
                        else {
                            if (this.luItems) {
                                this.luItems.unshift(this.editedItem);

                            }
                        }
                        this.close();
                    }
                    else {
                        this.errorMessage = "Unable to edit the eye color.";
                        return;
                    }
                });
            }
            else if (this.luType == 'Body') {
                lookupsService.editBodyColor(this.editedItem).then((res: any) => {
                    if (res.success) {
                        if (this.editedIndex > -1) {
                            Object.assign(this.luItems[this.editedIndex], this.editedItem)

                        }
                        else {
                            if (this.luItems) {
                                this.luItems.unshift(this.editedItem);

                            }
                        }
                        this.close();
                    }
                    else {
                        this.errorMessage = "Unable to edit the body color.";
                        return;
                    }
                });
            }
            else if (this.luType == 'Trim') {
                lookupsService.editTrimColor(this.editedItem).then((res: any) => {
                    if (res.success) {
                        if (this.editedIndex > -1) {
                            Object.assign(this.luItems[this.editedIndex], this.editedItem)

                        }
                        else {
                            if (this.luItems) {
                                this.luItems.unshift(this.editedItem);

                            }
                        }
                        this.close();
                    }
                    else {
                        this.errorMessage = "Unable to edit the trim color.";
                        return;
                    }
                });
            }
            else if (this.luType == 'Breeds') {
                lookupsService.editBreed(this.editedItem).then((res: any) => {
                    if (res.success) {
                        if (this.editedIndex > -1) {
                            Object.assign(this.luItems[this.editedIndex], this.editedItem)

                        }
                        else {
                            if (this.luItems) {
                                this.luItems.unshift(this.editedItem);

                            }
                        }
                        this.close();
                    }
                    else {
                        this.errorMessage = "Unable to edit the breed.";
                        return;
                    }
                });
            }

        },
    },
    computed: {
        formTitle() {
            return this.$data.editedIndex === -1 ? "New Item" : "Edit Item";
        },
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    props: {
        luType: { type: String },
        maxSize: { type: String },
    },
});
