
import Vue from "vue";
export default Vue.extend({
  name: "LitterStatusDropdown",
  props: {
    status: Number,
    required: Boolean,
    disabled: Boolean,
    inline: Boolean,
    classNames: String,
    label: String,
  },
  data: function () {
    return {
      statuses: [
        { value: 1, text: "Registered" },
        { value: 2, text: "Pending" },
        { value: 3, text: "Cancelled" },
      ],
    };
  },
});
