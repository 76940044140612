
import Vue from "vue";
import ChangeKennelOwnersForm1 from "./Steps/ChangeKennelOwnersForm1.vue";
import ChangeKennelOwnersForm2 from "./Steps/ChangeKennelOwnersForm2.vue";
import registerKennelService from "@/services/portal/registerKennelService";
import workflowService from "@/services/portal/workflowService";

import { UserInfo } from "@/types/User";
import { PaymentInfo } from "@/types/portal/Registration";
import AuthorizeNetResponseData from "@/types/AuthorizeNetResponseData";

const registerDogImage = require('@/assets/register-dog.png');

export default Vue.extend({
  name: "ChangeKennelOwners",
  components: {
    ChangeKennelOwnersForm1,
    ChangeKennelOwnersForm2,
  },
  data: function () {
    return {
      registerDogImage,
      currentPage: 1,
      coOwnerInfo: [] as any[],
      primaryOwner: 0,
      registrationRequestStatus: { message: "", status: "" },
      paymentInfo: {
        cardholderName: "",
        cardNumber: "",
        expirationDate: "",
        securityCode: "",
      },
      signature: "",
      selectedKennel: {} as any,
      priceInfo: { fullMemberPrice: 0, serviceMemberPrice: 0 },
      price: 0,
    };
  },
  methods: {
    changePage(page: number) {
      this.currentPage = page;
    },
    changeCoOwner(coOwnerInfo: any[]) {
      if (
        coOwnerInfo.findIndex((co: { isPrimary: boolean }) => co.isPrimary) ===
        -1
      ) {
        coOwnerInfo[0].isPrimary = true;
        this.primaryOwner = coOwnerInfo[0].personId;
      }
      this.coOwnerInfo = coOwnerInfo;
    },
    changePrimaryOwner(personId: number) {
      this.primaryOwner = personId;
      const index = this.coOwnerInfo.map((c) => c.personId).indexOf(personId);
      this.coOwnerInfo.forEach((co) => (co.isPrimary = false));
      this.coOwnerInfo[index].isPrimary = true;
    },
    submitRequest(paymentInfo: AuthorizeNetResponseData, requesterSignatureText: string) {
      let req = {
        paymentInfo,
        requesterSignatureText,
        fee: this.price,
        kennelId: this.$props.kennelId,
        requesterPersonId: this.userInfo.personId,
        newOwners: this.coOwnerInfo,
      };

      registerKennelService.submitChangeKennelOwnerRequest(req).then((res) => {
        this.registrationRequestStatus = res;
      });
    },
    updateSignature(signature: string) {
      this.signature = signature;
    },
    updatePaymentInfo(paymentInfo: PaymentInfo) {
      this.paymentInfo = paymentInfo;
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    searchMemberInfo() {
      return this.$store.state.searchMemberInfo;
    },
  },
  created() {
    registerKennelService.getKennelById(this.kennelId).then((res) => {
      this.selectedKennel = res;
    });
    workflowService.getChangeKennelOwnerFees().then((res) => {
      console.log(res);
      if (!isNaN(res)) {
        this.priceInfo = { fullMemberPrice: res, serviceMemberPrice: res };
        this.price = res;
      } else {
        this.priceInfo = res;
      }
    });
  },
  watch: {
    kennelId: function (newValue) {
      registerKennelService.getKennelById(newValue).then((res) => {
        this.selectedKennel = res;
      });
    },
  },
  props: {
    kennelId: String,
  },
});
