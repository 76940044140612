
import Vue from "vue";
import CoownersList, { OwnershipType } from "@/components/common/CoownersList.vue";
import { UserInfo } from "@/types/User";
import dogService from "@/services/dogService";

const defaultImage = require('@/assets/photouploadblue.png');

export default Vue.extend({
  name: "DogCard",
  data: function () {
    return {
      dogInfo: {} as any,
      defaultImage,
    };
  },
  components: {
    CoownersList,
  },
  props: {
    selectedDog: String,
  },
  created: function () {
    if (this.selectedDog && this.userInfo) {
      dogService
        .getDogById(this.selectedDog, this.userInfo.personId)
        .then((res) => {
          this.dogInfo = res;
        })
        .catch((e) => console.log(e));
    }
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    dogOwnershipType: function () {
      return OwnershipType.Dog;
    },
  },
  watch: {
    selectedDog: function (newVal: String) {
      dogService
        .getDogById(this.selectedDog, this.userInfo.personId)
        .then((res) => {
          this.dogInfo = res;
        })
        .catch((e) => console.log(e));
    },
  },
});
