
import Vue from 'vue';
import axios from "axios";
import FooterText from '@/components/common/FooterText.vue';
import VerifyCodeDialog from '@/components/common/VerifyCodeDialog.vue';

import userService from "@/services/portal/userService";
import authenticationService from "@/services/authenticationService";
import { VerificationType } from "@/services/codeVerificationService";

import { UserInfo } from "@/types/User";

const logoImage = require('@/assets/logo.svg');

export default Vue.extend({
  components: { FooterText, VerifyCodeDialog },
  name: 'Agreements',
  data() {
    return {
      logoImage,
      isError: false,
      isProcessing: false,
      errorMessage: '',

      showVerifyEmail: false,
      verifiedEmail: false,
      showVerifySMS: false,
      verifiedSMS: false,

      ascaPrivacyPolicy: false,
      ascaDataSharingPolicy: false,
      useEmail: false,
      useSMS: false,
    }
  },
  watch: {
    userInfo: function (newValue) {
      if (!!newValue) {
        this.verifiedEmail = newValue.verifiedEmail;
        this.verifiedSMS = newValue.verifiedSMS;
      }
    }
  },
  computed: {
    agreementFor(): string {
      return this.$store.state.agreementFor;
    },
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    targetEmail(): string {
      const agreementFor = this.agreementFor;

      switch (agreementFor) {
        case "VerifyJunior":
          const parentVerification = this.$store.state.parentVerification;
          return parentVerification.email;
        case "NewAccount":
        case "ExistingAccount":
          const userInfo = this.userInfo;
          return userInfo?.email;
        default:
          console.log("Unknown case for target email");
          return "";
      }
    },
    targetSMS(): string {
      const agreementFor = this.agreementFor;

      switch (agreementFor) {
        case "VerifyJunior":
          const parentVerification = this.$store.state.parentVerification;
          return parentVerification.phone;
        case "NewAccount":
        case "ExistingAccount":
          const userInfo = this.userInfo;
          return userInfo?.phone;
        default:
          console.log("Unknown case for target sms");
          return "";
      }
    },
    emailVerificationType() {
      return VerificationType.Email;
    },
    phoneVerificationType() {
      return VerificationType.PhoneNumber;
    }
  },
  methods: {
    goBack() {
      // User hasn't agreed keep them out till they do
      if (!!this.userInfo) {
        axios.defaults.headers.common["Authorization"] = null;
        localStorage.removeItem("userToken");
        this.$store.commit("loadUserInfo", null);
      }

      this.$router.back();
    },
    verifyJunior: async function() {
      const parentVerification = this.$store.state.parentVerification;

      await authenticationService.activateJunior({
        juniorId: parseInt(parentVerification.juniorId),
        parentId: parentVerification.parentId ? parseInt(parentVerification.parentId) : null,
        verifiedEmail: this.verifiedEmail,
        verifiedSMS: this.verifiedSMS,
        useEmail: this.useEmail,
        useSMS: this.useSMS,
        agreedToPrivacyPolicy: this.ascaPrivacyPolicy,
        agreedToDataSharingPolicy: this.ascaDataSharingPolicy,
      });

      this.isProcessing = false;
      this.$router.push('/verification/success');
    },
    saveAgreements: async function() {
      await userService.setUserAgreements({
        verifiedEmail: this.verifiedEmail,
        verifiedSMS: this.verifiedSMS,
        useEmail: this.useEmail,
        useSMS: this.useSMS,
        agreedToPrivacyPolicy: this.ascaPrivacyPolicy,
        agreedToDataSharingPolicy: this.ascaDataSharingPolicy,
      });

      const newUserInfo = await userService.getProfile();

      this.$store.commit('loadUserInfo', newUserInfo);

      return newUserInfo;
    },
    setAgrementsForAccount: async function(newAccount: boolean) {
      const newUserInfo = await this.saveAgreements();

      this.isProcessing = false;

      if (newAccount) this.$router.push('/signup/success');
      else if (newUserInfo.memberId == null) this.$router.push('/gain-membership');
      else this.$router.push(newUserInfo.isActiveMember ? '/profile' : '/expired-membership');
    },
    submit: function() {
      this.isProcessing = true;

      const agreementFor = this.agreementFor;

      switch (agreementFor) {
        case "VerifyJunior":
          return this.verifyJunior();
        case "NewAccount":
        case "ExistingAccount":
          return this.setAgrementsForAccount(agreementFor === "NewAccount");
      }
    },
    validAgreements() {
      return !!this.ascaDataSharingPolicy
        && !!this.ascaPrivacyPolicy
        && (this.useEmail || this.useSMS);
    },
  },
});
