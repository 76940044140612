
import Vue from 'vue';
import FooterText from '@/components/common/FooterText.vue';
import authenticationService from '@/services/authenticationService';

const logo = require('@/assets/logo.svg');

export default Vue.extend({
    components: { FooterText },
    name: 'ParentVerification',
    data() {
        return {
            logo,
            isProcessing: false,
            affirm: false,
            acknowledgeSignature: false,
            agreeToAuthorize: false,
            acknowledgeExpiration: false,
        };
    },
    methods: {
        submit: async function() {
            this.isProcessing = true;

            const juniorData = await authenticationService.getBasicJuniorData(this.$route.query.juniorId);

            this.$store.commit('changeParentVerification', {
                parentId: this.$route.query.parentId,
                juniorId: this.$route.query.juniorId,
                firstName: juniorData.firstName,
                lastName: juniorData.lastName,
                phone: juniorData.phone,
                email: juniorData.email,
                affirm: this.affirm,
                acknowledgeSignature: this.acknowledgeSignature,
                agreeToAuthorize: this.agreeToAuthorize,
                acknowledgeExpiration: this.acknowledgeExpiration,
            });

            this.isProcessing = false;
            this.$router.push('/agreements');
        },
        validAgreements() {
            return !!this.affirm
                && !!this.acknowledgeSignature
                && !!this.agreeToAuthorize
                && !!this.acknowledgeExpiration;
        },
    }
});
