
import Vue from "vue";
import CoownersList, { OwnershipType } from "@/components/common/CoownersList.vue";
import Datepicker from "@/components/common/inputs/DatepickerFormatted.vue";
import PaginationSelect from "@/components/common/PaginationSelect.vue";

import litterService from "@/services/admin/litterService";

import { MetaNames } from "@/constants";
import { UserInfo } from "@/types/User";
import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";

export default Vue.extend({
  name: "LitterList",
  components: {
    CoownersList,
    Datepicker,
    PaginationSelect
  },
  data: function () {
    return {
      title: MetaNames.AdminLitters,
      loading: false,
      isError: false,
      errorMessage: "",
      paginatedResponse: {} as PaginatedResponse<object>,
      searchParams: {
        damId: "",
        damName: "",
        sireId: "",
        sireName: "",
        ascending: false,
        ownerName: "",
        memberId: "",
        whelpingDateFrom: "",
        whelpingDateTo: "",
      },
      options: {} as PaginationOptions,
      headers: [
        { text: "", align: "left", value: "litterId", width: "100px" },
        { text: "", align: "left", value: "damId", width: "200px" },
        { text: "", align: "left", value: "sireId", width: "200px" },
        { text: "", align: "center", value: "whelpingDate", width: "175px" },
        { text: "", align: "left", value: "owners", width: "200px" },
        {
          text: "Registration",
          align: "left",
          value: "registrationId",
          width: "75px",
        },
        {
          text: "Registration date",
          align: "center",
          value: "registrationDate",
          width: "100px",
        },
      ],
      footerProps: { 'items-per-page-options': [10, 20, 30, 40, 50, 100] },
    };
  },
  created: function () {
    this.load();
  },
  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true,
    }
  },
  methods: {
    createNewLitter: function() {
      this.$router.push({path: '/admin/litters'});
    },
    clearAllFilters: function () {
      this.options.page = 1;
      this.options.itemsPerPage = 10;
      this.searchParams = {
        damId: "",
        damName: "",
        sireId: "",
        sireName: "",
        ascending: false,
        ownerName: "",
        memberId: "",
        whelpingDateFrom: "",
        whelpingDateTo: "",
      };
    },
    updateDates: function (val: any, key: string) {
      (this as any).searchParams[key] = val.date;
    },
    updateDateSearch: async function(val: any, key: string) {
      await this.updateDates(val, key);
      this.applyFilter();
    },
    showRegistration: function (id: any, _: any) {
      this.$router.push({ path: `/admin/registration/litters/${id}` });
    },
    load: async function () {
      this.loading = true;

      const {
        page,
        itemsPerPage,
      } = this.options;

      try {
        const response = await litterService.getLittersList({
          ...this.options,
          page: (page ?? 1) - 1,
          itemsPerPage: itemsPerPage ?? 10
        }, this.searchParams);

        this.loading = false;
        this.paginatedResponse = response;
      } catch (ex: any) {
        console.log(ex.response.data.errors)

        this.loading = false;
        this.isError = true;
        
        const descriptiveMessage = ex.response.data.errors
          ? `Error(s): ${Object.values(ex.response.data.errors).join(" & ")}`
          : null

        this.errorMessage = descriptiveMessage ?? ex.message ?? "Unknown error occurred when retrieving data.";
      }
    },
    applyFilter() {
      this.options.page = 1;
      this.options.itemsPerPage = 10;

      this.load();
    },
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    dogOwnershipType: function () {
      return OwnershipType.Dog;
    },
    litterOwnershipType: function () {
      return OwnershipType.Litter;
    },
  },
});
