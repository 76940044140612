
import Vue from 'vue';
import jwt_decode from "jwt-decode";

import passwordValidators from '@/helpers/validate-password';

import authenticationService from '@/services/authenticationService';
import { VerificationType } from "@/services/codeVerificationService";

import FooterText from '@/components/common/FooterText.vue';
import VerifyCodeDialog from '@/components/common/VerifyCodeDialog.vue';
import UniqueUsernameDialog from '@/components/common/UniqueUsernameDialog.vue';
import PasswordRules from '@/components/common/PasswordRules.vue';

export default Vue.extend({
  components: { FooterText, VerifyCodeDialog, UniqueUsernameDialog, PasswordRules },
  name: 'RecoverAccount',
  data() {
    const recoverInfo = this.$store.state.recoverInfo;

    return {
      isValid: false,
      isError: false,
      isProcessing: false,
      errorMessage: '',

      showVerifyEmail: false,
      verifiedEmail: false,

      showNonUniqueUsername: false,

      email: recoverInfo?.email,
      username: recoverInfo?.username,
      password1: recoverInfo?.password,
      password2: recoverInfo?.confirmPassword,
      passwordRules: passwordValidators,
    }
  },
  computed: {
    emailVerificationType() {
      return VerificationType.Email;
    },
  },
  methods: {
    goBack() { this.$router.back(); },
    processRecovery: async function (res: any) {
      const decoded: any = jwt_decode(res.token);
      const event = new CustomEvent("userLoaded", {
          detail: { personId: decoded.nameid, token: res.token },
      });
      window.dispatchEvent(event);

      localStorage.setItem("userToken", res.token);
    },
    checkUsername: async function(newUsername?: string) {
      if (!!newUsername) this.username = newUsername;

      const uniqueUsername = await authenticationService.uniqueUsername(this.username);

      this.showNonUniqueUsername = !uniqueUsername;

      return uniqueUsername;
    },
    submit: async function() {
      this.isProcessing = true;

      const uniqueUsername = await this.checkUsername();
      
      if (uniqueUsername && this.verifiedEmail) {
        const response = await authenticationService.recoverAccount({
          email: this.email,
          username: this.username,
          password1: this.password1,
          password2: this.password2,
          verifiedEmail: this.verifiedEmail,
        });

        if (response.errors != null) {
          this.isProcessing = false;
          this.isError = true;
          this.errorMessage = response.errors ? response.errors.join(',') : 'An Unknown Error Occured';
        } else if (response.frozen) {
          this.isProcessing = false;

          this.$store.commit('changeParentVerification', {
            juniorId: response.personId,
            firstName: response.firstName,
            lastName: response.lastName
          });
          this.$router.push('/parent-member');
        } else {
          await this.processRecovery(response);

          this.isProcessing = false;
          this.$router.push('/agreements');
        }
      }

      this.isProcessing = false;
    },
  },
});
