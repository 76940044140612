
import Vue from 'vue'
import TitledDogs from '@/components/Admin/JuniorHandler/TitledDogs.vue';
import JuniorHandlingScores from '@/components/Admin/JuniorHandler/JuniorHandlingScores.vue';
import JuniorRallyScores from '@/components/Admin/JuniorHandler/JuniorRallyScores.vue'
import JuniorTrackingScores from '@/components/Admin/JuniorHandler/JuniorTrackingScores.vue';
import JuniorObedienceScores from '@/components/Admin/JuniorHandler/JuniorObedienceScores.vue';
import JuniorStockdogOpenScores from '@/components/Admin/JuniorHandler/JuniorStockdogOpenScores.vue';
import JuniorStockdogStandardScores from '@/components/Admin/JuniorHandler/JuniorStockdogStandardScores.vue';
import DatepickerFormatted from "@/components/common/inputs/DatepickerFormatted.vue";

import { ValueGroup } from '@/services/adminService';
import lookupsService from '@/services/admin/lookupsService';
import juniorHandlerService from '@/services/admin/juniorHandlerService';

import { Messages } from "@/constants";
import { JuniorHandler, Parent } from '@/types/admin/JuniorHandler';
import { JrHandlerScoreType } from "@/types/admin/JuniorHandler";

export default Vue.extend({
    components: { 
        TitledDogs, 
        JuniorHandlingScores, JuniorRallyScores, JuniorTrackingScores, JuniorObedienceScores, 
        JuniorStockdogOpenScores, JuniorStockdogStandardScores,
        DatepickerFormatted 
    },
    name: "JuniorHandlerDetails",
    data: function () {
        return {
            juniorHandlerInfo: {
                parent1: {} as Parent,
                parent2: {} as Parent
            } as JuniorHandler,
            tab: null,
            isEditMode: false,
            showValidationDialog: false,
            validationResult: {} as any,
            searchResults: [] as any[],
            ageGroups: [] as ValueGroup[],
            ageGroupDescriptions: [] as string[],
            regions: [] as ValueGroup[],
            regionDescriptions: [] as string[],
            reloadPage: false,
            showParentDialog: false,
            hasSearched: false,
            hasSearchedParent: false,
            searchResultsParent: [] as Parent[],
            activeParentIndex: -1,
            parentFirstName: "",
            parentLastName:"",
            parentMemberId: "",
            headersParents: [
                { text: "Member ID", align: "left", value: "memberId" },
                { text: "First Name", align: "left", value: "firstName" },
                { text: "Last Name", align: "left", value: "lastName" },
                { text: "Active", align: "left", value: "isActive" },
            ],
            isLoading: false,
            titlesTypes: [] as any[],
            handlingClasses: [] as any[],
            obedienceClasses: [] as any[],
            stockdogOpenClasses: [] as any[],
            stockdogStandardClasses: [] as any[],
            trackingClasses: [] as any[],
            rallyClasses: [] as any[],
            errorMessage: '',
            infoMessage: '',
            showErrorMessage: false,
            showInfoMessage: false
        }
    },
    created: function () {
        if (this.personId) {
            juniorHandlerService.getJuniorHandlerById(this.personId).then((res: JuniorHandler) => {
                if (res) {
                    this.juniorHandlerInfo = res;
                    this.reloadPage = false;
                } else {
                    this.juniorHandlerInfo = {} as JuniorHandler;
                    this.juniorHandlerInfo.parent1 = {} as Parent;
                    this.juniorHandlerInfo.parent2 = {} as Parent;
                    this.isEditMode = true;
                    this.reloadPage = true;
                }
            }).catch(e => {
                this.juniorHandlerInfo = {} as JuniorHandler;
                this.juniorHandlerInfo.parent1 = {} as Parent;
                this.juniorHandlerInfo.parent2 = {} as Parent;
                this.juniorHandlerInfo.personId = this.$props.personId;
                this.reloadPage = true;
            })
        }
        else {
            this.juniorHandlerInfo = {} as JuniorHandler;
            this.juniorHandlerInfo.parent1 = {} as Parent;
            this.juniorHandlerInfo.parent2 = {} as Parent;
            this.isEditMode = true;
            this.reloadPage = true;
        }

        juniorHandlerService.getJuniorHandlerAgeGroups().then((res: any) => {
            this.ageGroups = res;
            this.ageGroupDescriptions.push('');
            for (let i = 0; i < this.ageGroups.length; i++) {
                this.ageGroupDescriptions.push(this.ageGroups[i].description);
            }
        });

        juniorHandlerService.getJuniorHandlerRegions().then((res: any) => {
            this.regions = res;
            this.regionDescriptions.push('');
            for (let i = 0; i < this.regions.length; i++) {
                this.regionDescriptions.push(this.regions[i].description);
            }
        });

        lookupsService
            .getProgramTitlesTypes('') 
            .then((res) => {
                this.titlesTypes = res; 
            });

        juniorHandlerService
            .getJuniorHandlerProgramClasses(JrHandlerScoreType.Handling) 
            .then((res) => {
                this.handlingClasses = res; 
            });

        juniorHandlerService
            .getJuniorHandlerProgramClasses(JrHandlerScoreType.Obedience) 
            .then((res) => {
                this.obedienceClasses = res; 
            }); 

        juniorHandlerService
            .getJuniorHandlerProgramClasses(JrHandlerScoreType.StockdogOpen) 
            .then((res) => {
                this.stockdogOpenClasses = res; 
            });  

        juniorHandlerService
            .getJuniorHandlerProgramClasses(JrHandlerScoreType.StockdogStandard) 
            .then((res) => {
                this.stockdogStandardClasses = res; 
            });    

        juniorHandlerService
            .getJuniorHandlerProgramClasses(JrHandlerScoreType.Tracking) 
            .then((res) => {
                this.trackingClasses = res; 
            }); 

        juniorHandlerService
            .getJuniorHandlerProgramClasses(JrHandlerScoreType.Rally) 
            .then((res) => {
                this.rallyClasses = res; 
            });       
    },
    watch: {
        personId: function (newVal: String) {
        juniorHandlerService
            .getJuniorHandlerById(this.personId)
            .then((res) => {
                this.juniorHandlerInfo = res;
            });
        },
    },
    methods: {
        reloadData: function () {
            if (this.personId) {
                juniorHandlerService.getJuniorHandlerById(this.personId).then((res: JuniorHandler) => {
                    if (res) {
                        this.juniorHandlerInfo = res;
                    } else {
                        this.juniorHandlerInfo = {} as JuniorHandler;
                        this.isEditMode = true;
                    }
                }).catch(e => {
                    this.juniorHandlerInfo = {} as JuniorHandler;
                    this.juniorHandlerInfo.personId = this.$props.personId;
                })
            }
            else {
                this.juniorHandlerInfo = {} as JuniorHandler;
                this.isEditMode = true;
            }
            this.showValidationDialog = false;
            this.errorMessage = '';
            this.showErrorMessage = false;
            this.validationResult = {};
        },
        validateAndConfirmSavingChanges: function () {
            this.errorMessage = "";
            this.showErrorMessage = false;
         
            if (!this.validateValues()) {
                this.isEditMode = false; //will toggle it back to edit mode
                return false;
            }
            this.updateRegionsAgeGroups();
            this.juniorHandlerInfo.personId = this.$props.personId;
            this.saveChanges();

        },
        toggleEditMode: function () {
            if (this.isEditMode) {
                this.validateAndConfirmSavingChanges();
            }
            this.isEditMode = !this.isEditMode;
        },
        updateDates: function (val: any, key: string) {
            this.$data.juniorHandlerInfo[key] = val?.date;
            this.$data.juniorHandlerInfo = Object.assign({}, this.$data.juniorHandlerInfo);
        },
        saveChanges: function () {
            this.errorMessage = "";
            this.showErrorMessage = false;
            this.showValidationDialog = false;
            this.$store.dispatch("changeLoaderStatus", true);
            juniorHandlerService
                .editJuniorHandler(this.juniorHandlerInfo)
                .then((res: any) => {
                    if (res.success) {
                        this.reloadData();
                        if (this.reloadPage) {
                            window.location.reload();
                        }
                        this.infoMessage = Messages.SaveSuccessful;
                        this.showInfoMessage = true;
                    } else {              
                        this.errorMessage = Messages.SaveError + " " + res.messages.join(', ');
                        this.showErrorMessage = true;
                    }
                })
                .catch((e) => {                  
                    this.errorMessage = Messages.SaveError;
                    this.showErrorMessage = true;
                })
                .finally(() => this.$store.dispatch("changeLoaderStatus", false));
        },
        updateRegionsAgeGroups: function () {
            if (this.juniorHandlerInfo.ageGroup != null) {
                var ageGroup = this.ageGroups.find((x: ValueGroup) => x.description == this.juniorHandlerInfo.ageGroup);
                if (ageGroup != null) {
                    this.juniorHandlerInfo.ageGroupId = ageGroup.id;
                }
            }
            if (this.juniorHandlerInfo.region != null) {
                var region = this.regions.find((x: ValueGroup) => x.description == this.juniorHandlerInfo.region);
                if (region != null) {
                    this.juniorHandlerInfo.regionId = region.id;
                }
            }
        },
        validateValues: function () {
            if (this.juniorHandlerInfo.birthdate == null) {
                this.errorMessage = "DOB is required.";
                this.showErrorMessage = true;
                return false;
            }
            if (!this.juniorHandlerInfo.ageGroup || this.juniorHandlerInfo.ageGroup.length === 0) {
                this.errorMessage = "Age Group is required.";
                this.showErrorMessage = true;
                return false;
            }
            if (!this.juniorHandlerInfo.region || this.juniorHandlerInfo.region.length === 0) {
                this.errorMessage = "Region is required.";
                this.showErrorMessage = true;
                return false;
            }
            if (!this.juniorHandlerInfo.state || this.juniorHandlerInfo.state.length === 0 || /^\s*$/.test(this.juniorHandlerInfo.state)) {
                this.errorMessage = "State is required.";
                this.showErrorMessage = true;
                return false;
            }
            else {
                if (this.juniorHandlerInfo.state.length > 2) {
                    this.errorMessage = "State is too long.";
                    this.showErrorMessage = true;
                    return false;
                }
            }
            if (this.juniorHandlerInfo.parent1 != null) {
                if (this.juniorHandlerInfo.parent1.personId == null || this.juniorHandlerInfo.parent1.personId == -1) {
                    if (this.juniorHandlerInfo.parent1.firstName != null) {
                        if (this.juniorHandlerInfo.parent1.firstName.length > 50) {
                            this.errorMessage = "Parent 1 First Name is too long.";
                            this.showErrorMessage = true;
                            return false;
                        }
                    }
                    if (this.juniorHandlerInfo.parent1.lastName != null) {
                        if (this.juniorHandlerInfo.parent1.lastName.length > 50) {
                            this.errorMessage = "Parent 1 Last Name is too long.";
                            this.showErrorMessage = true;
                            return false;
                        }
                    }
                }
            }
            if (this.juniorHandlerInfo.parent2 != null) {
                if (this.juniorHandlerInfo.parent2.personId == null || this.juniorHandlerInfo.parent2.personId == -1) {
                    if (this.juniorHandlerInfo.parent2.firstName != null) {
                        if (this.juniorHandlerInfo.parent2.firstName.length > 50) {
                            this.errorMessage = "Parent 2 First Name is too long.";
                            this.showErrorMessage = true;
                            return false;
                        }
                    }
                    if (this.juniorHandlerInfo.parent2.lastName != null) {
                        if (this.juniorHandlerInfo.parent2.lastName.length > 50) {
                            this.errorMessage = "Parent 2 Last Name is too long.";
                            this.showErrorMessage = true;
                            return false;
                        }
                    }
                }
            }

            return true;
           
        },
        toggleParentSearchDialog: function (forParent: number) {
            this.activeParentIndex = forParent;
            this.showParentDialog = !this.showParentDialog;
        },
        parentSearch: function () {
            this.isLoading = true;
            this.hasSearchedParent = false;
            juniorHandlerService
                .searchJuniorHandlerParent({
                    memberId: this.parentMemberId,
                    firstName: this.parentFirstName,
                    lastName: this.parentLastName,
                    jrHandlerPersonId: this.personId,
                }).then((response: any) => {
                    this.searchResultsParent = response.data;
                    this.hasSearchedParent = true;
                });
            this.isLoading = false;

        },
        parentAdd: function (row: Parent) {
            if (this.activeParentIndex == 1) {
                this.juniorHandlerInfo.parent1 = row;
            }
            else {
                this.juniorHandlerInfo.parent2 = row;
            }
            this.showParentDialog = false;
            this.clearSearch();
           
        },
        parentCanEditName: function (forParent: number) {
            if (!this.isEditMode) {
                return false;
            }

            if (forParent == 1)
            {
                return (this.juniorHandlerInfo.parent1 == null || (this.juniorHandlerInfo.parent1.personId == null || this.juniorHandlerInfo.parent1.personId == -1));
            }
            else {
                return (this.juniorHandlerInfo.parent2 == null || (this.juniorHandlerInfo.parent2.personId == null || this.juniorHandlerInfo.parent2.personId == -1));
            }
        },
        clearParent: function (forParent: number) {
            if (forParent == 1) {
                this.juniorHandlerInfo.parent1.firstName = "";
                this.juniorHandlerInfo.parent1.lastName = "";
                this.juniorHandlerInfo.parent1.personId = -1;
                this.juniorHandlerInfo.parent1.memberId ="";
                
            }
            else {
                this.juniorHandlerInfo.parent2.firstName = "";
                this.juniorHandlerInfo.parent2.lastName = "";
                this.juniorHandlerInfo.parent2.personId = -1;
                this.juniorHandlerInfo.parent2.memberId = "";
            }
        },
        clearSearch: function () {
            this.parentFirstName = "";
            this.parentLastName = "";
            this.parentMemberId = "";
            this.searchResultsParent = [] as Parent[];
        },
    },
    props: {
        personId: { type: Number },
    },
})
