
import Vue from "vue";
import AffiliateWebsiteAndEvents from "./Steps/AffiliateWebsiteAndEvents.vue";
import AffiliateMembers from "./Steps/AffiliateMembers.vue";
import AffiliatePayment from "./Steps/AffiliatePayment.vue";
import AuthorizeNetResponseData from "@/types/AuthorizeNetResponseData";
import { UserInfo } from "@/types/User";
import workflowService from "@/services/portal/workflowService";
import affiliateService from "@/services/portal/affiliateService";
import registerAffiliateService from "@/services/portal/registerAffiliateService";
import reducePeopleToAffiliateMembers from "@/helpers/reduce-people-to-affiliate-members";

const registerDogImage = require('@/assets/register-dog.png');

export default Vue.extend({
  name: "RenewAffiliate",
  components: {
    AffiliateWebsiteAndEvents,
    AffiliateMembers,
    AffiliatePayment,
  },
  props: {
    affiliateId: { type: String },
  },
  data: function () {
    return {
      registerDogImage,
      currentPage: 1,
      fee: 0,
      feeInfo: { fullMemberPrice: 0, serviceMemberPrice: 0 },
      affiliateInfo: {},
      affiliatePeople: [] as any[],
      renewalRequestStatus: {},
      affiliateMemberTypes: {},
      membershipTypes: { Single: 1, Dual: 2, Affiliate: 3, Junior: 4, Service: 5, Lifetime: 6 }
    };
  },
  created: function () {
    workflowService.getRenewAffiliateFees(this.affiliateId).then((res: any) => {
      if (!isNaN(res)) {
        this.feeInfo = { fullMemberPrice: res, serviceMemberPrice: res };
        this.fee = res;
      } else {
        this.feeInfo = res;
      }
    });

    registerAffiliateService.getAffiliateMemberTypes().then((res: any) => {
      this.affiliateMemberTypes = res.memberTypes;
    });

    if (this.affiliateId && this.userInfo) {
      affiliateService
        .getAffiliateById(this.affiliateId, this.userInfo.personId)
        .then((res: any) => {
          this.$data.affiliateInfo = res;
        })
        .catch((e: any) => console.log(e));
    }
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    changePage(page: number) {
      this.currentPage = page;
    },
    changeAffiliatePeople(affiliatePeople: any) {
      this.$data.affiliatePeople = affiliatePeople;
    },
    changeFirstPageInfo(website: string, eventsAndActivities: string) {
      this.$data.affiliateInfo.website = website;
      this.$data.affiliateInfo.eventsAndActivities = eventsAndActivities;
    },
    submitRequest(paymentInfo: AuthorizeNetResponseData, signature: string) {
      const request = {
        ...this.affiliateInfo,
        paymentInfo,
        requesterSignatureText: signature,
        requesterPersonId: this.userInfo.personId,
        fee: this.fee,
        affMembers: reducePeopleToAffiliateMembers(
          this.$data.affiliatePeople,
          this.$data.affiliateMemberTypes
        ),
      };

      registerAffiliateService.renewAffiliate(request).then((response) => {
        this.renewalRequestStatus = response;
      });
    },
  },
  watch: {
    'affiliateInfo.members'(newVal: any[]) {
      if (!!newVal) {
        const members: any[] = [];

        // if duplicate people for secretary & treasurer, convert into one
        let secretaryAndTreasurer = !!(this as any).affiliateInfo.members
          ? (this as any).affiliateInfo.members.filter(
              (person: { position: string }) =>
                person.position === "Treasurer" || person.position === "Secretary"
            )
            .map((person: { personId: any }) => person.personId)
          : [];

        for (let i = 0; i < (this as any).affiliateInfo.members.length; i++) {
          if (
            (this as any).affiliateInfo.members[i].position ===
              "Show Coordinator" &&
            (this as any).affiliateInfo.members[i].isPrimary
          ) {
            members.push({
              ...(this as any).affiliateInfo.members[i],
              position: "Primary Show Coordinator",
            });
          } else if (
            (this as any).affiliateInfo.members[i].position == "Treasurer" ||
            (this as any).affiliateInfo.members[i].position == "Secretary"
          ) {
            if (secretaryAndTreasurer[0] === secretaryAndTreasurer[1]) {
              if (
                !members.filter(
                  (p) =>
                    p.personId === (this as any).affiliateInfo.members[i].personId
                ).length
              ) {
                members.push({
                  ...(this as any).affiliateInfo.members[i],
                  position: "Secretary & Treasurer",
                });
              }
            } else {
              members.push((this as any).affiliateInfo.members[i]);
            }
          } else {
            members.push((this as any).affiliateInfo.members[i]);
          }
        }

        this.affiliatePeople = members;
      }
    },
    affiliateId: function (newVal: String) {
      affiliateService
        .getAffiliateById(this.affiliateId, this.userInfo.personId)
        .then((res: any) => {
          this.$data.affiliateInfo = res;
        })
        .catch((e: any) => console.log(e));
    },
    userInfo: function (newVal: object) {
      affiliateService
        .getAffiliateById(this.affiliateId, this.userInfo.personId)
        .then((res: any) => {
          this.$data.affiliateInfo = res;
        })
        .catch((e: any) => console.log(e));
    },
  },
});
