
import Vue from "vue";
import PasswordRules from '@/components/common/PasswordRules.vue';

import passwordValidators from '@/helpers/validate-password';

import { UserInfo } from "@/types/User";
import personService from "@/services/portal/personService";

export default Vue.extend({
  name: "ChangePassword",
  components: { PasswordRules },
  data: function () {
    return {
      isValid: false,
      alertType: "info",
      alertMessage:
        "To change your password, enter your current password and your new password below",
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      passwordRules: passwordValidators,
    };
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    }
  },
  methods: {
    changePassword() {
      personService
        .changePassword(
          this.userInfo.personId,
          this.currentPassword,
          this.newPassword
        )
        .then((res) => {
          if (res.success) {
            this.alertType = "success";
            this.alertMessage = "Password updated successfully!";
          } else if (res.errorMessage) {
            this.alertType = "error";
            this.alertMessage = res.errorMessage;
          } else {
            this.alertType = "error";
            this.alertMessage = "Something unexpectedly went wrong.";
          }
        });
    },
  },
});
