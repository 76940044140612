
import Vue from 'vue';
import Sidebar from './Sidebar.vue';
import LinkSummary from './LinkSummary.vue';
import UserFeed from './UserFeed.vue';
import AppNavbar from './AppNavbar.vue';
import { UserInfo } from "@/types/User";

export default Vue.extend({
  name: 'Home',
  data () {
    return {
      drawer: false
    }
  },
  props: {
    searchText: String,
  },
  components: {
    Sidebar,
    LinkSummary,
    UserFeed,
    AppNavbar,
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    }
  },
  methods: {
    toggleDrawer: function () {
      this.drawer = !this.drawer
    }
  }
});

