
import Vue from 'vue';
import FooterText from '@/components/common/FooterText.vue';

import { UserInfo } from "@/types/User";

const logoImage = require('@/assets/logo.svg');

export default Vue.extend({
  components: { FooterText },
  name: 'ExpiredMembership',
  data() {
    return {
      logoImage,
    };
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
    juniorHasToRegister(): boolean | null {
      return this.userInfo?.juniorHasToRegister;
    },
  },
  methods: {
    onSkip() {
      this.$router.push("/");
    },
    onRenewMembership() {
      this.$router.push(this.juniorHasToRegister ? '/registration/member' : '/renew/member');
    }
  }
});
