
import Vue from "vue";
import * as _ from "lodash";
import DatepickerFormatted from "@/components/common/inputs/DatepickerFormatted.vue";
import JuniorTotalPoint from "@/components/common/JuniorTotalPoint.vue";
import juniorHandlerService from "@/services/admin/juniorHandlerService";
import { JrHandlerScoreType, JuniorHandlingScores, JuniorHandlingScoresResponse } from "@/types/admin/JuniorHandler";

export default Vue.extend({
  name: "JuniorHandlingScores",
  props: {
    personId: { type: Number },
    classes: { type: [] as any[]},
  },
  components: {
    DatepickerFormatted,
    JuniorTotalPoint,
  },
  data: function () {
    return {
      scoreType: JrHandlerScoreType.Handling,
      showResults: [] as JuniorHandlingScores[],
      persistedShowResults: [] as JuniorHandlingScores[],
      juniorHandlingScoresInfo: {} as JuniorHandlingScoresResponse,
      emptyRow: {} as JuniorHandlingScores,
      deletedItem: null as any,
      errorMessage: null as any,
      infoMessage: null as any,
      showDeleteConfirmationDialog: false,
    };
  },
  created: function () {
    if (this.personId) {
      this.reloadData();
    }
  },
  watch: {
    personId: function (newVal: String) {
      this.reloadData();
    },
  },
  methods: {
    emptyShowResultRow: function () {
      return {
        id: null,
        showDate: null,
        classId: null,
        numberCompeting: null,
        place: null,
        isBJH: null,
        isRJH: null,
        points: null,
        juniorPersonId: this.personId
      }
    },
    reloadData: function () {
      juniorHandlerService
        .getJuniorHandlingResultsByJuniorPersonId(
          this.personId,
          JrHandlerScoreType.Handling
        )
        .then((res) => {
          this.juniorHandlingScoresInfo = res;
          this.showResults = res.scores;
          this.persistedShowResults = res.scores;
          this.emptyRow = this.emptyShowResultRow();
        });
    },
    updateDates: function (val: any, key: string, item: any) {
      item[key] = val?.date;
      //workaround to force rerendering after setting up formatted date
      this.$set(this, "showResults", this.showResults);
    },
    saveShowResult: function (item: JuniorHandlingScores) {
      this.$store.dispatch("changeLoaderStatus",true);
      const isAddingNewRow = !item.id;
      
      juniorHandlerService.updateHandlingScoreEntry("handling", item)
        .then((res: any) => {
          if(!res.success){
            this.errorMessage = res.messages.join(", ");
            if(isAddingNewRow){
              const input = document.querySelector('#srEmptyDogId') as HTMLElement | null;

              if (input != null) {
                  input.focus();
              }
            }
          } else {
            if(isAddingNewRow){
              this.emptyRow.id = res.id;
              this.showResults.unshift(this.emptyRow);
              this.persistedShowResults = _.cloneDeep(this.showResults);

              this.emptyRow = Object.assign({}, this.emptyShowResultRow());
              this.$set(this, "emptyRow", this.emptyRow);

              const input = document.querySelector('#srEmptyDogId') as HTMLElement | null;

              if (input != null) {
                  input.focus();
              }
            } else {
              this.persistedShowResults = _.cloneDeep(this.showResults);
            }
            this.errorMessage = null;
          }
        })
        .catch((e) => {
          this.errorMessage = "Changes couldn't be saved. Ensure there are no validation errors before retrying.";
        })
        .finally(() => { this.$store.dispatch("changeLoaderStatus",false);}); },
    row_classes: function (item: any, index: number) {
      if (!!item.id) {
        const index = this.showResults.findIndex((i) => i.id === item.id);
        if (
          _.isEqual(this.showResults[index], this.persistedShowResults[index])
        ) {
          return "";
        }
      }
      return "orange  lighten-4";
    },
    deleteShowResult: function (item: JuniorHandlingScores) {
      if (item.id) {
        //if that item has been persisted already
        this.deletedItem = item;
        this.showDeleteConfirmationDialog = true;
      }
    },
    confirmDelete: function () {
      if (this.deletedItem == null) {
        return;
      }
      juniorHandlerService.deleteHandlingScoreEntry(this.deletedItem.id)
        .then((res: any) => {
          if(!res.success){
            this.errorMessage = res.messages.join(", ");
          } else {
            this.deletedItem = null;
            this.reloadData();
          }   
      })
      .finally(() => {this.showDeleteConfirmationDialog = false});
    },
  },
});
