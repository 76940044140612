import { api } from "../api";
import { ShowInfo, PointSchedule, ShowType } from '@/types/admin/Show';
import { PaginationOptions, PaginatedResponse } from "@/types/Pagination";

export const getPointSchedules = async (): Promise<PointSchedule> => {
    const { data } = await api.get<PointSchedule>("/Admin/pointschedules");
    return data;
};

export const getProgramShowResultCategories = async (programSymbol: String): Promise<any> => {
    const { data } = await api.get<object>(`/Lookups/categories?program=${programSymbol}`);  
    return data;
};

export const getShowsList = async (
    options: PaginationOptions,
    params: object,
): Promise<PaginatedResponse<object>> => {  
    const { page, itemsPerPage, sortBy, sortDesc } = options;

    const sort = !!sortBy.length ? sortBy[0] : 'requestId';
    const asce = !!sortDesc.length ? !sortDesc[0] : true;

    const { data } = await api.get<PaginatedResponse<object>>(`/Admin/shows?pageSize=${itemsPerPage}&page=${page}&ascending=${asce}&sort=${sort}`, { params });

    return data;
};

export const getShowById = async (showId: String): Promise<ShowInfo> => {
    const { data } = await api.get<ShowInfo>(`/Admin/shows/${showId}`);
  
    return data;
};

export const getShowPeopleList = async (params: object): Promise<object[]> => {
    const { data } = await api.get<object[]>(`/Admin/showpeople`,
        {
            params: params
        }
    );

    return data;
};

export const getShowJudgesList = async (params: object): Promise<object[]> => {
    const { data } = await api.get<object[]>(`/Admin/showjudges`,
        {
            params: params
        }
    );

    return data;
};

export const getShowResultsByShowId = async (showId: String, programType: String): Promise<any> => {
    const { data } = await api.get<object>(`/Admin/shows/${showId}/results?programType=${programType}/`);
  
    return data;
};

export const validateShowEdit = async (request: ShowInfo): Promise<object> => {
    const { data } = await api.post<object>(`/Admin/shows/details/validate`, request);
    return data;
};


export const editShowDetails = async (request: ShowInfo): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/shows/details/edit`, request);
    return data;
};

export const editShowProcessing = async (request: ShowInfo): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/shows/details/processing`, request);
    return data;
};

export const getShowInfoForShowResults = async (showId: String): Promise<any> => {
    const { data } = await api.get<object>(`/Admin/shows/${showId}/results/info`);
  
    return data;
  };

export const getConformationShowResultsSummaryList = async (showId: String): Promise<any> => {
    const { data } = await api.get<object>(`/Admin/shows/${showId}/conformation/info`);
    
    return data;
};

export const getConformationShowResultsSummary = async (showId: String, showDate: object, showTime: String, showType: String): Promise<any> => {
    const request = {
        showId: showId, 
        showDate: showDate,
        showTime: showTime,
        showType: showType,
    };
    const { data } = await api.post<object>(`/Admin/shows/${showId}/conformation/details`, request);
    
    return data;
};


export const calculatePointsForConformationShowResultsSummary = async (showId: String, request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/shows/${showId}/conformation/details/calculate`, request);
    
    return data;
};

export const updateConformationShowResultsSummary = async (showId: String, request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/shows/${showId}/conformation/details/update`, request);
    
    return data;
};

export const deleteConformationShowResultsSummary = async (showId: String, showDate: object, showTime: String, showType: String): Promise<any> => {
    const request = {
        showId: showId, 
        showDate: showDate,
        showTime: showTime,
        showType: showType,
    };
    const { data } = await api.post<object>(`/Admin/shows/${showId}/conformation/details/delete`, request);
    
    return data;
};

export const getStockResultsSummaryList = async (showId: String): Promise<any> => {
    const { data } = await api.get<object>(`/Admin/shows/${showId}/stock/details`);
    
    return data;
};

export const updateStockResultsSummary = async (showId: String, request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/shows/${showId}/stock/details/update`, request);
    
    return data;
};

export const deleteStockResultsSummary = async (showId: String, request: any): Promise<any> => {
    const { data } = await api.post<object>(`/Admin/shows/${showId}/stock/details/delete`, request);
    
    return data;
};

export const updateShowResult = async (showId: string, programType: string, showResult: any): Promise<any> => {
    if(programType){
        const { data } = await api.post<object>(`/Admin/shows/${programType}/${showId}/results`, showResult);
        return data;
    } else {
        const { data } = await api.post<object>(`/Admin/shows/${showId}/results`, showResult);
        return data;
    }
};

export const deleteShowResult = async (showId: any, resultId: any): Promise<any> => {
    const { data } = await api.delete<object>(`/Admin/shows/${showId}/results/${resultId}`);
    return data;
};

export const getJudgeShowTypes = async () => {
    const { data } = await api.get<ShowType[]>(`/Admin/judges/showtypes`);
    return data;
};

export default {
    getPointSchedules,
    getProgramShowResultCategories,
    getShowsList,
    getShowById,
    getShowResultsByShowId,
    getShowInfoForShowResults,
    getConformationShowResultsSummary,
    deleteConformationShowResultsSummary,
    calculatePointsForConformationShowResultsSummary,
    updateConformationShowResultsSummary,
    getConformationShowResultsSummaryList,
    getStockResultsSummaryList, 
    updateStockResultsSummary,
    deleteStockResultsSummary,
    updateShowResult,
    deleteShowResult,
    getShowPeopleList,
    validateShowEdit,
    editShowDetails,
    getShowJudgesList,
    editShowProcessing,
    getJudgeShowTypes,
};