
import Vue from 'vue';
import { debounce } from "throttle-debounce";

import FooterText from '../../../components/common/FooterText.vue';

import validateEmail from '@/helpers/validate-email';
import authenticationService from "../../../services/authenticationService";

export default Vue.extend({
    components: { FooterText },
    name: 'ParentMember',
    data() {
        return {
            showSuccess: false,
            isError: false,
            isProcessing: false,
            errorMessage: '',

            memberId: '',
            memberEmail: '',
            validEmail: null,

            toEmail: '',
        }
    },
    methods: {
        submit: async function() {
            this.isProcessing = true;

            if (!!this.memberEmail && !validateEmail(this.memberEmail)) {
                this.isProcessing = false;
                (this.validEmail as any) = false;
                return;
            }

            const data = this.$store.state.parentVerification;

            const response = await authenticationService.sendActivationRequest({
                email: this.memberEmail,
                memberId: this.memberId,
                juniorId: data.juniorId,
                firstName: data.firstName,
                lastName: data.lastName,
            });

            this.isProcessing = false;
            this.errorMessage = '';

            if (response.sent) {
                this.showSuccess = true;
                this.toEmail = response.to;
            } else this.errorMessage = response.errors ? response.errors.join(',') : 'An Unknown Error Occured';
        },
        returnHome() {
            this.showSuccess = false;
            this.$router.push('/login');
        },
        isValid() {
            return !!this.memberId || this.validEmail;
        },
    },
    watch: {
        memberEmail: {
            handler: debounce(
                500,
                function (this: any, newVal: string, oldVal: string) {
                    if (newVal === oldVal || !newVal) return;

                    if (!newVal) this.validEmail = null;
                    else this.validEmail = validateEmail(newVal);
                }
            ),
            deep: true,
        },
    }
});
