
import Vue from "vue";
import CreateAffiliateRequest from "@/components/Portal/MyRequest/CreateAffiliateRequest.vue";
import RegistrationRequestLayout from "@/components/common/RegistrationRequestLayout.vue";
import workflowService from "@/services/admin/workflowService";

export default Vue.extend({
  name: "CreateAffiliateCard",
  props: {
    requestId: { type: Number },
  },
  components: {
    RegistrationRequestLayout,
    CreateAffiliateRequest,
  },
  data: function () {
    return {
      registration: {
        workflowGuid: "",
        requestId: 0,
      } as any,
    };
  },
  computed: {
    workflowService() {
      return workflowService;
    },
  },
  methods: {
    onRequestProcessed: function (requestId: number) {
      this.$router.push({ path: `/admin/registration/affiliate` });
    },
  },
});
