
import Vue from "vue";
import RegisterKennelForm1 from "./Steps/RegisterKennelForm1.vue";
import RegisterKennelForm2 from "./Steps/RegisterKennelForm2.vue";

import workflowService from "@/services/portal/workflowService";
import { submitKennelRegistration } from "@/services/portal/registerKennelService";

import { UserInfo } from "@/types/User";
import AuthorizeNetResponseData from "@/types/AuthorizeNetResponseData";
import { SubmitKennelRequest, CoOwnerResult } from "@/types/portal/Registration";

const registerDogImage = require('@/assets/register-dog.png');

export default Vue.extend({
  name: "RegisterKennel",
  components: {
    RegisterKennelForm1,
    RegisterKennelForm2,
  },
  data: function () {
    return {
      registerDogImage,
      currentPage: 1,
      coOwnerInfo: [] as CoOwnerResult[],
      kennelNames: { firstChoice: "", secondChoice: "" },
      signature: "",
      registrationRequestStatus: { message: "", status: "" },
      priceInfo: { fullMemberPrice: 0, serviceMemberPrice: 0 },
      price: 0,
    };
  },
  methods: {
    changePage(page: number) {
      this.currentPage = page;
    },
    changeCoOwner(coOwnerInfo: CoOwnerResult[]) {
      this.coOwnerInfo = coOwnerInfo;
    },
    changeKennelNames(firstChoice: string, secondChoice: string) {
      this.kennelNames.firstChoice = firstChoice;
      this.kennelNames.secondChoice = secondChoice;
    },
    updateSignature(signature: string) {
      this.signature = signature;
    },
    sendRegistrationRequest(paymentInfo: AuthorizeNetResponseData) {
      let req = <SubmitKennelRequest>{
        paymentInfo,
        firstName: this.kennelNames.firstChoice,
        secondName: this.kennelNames.secondChoice,
        primaryOwnerPersonId: this.userInfo.personId,
        primaryOwnerSignatureText: this.signature,
        coOwnersPersonIds: this.coOwnerInfo.map((co) => co.personId),
        fee: this.price,
      };
      submitKennelRegistration(req).then((res) => {
        this.registrationRequestStatus = res;
      });
    },
  },
  created() {
    workflowService.getRegisterKennelFees().then((res) => {
      if (!isNaN(res)) {
        this.$data.priceInfo = { fullMemberPrice: res, serviceMemberPrice: res };
        this.$data.price = res;
      } else {
        this.$data.priceInfo = res;
      }
    });
  },
  computed: {
    searchMemberInfo() {
      return this.$store.state.searchMemberInfo;
    },
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
});
