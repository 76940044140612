
import Vue from "vue";
import Datepicker from "../../common/inputs/DatepickerFormatted.vue";

import memberService from "@/services/admin/memberService";

import { Messages } from "@/constants";
import { MemberType } from "@/types/Member";
import { AdminMemberType } from "@/types/admin/Member";
import { AdminMemberInfo } from '@/types/admin/Person';

export default Vue.extend({
  name: "MemberDetails",
  props: {
    personId: { type: Number },
  },
  components: {
    Datepicker,
  },
  data: function () {
    return {
      memberInfo: {} as AdminMemberInfo,
      isEditMode: false,
      showValidationDialog: false,
      validationResult: {} as any,
      memberTypes: [] as AdminMemberType[],
      selectedMemberType: "",
      possibleMemberTypes: [] as string[],
      reloadPage: false,
      errorMessage: "",
      infoMessage: "",
      showErrorMessage: false,
      showInfoMessage: false,
      searchId: "",
      autofillAddress: false,
      searchInfo: {
        found: null,
        error: "",
        data: null,
      },
      canLinkToAnotherMember: false,
      showSearchLinkableMembersDialog: false,
      paymentHeaders: [
        { text: "Member ID", align: "left", value: "memberId" },
        { text: "Dollar Amount", align: "left", value: "transactionAmount" },
        { text: "Captured", align: "left", value: "captured" },
        { text: "Authorized", align: "left", value: "authorized" },
        { text: "Paid At", align: "left", value: "paidAt" },
        { text: "Request Type", align: "left", value: "requestType" },
        { text: "Notes", align: "left", value: "notes" },
      ],
    };
  },
  computed: {
    formattedMemberInfo(): AdminMemberInfo {
      const data = { ...this.memberInfo };

      switch (this.memberInfo.membershipType) {
        case MemberType.Lifetime:
        case MemberType.LifetimeDual:
          if (!!data.membershipExpiryDate) data.membershipExpiryDate = null;
          break;
        case MemberType.Service:
        if (!!data.aussieTimesSubscriptionExpiryDate) data.aussieTimesSubscriptionExpiryDate = null;
          break;
      }

      return data;
    }
  },
  created: function () {
    if (this.personId) {
      memberService
        .getMemberByPersonId(this.personId)
        .then((res: AdminMemberInfo) => {
          if (res) {
            this.memberInfo = res;
            if (res.memberId) {
              this.selectedMemberType =
                this.memberInfo.membershipTypeDescription;
              this.reloadPage = false;
            } else {
              const today = new Date().toISOString() as any;
              const expirationDate = new Date();
              expirationDate.setDate(new Date().getDate() + 365);

              this.memberInfo.consecutiveMembershipStartDate = today;
              this.memberInfo.membershipStartDate = today;
              this.memberInfo.membershipPostDate = today;
              this.memberInfo.membershipExpiryDate =
                expirationDate.toISOString() as any;

              this.isEditMode = true;
              this.reloadPage = true;
            }
          }
        })
        .catch((e) => {
          this.memberInfo = {} as AdminMemberInfo;
        });

      this.possibleMemberTypes.push("");
      memberService.getMembershipTypes().then((res: any) => {
        this.memberTypes = res;
        for (let i = 0; i < res.length; i++) {
          this.possibleMemberTypes.push(res[i].membershipTypeDescription);
        }
      });
    } else {
      this.errorMessage = "Invalid data.";
    }
  },
  watch: {
    personId: function (newVal: String) {
      memberService.getMemberByPersonId(this.personId).then((res) => {
        this.memberInfo = res;
        this.selectedMemberType = this.memberInfo.membershipTypeDescription;
      });
    },
    selectedMemberType: function (newValue: String) {
      switch (newValue) {
        case 'Dual':
        case 'LifetimeDual':
          this.canLinkToAnotherMember = true;
          break;
        default:
          this.canLinkToAnotherMember = false;
          break;
      }
    },
  },
  methods: {
    requestTypeText(type: number) {
      switch (type) {
        case 1:
          return "Registration";
        case 2:
          return "Renewal";
        case 3:
          return "Aussie Times Renewal";
        default:
          return "Unknown Request Type";
      }
    },
    onSearchLinkableMember: async function () {
      const response = await memberService.searchLinkableMember({ memberId: this.searchId });
      this.searchInfo = response;
    },
    onLinkMember: async function () {
      this.showSearchLinkableMembersDialog = false;

      const response = await memberService.linkToMember({ personId: this.personId, memberId: this.searchId, autofillAddress: this.autofillAddress });

      if (response.success)
      {
        this.searchId = "";
        this.autofillAddress = false;
        this.searchInfo = { found: null, error: "", data: null };
        window.location.reload();
      } else if (response.errorMessage) this.searchInfo.error = response.errorMessage;
    },
    reloadData: function () {
      if (this.personId) {
        memberService.getMemberByPersonId(this.personId).then((res) => {
          this.memberInfo = res;
          this.selectedMemberType = this.memberInfo.membershipTypeDescription;
        });
      } else {
        this.memberInfo = {} as AdminMemberInfo;
        this.isEditMode = true;
      }
      this.showValidationDialog = false;
      this.errorMessage = "";
      this.showErrorMessage = false;
      this.validationResult = {};
    },
    saveChanges: function () {
      this.errorMessage = "";
      this.showErrorMessage = false;
      this.showValidationDialog = false;
      this.$store.dispatch("changeLoaderStatus", true);
      memberService
        .editMember(this.formattedMemberInfo)
        .then((res) => {
          if (res.success) {
            this.reloadData();
            if (this.reloadPage) {
              window.location.reload();
            }

            this.infoMessage = Messages.SaveSuccessful;
            this.showInfoMessage = true;
          } else {
            this.errorMessage =
              Messages.SaveError + " " + res.messages.join(", ");
            this.showErrorMessage = true;
          }
        })
        .catch((e) => {
          this.errorMessage = Messages.SaveError;
          this.showErrorMessage = true;
        })
        .finally(() => this.$store.dispatch("changeLoaderStatus", false));
    },
    updateDates: function (val: any, key: string) {
      this.$data.memberInfo[key] = val?.date;

      if (this.isEditMode && key === "membershipStartDate") {
        let expDate = null;
        let ausExpDate = null;

        if (val.date) {
          const startDate = new Date(val.date);
          const expirationDate = new Date(val.date);
          expirationDate.setDate(startDate.getDate() + 365);

          expDate = expirationDate.toISOString() as any;

          switch (this.selectedMemberType) {
            case "Service":
              ausExpDate = null;
              break;
            case "Junior":
              ausExpDate = startDate.toISOString() as any;
              break;
            default:
              ausExpDate = expirationDate.toISOString() as any;
              break;
          }
        }

        this.$data.memberInfo.membershipExpiryDate = expDate;
        this.$data.memberInfo.aussieTimesSubscriptionExpiryDate = ausExpDate;
      }

      this.$data.memberInfo = Object.assign({}, this.$data.memberInfo);
    },
    updateText: function (key: string, event: string) {
      this.$data.memberInfo[key] = event;
    },
    toggleEditMode: function () {
      if (this.isEditMode) {
        this.validateAndConfirmSavingChanges();
      }
      this.isEditMode = !this.isEditMode;
    },
    validateAndConfirmSavingChanges: function () {
      this.errorMessage = "";
      this.showErrorMessage = false;
      if (!this.validateMemberType() || !this.validateFields()) {
        this.isEditMode = false; //this will toggle it to true
        return false;
      }
      memberService.validateMemberEdit(this.formattedMemberInfo).then((res: any) => {
        this.validationResult = res;
        if (
          this.validationResult.errors.length > 0 ||
          this.validationResult.warnings.length > 0
        ) {
          this.showValidationDialog = true;
        } else {
          this.saveChanges();
          this.validationResult = {};
          this.isEditMode = false; //this will toggle it to true
        }
      });
    },
    validateMemberType: function () {
      if (this.selectedMemberType == "") {
        this.errorMessage = "Please select a member type";
        this.showErrorMessage = true;
        return false;
      }
      var memberTypeSelected = this.memberTypes.find(
        (x) => x.membershipTypeDescription === this.selectedMemberType
      );
      if (memberTypeSelected) {
        this.memberInfo.membershipType = memberTypeSelected.membershipType;
        this.memberInfo.membershipTypeDescription =
          memberTypeSelected.membershipTypeDescription;
      } else {
        this.errorMessage = "Please select a member type";
        this.showErrorMessage = true;
        return false;
      }

      return true;
    },
    validateFields() {
      if (
        this.memberInfo.membershipExpiryDate == null &&
        this.selectedMemberType != "Lifetime" &&
        this.selectedMemberType != "Lifetime Dual"
      ) {
        this.errorMessage = "Member Expiration Date is required.";
        this.showErrorMessage = true;
        return false;
      }
      return true;
    },
    generateMemberId: function () {
      if (this.validateMemberType()) {
        memberService
          .generateMemberId({ membershipType: this.memberInfo.membershipType })
          .then((res: any) => {
            this.memberInfo.memberId = res.id;
            if (res.success && res.id) {
              this.memberInfo.memberId = res.id;
              this.errorMessage = "";
              this.showErrorMessage = false;
            } else {
              this.errorMessage = "Could not generate new ID.";
              this.showErrorMessage = true;
            }
          });
      }
    },
    changeExpirationDate() {
      if (this.isEditMode) {
        let startDate = new Date();

        if (this.$data.memberInfo.membershipStartDate)
          startDate = new Date(this.$data.memberInfo.membershipStartDate);

        const expirationDate = new Date(startDate);
        expirationDate.setDate(startDate.getDate() + 365);

        switch (this.selectedMemberType) {
          case "Lifetime":
          case "Lifetime Dual":
            this.$data.memberInfo.membershipExpiryDate = null;
            this.$data.memberInfo.aussieTimesSubscriptionExpiryDate =
              expirationDate.toISOString() as any;
            break;
          case "Service":
            if (this.$data.memberInfo.membershipExpiryDate == null)
              this.$data.memberInfo.membershipExpiryDate =
                expirationDate.toISOString() as any;

            this.$data.memberInfo.aussieTimesSubscriptionExpiryDate = null;
            break;
          case "Junior":
            if (this.$data.memberInfo.membershipExpiryDate == null)
              this.$data.memberInfo.membershipExpiryDate =
                expirationDate.toISOString() as any;

            this.$data.memberInfo.aussieTimesSubscriptionExpiryDate =
              startDate.toISOString() as any;
            break;
          default:
            if (this.$data.memberInfo.membershipExpiryDate == null)
              this.$data.memberInfo.membershipExpiryDate =
                expirationDate.toISOString() as any;

            this.$data.memberInfo.aussieTimesSubscriptionExpiryDate =
              expirationDate.toISOString() as any;
            break;
        }
      }
    },
  },
});
