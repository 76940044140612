
import Vue from "vue";
import DatepickerFormatted from "../../../common/inputs/DatepickerFormatted.vue";

import showService from "@/services/admin/showService";

import { UserInfo } from "@/types/User";
import { Messages } from "@/constants";
import { ShowInfo, ShowType, ShowAffiliateInfo, ShowPerson, JudgeScheduleInfo, PointSchedule } from '@/types/admin/Show';

export default Vue.extend({
    name: "ShowProcessing",
    components: {
        DatepickerFormatted,
    },
    data: function () {
        return {
            tab: null,
            showInfo: {
                showId: "",
                clubId: "",
                showType: {} as ShowType,
                showCoordinator: {
                } as ShowPerson, showSecretary: {} as ShowPerson,
                sponsoringAffiliate: {} as ShowAffiliateInfo, judgeSchedules: [] as JudgeScheduleInfo[],
            } as ShowInfo,
            pointSchedules: [] as PointSchedule[],
            pointScheduleIds: [] as any[],
            isEditMode: false,
            isValid:true,
            showValidationDialog: false,
            validationResult: {
                warnings: [],
                errors: []
            } as any,
            reloadPage: false,
            showTypes: [] as ShowType[],
            showTypeDescriptions: [] as string[],
            showTypeIds: [] as string[],
            isLoading: false,
            calculatedValue:"0.00",
            errorMessage: '',
            infoMessage: '',
            showErrorMessage: false,
            showInfoMessage: false
        };
    },
    methods: {
        reloadData: function () {
            if (this.showId) {
                showService
                    .getShowById(this.showId)
                    .then((res) => {
                        this.showInfo = res;
                        this.errorMessage = '';
                        this.showErrorMessage = false;
                    });
            }
        },
        calculateTotalFees: function () {
            if (!this.showInfo) {
                return;
            }
            if (this.showInfo.totalEntries == null) {
                return;
            }
            var feeAmount = Number(this.showInfo.fees);
            this.showInfo.totalFees = (feeAmount * this.showInfo.totalEntries).toFixed(2).toString();
        },
        toggleEditMode: function () {
            if (this.isEditMode) {
                this.saveChanges();
            }

            this.isEditMode = !this.isEditMode;
        },
        saveChanges: function () {
            this.errorMessage = "";
            this.$store.dispatch("changeLoaderStatus", true);
            showService
                .editShowProcessing(this.showInfo)
                .then((res) => {
                    if (res.success) {
                        if (res.id && res.id != this.$props.showId) {
                            this.$router.push({ path: `/admin/shows/${res.id}` }).catch(() => { });
                        }
                        this.reloadData();
                        this.isEditMode = false; 
                        this.infoMessage = Messages.SaveSuccessful;
                        this.showInfoMessage = true;
                    }
                    else {
                        this.errorMessage = Messages.SaveError + " " + res.messages.join(', ');
                        this.showErrorMessage = true;
                    }
                }).catch(e => {
                    this.errorMessage = Messages.SaveError + " " + "Please enter valid numbers";
                    this.isEditMode = true;
                })
                .finally(() => this.$store.dispatch("changeLoaderStatus", false));
        },
        isValidNumber(value: string) {
            if (!value) {
                return true;
            }
            if (!isNaN(parseFloat(value))) return true;

            return 'Must be valid number';

        },
        updateDates: function (val: any, key: string) { 
            this.$data.showInfo[key] = val?.date;
            this.$data.showInfo = Object.assign({}, this.$data.showInfo);
        },
        updateText: function (key: string, event: string) {
            this.$data.showInfo[key] = event;
        },

        
    },
    created: function () {
        this.reloadData();
    },
    watch: {
        showId: function (newVal: String) {
            this.reloadData();             
        },
        
    },
    props: {
        showId: { type: String },
    },
    computed: {
        userInfo(): UserInfo {
            return this.$store.state.userInfo;
        },
        
    },
});
