
import Vue from 'vue';
import { debounce } from "throttle-debounce";

import validateEmail from '@/helpers/validate-email';

export default Vue.extend({
  name: 'UniqueEmailDialog',
  props: {
    showDialog: {type: Boolean},
    onClose: {type: Function},
    onCheck: {type: Function},
    onRecover: {type: Function},
    email: {type: String},
    recoverable: {type: Boolean},
    loggedIn: {type: Boolean},
  },
  data() {
    return {
      newEmail: '',
      validEmail: null,
    }
  },
  methods: {
    internalOnCheck() {
      if (!validateEmail(this.newEmail)) {
        (this.validEmail as any) = false;
        return;
      }

      this.onCheck(this.newEmail);
    },
  },
  watch: {
    newEmail: {
      handler: debounce(
        500,
        function (this: any, newVal: string, oldVal: string) {
          if (newVal === oldVal || !newVal) return;

          if (!newVal) this.validEmail = null;
          else this.validEmail = validateEmail(newVal);
        }
      ),
      deep: true,
    },
  }
});
