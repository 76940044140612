
import Vue from "vue";
import { UserInfo } from "@/types/User";
export default Vue.extend({
  name: "AussieTimes",
  data: function () {
    return {
      links: [
        "https://www.yumpu.com/en/embed/view/R14w0hqVBcF9leWo",
        "https://www.yumpu.com/en/embed/view/b5jkG2ZmIuUQSqtC",
        "https://www.yumpu.com/en/embed/view/tZnhYI01qkrjeDwB",
        "https://www.yumpu.com/en/embed/view/EAQCfuDI0tG7lqHa",
        "https://www.yumpu.com/en/embed/view/gCf6vmkdDRXbUc84",
        "https://www.yumpu.com/en/embed/view/Krma61fn5Mp4TcDv",
      ],
    };
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.state.userInfo;
    },
  },
});
